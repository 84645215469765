import React, { useEffect, useRef, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import footerImg from '../../assets/footer_placeholder_transparent.png';
import ContestCard from '../../components/Contest/ContestCard/ContestCard';
import ContestRow from '../../components/Contest/ContestRow/ContestRow';
import ContestRowBig from '../../components/Contest/ContestRowBig/ContestRowBig';
import { Button, Modal } from 'react-bootstrap';
import {
  getContests,
  getLayoutContest,
  getContestPageNumber,
  getFooterMenu,
  getHeaderMenu,
  getCurrentUser,
  getContestsFiltered,
  getAllCoCreationContests,
  getContestCategories,
} from '../../store/Selectors';
import DslStore, {
  fetchContest,
  setLayoutContest,
  setContestPageNumber,
  fetchCoCreationContest,
  setCategories,
} from '../../store/DslStore';
import { useStoreState } from 'pullstate';
import {
  choseUserName,
  numberVotes,
  registrationDateString,
  setTimelines,
  startDateString,
  typeCategoryString,
} from '../../helpers/utils';
import ContestsNavigation, { PaginationSwitch } from '../../components/Contest/ContestsNavigation/ContestsNavigation';
import { Award, CoCreationContest, Contest, ContestStatus, ItemLiked } from '../../global';
import { NoContent } from '../../components/Reusable/NoContent';
import PopUpRedirectContest from '../../components/PopUp/PopUpRedirectContest/PopUpRedirectContest';
import { useNavigate } from 'react-router-dom';

// const mockGalleryImages = [
//   'https://source.unsplash.com/_HqHX3LBN18/800x680',
//   'https://source.unsplash.com/OzqieLcs464/800x680',
//   'https://source.unsplash.com/RFP4D5hGTB0/800x680',
//   'https://source.unsplash.com/rvftn4hX7AY/800x680',
//   'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
// ];

// const mockGalleryImagesHalf = [
//   'https://source.unsplash.com/OzqieLcs464/800x680',
//   'https://source.unsplash.com/rvftn4hX7AY/800x680',
//   'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
// ];

const ContestList: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const cocreationcontests = useStoreState(DslStore, getAllCoCreationContests);
  const contestCategories = useStoreState(DslStore, getContestCategories);
  const allContests = useStoreState(DslStore, getContests);
  const sortedContests = useStoreState(DslStore, getContestsFiltered);
  const layoutContest = useStoreState(DslStore, getLayoutContest);
  const pageNumber = useStoreState(DslStore, getContestPageNumber);
  const [popup, setPopup] = useState(false);
  const [popupLink, setPopupLink] = useState('');
  const [linkToDescription, setLinkToDescription] = useState('');
  const date = new Date().getTime();
  const contestEndDate = useRef('');
  const endvotingdate = useRef('');
  const redirect = useNavigate();

  const [visibleContests, setVisibleContests] = useState<Contest[]>([]);

  const arraysfill = () => {
    let chunkSize = 20;
    let tempArray: Contest[] = [];
    let ongoing: number = 0;
    sortedContests.map((item) => {
      if (checkStatus(item) === 'ongoing') {
        ongoing++;
      }
    });
    console.log(ongoing % 4);
    let startchunk = (pageNumber - 1) * chunkSize;
    const chunk: Contest[] =
      pageNumber === 1
        ? sortedContests.slice(startchunk, startchunk + (chunkSize + (ongoing % 4)))
        : sortedContests.slice(startchunk + (ongoing % 4), startchunk + (chunkSize + (ongoing % 4)));

    // for (let i = 0; i < sortedContests.length; i += chunkSize + (ongoing % 4)) {
    //   const chunk: Contest[] = sortedContests.slice(startchunk, startchunk + (chunkSize + (ongoing % 4)));
    // }
    if (pageNumber === 1 && ongoing < 20) {
      for (let i = 0; i < chunkSize + (ongoing % 4); i++) {
        if (chunk[i] !== undefined) {
          tempArray.push(chunk[i]);
        } else {
          break;
        }
      }
    } else {
      for (let i = 0; i < chunkSize; i++) {
        if (chunk[i] !== undefined) {
          tempArray.push(chunk[i]);
        } else {
          break;
        }
      }
    }
    setVisibleContests(tempArray);
  };

  useEffect(() => {
    // console.log('sono dentro contest');
    // console.log(allContests);
    setContestPageNumber(1);
    arraysfill();
    // fetchContestMini.run().then((data) => {
    //   console.log(data);
    // });
    // fillContests((pageNumber -1));
    if (!allContests || !sortedContests) {
      fetchContest.run().then((data) => {
        // console.log(data);
      });
    }
    arraysfill();
  }, []);

  useEffect(() => {
    setVisibleContests(sortedContests);
    arraysfill();
  }, [sortedContests]);

  useEffect(() => {
    // console.log(pageNumber);
    setVisibleContests(sortedContests);
    // fillContests(pageNumber -1);
    arraysfill();
  }, [pageNumber]);

  const handleClick = (e: any) => {
    let url: string = e.split('/')[2];
    let linkto = e.split('/')[3];
    console.log(popup);
    console.log(e);
    console.log(linkto);
    console.log(url);
    console.log(linkToDescription);
    // console.log(e.split('/'[2]));
    if (url.includes('bit.ly')) {
      // console.log(url);
      // setPopupLink(e);
      // setPopup(true);
      // redirect(e);
      // console.log(linkto);
      //redirect hidden menu
    } else {
      setPopupLink(e);
      setPopup(true);
    }
  };

  const getCorrectEndDate = (contest: Contest) => {
    // console.log('correctDate');

    const date = new Date().getTime();

    // console.log('GET CORRECT END DATE : ');
    if (contest) {
      if (contest?.contestType === 'Co-creation') {
        let contestAttached: CoCreationContest[] = [];
        // console.log('GET CORRECT END DATE : CO-CREATION');
        cocreationcontests.map((item) => {
          if (contest?.id === item.mainContest.id) {
            contestAttached.push(item);
          }
        });
        // console.log('GET CORRECT END DATE : contestattached fatto' + JSON.stringify(contestAttached));
        contestAttached.map((item) => {
          // console.log(item);
          if (item.endVotingDate) {
            endvotingdate.current = item.endVotingDate;
          }
          if (!item.isClosed) {
            if (date > new Date(item.startUploadDate).getTime() && date < new Date(item.endUploadDate).getTime()) {
              // console.log('CO-CREATION UPLOAD');
              contestEndDate.current = `Ends ${registrationDateString(item.endUploadDate)}`;
              setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
              return;
            } else if (item.endVotingDate) {
              if (date > new Date(item.endUploadDate).getTime() && date < new Date(item.endVotingDate).getTime()) {
                // console.log(date);
                // console.log(new Date(item.endUploadDate).getTime());
                // console.log(new Date(item.endVotingDate).toLocaleDateString());
                // console.log(new Date(item.endVotingDate).getTime());
                // console.log('CO-CREATION COMMUNITY');
                setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
              } else if (
                date > new Date(item.endUploadDate).getTime() &&
                date > new Date(item.endVotingDate).getTime()
              ) {
                // console.log('CO-CREATION CLIENT');
                setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
                contestEndDate.current = `Ends: ${registrationDateString(item.hypotheticalEndDate)}`;
              }
            } else if (date > new Date(item.endUploadDate).getTime()) {
              // console.log('CO-CREATION CLIENT VOTE');
              setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
              contestEndDate.current = `Ends: ${registrationDateString(item.hypotheticalEndDate)}`;
            }
          } else {
            // console.log('CO-CREATION CLOSED');
            contestEndDate.current = `Closed: ${registrationDateString(item.endDate)}`;
          }
        });
      } else {
        if (contest.endVotingDate) {
          endvotingdate.current = contest.endVotingDate;
        }
        // console.log('GET CORRECT END DATE : NORMAL CONTEST');
        if (date < new Date(contest.endDate).getTime() && !contest.isClosed) {
          // console.log('GET CORRECT END DATE : UPLOAD PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.endDate)}`;
          return;
        } else if (contest.endVotingDate && date < new Date(contest.endVotingDate).getTime() && !contest.isClosed) {
          // console.log('GET CORRECT END DATE : COMMUNITY VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.endVotingDate)}`;
          return;
        } else if (
          (contest.endVotingDate &&
            date > new Date(contest.endVotingDate).getTime() &&
            date < new Date(contest.hypotheticalEndDate).getTime() &&
            !contest.isClosed) ||
          (date > new Date(contest.endDate).getTime() &&
            date < new Date(contest.hypotheticalEndDate).getTime() &&
            !contest.isClosed)
        ) {
          // console.log('GET CORRECT END DATE : CLIENT VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.hypotheticalEndDate)}`;
          return;
        } else {
          console.log('GET CORRECT END DATE : CLOSED PHASE');
          contestEndDate.current = `Closed: ${registrationDateString(
            contest.assignmentDate ? contest.assignmentDate : contest.endDate
          )}`;
          return;
        }
      }
      // return contest?.endDate;
    }
  };

  const statusText = (contest: Contest) => {
    // console.log(date);
    // console.log(endDate);

    // console.log(date > endDate);
    if (contest.contestType !== 'Co-creation') {
      if (contest.isClosed && contest.endVotingDate) {
        return `closed: ${startDateString(contest.assignmentDate ? contest.assignmentDate : contest.endVotingDate)}`;
      } else if (contest.isClosed && contest.endDate) {
        return `closed: ${startDateString(contest.assignmentDate ? contest.assignmentDate : contest.endDate)}`;
      } else if (date < new Date(contest.startDate).getTime()) {
        return 'coming soon';
      } else if (date < new Date(contest.endDate).getTime()) {
        return `ends:  ${startDateString(contest.endDate)}`;
      } else if (contest.endVotingDate && date < new Date(contest.endVotingDate).getTime()) {
        return `community vote`;
        // console.log(endVotingDate);
        // console.log(date > endVotingDate);
      } else if (contest.endVotingDate && date > new Date(contest.endVotingDate).getTime() && !contest.isClosed) {
        return `client vote`;
      } else if (date > new Date(contest.endDate).getTime() && !contest.isClosed) {
        return `client vote`;
      }
    } else {
      const contestAttached: CoCreationContest[] = [];
      let stat = 'ongoing';
      cocreationcontests.map((item: CoCreationContest) => {
        if (item.mainContest.id === contest.id) {
          contestAttached.push(item);
        }
      });

      contestAttached.map((item: CoCreationContest, index: number) => {
        // console.log(item);
        // console.log(item);
        // console.log(stat);
        // console.log(contestAttached.length);

        if (date < new Date(item.startUploadDate).getTime()) {
          stat = 'coming-soon';
        } else if (date > new Date(item.startUploadDate).getTime() && date < new Date(item.endUploadDate).getTime()) {
          stat = `Ends: ${startDateString(item.endUploadDate)}`;
        } else {
          if (item.endVotingDate) {
            if (date < new Date(item.endVotingDate).getTime()) {
              stat = 'community-vote';
            } else {
              if (item.hypotheticalEndDate) {
                if (
                  date > new Date(item.endVotingDate).getTime() &&
                  date < new Date(item.hypotheticalEndDate).getTime() &&
                  !item.isClosed
                ) {
                  stat = 'client-vote';
                }
              } else {
                if (date > new Date(item.endVotingDate).getTime() && !item.isClosed) {
                  stat = 'client-vote';
                }
              }
            }
          } else {
            if (item.hypotheticalEndDate) {
              if (
                date > new Date(item.endUploadDate).getTime() &&
                date < new Date(item.hypotheticalEndDate).getTime() &&
                !item.isClosed
              ) {
                stat = 'client-vote';
              } else {
                if (
                  date > new Date(item.endUploadDate).getTime() &&
                  date > new Date(item.hypotheticalEndDate).getTime()
                ) {
                  stat = 'closed';
                }
              }
            } else {
              if (date > new Date(item.endUploadDate).getTime()) {
                stat = 'client-vote';
              }
            }
          }
          if (item.isClosed) {
            stat = 'client-vote';
          }
        }

        if (item.endVotingDate && date < new Date(item.endVotingDate).getTime()) {
          stat = 'community-vote';
        } else if (item.endVotingDate && date > new Date(item.endVotingDate).getTime() && !item.isClosed) {
          stat = 'client-vote';
        } else if (
          date > new Date(item.endVotingDate).getTime() &&
          date < new Date(item.hypotheticalEndDate).getTime() &&
          !item.isClosed
        ) {
          stat = 'client-vote';
        } else if (
          date > new Date(item.endUploadDate).getTime() &&
          date < new Date(item.hypotheticalEndDate).getTime() &&
          !item.isClosed
        ) {
          stat = 'client-vote';
        } else if (item.isClosed) {
          stat = 'closed';
        }
      });
      return stat;
    }
    return `standby`;
  };

  const checkStatus = (contest: Contest) => {
    // console.log(date);
    // console.log(endDate);
    if (contest.contestType !== 'Co-creation') {
      // console.log(date > endDate);
      if (contest.isClosed) {
        return 'closed';
      } else if (date < new Date(contest.startDate).getTime()) {
        return 'coming-soon';
      } else if (date < new Date(contest.endDate).getTime()) {
        return 'ongoing';
      } else if (contest.endVotingDate && date < new Date(contest.endVotingDate).getTime()) {
        return 'community-vote';
        // console.log(endVotingDate);
        // console.log(date > endVotingDate);
      } else if (contest.endVotingDate && date > new Date(contest.endVotingDate).getTime() && !contest.isClosed) {
        return 'client-vote';
      } else if (
        date > new Date(contest.endDate).getTime() &&
        date < new Date(contest.hypotheticalEndDate).getTime() &&
        !contest.isClosed
      ) {
        return 'client-vote';
      }
      return 'standby';
    } else {
      const contestAttached: CoCreationContest[] = [];
      let stat: ContestStatus = 'ongoing';
      cocreationcontests.map((item: CoCreationContest) => {
        if (item.mainContest.id === contest.id) {
          contestAttached.push(item);
        }
      });

      contestAttached.map((item: CoCreationContest, index: number) => {
        // console.log(item);
        // console.log(item);
        // console.log(stat);
        // console.log(contestAttached.length);
        if (stat !== 'closed' && index < contestAttached.length)
          if (item.isClosed) {
            stat = 'closed';
          } else if (date < new Date(item.startUploadDate).getTime()) {
            stat = 'coming-soon';
          } else if (date < new Date(item.endUploadDate).getTime()) {
            stat = 'ongoing';
          } else if (item.endVotingDate && date < new Date(item.endVotingDate).getTime()) {
            stat = 'community-vote';
          } else if (item.endVotingDate && date > new Date(item.endVotingDate).getTime() && !item.isClosed) {
            stat = 'client-vote';
          } else if (
            date > new Date(item.endVotingDate).getTime() &&
            date < new Date(item.hypotheticalEndDate).getTime() &&
            !item.isClosed
          ) {
            stat = 'client-vote';
          }
      });
      return stat;
    }
  };

  const goTo = (link?: string) => {
    if (link) window.open(link, '_blank');
  };

  useEffect(() => {
    if (cocreationcontests.length === 0) {
      fetchCoCreationContest.run();
    }

    if (contestCategories) setCategories.run();
  }, []);

  //FIXME FIXME FIXME CONTEST LIKED

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#C3DE72"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Contest"
        breadcrumbs={'Home/Contest'}
      />
      {/* <PageNavigation bgColor="#E3E3E3" links={topNavPages} /> */}
      <main className="container-md ps-md-1  px-sm-5 px-md-0  mb-5">
        <div className="row ">
          <div className="col-12 px-xl-4 px-lg-3 mb-3 ">
            <ContestsNavigation onChangeLayout={setLayoutContest} />
          </div>
        </div>

        {/* <Modal  show={popup} onHide={() => setPopup(!popup)} className={`d-flex flex-column justify-content-center`}>
          <div className="d-flex flex-column justify-content-center text-center">
            <Modal.Header className="d-flex justify-content-center">
              <h3>
                <b> You are leaving desall.com </b>
              </h3>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
              <h5>You will be redirected to the Artsana platform innovation.artsana.com</h5>
            </Modal.Body>
          </div>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button className="btn-red " onClick={() => setPopup(!popup)}>
              Close
            </Button>
            <a href={popupLink} target="_self">
              <Button className="btn-success" onClick={() => setPopup(!popup)}>
                Continue
              </Button>
            </a>
          </Modal.Footer>
        </Modal> */}

        <PopUpRedirectContest
          popup={popup}
          description={linkToDescription}
          close={() => setPopup(false)}
          link={popupLink}
        />

        <div className="row mt-lg-3 px-sm-2 px-lg-3 px-xl-4 ">
          {visibleContests?.map(
            (aContest: Contest, index: number) =>
              // console.log(aContest),
              // console.log(checkStatus(aContest.isClosed, aContest.startDate, aContest.endDate, aContest.endVotingDate)),
              (checkStatus(aContest) === 'ongoing' || checkStatus(aContest) === 'coming-soon') &&
              layoutContest === 1 &&
              aContest.isPublished &&
              (getCorrectEndDate(aContest),
              (
                // console.log(aContest),
                <div className=" px-sm-1 px-md-1">
                  {aContest.linkToUrl && aContest.linkToUrl.includes('bit.ly') ? (
                    <a href={aContest.linkToUrl}>
                      <ContestRowBig
                        key={index}
                        contest={aContest}
                        image={aContest?.image}
                        contestTitle={aContest?.name}
                        entries={aContest?.entries}
                        award={aContest?.prices}
                        category={aContest.category}
                        endDate={startDateString(aContest?.endDate)}
                        clientName={choseUserName(aContest)}
                        private={aContest.isPrivate}
                        accesscode={aContest.accessCode}
                        nda={aContest.nda}
                        nameforurl={aContest.nameForUrl}
                        statusText={contestEndDate.current}
                        status={checkStatus(aContest)}
                        progress={60}
                        contestType={aContest.contestType}
                        linkToAward={aContest.linkToAward}
                        linkToUrl={aContest.linkToUrl}
                        click={(e) => (handleClick(e), setLinkToDescription(aContest.textDescription))}
                      ></ContestRowBig>
                    </a>
                  ) : (
                    <ContestRowBig
                      key={index}
                      contest={aContest}
                      image={aContest?.image}
                      contestTitle={aContest?.name}
                      entries={aContest?.entries}
                      award={aContest?.prices}
                      category={aContest.category}
                      endDate={startDateString(aContest?.endDate)}
                      clientName={choseUserName(aContest)}
                      private={aContest.isPrivate}
                      accesscode={aContest.accessCode}
                      nda={aContest.nda}
                      nameforurl={aContest.nameForUrl}
                      statusText={contestEndDate.current}
                      status={checkStatus(aContest)}
                      progress={60}
                      contestType={aContest.contestType}
                      linkToAward={aContest.linkToAward}
                      linkToUrl={aContest.linkToUrl}
                      click={(e) => (handleClick(e), setLinkToDescription(aContest.brief))}
                    ></ContestRowBig>
                  )}
                </div>
              ))
          )}
        </div>

        <div className="row  px-lg-1 ">
          {layoutContest === 1
            ? visibleContests?.map(
                (aContest: Contest, index: number) => (
                  checkStatus(aContest),
                  // console.log(aContest.accessCode),
                  checkStatus(aContest) !== 'ongoing' &&
                    checkStatus(aContest) !== 'coming-soon' &&
                    aContest.isPublished && (
                      // console.log(aContest),
                      <div className=" col-12  col-md-6 col-lg-3 py-3">
                        {aContest.linkToUrl && aContest.linkToUrl.includes('bit.ly') ? (
                          <div onClick={() => goTo(aContest.linkToUrl)}>
                            <ContestCard
                              key={index}
                              // liked={isLiked(aContest.id)}
                              contestId={aContest.id}
                              startDate={aContest.startDate}
                              endVotingDate={aContest.endVotingDate}
                              image={aContest?.image}
                              title={aContest?.name}
                              statusText={statusText(aContest)}
                              entries={numberVotes(aContest?.entries)}
                              category={aContest.category}
                              endDate={aContest?.endDate}
                              name={choseUserName(aContest)}
                              award={aContest?.prices}
                              linkToAward={aContest.linkToAward}
                              linkToUrl={aContest.linkToUrl}
                              private={aContest.isPrivate}
                              accesscode={aContest.accessCode}
                              global={!aContest.accessCode}
                              nda={aContest.nda}
                              status={checkStatus(aContest)}
                              userId={aContest.userId}
                              contestType={aContest.contestType}
                              nameforurl={aContest.nameForUrl}
                              click={(e) => (handleClick(e), setLinkToDescription(aContest.brief))}
                            ></ContestCard>
                          </div>
                        ) : (
                          <ContestCard
                            key={index}
                            // liked={isLiked(aContest.id)}
                            contestId={aContest.id}
                            startDate={aContest.startDate}
                            endVotingDate={aContest.endVotingDate}
                            image={aContest?.image}
                            title={aContest?.name}
                            statusText={statusText(aContest)}
                            entries={numberVotes(aContest?.entries)}
                            category={aContest.category}
                            endDate={aContest?.endDate}
                            name={choseUserName(aContest)}
                            award={aContest?.prices}
                            linkToAward={aContest.linkToAward}
                            linkToUrl={aContest.linkToUrl}
                            private={aContest.isPrivate}
                            accesscode={aContest.accessCode}
                            global={!aContest.accessCode}
                            nda={aContest.nda}
                            status={checkStatus(aContest)}
                            userId={aContest.userId}
                            contestType={aContest.contestType}
                            nameforurl={aContest.nameForUrl}
                            click={(e) => (handleClick(e), setLinkToDescription(aContest.brief))}
                          ></ContestCard>
                        )}
                      </div>
                    )
                )
              )
            : ''}
        </div>
        <div className="row mt-4   px-lg-4">
          {layoutContest === 2
            ? visibleContests?.map(
                (aContest: Contest, index: number) =>
                  // checkStatus(aContest) !== 'ongoing' &&
                  // checkStatus(aContest) !== 'coming-soon' &&
                  aContest.isPublished && (
                    <div className="col-12  px-md-1">
                      {aContest.linkToUrl && aContest.linkToUrl.includes('bit.ly') ? (
                        <a href={aContest.linkToUrl}>
                          <ContestRow
                            // liked={isLiked(aContest.id)}
                            key={index}
                            contestId={aContest.id}
                            endVotingDate={aContest.endVotingDate}
                            image={aContest.image}
                            endDate={aContest.endDate}
                            contestTitle={aContest.name}
                            entries={numberVotes(aContest?.entries)}
                            award={aContest.prices}
                            category={aContest.category}
                            startDate={aContest.startDate}
                            clientName={choseUserName(aContest)}
                            galleryImages={[]}
                            statusText={statusText(aContest)}
                            status={checkStatus(aContest)}
                            progress={60}
                            private={aContest.isPrivate}
                            accesscode={aContest.accessCode}
                            nda={aContest.nda}
                            userId={aContest.userId}
                            nameforurl={aContest.nameForUrl}
                            contestType={aContest.contestType}
                            linkToUrl={aContest.linkToUrl}
                            linkToAward={aContest.linkToAward}
                            hideGallery={aContest.hideGalleryTab}
                            click={(e) => (handleClick(e), setLinkToDescription(aContest.brief))}
                          ></ContestRow>
                        </a>
                      ) : (
                        <ContestRow
                          // liked={isLiked(aContest.id)}
                          key={index}
                          contestId={aContest.id}
                          endVotingDate={aContest.endVotingDate}
                          image={aContest.image}
                          endDate={aContest.endDate}
                          contestTitle={aContest.name}
                          entries={numberVotes(aContest?.entries)}
                          award={aContest.prices}
                          category={aContest.category}
                          startDate={aContest.startDate}
                          clientName={choseUserName(aContest)}
                          galleryImages={[]}
                          statusText={statusText(aContest)}
                          status={checkStatus(aContest)}
                          progress={60}
                          private={aContest.isPrivate}
                          accesscode={aContest.accessCode}
                          nda={aContest.nda}
                          userId={aContest.userId}
                          nameforurl={aContest.nameForUrl}
                          contestType={aContest.contestType}
                          linkToUrl={aContest.linkToUrl}
                          linkToAward={aContest.linkToAward}
                          hideGallery={aContest.hideGalleryTab}
                          click={(e) => (handleClick(e), setLinkToDescription(aContest.brief))}
                        ></ContestRow>
                      )}
                    </div>
                  )
              )
            : ''}
        </div>
        <div className="row mt-4 px-3 ">{visibleContests.length === 0 && <NoContent />}</div>

        <div className="row ">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
            <PaginationSwitch onChangePage={setContestPageNumber} />
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default ContestList;
