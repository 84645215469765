import React, { useEffect, useState } from 'react';
import { FreeHtmlSectionProps } from '../../../global';
import HorizontalSection from '../../HorizontalSection/HorizontalSection';
import ReactMarkdown from 'react-markdown';
import parse from 'html-react-parser';

const FreeHtmlSection: React.FC<FreeHtmlSectionProps> = (props) => {
  const [html, setHtml] = useState(props.html);

  useEffect(() => {
    setHtml(props.html);
    // console.log(props.html);
  }, [props.html]);

  return (
    <>
      <HorizontalSection bgColor={props.bgcolor ? `${props.bgcolor}` : '#f6f6f6'} key={props.id}>
        
        <div dangerouslySetInnerHTML={{ __html: html }} />
        
        {/* <div  style={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div> */}
        {/* {parse(html)} */}
      </HorizontalSection>
    </>
  );
};

export default FreeHtmlSection;
