import { useContext, useEffect, useState } from 'react';
import {
  Job,
  SocialNetwork,
  SocialNetworkFinder,
  UpdateProfessionalProfile,
  UpdateUserProfile,
  User,
} from '../../../global';
import { DropDownJob } from '../../Forms/DropDown/DropDownJob';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBehanceSquare,
  faDribbbleSquare,
  faFacebookSquare,
  faFlickr,
  faGooglePlusSquare,
  faInstagramSquare,
  faLinkedin,
  faPinterestSquare,
  faSquareXTwitter,
  faTumblrSquare,
  faTwitterSquare,
  faVimeoSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { socialString } from '../../../helpers/utils';
import axiosHelper from '../../../helpers/axiosHelper';

import styles from './YourProfessionalProfile.module.scss';
import { useStoreState } from 'pullstate';
import DslStore, { setCurrentUser } from '../../../store/DslStore';
import { getCurrentUser } from '../../../store/Selectors';
import YourProfile from '../YourProfile/YourProfile';
import { MyUserContext } from '../../../UserContext';
import xLogoSquare from '../../../assets/square-x-twitter_azure.png';

interface YourProfessionalProfileProps {
  user?: User;
  upload: (n: number) => void;
  preview: boolean;
  sendPreview: (send: boolean) => void;
  open: (open: number) => void;
  isOpen: boolean;
}

const YourProfessionalProfile: React.FC<YourProfessionalProfileProps> = (props) => {
  const currentUser: any = useStoreState(DslStore, getCurrentUser);
  const { register, handleSubmit, getValues } = useForm<UpdateProfessionalProfile>();
  const [yourProfessionalProfilePopUp, setYourProfessionalProfilePopUp] = useState(props.isOpen);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [key, setKey] = useState(1);
  const [job, setJob] = useState(currentUser?.job);
  const [jobtitle, setJobtitle] = useState(currentUser?.jobTitle);
  const [company, setCompany] = useState(currentUser?.company);
  const [firstSchool, setFirstSchool] = useState(currentUser?.school1);
  const [secondSchool, setSecondSchool] = useState(currentUser?.school2);
  const [hasPublicFirstSchool, setHasPublicFirstSchool] = useState(currentUser?.hasPublicFirstSchool);
  const [hasPublicSecondSchool, setHasPublicSecondSchool] = useState(currentUser?.hasPublicSecondSchool);
  const [freelanceLicense, setFreelanceLicense] = useState(currentUser?.freelanceLicense);
  const [website, setWebsite] = useState(currentUser?.website);
  const [websiteError, setWebsiteError] = useState(false);
  const [socialArray, setSocialArray] = useState<SocialNetwork[]>(props.user ? props.user.socialNetworks : []);
  const [newSocial, setNewSocial] = useState<SocialNetwork>();
  const [socialString, setSocialString] = useState('');
  const [socialNetworkFinder, setSocialNetworkFinder] = useState<SocialNetworkFinder[]>([]);
  const [socialError, setSocialError] = useState('');
  const [sector, setSector] = useState(currentUser?.sector);
  const [dataRequested, setDataRequested] = useState(false);
  const [companyName, setCompanyName] = useState(currentUser?.companyName);
  const {
    setContextjob,
    setContextwebsite,
    setContextjobname,
    setContextcompany,
    setContextfreelancelicense,
    setContextschool1,
    setContextschool2,
    setContextsector,
    setContextcompanyname,
    setContexthaspublicfirstschool,
    setContexthaspublicsecondschool,
    Contextfirstname,
    Contextlastname,
    Contexthaspublicname,
    Contextbio,
    Contextcity,
    Contexthaspubliccity,
    Contextcountry,
    Contexthaspubliccountry,
    Contextgender,
    ContextbirthDate,
    Contexthaspublicbirthdate,
    Contexttags,
    Contextaddressline1,
    Contextcontactlanguage,
    Contextphonenumber,
    Contextzipcode,
  } = useContext(MyUserContext);

  const emptyFinder: SocialNetworkFinder = {
    id: 0,
    type: 'SocialNetworkFinder',
    comparisonString: '',
  };

  const emptySocial: SocialNetwork = {
    id: 0,
    url: '',
    user: 0,
    type: 'SocialNetwork',
    socialNetworkFinder: emptyFinder,
  };
  const userSocial: SocialNetwork = emptySocial;

  useEffect(() => {
    setDataRequested(true);
    handleSubmit(onSubmit);
    props.sendPreview(true);
  }, [props.preview]);

  const checkWebsite = () => {
    console.log(website);
    setWebsiteError(false);
    if (website?.includes('https://')) {
      return true;
    } else if (website !== null) {
      setWebsiteError(true);
      return false;
    } else {
      return true;
    }
  };

  const checkSocialString = async () => {
    setSocialError('');
    let trovata = false;
    // let stringa = (document.getElementById('social') as HTMLInputElement).value || '';
    // console.log(socialString.current?.value);
    if (socialString && (socialString.includes('http://') || socialString.includes('https://'))) {
      let stringa = socialString;
      let stringaDown = stringa.toLowerCase();
      // console.log(stringa);
      await socialNetworkFinder.map((item, index: number) => {
        console.log(stringaDown, item);
        if (stringaDown && stringaDown.includes(item.comparisonString)) {
          if (socialArray.some((e) => e.socialNetworkFinder.comparisonString === item.comparisonString)) {
            console.log('gia trovata');
            trovata = true;
            setSocialError("this social already exists in your social's list");
          } else {
            // console.log(item);
            trovata = true;
            axiosHelper.addSocial(stringa, item.id)?.then((response) => {
              console.log(response);
              fillSocialArray(item, stringa, response.id);
              setSocialError('');
              setSocialString('');
            });
          }
        }
        if (index === socialNetworkFinder.length - 1 && !trovata) {
          // console.log('ara che so qua');
          setSocialError('social not found');
        }

        // if (index === socialNetworkFinder.length - 1) {
        //
        // }
      });
    } else {
      setSocialError(`please add the prefix 'https://'`);
    }
  };

  const fillSocialArray = (finder: SocialNetworkFinder, url: string, socialId: number) => {
    if (finder && url) {
      if (newSocial && props.user) {
        userSocial.socialNetworkFinder.comparisonString = finder.comparisonString;
        userSocial.socialNetworkFinder.id = finder.id;
        userSocial.user = props.user.id;
        userSocial.type = 'SocialNetwork';
        userSocial.socialNetworkFinder.type = 'SocialNetworkFinder';
        userSocial.url = url;
        userSocial.id = socialId;
        setSocialArray((currentValues) => [...currentValues, userSocial]);
      }
    }
  };

  const removeDot = (dottedString: string) => {
    return dottedString.slice(0, dottedString.length - 1);
  };

  const removeSocial = (item: SocialNetwork, index: number) => {
    if (item) {
      if (item.id) {
        axiosHelper.removeSocial(item.id)?.then((response) => {
          setSocialArray((oldArray) => {
            return oldArray.filter((value, i) => i !== index);
          });
        });
      }
    }
  };

  const gotoProfilePreview = (data: UpdateProfessionalProfile) => {
    fillData(data);
    // let tagString: string = '';
    // contextUser.user?.tags.map((item) => {
    //   tagString = tagString + item + ', ';
    // });
    // setGetPreview(true);
    setProfileDataOnLocalStorage();
    // const datastored = localStorage.getItem('editYourProfileData');

    console.log(data);
    window.open(`/community/${currentUser?.nameForURL}/profile-preview`, 'blank');

    // redirect(`/community/${props.user.username}/profile-preview`);
  };

  const fillData = (data: UpdateProfessionalProfile) => {
    // console.log(data);

    data.freelanceLicense = freelanceLicense;

    if (!data.job) {
      data.job = job;
    }
    if (!data.jobTitle) {
      data.jobTitle = currentUser?.jobTitle;
    }
    if (!data.company) {
      data.company = currentUser?.company;
    }
    if (!data.school1) {
      data.school1 = currentUser?.school1;
    }
    if (!data.school2) {
      data.school2 = currentUser?.school2;
    }
    data.socialNetworks = socialArray;
    if (!data.sector) {
      data.sector = sector;
    }
    if (!data.companyName) {
      data.companyName = companyName;
    }

    localStorage.setItem('editProfessionalProfileData', JSON.stringify(data));
  };

  const setProfileDataOnLocalStorage = () => {
    let tagstring: string = '';
    let tags: String[] = Contexttags.split(' ');
    tags.map((item, index) => {
      index < tags.length - 1 ? (tagstring = tagstring + item + ' ') : (tagstring = tagstring + item);
    });
    const profileData: UpdateUserProfile = {
      hasPublicBirthDate: Contexthaspublicbirthdate,
      hasPublicCountry: Contexthaspubliccountry,
      hasPublicCity: Contexthaspubliccity,
      hasPublicName: Contexthaspublicname,
      firstName: Contextfirstname !== currentUser?.firstName ? Contextfirstname : currentUser?.firstName,
      lastName: Contextlastname !== currentUser?.lastName ? Contextlastname : currentUser?.lastName,
      biography: Contextbio !== currentUser?.biography ? Contextbio : currentUser?.biography,
      city: Contextcity !== currentUser?.city ? Contextcity : currentUser.city,
      country: Contextcountry !== currentUser?.country ? Contextcountry : currentUser?.country,
      gender: Contextgender !== currentUser?.gender ? Contextgender : currentUser.gender,
      birthDate: ContextbirthDate !== currentUser?.birthDate ? ContextbirthDate : currentUser?.birthDate,
      tempTags: Contexttags !== tagstring ? Contexttags : tagstring,
      addressLine1: Contextaddressline1 !== currentUser?.addressLine1 ? Contextaddressline1 : currentUser.addressLine1,
      contactLanguage:
        Contextcontactlanguage !== currentUser?.contactLanguage ? Contextcontactlanguage : currentUser.contactLanguage,
      phoneNumber: Contextphonenumber !== currentUser?.phoneNumber ? Contextphonenumber : currentUser.phoneNumber,
      zipCode: Contextzipcode !== currentUser?.zipCode ? Contextzipcode : currentUser.zipCode,
    };
    localStorage.setItem('editYourProfileData', JSON.stringify(profileData));
  };

  const onSubmit = async (data: UpdateProfessionalProfile) => {
    fillData(data);
    if (checkWebsite()) {
      console.log(data);
      if (data && currentUser?.id) {
        //TODO BEFORE COMMIT
        if (currentUser.userType === 'D') {
          await axiosHelper
            .updateUserProfessionalProfile(data, currentUser.id)
            ?.then((response) => {
              console.log(response);
              // console.log(result);
              props.upload(1);
              window.scrollTo(0, 0);
              setCurrentUser(response);
            })
            .catch((error) => {
              props.upload(2);
            });
        } else {
          await axiosHelper
            .updateClientProfessionalProfile(data, currentUser.id)
            ?.then((response) => {
              console.log(response);
              // console.log(result);
              props.upload(1);
              window.scrollTo(0, 0);
              setCurrentUser(response);
            })
            .catch((error) => {
              props.upload(2);
            });
        }
      }
    }
  };

  const goTo = (link?: string) => {
    if (link) window.open(link, '_blank');
  };

  const getJobs = async () => {
    let result: Job[] = await axiosHelper.getJobs();
    if (result) {
      // console.log(result);
      setJobs(result);
    }
  };

  const getSocialNetworkFinder = async () => {
    let result: SocialNetworkFinder[] = await axiosHelper.getSocialNetworkFinder();
    if (result) {
      // console.log(result);
      setSocialNetworkFinder(result);
    }
  };

  useEffect(() => {
    getJobs();
    getSocialNetworkFinder();
  }, []);

  useEffect(() => {
    // if (notificationsPopUp) {
    //   props.open(true);
    // } else {
    //   setNotificationsPopUp(false);
    //   props.open(false);
    // }
    setYourProfessionalProfilePopUp(props.isOpen);
  }, [props.isOpen]);
  return (
    <>
      <div
        key={key}
        className="col-12   bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={() => (
          setYourProfessionalProfilePopUp(!yourProfessionalProfilePopUp),
          props.open(yourProfessionalProfilePopUp === true ? 0 : 2),
          setKey(key + 1)
        )}
      >
        <div className="col-2"></div>
        <div className="col-8 justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            Your professional profile
          </span>
        </div>

        <div
          className={`col-2 d-flex justify-content-end align-items-center ${styles.toggle} ${
            yourProfessionalProfilePopUp && styles.open
          }`}
          onClick={() => setYourProfessionalProfilePopUp(!yourProfessionalProfilePopUp)}
        >
          <div className={`${styles.arrow} `}> </div>
        </div>
      </div>
      {yourProfessionalProfilePopUp && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-5 mt-7">
            <div className="row mb-7 mt-4">
              <div className="col-12 col-md-2 text fw-bold"></div>
              <div className="col-12 col-md-10">
                <span className="text color-blue">
                  <i>
                    <b>
                      <span className="color-red">*</span> &nbsp; Mandatory fields (
                      {currentUser.userType === 'D' ? 'required to upload your design' : 'required to launch a contest'}
                      )
                    </b>
                  </i>
                </span>
              </div>
            </div>

            {currentUser.userType === 'D' && (
              <div className="row mb-5">
                <div className="col-12 col-md-2 text fw-bold">
                  Who are you <span className="color-red">*</span>
                </div>
                <div className="col-12 col-md-4 d-flex flex-row justify-content-start align-items-center ">
                  <DropDownJob options={jobs} onSelect={(e: Job) => (setJob(e), setContextjob(e))} />
                </div>
              </div>
            )}
            {currentUser?.userType === 'C' && (
              <>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Company name <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      value={companyName}
                      {...register('companyName', {
                        required: true,
                        onChange: (e) => (setCompanyName(e.target.value), setContextcompanyname(e.target.value)),
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Sector <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      value={sector}
                      {...register('sector', {
                        required: true,
                        onChange: (e) => (setSector(e.target.value), setContextsector(e.target.value)),
                      })}
                    />
                  </div>
                </div>
              </>
            )}
            {currentUser?.userType === 'D' && (
              <>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Job title <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      value={jobtitle}
                      {...register('jobTitle', {
                        required: currentUser?.jobTitle ? false : true,
                        onChange: (e) => (setJobtitle(e.target.value), setContextjobname(e.target.value)),
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Company</div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      value={company}
                      {...register('company', {
                        required: false,
                        onChange: (e) => (setCompany(e.target.value), setContextcompany(e.target.value)),
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    School/ University <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      // value={firstSchool}
                      defaultValue={firstSchool}
                      {...register('school1', {
                        required: currentUser?.school1 ? false : true,
                        onChange: (e) => (setFirstSchool(e.taget.value), setContextschool1(e.target.value)),
                      })}
                    />
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2"></div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                    <input
                      type="checkbox"
                      checked={hasPublicFirstSchool}
                      onClick={() => (
                        setHasPublicFirstSchool(!hasPublicFirstSchool),
                        setContexthaspublicfirstschool(!hasPublicFirstSchool)
                      )}
                      className="flex-shrink-none"
                      {...register('hasPublicFirstSchool')}
                    />
                    <span className="align-self-middle ps-3 ">
                      Public (School/ University visible in your profile page)
                    </span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Other School/ University </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      value={secondSchool}
                      {...register('school2', {
                        required: false,
                        onChange: (e) => (
                          console.log(e.target.value),
                          setSecondSchool(e.target.value),
                          setContextschool2(e.target.value)
                        ),
                      })}
                    />
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2"></div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                    <input
                      type="checkbox"
                      checked={hasPublicSecondSchool}
                      onClick={() => (
                        setHasPublicSecondSchool(!hasPublicSecondSchool),
                        setContexthaspublicsecondschool(!hasPublicSecondSchool)
                      )}
                      className="flex-shrink-none"
                      {...register('hasPublicSecondSchool')}
                    />
                    <span className="align-self-middle ps-3 ">
                      Public (Other School/ University visible in your profile page)
                    </span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    License to work as freelance <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                    <div>
                      <input
                        type="radio"
                        value={'N'}
                        onChange={(e) => (
                          setFreelanceLicense(e.target.value), setContextfreelancelicense(e.target.value)
                        )}
                        checked={freelanceLicense === 'N' ? true : false}
                      />
                      <label className="align-self-middle fw-normal ">N/A</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value={'Y'}
                        onChange={(e) => (
                          setFreelanceLicense(e.target.value), setContextfreelancelicense(e.target.value)
                        )}
                        checked={freelanceLicense === 'Y' ? true : false}
                      />
                      <label className="align-self-middle fw-normal ">Yes</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value={'F'}
                        onChange={(e) => (
                          setFreelanceLicense(e.target.value), setContextfreelancelicense(e.target.value)
                        )}
                        checked={freelanceLicense === 'F' ? true : false}
                      />
                      <label className="align-self-middle fw-normal ">No</label>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row mb-5">
              <div className="col-12 col-md-2 mb-3 text fw-bold">Website</div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={website}
                  {...register('website', {
                    onChange: (e) => (
                      setWebsite(e.target.value),
                      setContextwebsite(e.target.value),
                      websiteError && setWebsiteError(false)
                    ),
                  })}
                />
              </div>
              {websiteError && (
                <>
                  <div className="col-12 col-md-2 mb-3 text fw-bold"></div>
                  <div className="col-12 col-md-10">
                    <span className="color-dark_red ps-3">Please use the form https://</span>
                  </div>
                </>
              )}
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 mb-3 text fw-bold"> Social</div>
              <div className="col-12 col-md-8 d-flex flex-row align-items-center ">
                {socialArray && socialArray.length < 1 ? <span> - </span> : null}
                {/* {console.log(currentUser?.socialNetworks)} */}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'facebook.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faFacebookSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'twitter.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faSquareXTwitter as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                      {/* <img src={xLogoSquare} style={{ height: '2rem', width: '2rem' }} /> */}
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'behance.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faBehanceSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'dribbble.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faDribbbleSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'flickr.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faFlickr as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'google.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faGooglePlusSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'linkedin.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faLinkedin as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'pinterest.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faPinterestSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'tumblr.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faTumblrSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'vimeo.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faVimeoSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'instagram.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faInstagramSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
                {socialArray?.map((aSocialNetwork, index) =>
                  aSocialNetwork.socialNetworkFinder.comparisonString === 'youtube.' ? (
                    <span>
                      <FontAwesomeIcon
                        key={index}
                        icon={faYoutubeSquare as IconProp}
                        className=" text color-blue me-3 cursor-pointer"
                        style={{ fontSize: '2rem' }}
                        onClick={() => goTo(aSocialNetwork.url)}
                      />
                    </span>
                  ) : null
                )}
              </div>
              {!newSocial && (
                <div className="col-12 col-md-2">
                  <div className={`btn-blue full rounded ${styles.button} `} onClick={() => setNewSocial(emptySocial)}>
                    insert
                  </div>
                </div>
              )}
            </div>
            {newSocial ? (
              <div className="row mb-5">
                <div className="col-12 col-md-2 mb-3 text fw-bold">New Social</div>
                <div className="col-12 col-md-8">
                  <input
                    type="text"
                    id="social"
                    value={socialString}
                    onChange={(e) => (setSocialString(e.target.value), socialError && setSocialError(''))}
                  />
                </div>
                <div className="col-12 col-md-2 ">
                  <div
                    className={`btn-blue full rounded d-flex flex-row align-items-center ${styles.button} `}
                    onClick={checkSocialString}
                  >
                    Add social
                  </div>
                </div>
                {socialError !== '' && (
                  <>
                    <div className="col-12 col-md-2 mb-3 text fw-bold"></div>
                    <div className="col-12 col-md-10">
                      <span className="color-dark_red ps-3"> {socialError}</span>
                    </div>
                  </>
                )}
              </div>
            ) : (
              ''
            )}

            {socialArray &&
              socialArray.map((item: SocialNetwork, index: number) => (
                <div className="row mb-5">
                  <div className="col-12 col-md-2 mb-3 text fw-bold ">
                    {item.socialNetworkFinder.comparisonString && removeDot(item.socialNetworkFinder.comparisonString)}
                  </div>
                  <div className="col-12 col-md-8">
                    <span>{item.url}</span>
                  </div>
                  <div className="col-12 col-md-2  d-flex justify-content-end pe-5">
                    <div className="col-12 col-md-1  ">
                      <div className=" btn-danger full rounded" onClick={() => removeSocial(item, index)}>
                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div className="row  d-flex justify-content-center align-items-center mb-7 mt-7">
              <div
                className={`btn-dark_blue col-md-2 mb-sm-3 mb-md-0 rounded mx-5 ${styles.button} `}
                onClick={handleSubmit(gotoProfilePreview)}
              >
                preview
              </div>

              <button className={`btn-success rounded col-md-2 mx-5 ${styles.button}  `}>update</button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default YourProfessionalProfile;
