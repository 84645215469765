import React from 'react';
import { CardComponent, CardSectionProps, ElementComponent, FourElementSectionProps } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import HorizontalSection from '../../HorizontalSection/HorizontalSection';

function ThreeElementComponent(props: FourElementSectionProps) {
  return (
    <HorizontalSection bgColor={props.bgcolor ? `${props.bgcolor}` : '#f6f6f6'}>
      <div className="container py-7 px-0">
        <div className="row justify-content-center pb-5">
          {props.title || props.subtitle || props.text ? (
            <div className="COL-12 col-md-10">
              <h2 className={`text-center bold-title color-${props.titlecolor}`}>{props.title}</h2>
              <h3 className={`text-center sub-title color-${props.subtitlecolor}`}>{props.subtitle}</h3>
              <p className={`text-16 text-center color-${props.textcolor}`}>{props.text}</p>
            </div>
          ) : null}
        </div>
        <div className="row">
          {props.elementcomponent.map((item: ElementComponent) => (
            <div
              className="col-12 col-sm-6 col-md-6 col-lg-4 text-center d-flex flex-column align-items-center pb-5 pb-lg-3"
              key={item.id}
            >
              {item.image?.data && (
                <div className="d-flex justify-content-center  mx-3  ">
                  {item.link ? (
                    <img className="img-fluid " src={pathStrapiImage(item.image.data.attributes.url)} alt="Product" />
                  ) : (
                    <a href={item.link && item.link} target={`${item.target ? item.target : '_self'}`}>
                      <img className="img-fluid " src={pathStrapiImage(item.image.data.attributes.url)} alt="Product" />
                    </a>
                  )}
                </div>
              )}

              {item.title && (
                <div
                className={` mt-4 d-flex flex-row w-100 px-3 align-items-center ${
                item.titlealign ? `justify-content-${item.titlealign}` : `justify-content-center`
                  }`}
                >
                  <span className={`bold-title  color-${item.titleCol}`}>{item.title}</span>{' '}
                </div>
              )}
              {item.bigtext && (
                <div
                  className={` mt-3 mb-3 d-flex align-items-center w-100 px-3  ${
                    item.bigtextalign ? `justify-content-${item.bigtextalign}` : `justify-content-center`
                  }`}
                  style={{ height: '35px' }}
                >
                  <span className={`sub-title color-${item.bigtextColor} `} style={{ height: '35px' }}>
                    {item.bigtext}
                  </span>
                </div>
              )}
              {item.boldtext && (
                <div
                  className={` mt-4 mb-3 d-flex align-items-center px-3 w-100 ${
                    item.boldtextalign ? `justify-content-${item.boldtextalign}` : `justify-content-center`
                  }`}
                  style={{ height: '35px' }}
                >
                  <span className={`fw-bold text-16 color-${item.boldtextColor} `}>{item.boldtext}</span>
                </div>
              )}

              {item.text && (
                <p
                  className={`text-16 mb-5 ${
                    item.textalign ? `text-${item.textalign}` : `text-end`
                  }  mt-3 mx-3 color-${item.txtColor}`}
                >
                  {item.text}
                </p>
              )}
              {item.buttontext && (
                <div className="col-12 col-md-4 col-lg-6 align-self-center mb-7">
                  <a href={item.link && item.link} target={`${item.target ? item.target : '_self'}`}>
                    <button className={`btn-${item.buttcolor} full rounded mt-2`}>{item.buttontext}</button>
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </HorizontalSection>
  );
}

export default ThreeElementComponent;
