import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

import './App.scss';

//Components

import DslStore, {
  endBusyMode,
  fetchContest,
  fetchMenus,
  fetchTags,
  setCounters,
  setNavLinkActive,
  setStrapiCommunity,
} from './store/DslStore';

import { INavLink } from './global';

import Container from './Container';
import ScrollToTop from './ScrollToTop';
import axiosHelper from './helpers/axiosHelper';
import { useStoreState } from 'pullstate';
import { getStrapiCommunity } from './store/Selectors';
import { UserContextProvider } from './UserContext';

const App: React.FC = () => {
  const defaultNavLink: INavLink = { text: 'Home', path: '/', id: 0 };
  const [menusFetched, setMenusFetched] = useState(false);
  const strapiCommunity = useStoreState(DslStore, getStrapiCommunity);

  // const footerMenu = useStoreState(DslStore, getFooterMenu);
  // const [headerColor, setHeaderColor] = useState('');

  // const handlefooter = (e: any) => {
  //   // console.log(e);
  //   setFooterData(e);
  // };

  const getstrapiCommunity = async () => {
    await axiosHelper.fetchStrapiCommunity().then((response) => {
      // console.log(response.data.attributes.DynamicCommunity);
      setStrapiCommunity(response.data.attributes.DynamicCommunity);
    });
  };

  useEffect(() => {
    console.log('INIZIO');

    axiosHelper.tokLogin().then(() => {
      // fetchMenus.run().then(async () => {
      fetchMenus.run().then(() => {
        setMenusFetched(true);
        getstrapiCommunity();
        // await axiosHelper.fetchStrapiCommunity().then((response) => {
        //   // console.log(response.data.attributes.DynamicCommunity);
        //   setStrapiCommunity(response.data.attributes.DynamicCommunity);
        // });
      });
      // setCategories.run();
      setCounters.run();
      Promise.all([
        fetchContest.run(),
        // fetchCoCreationContest.run(),
        fetchTags.run(),
      ]).then((values) => {
        // console.log(' fetchAll');
        console.log(values);
        endBusyMode();
        setNavLinkActive(defaultNavLink);
      });
    });
  });

  return (
    <>
      {menusFetched && (
        <UserContextProvider>
          <Router>
            <ScrollToTop />
            <Container />
          </Router>
        </UserContextProvider>
      )}
      {!menusFetched && (
        <>
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className=" align-self-center">
              <TailSpin
                height="250"
                width="250"
                color="#7997c5"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="tail-spin-loading"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default App;
