import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './HomeHeader.module.scss';
import { fullPathLogo, hexToRGB } from '../../helpers/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose, faBars } from '@fortawesome/free-solid-svg-icons';
import UserPic from '../Community/UserPic/UserPic';
import { FooterMenuData } from '../../global';
import { useStoreState } from 'pullstate';
import DslStore, { logout } from '../../store/DslStore';
import { getCurrentUser, getUserLogged } from '../../store/Selectors';

import axiosHelper from '../../helpers/axiosHelper';

interface IHeader {
  links: FooterMenuData[];
  bgColor: string;
  bgImage?: string;
  alpha?: number;
  logoImg?: string;
  pageTitle?: string;
  transparent?: boolean;
}

const TopNav = ({ links }: { links: FooterMenuData[] }) => {
  return (
    <ul className={`${styles.headerTopNav} d-block d-lg-flex justify-content-start pt-5 mt-2 pt-lg-0 mt-lg-2 mb-0`}>
      {links.map((navLink, index) => (
        <li
          key={'topnavlink_' + index}
          onClick={() => {
            // setNavLinkActive(navLink);
          }}
          className="mb-5 mb-lg-0"
        >
          <Link className="text-16 color-light font-heading d-block d-lg-flex" to={navLink.path}>
            {navLink.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const BreadCrumbs = () => {
  return (
    <div className={`col-12 text color-light fw-bold " ${styles.headerBreadCrumbs}`}>
      <Link className="text color-light font-heading px-1" to={'/'}>
        Home
      </Link>{' '}
      /{' '}
      <Link className="text color-light font-heading" to={'/'}>
        Breadcrumbs Example
      </Link>
    </div>
  );
};

const ToggleButton = (props: { opened: boolean; onToggle: () => void }) => {
  return (
    <button className={`${styles.openCloseButton}`} onClick={() => props.onToggle()}>
      {props.opened ? (
        <FontAwesomeIcon icon={faClose as IconProp} className="bigger-text color-light" />
      ) : (
        <FontAwesomeIcon icon={faBars as IconProp} className="bigger-text color-light" />
      )}
    </button>
  );
};

interface IUserArea {
  isLogged: boolean;
  user: any;
  onSignUp: () => void;
  onSignIn: () => void;
}

const UserArea: React.FC<IUserArea> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const userLogged = useStoreState(DslStore, getUserLogged);
  const redirect = useNavigate();

  const logoutFunction = () => {
    logout();
    axiosHelper.logout();
  };

  const goToProfileEditable = async () => {
    // await axiosHelper.retrieveUserById(userProfile?.id)?.then((response: User) => {
    //   setCurrentUser(response);
    // });
    redirect(`/community/${currentUser?.nameForURL}/profile-editable`);
  };

  return userLogged ? (
    <div className="col-6 col-md-3 d-flex flex-column justify-content-start justify-content-md-center pt-5 pt-md-0">
      <div
        className={`${styles.profileWrapper} d-flex flex-column flex-md-row justify-content-end align-items-center align-items-md-start position-relative`}
      >
        <div className="d-flex justify-content-end align-items-center position-relative">
          <div className="text fw-bold color-light pe-3">Welcome {currentUser?.username}!</div>
          <div className={`${styles.profilePic} position-relative`}>
            <UserPic image={currentUser?.logoAttachment ? currentUser?.logoAttachment.s3Path : ''} />
          </div>
        </div>
        <div className={`${styles.profileActions} pt-3 pt-md-0 ps-0 ps-md-4 px-lg-5`}>
          <div className={`${styles.profileName} text-xxl fw-bold pb-3`}>
            {/* {currentUser?.firstName + ' ' + (currentUser?.lastName ? currentUser.lastName : ' ')} */}
            {currentUser?.username}
          </div>
          <div className={`${styles.profileMail} text mb-5 mb-md-4`}>{currentUser?.email}</div>
          <div className="pb-3" onClick={goToProfileEditable}>
            <Link
              to={`/community/${currentUser?.nameForURL}/profile-editable`}
              className={`${styles.profileLink} text d-block`}
            >
              Profile
            </Link>
          </div>
          <div>
            <Link
              to={'/'}
              onClick={() => {
                logoutFunction();
              }}
              className={`${styles.profileLink} text d-block`}
            >
              Logout
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="col-12 col-md-3 d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center justify-content-md-end my-5 my-lg-0">
        <button className="btn-dark rounded me-5" onClick={() => props.onSignUp()}>
          Signup
        </button>
        <button className="btn-dark rounded" onClick={() => props.onSignIn()}>
          Login
        </button>
      </div>
    </div>
  );
};

//TODO add breadcrumbs (https://www.npmjs.com/package/use-react-router-breadcrumbs)
const HomeHeader: React.FC<IHeader> = (props) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [opened, setOpened] = useState<boolean>(false);
  let navigate = useNavigate();

  const userLogged = useStoreState(DslStore, getUserLogged);
  const currentuser = useStoreState(DslStore, getCurrentUser);

  const handleScroll = () => {
    let position = window.pageYOffset;
    setScrollPosition(position);
  };

  const mockUser = {
    username: currentuser?.firstName + ' ' + currentuser?.lastName,
    profilePic: currentuser?.profilePic,
    email: currentuser?.email,
    id: currentuser?.id,
    firstName: currentuser?.firstName,
    lastName: currentuser?.lastName,
    userType: currentuser?.userType,
    dslToken: currentuser?.dslToken,
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let c = hexToRGB(props.bgColor);

  const handleOpenCloseHeader = () => {
    console.log(opened);
    setOpened(!opened);
  };

  const handleSignUp = () => {
    let path = `/signup`;
    navigate(path);
    // alert('Signup');
  };

  const handleSignIn = () => {
    let path = `/login`;
    // alert('Signin');
    navigate(path);
  };

  return (
    <div
      className={`${styles.headerWrapper} container-fluid  px-0`}
      style={{
        backgroundColor: `${props.transparent ? 'transparent' : props.bgColor}`,
        position: `${props.transparent ? 'absolute' : 'relative'}`,
      }}
    >
      {/* Top Row ============================================================================== */}
      <div
        className={`container-fluid ${styles.headerTopRowContainer}   pt-3 pb-3 ${opened ? styles.opened : ''}`}
        // style={{ backgroundColor:'transparent' }}
        style={{ backgroundColor: scrollPosition > 50 || opened ? props.bgColor : 'transparent' }}
        // style={{ backgroundColor: `${props.transparent ? 'transparent' : props.bgColor}` }}
      >
        <div className="container-md d-flex flex-column justify-content-center ">
          <div className={`row ${styles.headerTopRow}  pt-3 pb-3  ${opened ? styles.opened : ''}`}>
            <div className={`col-10 col-lg-2 ${styles.headerLogo} `}>
            <img src={props.logoImg} alt="Desall" className='cursor-pointer' onClick={() => navigate('/')} />
            </div>
            <div className=" col-2 d-flex d-lg-none justify-content-end ">
              <ToggleButton opened={opened} onToggle={handleOpenCloseHeader} />
            </div>
            <div className="col-12 col-lg-10">
              <div className="row d-flex align-items-center h-100 justify-content-between ">
                <div className={`${styles.navigationWrapper} col-6 col-md-7 d-flex flex-column justify-content-center`}>
                  <TopNav links={props.links} />
                </div>
                <UserArea isLogged={userLogged} onSignIn={handleSignIn} onSignUp={handleSignUp} user={mockUser} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Row ============================================================================== */}
      <div
        className={`${styles.headerContentWrapper} container-md pb-7`}
        style={{
          backgroundImage: `${
            props.transparent && scrollPosition > 50
              ? `
          linear-gradient(rgba(${c[0]},${c[1]},${c[2]},${props.alpha ?? 0.8}), 
          rgba(${c[0]},${c[1]},${c[2]},${props.alpha ?? 0.8})), 
          url(${props.bgImage})
          `
              : ''
          }`,
          backgroundColor: `${props.transparent ? 'transparent' : props.bgColor}`,
        }}
      >
        {/* Bottom Row */}
        <div className="row mt-5 px-2">{/* <BreadCrumbs /> */}</div>
        <div className="row px-2">
          <div className="col-12 light-title color-light">{props.pageTitle}</div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
