import React, { useEffect, useRef, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';

import ContestStats from '../../components/Community/ContestStats/ContestStats';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faIndustry, faXmark } from '@fortawesome/free-solid-svg-icons';
import styles from './ContestSkeleton.module.scss';

//Header Data

import headerLogo from '../../assets/desall_logo.png';
import { briefNavPages } from '../../mockdata/navPages';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';
import TimeLine from '../../components/TimeLine/TimeLine';
import ActionButton, { MultiActionButtonsWrapper } from '../../components/ActionButton/ActionButton';

import {
  getCoCreationContestStats,
  getCoCreationContests,
  getCommunityUser,
  getCurrentContest,
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getTimelinePassedUpload,
  getTimelinePassedCommunityVote,
  getTimelineTotUpload,
  getTimelineTotCommunityVote,
  getTimelinePassedClientVote,
  getTimelineTotClientVote,
  getAllCoCreationContests,
  getContestAccessDenied,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, {
  setCoCreationContest,
  setCoCreationContestGallery,
  setCoCreationContestStats,
  setCommunityUser,
  setContestAccessDenied,
  setContestGallery,
  setContestGalleryCopy,
  setContestInspirations,
  setContestTags,
  setCurrentContest,
} from '../../store/DslStore';
import { finalSlicedString, finalString, numberVotes, registrationDateString, setTimelines } from '../../helpers/utils';
import axiosHelper from '../../helpers/axiosHelper';
import TLHLP from '../../components/TimeLine/timeline.helper';

import { PageNavigationBrief } from '../../components/PageNavigation/PageNavigation';

import { AccessCode, CoCreationContest, CoCreationContestStats, User } from '../../global';
import { useNavigate, useParams } from 'react-router-dom';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import Brief from './Brief';
import Inspirations from './inspirations';
import Awards from './Awards';
import Gallery from './Gallery';
import UploadContest from './UploadContest';
import UploadInspiration from './UploadInspiration';
import { response } from 'express';
import EditContestInspiration from './EditContestInspiration';
import { error } from 'console';

const ContestSkeleton: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const allCocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const cocreationContests = useStoreState(DslStore, getCoCreationContests);
  const timelineTotUpload = useStoreState(DslStore, getTimelineTotUpload);
  const timelinePassedUpload = useStoreState(DslStore, getTimelinePassedUpload);
  const timelineTotCommunityVote = useStoreState(DslStore, getTimelineTotCommunityVote);
  const timelinePassedCommunityVote = useStoreState(DslStore, getTimelinePassedCommunityVote);
  const timelineTotClientVote = useStoreState(DslStore, getTimelineTotClientVote);
  const timelinePassedClientVote = useStoreState(DslStore, getTimelinePassedClientVote);
  const contest = useStoreState(DslStore, getCurrentContest);
  const cocreationstats = useStoreState(DslStore, getCoCreationContestStats);
  const contestAccessDenied = useStoreState(DslStore, getContestAccessDenied);
  const { nameForUrl } = useParams();
  const [breadcrumbs, setBreadcurmbs] = useState('');
  const [messageBanner, setMessageBanner] = useState(0);
  const [privateGalleryMessage, setPrivateGalleryMessage] = useState(false);
  const path = window.location.pathname;
  const date = new Date().getTime();
  const contestEndDate = useRef('');
  const endvotingdate = useRef('');

  const [accesscodeauth, setAccessCodeAuth] = useState(true);
  const [ndaauth, setNdaAuth] = useState(true);

  const [popup, setPopup] = useState(false);
  const redirect = useNavigate();
  const communityUser = useStoreState(DslStore, getCommunityUser);

  // useEffect(() => {
  //   console.log(popup);
  // }, [popup]);

  useEffect(() => {
    // conta();
    // console.log(contest);

    // console.log(contest?.accessCode);
    contest &&
      contest.contestType !== 'Co-creation' &&
      setTimelines(contest.startDate, contest.endDate, contest.endVotingDate, contest.hypotheticalEndDate, contest);
    // console.log(allCocreationContests);
  }, []);

  let tlUCC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let tlUC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  // useEffect(() => {
  //   console.log(contest);
  //   console.log(timelineTotUpload);
  //   console.log(timelinePassedUpload);
  // }, [contest]);

  const coCreationContestStats = () => {
    let stats: CoCreationContestStats = {
      entries: 0,
      votes: 0,
      inspirations: contest?.inspirationCount ? contest.inspirationCount : 0,
      awards: 0,
    };
    cocreationContests?.map(
      (item: CoCreationContest) => (
        // console.log(item.sumVotesPublicDesigns),
        item.publicDesignsOrderedCount ? (stats.entries = stats.entries + item.publicDesignsOrderedCount) : null,
        item.realTotalAwards ? (stats.awards = stats.awards + item.realTotalAwards) : null,
        item.sumVotesPublicDesigns ? (stats.votes = stats.votes + item.sumVotesPublicDesigns) : null
      )
    );
    setCoCreationContestStats(stats);
  };

  useEffect(() => {
    if (contest?.isPrivate) {
      setPrivateGalleryMessage(true);
    }
    if (contest?.hideGalleryTab) {
      setPrivateGalleryMessage(true);
    }
  }, [contest]);

  const goToDetail = async (userid?: number) => {
    if (userid) {
      console.log(userid);
      try {
        await axiosHelper.retrieveUserById(contest?.userId)?.then((response) => {
          console.log(response);
          setCommunityUser(response);
          redirect(`/community/${response.nameForURL}/profile`);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const setTags = async (contestId: number) => {
    let contestTags = await axiosHelper.getContestTags(contestId);
    if (contestTags) {
      console.log(contestTags);
      setContestTags(contestTags.tags);
    }
  };

  const fetchContestByName = (contestName: string) => {
    // console.log('BY NAME');
    if (contestName) {
      axiosHelper
        .retrieveContestByName(contestName)
        .then((response) => {
          // console.log(response);
          setCurrentContest(response.data);
          setTags(response.data.tags);
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => {
    permissions();
  });

  const permissions = () => {
    if (path.includes('gallery')) {
      console.log('INCLUDE GALLERY');
      axiosHelper
        .getContestGallery(contest?.id)
        ?.then((response) => {
          setContestGallery(response);
          setContestGalleryCopy(response);
          if (contest?.contestType === 'Co-creation') {
            setCoCreationContestGallery(response);
          }
        })
        .catch((e) => {
          if (e) {
            console.log('erroreeee vai via da qui ');
            //todo attiva notifica contest access denied
            setContestAccessDenied(true);
            redirect(`/contest/${contest?.nameForUrl}/brief`);
          }
        });
    }

    if (path.includes('inspirations')) {
      axiosHelper
        .getContestInspirations(contest?.id)
        ?.then((response) => {
          setContestInspirations(response.inspirations);
        })
        .catch((e) => {
          if (e) {
            console.log('erroreeee vai via da qui ');
            setContestAccessDenied(true);
            redirect(`/contest/${contest?.nameForUrl}/brief`);
          }
        });
    }

    if (path.includes('upload') && !contest?.brief && loggedUser?.id !== contest?.userId) {
      console.log(contest);
      if (contest?.contestType !== 'Co-creation') {
        axiosHelper
          .getUploadPermission(contest?.id)
          ?.then((response) => {})
          .catch((error) => {
            if (error) {
              console.log('non posso entrare');
              setContestAccessDenied(true);
              redirect(`/contest/${contest?.nameForUrl}/brief`);
            }
          });
      }
    }
    if (path.includes('upload')) {
      if (contest?.contestType !== 'Co-creation') {
        axiosHelper
          .getUploadPermission(contest?.id)
          ?.then((response) => {})
          .catch((error) => {
            if (error) {
              console.log('non posso entrare');
              setContestAccessDenied(true);
              redirect(`/contest/${contest?.nameForUrl}/brief`);
            }
          });
      }
    }

    if (path.includes('award')) {
      if (contest?.contestType !== 'Co-creation') {
        if (!contest?.brief && loggedUser?.id !== contest?.userId && contest?.nameForUrl) {
          setContestAccessDenied(true);
          redirect(`/contest/${contest?.nameForUrl}/brief`);
        }
      } else {
      }
    }
  };

  useEffect(() => {
    // console.log(nameForUrl);
    nameForUrl && nameForUrl !== contest?.nameForUrl && fetchContestByName(nameForUrl);
  }, [nameForUrl]);

  useEffect(() => {
    permissions();
    setContestGallery([]);
    if (contest?.contestType === 'Co-creation') {
      getCoCreationContestDetails(contest.id);
    }
    contest &&
      contest.contestType !== 'Co-creation' &&
      setTimelines(contest.startDate, contest.endDate, contest.endVotingDate, contest.hypotheticalEndDate, contest);
  }, [contest]);

  useEffect(() => {
    coCreationContestStats();
  }, [cocreationContests]);

  const getCoCreationContestDetails = async (contestId: number) => {
    console.log('get cocreation contest details');
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);

      // cocreationFill();
    }
  };

  const getCorrectEndDate = () => {
    // console.log('correctDate');

    const date = new Date().getTime();

    // console.log('GET CORRECT END DATE : ');
    if (contest) {
      if (contest?.contestType === 'Co-creation') {
        let contestAttached: CoCreationContest[] = [];
        // console.log('GET CORRECT END DATE : CO-CREATION');
        allCocreationContests.map((item) => {
          if (contest?.id === item.mainContest.id) {
            contestAttached.push(item);
          }
        });
        // console.log('GET CORRECT END DATE : contestattached fatto' + JSON.stringify(contestAttached));
        contestAttached.map((item) => {
          console.log(item);
          if (item.endVotingDate) {
            endvotingdate.current = item.endVotingDate;
          }
          if (!item.isClosed) {
            if (date > new Date(item.startUploadDate).getTime() && date < new Date(item.endUploadDate).getTime()) {
              // console.log('CO-CREATION UPLOAD');
              contestEndDate.current = `Ends ${registrationDateString(item.endUploadDate)}`;
              setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
              return;
            } else if (item.endVotingDate) {
              if (date > new Date(item.endUploadDate).getTime() && date < new Date(item.endVotingDate).getTime()) {
                // console.log(date);
                // console.log(new Date(item.endUploadDate).getTime());
                // console.log(new Date(item.endVotingDate).toLocaleDateString());
                // console.log(new Date(item.endVotingDate).getTime());
                // console.log('CO-CREATION COMMUNITY');
                setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
              } else if (
                date > new Date(item.endUploadDate).getTime() &&
                date > new Date(item.endVotingDate).getTime()
              ) {
                // console.log('CO-CREATION CLIENT');
                setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
                contestEndDate.current = `Ends: ${registrationDateString(item.hypotheticalEndDate)}`;
              }
            } else if (date > new Date(item.endUploadDate).getTime()) {
              // console.log('CO-CREATION CLIENT VOTE');
              setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
              contestEndDate.current = `Ends: ${registrationDateString(item.hypotheticalEndDate)}`;
            }
          } else {
            // console.log('CO-CREATION CLOSED');
            contestEndDate.current = `Closed: ${registrationDateString(item.endDate)}`;
          }
        });
      } else {
        if (contest.endVotingDate) {
          endvotingdate.current = contest.endVotingDate;
        }
        // console.log('GET CORRECT END DATE : NORMAL CONTEST');
        if (date < new Date(contest.endDate).getTime() && !contest.isClosed) {
          // console.log('GET CORRECT END DATE : UPLOAD PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.endDate)}`;
          return;
        } else if (contest.endVotingDate && date < new Date(contest.endVotingDate).getTime() && !contest.isClosed) {
          // console.log('GET CORRECT END DATE : COMMUNITY VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.endVotingDate)}`;
          return;
        } else if (
          (contest.endVotingDate &&
            date > new Date(contest.endVotingDate).getTime() &&
            date < new Date(contest.hypotheticalEndDate).getTime() &&
            !contest.isClosed) ||
          (date > new Date(contest.endDate).getTime() &&
            date < new Date(contest.hypotheticalEndDate).getTime() &&
            !contest.isClosed)
        ) {
          // console.log('GET CORRECT END DATE : CLIENT VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.hypotheticalEndDate)}`;
          return;
        } else {
          console.log('GET CORRECT END DATE : CLOSED PHASE');
          contestEndDate.current = `Closed: ${registrationDateString(
            contest.assignmentDate ? contest.assignmentDate : contest.endDate
          )}`;
          return;
        }
      }
      // return contest?.endDate;
    }
  };

  useEffect(() => {
    // console.log(path);
    contestEndDate.current = '';
    getCorrectEndDate();
  }, [contest?.id, allCocreationContests]);

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor={contest?.bannerColor ? contest.bannerColor : '#C3DE72'}
        logoImg={headerLogo}
        bgImage={contest?.bannerImage}
        alpha={0}
        breadcrumbs={`Contest/${contest?.name}`}
        pageTitle={breadcrumbs}
      />
      <PageNavigationBrief
        bgColor="#E3E3E3"
        links={briefNavPages}
        // hideClientGallery={typeGod() && contest?.hideGalleryTab}
        // hideClientGallery={contest?.hideGalleryTab}
        hideClientGallery={contest?.hideGalleryTab}
        hideAwardsTab={contest?.hideAwardsTab}
        hideInspirationTab={contest?.hideInspirationTab}
        clientlogged={loggedUser?.userType === 'C' && loggedUser.id === contest?.userId ? true : false}
        closed={contest?.isClosed}
        accesscodeAuth={accesscodeauth}
        ndaAuth={ndaauth}
        // private={false}
        private={contest?.isPrivate}
      />

      <div
        className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
          privateGalleryMessage ? `${styles.warningBackground}` : `d-none`
        } `}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center ">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light " style={{ zIndex: '10', opacity: '2' }}>
                  This contest has a private gallery. You can join it but you can’t see its entries
                </span>
              </div>
              <div>
                <button
                  className={`${styles.removeBannerButton} bg-blue`}
                  onClick={() => setPrivateGalleryMessage(false)}
                >
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`d-flex  flex-row align-items-center w-100 justify-content-center ${
          messageBanner === 1
            ? `${styles.successBackground}`
            : messageBanner === 2
            ? `${styles.errorBackground}`
            : 'd-none'
        }
        ${privateGalleryMessage ? `${styles.translateY60}` : ''}`}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center ">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light d-flex flex-row align-items-center justify-content-center  ">
                  {messageBanner === 1 ? 'Message sent!' : 'The mailserver is busy, please try again!'}
                </span>
              </div>
              <div>
                <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setMessageBanner(0)}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
          contestAccessDenied ? `${styles.errorBackground}` : `d-none`
        } ${
          privateGalleryMessage
            ? messageBanner !== 0
              ? `${styles.translateY120}`
              : `${styles.translateY60}`
            : messageBanner !== 0
            ? `${styles.translateY60}`
            : ``
        }`}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center ">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light ">Access Denied!</span>
              </div>
              <div>
                <button
                  className={`${styles.removeBannerButton} bg-blue`}
                  onClick={() => setContestAccessDenied(false)}
                >
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className={`container mb-5 overflow-hidden`}>
        {/* Head ====================================================================== */}
        {/* <div className="row ">
          {contest?.bannerImage
            ? (console.log(contest),
              (
                <div className="col-12  px-3">
                  <ProfileHeaderContest bgImage={contest?.bannerImage} />
                </div>
              ))
            : null}
          </div> */}
        <div className="row px-4  px-sm-1  ">
          <div className="col-12 col-md-3  text-center mx-3 mx-sm-0  d-flex align-items-end justify-content-sm-center justify-content-md-start ">
            <div className="position-relative ">
              <img
                style={{ width: '270px', height: '270px' }}
                className="img-fluid rounded mt-5 "
                src={contest?.image}
                alt="Placeholder"
              />
              {contest?.isPrivate ||
                (contest?.hideGalleryTab && (
                  <MultiActionButtonsWrapper position="bottom-left" rounded>
                    <ActionButton label="Private gallery" icon="private" />
                  </MultiActionButtonsWrapper>
                ))}
            </div>
          </div>
          <div className="col-12 col-md-9 px-3 pt-5">
            <div className="row">
              <div className="info-main mb-5 px-3 px-sm-2  col-12">
                <h1 className="sub-title-brief  color-dark">
                  {/* {() => console.log(isUpperCase(contest!.userName[0]))} */}
                  {finalString(contest?.name)}
                </h1>
                <div className="text-16 color-dark">{finalString(contest?.category)}</div>
              </div>
            </div>
            <div className="row ">
              <div className="col-12 px-3 px-sm-2 col-md-3">
                <div className="info">
                  <div className="d-flex justify-content-start mb-3" style={{ height: '20px', overflow: 'hidden' }}>
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-2" />
                    </div>
                    <div
                      className="text color-blue"
                      style={{ cursor: 'pointer' }}
                      onClick={() => goToDetail(contest?.userId)}
                    >
                      {contest?.userName != null
                        ? finalSlicedString(contest?.userName)
                        : finalString(contest?.linkToClient)}
                    </div>
                  </div>
                  <div className="d-flex  justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className="text">{contest?.userCity + ', ' + contest?.countryName}</div>
                  </div>
                  <div className="d-flex  justify-content-start">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className="text ">{contestEndDate.current}</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-9  mt-5 mt-md-0 ">
                {contest?.contestType !== 'Co-creation' && (
                  <ContestStats
                    ndaAuth={ndaauth}
                    accessCodeAuth={accesscodeauth}
                    isPrivate={contest?.isPrivate}
                    entries={contest?.entries ? contest.entries : 0}
                    inspirations={numberVotes(contest?.inspirationCount)}
                    awards={contest?.prices}
                    votes={numberVotes(contest?.sumVotesCount)}
                  />
                )}
                {contest?.contestType === 'Co-creation' && (
                  <ContestStats
                    entries={cocreationstats?.entries}
                    inspirations={cocreationstats?.inspirations}
                    awards={cocreationstats?.awards}
                    votes={cocreationstats?.votes}
                  />
                )}
              </div>
              {contest && (
                <div className=" d-none  d-md-flex flex-row align-items-end justify-content-center ">
                  <div className="container px-1 ps-sm-2 mx-0 pe-lg-1">
                    <TimeLine
                      progress={endvotingdate.current !== '' ? tlUCC : tlUC}
                      contest={contest}
                      cocreationcontest={allCocreationContests}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Body ====================================================================== */}

        {path.includes('brief') && (
          <Brief
            breadcrumbs={(title) => setBreadcurmbs(title)}
            messageBanner={(message) => setMessageBanner(message)}
            accesscodeauth={(auth) => setAccessCodeAuth(auth)}
            ndaauth={(auth) => setNdaAuth(auth)}
          />
        )}
        {path.includes('new') && <UploadInspiration breadcrumbs={(title) => setBreadcurmbs(title)} />}
        {path.includes('edit') && <EditContestInspiration breadcrumbs={(title) => setBreadcurmbs(title)} />}
        {path.includes('inspirations') && !path.includes('new') && !path.includes('edit') && (
          <Inspirations breadcrumbs={(title) => setBreadcurmbs(title)} />
        )}
        {path.includes('awards') && (
          <Awards
            breadcrumbs={(title) => setBreadcurmbs(title)}
            messageBanner={(message) => setMessageBanner(message)}
          />
        )}

        {path.includes('gallery') && <Gallery breadcrumbs={(title) => setBreadcurmbs(title)} />}
        {path.includes('upload') && (
          <UploadContest
            breadcrumbs={(title) => setBreadcurmbs(title)}
            messageBanner={(message) => setMessageBanner(message)}
          />
        )}
      </main>
      {!loggedUser && <PopUpLogin popup={popup} close={() => setPopup(false)} login={(e: boolean) => setPopup(e)} />}
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default ContestSkeleton;
