import { useStoreState } from 'pullstate';
import React, { useState, useRef, useEffect } from 'react';

import axiosHelper from '../../../helpers/axiosHelper';
import DslStore from '../../../store/DslStore';
import { getCurrentUser } from '../../../store/Selectors';

import styles from './DropDown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IDropDown {
  options?: number[];
  onSelect?: (o: number) => void;
  label?: string;
  thin?: boolean;
}

interface IDropDownItem {
  index: number;
  val: number;
}
export interface IDropDownOption extends IDropDownItem {
  index: number;
  filter?: string;
}

export const DropDownYears: React.FC<IDropDown> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const dropDownContainer = useRef<any>(null);
  let [open, setOpen] = useState<boolean>(false);
  let [itemSelected, setItemSelected] = useState(currentUser?.birthYear);
  const items: IDropDownItem[] = [];

  const globalClickListener = (e: MouseEvent) => {
    if (dropDownContainer.current && dropDownContainer.current.contains(e.target)) {
      // setDropDownAnimation(true);
      return;
    } else {
      setOpen(false);
    }
  };

  const handleOnClick = () => {
    if (!open) {
      document.addEventListener('click', (e) => globalClickListener(e));
    } else {
      document.removeEventListener('click', (e) => globalClickListener(e));
    }
    setOpen(!open);
  };

  const handleOnSelect = (option: number) => {
    // console.log(option);
    setItemSelected(option);
    setOpen(!open);
    props.onSelect && props.onSelect(option);
  };
  const getSelectedStyle = (index: number) => {
    return index ? styles.selected : '';
  };

  const optionsList = props.options ? props.options : [];

  // const [dropdownAnimation, setDropDownAnimation] = useState(false);

  // useEffect(() => {
  //   console.log(open);
  // }, [open]);
  return (
    <div ref={dropDownContainer} className={`${styles.dropdown_wrapper_year} ${props.thin && styles.thin_ui}`}>
      <div className={`${styles.dropdown} ${open ? styles.open : ''}`}>
        <div className={`${styles.dropdown__toggle}`} onClick={handleOnClick}>
          {props.label && <div className="fw-bold text-uppercase pe-3"> {props.label}</div>}
          <span className={`${styles.dropdown__label} ${props.label && 'text-center flex-grow-1'}`}>
            {itemSelected ? itemSelected : 'Choose'}
          </span>
          <div className={`${styles.arrow} `}></div>
          {/* <div
            className=" d-flex w-25 flex-row-reverse "
            onClick={handleOnClick}
            // onAnimationEnd={() => setDropDownAnimation(false)}
          > */}
            {/* {!open && (
              <div
                className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}
                // onAnimationEnd={() => setDropDownAnimation(false)}
              >
                <FontAwesomeIcon icon={faAngleDown as IconProp} className={`text color-blue `} />
              </div>
            )}
            {open && (
              <div className={`${styles.dropdown_angle} ${dropdownAnimation ? styles.transitionIn : null}`}>
                <FontAwesomeIcon icon={faAngleUp as IconProp} className={`text color-blue `} />
              </div>
            )} */}
          {/* </div> */}
        </div>

        <div className={`${styles.dropdown__optionslist}`}>
          {optionsList.map((option, index) => (
            <div
              key={index}
              className={`${styles.dropdown__option} `}
              data-value={option}
              onClick={() => handleOnSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
