import React, { useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Icons
import {
  IconDefinition,
  faPencil,
  faLightbulb,
  faDollarSign,
  faTrophy,
  faStar,
  faHeart,
  faBookmark,
  faComment,
} from '@fortawesome/free-solid-svg-icons';

import styles from './contestStats.module.scss';
import { useStoreState } from 'pullstate';
import DslStore from '../../../store/DslStore';
import { getCoCreationContests, getCommunityUser, getCurrentContest } from '../../../store/Selectors';
import { useNavigate } from 'react-router-dom';
import { CoCreationContest } from '../../../global';

interface IContestStats {
  entries?: number;
  inspirations?: number;
  awards?: number;
  contests?: number;
  reputation?: number;
  favorites?: number;
  votes?: number;
  comments?: number;
  ndaAuth?: boolean;
  accessCodeAuth?: boolean;
  isPrivate?: boolean;
}

interface IStatsItem {
  icon: IconDefinition;
  name: string;
  value: number | string;
  clickable?: boolean;
}

const StatsItem = (props: IStatsItem) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const redirect = useNavigate();

  const goTo = () => {
    if (props.clickable) {
      console.log(props.name);
      if (props.name === 'entries') {
        redirect(`/contest/${contest?.nameForUrl}/gallery`);
      } else if (props.name === 'inspirations') {
        redirect(`/contest/${contest?.nameForUrl}/inspirations`);
      } else if (props.name === 'total awards') {
        redirect(`/contest/${contest?.nameForUrl}/awards`);
      } else if (props.name === 'votes received') {
        redirect(`/contest/${contest?.nameForUrl}/gallery`);
      }
    }
  };

  return (
    <div
      className={`col-6 col-lg-3 d-flex flex-column justify-content-center align-items-center text-center mb-4 px-4`}
    >
      <div
        className="text fw-bold w-100 d-flex justify-content-start align-items-center text-left"
        style={{ height: '35px' }}
      >
        <FontAwesomeIcon icon={props.icon as IconProp} className="text color-blue mt-1 me-3" /> {props.name}
      </div>
      <div
        className={`sub-title ${props.clickable ? `color-blue cursor-pointer` : ``}  text-center py-2`}
        onClick={() => goTo()}
      >
        {props.value}
      </div>
    </div>
  );
};
const ContestStats: React.FC<IContestStats> = (props) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const cocreationContest = useStoreState(DslStore, getCoCreationContests);
  const clickableEntries = () => {
    // console.log('CIAO DA CONTESTSTATS ENTRIES');
    if (contest?.contestType !== 'Co-creation') {
      if (props.accessCodeAuth && props.ndaAuth) {
        // console.log('CIAO DA CONTESTSTATS ENTRIES');
        if (!props.isPrivate) {
          return true;
        }
      }
      return false;
    } else {
      // console.log('CIAO DA CONTESTSTATS ENTRIES COCREATION');
      let visible: boolean = true;
      cocreationContest.map((item: CoCreationContest) => {
        if (item.isPrivate || item.hideGalleryTab) {
          visible = false;
        }
      });
      return visible;
    }
  };

  const clickableInspirations = () => {
    // console.log('CIAO DA CONTESTSTATS INSPIRATIONS');
    if (contest?.contestType !== 'Co-creation') {
      if (props.accessCodeAuth && props.ndaAuth) {
        // console.log('CIAO DA CONTESTSTATS INSPIRATIONS');
        if (!contest?.hideInspirationTab) {
          return true;
        }
      }
      return false;
    } else {
      console.log('CIAO DA CONTESTSTATS INSPIRATIONS COCREATION');
      let visible: boolean = true;
      cocreationContest.map((item) => {
        if (item.isPrivate || item.hideInspirationTab) {
          visible = false;
        }
      });
      return visible;
    }
  };

  const clickableAwards = () => {
    // console.log('CIAO DA CONTESTSTATS AWARDS');
    if (contest?.contestType !== 'Co-creation') {
      if (props.accessCodeAuth && props.ndaAuth) {
        // console.log('CIAO DA CONTESTSTATS AWARDS');
        if (!contest?.hideAwardsTab) {
          return true;
        }
      }
      return false;
    } else {
      // console.log('CIAO DA CONTESTSTATS AWARDS COCREATION');
      let visible: boolean = true;
      cocreationContest.map((item) => {
        if (item.isPrivate || item.hideAwardsTab) {
          visible = false;
        }
      });
      return visible;
    }
  };

  return (
    <div className={`row ${styles.statsWrapper}`}>
      {props.entries !== 0 ? (
        props.entries && (
          <StatsItem name="entries" value={props.entries} clickable={clickableEntries()} icon={faPencil} />
        )
      ) : (
        <StatsItem name="entries" value={0} clickable={clickableEntries()} icon={faPencil} />
      )}
      {props.inspirations !== 0 ? (
        props.inspirations && (
          <StatsItem
            name="inspirations"
            clickable={clickableInspirations()}
            value={props.inspirations}
            icon={faLightbulb}
          />
        )
      ) : (
        <StatsItem name="inspirations" clickable={clickableInspirations()} value={0} icon={faLightbulb} />
      )}
      {props.awards !== 0 ? (
        props.awards && (
          <StatsItem
            name="total awards"
            value={'€ ' + props.awards}
            clickable={clickableAwards()}
            icon={faDollarSign}
          />
        )
      ) : (
        <StatsItem name="total awards" value={'€ ' + 0} clickable={clickableAwards()} icon={faDollarSign} />
      )}
      {/* {props.contests !== 0 ? props.contests && <StatsItem name="contests won" value={props.contests} icon={faTrophy} /> : <StatsItem name="contests won" value={0} icon={faTrophy} />} */}
      {/* {props.reputation !== 0 ?props.reputation && <StatsItem name="reputation" value={props.reputation} icon={faStar} /> : <StatsItem name="reputation" value={0} icon={faStar} />} */}
      {/* {props.favorites !== 0 ? props.favorites && <StatsItem name="added to favorites" value={props.favorites} icon={faBookmark} /> : <StatsItem name="added to favorites" value={0} icon={faBookmark} />} */}
      {props.votes !== 0 ? (
        props.votes && (
          <StatsItem name="votes received" clickable={clickableEntries()} value={props.votes} icon={faHeart} />
        )
      ) : (
        <StatsItem name="votes received" value={0} clickable={clickableEntries()} icon={faHeart} />
      )}
      {/* {props.comments !== 0 ? props.comments && <StatsItem name="comments received" value={props.comments} icon={faComment} /> : <StatsItem name="comments received" value={0} icon={faComment} />} */}
    </div>
  );
};

export default ContestStats;

export const ProfileStats: React.FC<IContestStats> = (props) => {
  const communityUser = useStoreState(DslStore, getCommunityUser);

  return (
    <div className={`row ${styles.statsWrapper}`}>
      {props.entries !== 0 ? (
        props.entries && <StatsItem name="entries" value={props.entries} icon={faPencil} />
      ) : (
        <StatsItem name="entries" value={0} icon={faPencil} />
      )}
      {props.inspirations !== 0 ? (
        props.inspirations && <StatsItem name="inspirations" value={props.inspirations} icon={faLightbulb} />
      ) : (
        <StatsItem name="inspirations" value={0} icon={faLightbulb} />
      )}
      {props.awards !== 0 ? (
        props.awards && communityUser?.userType === 'D' ? (
          <StatsItem name="awards won" value={'€ ' + props.awards} icon={faDollarSign} />
        ) : (
          <StatsItem name="awarded" value={'€ ' + props.awards} icon={faDollarSign} />
        )
      ) : props.awards && communityUser?.userType === 'D' ? (
        <StatsItem name="awards won" value={'€ ' + 0} icon={faDollarSign} />
      ) : (
        <StatsItem name="awarded" value={'€ ' + 0} icon={faDollarSign} />
      )}
      {props.contests !== 0 ? (
        props.awards && communityUser?.userType === 'D' ? (
          props.contests && <StatsItem name="contests won" value={props.contests} icon={faTrophy} />
        ) : (
          props.contests && <StatsItem name="contests launched " value={props.contests} icon={faTrophy} />
        )
      ) : props.awards && communityUser?.userType === 'D' ? (
        <StatsItem name="contests won" value={0} icon={faTrophy} />
      ) : (
        <StatsItem name="contests launched" value={0} icon={faTrophy} />
      )}
      {props.reputation !== 0 ? (
        props.reputation && <StatsItem name="reputation" value={props.reputation} icon={faStar} />
      ) : (
        <StatsItem name="reputation" value={0} icon={faStar} />
      )}
      {props.favorites !== 0 ? (
        props.favorites && <StatsItem name="added to favorites" value={props.favorites} icon={faBookmark} />
      ) : (
        <StatsItem name="added to favorites" value={0} icon={faBookmark} />
      )}
      {props.votes !== 0 ? (
        props.votes && <StatsItem name="votes received" value={props.votes} icon={faHeart} />
      ) : (
        <StatsItem name="votes received" value={0} icon={faHeart} />
      )}
      {props.comments !== 0 ? (
        props.comments && <StatsItem name="comments received" value={props.comments} icon={faComment} />
      ) : (
        <StatsItem name="comments received" value={0} icon={faComment} />
      )}
    </div>
  );
};
