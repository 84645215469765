import React, { useEffect, useRef, useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import styles from './Brief.module.scss';

//Header Data

import {
  getCoCreationContestAwards,
  getCoCreationContestStats,
  getContestAwards,
  getContestTags,
  getCurrentContest,
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getTimelinePassedUpload,
  getTimelinePassedCommunityVote,
  getTimelineTotUpload,
  getTimelineTotCommunityVote,
  getTimelinePassedClientVote,
  getTimelineTotClientVote,
  getCoCreationContests,
  getAllCoCreationContests,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setCommunityUser, setContestTags, setContestsILike, setCurrentContest } from '../../store/DslStore';
import { fullPathLogoDesign, fullPathLogoUser, registrationDateString } from '../../helpers/utils';

import axiosHelper from '../../helpers/axiosHelper';

import { faFacebookF, faPinterest, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { AwardRow } from '../../components/Contest/AwardRow/AwardRow';
import { useNavigate, useParams } from 'react-router-dom';
import { Award, CoCreationContest, ContestTag, User } from '../../global';

import { NoContent } from '../../components/Reusable/NoContent';
import SideActionsCard from '../../components/SideActionsCard/SideActionsCard';
import MiniTimeLine from '../../components/TimeLine/MiniTimeLine';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import profileImg from '../../assets/Profile.png';
import designImg from '../../assets/Design.png';
import PopUpSendMessage from '../../components/PopUp/PopUpSendMessage/PopUpSendMessage';

import xLogo from '../../assets/x-twitter-01.png';

interface ContestProps {
  breadcrumbs: (breadcrumbs: string) => void;
  messageBanner: (message: number) => void;
}

const Awards: React.FC<ContestProps> = (props) => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const cocreationcontests = useStoreState(DslStore, getCoCreationContests);
  const allcocreationcontests = useStoreState(DslStore, getAllCoCreationContests);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const contest = useStoreState(DslStore, getCurrentContest);
  const contestTag = useStoreState(DslStore, getContestTags);
  const contestAwards = useStoreState(DslStore, getContestAwards);
  const timelineTotUpload = useStoreState(DslStore, getTimelineTotUpload);
  const timelinePassedUpload = useStoreState(DslStore, getTimelinePassedUpload);
  const timelineTotCommunityVote = useStoreState(DslStore, getTimelineTotCommunityVote);
  const timelinePassedCommunityVote = useStoreState(DslStore, getTimelinePassedCommunityVote);
  const timelineTotClientVote = useStoreState(DslStore, getTimelineTotClientVote);
  const timelinePassedClientVote = useStoreState(DslStore, getTimelinePassedClientVote);
  const cocreationAwards = useStoreState(DslStore, getCoCreationContestAwards);
  const cocreationstats = useStoreState(DslStore, getCoCreationContestStats);
  const redirect = useNavigate();
  const [popup, setPopup] = useState(false);
  const [liked, setLiked] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState(false);
  const [popUpLogin, setPopUpLogin] = useState(false);
  const [sideAnchored, setSideAnchored] = useState(false);
  const date = new Date().toISOString();
  const contestEndDate = useRef('');
  const endvotingdate = useRef('');
  const uploadActive = useRef(false);
  const { nameForUrl } = useParams();
  const path = window.location.href;

  let tlUC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let tlUCC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let minitlUCC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };
  let minitlUC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  const getCorrectEndDate = () => {
    // console.log('correctDate');

    const date = new Date().toISOString();

    // console.log('GET CORRECT END DATE : ');
    if (contest) {
      if (contest?.contestType === 'Co-creation') {
        let contestAttached: CoCreationContest[] = [];
        // console.log('GET CORRECT END DATE : CO-CREATION');
        allcocreationcontests.map((item) => {
          if (contest?.id === item.mainContest.id) {
            contestAttached.push(item);
          }
        });
        // console.log('GET CORRECT END DATE : contestattached fatto' + JSON.stringify(contestAttached));
        contestAttached.map((item) => {
          console.log(item);
          if (item.endVotingDate) {
            endvotingdate.current = item.endVotingDate;
          }
          if (!item.isClosed) {
            if (date > item.startUploadDate && date < item.endUploadDate) {
              // console.log('CO-CREATION UPLOAD');
              contestEndDate.current = `Ends: ${registrationDateString(item.endUploadDate)}`;
              uploadActive.current = true;
              return;
            } else if (item.endVotingDate) {
              if (date > item.endUploadDate && date < item.endVotingDate) {
                // console.log('CO-CREATION COMMUNITY');
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
              } else if (date > item.endUploadDate && date > item.endVotingDate) {
                // console.log('CO-CREATION CLIENT');
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
              }
            } else if (date > item.endUploadDate) {
              // console.log('CO-CREATION CLIENT VOTE');
              contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
            }
          } else {
            // console.log('CO-CREATION CLOSED');
            contestEndDate.current = `Closed: ${registrationDateString(item.endDate)}`;
          }
        });
      } else {
        if (contest.endVotingDate) {
          endvotingdate.current = contest.endVotingDate;
        }
        // console.log('GET CORRECT END DATE : NORMAL CONTEST');
        if (date < contest.endDate && !contest.isClosed) {
          // console.log('GET CORRECT END DATE : UPLOAD PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.endDate)}`;
          uploadActive.current = true;
          return;
        } else if (contest.endVotingDate && date < contest.endVotingDate && !contest.isClosed) {
          // console.log('GET CORRECT END DATE : COMMUNITY VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.endDate)}`;
          return;
        } else if (
          (contest.endVotingDate && date > contest.endVotingDate && !contest.isClosed) ||
          (date > contest.endDate && !contest.isClosed)
        ) {
          // console.log('GET CORRECT END DATE : CLIENT VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.hypotheticalEndDate)}`;
          return;
        } else {
          // console.log('GET CORRECT END DATE : CLOSED PHASE');
          contestEndDate.current = `CLOSED ${registrationDateString(contest.endDate)}`;
          return;
        }
      }
      // return contest?.endDate;
    }
  };

  const contestUnliked = () => {
    if (contest?.id) {
      axiosHelper.unlikeContest(contest?.id).then((response) => {
        setLiked(false);
        // alert(`${props.title} removed from favorites`);
      });
    }
  };
  const contestLiked = () => {
    if (contest?.id) {
      axiosHelper.likeContest(contest?.id).then((response) => {
        setLiked(true);
        if (loggedUser?.username === response.data.username) {
          setContestsILike(response.data.contestsILike);
        }
        // alert(`${props.title} added to favorites`);
      });
    }
  };

  const isLiked = () => {
    // console.log(props.contestId);
    let like: boolean;

    if (!loggedUser) {
      like = false;
      setLiked(like);
    }

    if (loggedUser?.contestsILike) {
      like = loggedUser.contestsILike.some((item: any) => item.id === contest?.id);

      setLiked(like);
    }
  };

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Awards');
    isLiked();
  }, []);

  const goToDetail = async (userid: number) => {
    console.log(userid);

    let result: User = await axiosHelper.retrieveUserById(userid);
    console.log(result);

    setCommunityUser(result);
    redirect(`/community/${result.nameForURL}/profile`);
  };

  const fetchContestByName = (contestName: string) => {
    console.log('BY NAME');
    if (contestName) {
      axiosHelper
        .retrieveContestByName(contestName)
        .then((response) => {
          // console.log(response);
          setCurrentContest(response.data);
          setContestTags(response.data.tags);
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => {
    console.log(path);
  }, []);

  useEffect(() => {
    // console.log('cambiati cocreation contests');
    getCorrectEndDate();
  }, [allcocreationcontests, contest?.name]);

  useEffect(() => {
    console.log(nameForUrl);
    nameForUrl && nameForUrl !== contest?.nameForUrl && fetchContestByName(nameForUrl);
  }, [nameForUrl]);

  return (
    <div className="bg-light-gray">
      <main className={`container pt-5 mb-5 px-sm-0 overflow-hidden`}>
        {/* Body ====================================================================== */}
        <div className="row  ">
          <div className={`col-12 col-md-3 px-5 px-sm-3 position-relative pe-md-4 ${styles.sideContainer}`}>
            <SideActionsCard anchored={(anchored) => setSideAnchored(anchored)}>
              <button
                className={`${liked ? 'btn-danger' : 'btn-blue'} rounded full mb-3 `}
                onClick={() => (loggedUser ? (liked ? contestUnliked() : contestLiked()) : setPopUpLogin(true))}
              >
                {liked ? 'Remove from favorites' : 'Add to favorites'}
              </button>
              <button
                className="btn-blue rounded full mb-3"
                onClick={() => (loggedUser ? setPopUpMessage(true) : setPopUpLogin(true), console.log(popUpMessage))}
              >
                Have a question?
              </button>
              {popUpMessage && (
                <PopUpSendMessage
                  message={(message) => (props.messageBanner(message), setPopUpMessage(false))}
                  popup={popUpMessage}
                  close={() => setPopUpMessage(false)}
                  recipient={'Desall'}
                  contestId={contest?.id}
                />
              )}
              {popUpLogin && (
                <PopUpLogin
                  popup={popUpLogin}
                  close={() => setPopUpLogin(false)}
                  login={(e: boolean) => setPopUpLogin(false)}
                />
              )}
              <MiniTimeLine
                progress={contest?.endVotingDate ? minitlUCC : minitlUC}
                endDate={contest?.endDate}
                contest={contest}
                cocreationcontest={allcocreationcontests}
              />
              {console.log(uploadActive.current)}
              {!contest?.isClosed && contest && uploadActive.current ? (
                <button
                  className="btn-success big full  rounded"
                  style={{ height: '45px' }}
                  onClick={() =>
                    !loggedUser ? setPopup(true) : redirect(`/contest/${contest?.nameForUrl}/upload-contest`)
                  }
                >
                  <div className="btn-icon  ">
                    <FontAwesomeIcon
                      icon={faCloudArrowUp as IconProp}
                      style={{ fontSize: '3rem' }}
                      className="text color-light "
                    />
                  </div>
                  <div>Upload</div>
                </button>
              ) : (
                <button
                  className="btn-dark_grey_90 big full mb-4 rounded "
                  style={{ height: '45px', cursor: 'default' }}
                  disabled={true}
                >
                  <div className="btn-icon " style={{ cursor: 'default' }}>
                    <FontAwesomeIcon
                      icon={faCloudArrowUp as IconProp}
                      style={{ fontSize: '3rem', cursor: 'default' }}
                      className="text color-light"
                    />
                  </div>
                  <div>Upload</div>
                </button>
              )}
            </SideActionsCard>
            {sideAnchored && <div className="bg-light-gray" style={{ height: '410px' }}></div>}
            <div className="tags mt-7 d-flex flex-wrap">
              {contestTag &&
                contestTag.map((item: ContestTag, index: number) => (
                  // console.log(item),
                  <>
                    <button className="btn-tag mb-4 me-4" key={index}>
                      <span>{item.name.replace(',', '')}</span>
                    </button>
                  </>
                ))}
            </div>

            <div className="col-12 col-md-6   position-relative">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=https://desall.com/contest/${contest?.nameForUrl}/brief`}
                target="_blank"
              >
                <button className="btn-social__facebook full rounded mb-3 ">
                  <div className="btn-icon">
                    <FontAwesomeIcon icon={faFacebookF as IconProp} className="text color-light" />
                  </div>
                  <span className="text-lowercase">like</span>
                </button>
              </a>
            </div>
            <div className="col-12 col-md-6   position-relative">
              <a
                href={`http://twitter.com/share?url=https://desall.com/contest/${contest?.nameForUrl}/brief`}
                target="_blank"
              >
                <button className="btn-social__twitter full rounded mb-3">
                  <div className="btn-icon">
                    <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                    {/* <img src={xLogo} className="" style={{ height: '15px', width: '15px' }} /> */}
                  </div>
                  <span className="text-lowercase">post</span>
                </button>
              </a>
            </div>
            <div className="col-12 col-md-6   position-relative">
              <a
                href={`http://pinterest.com/pin/create/button/?url=desall.com/contest/${contest?.nameForUrl}/brief&media=${contest?.image}&description=Check it out on Desall.`}
                target="_blank"
              >
                <button className="btn-social__pinterest full rounded mb-3">
                  <div className="btn-icon">
                    <FontAwesomeIcon icon={faPinterest as IconProp} className="text color-light" />
                  </div>
                  <span className="text-lowercase">save</span>
                </button>
              </a>
            </div>
          </div>

          <div className="col-12 col-md-9  pt-5 px-5 px-sm-0">
            {loggedUser
              ? contest?.contestType !== 'Co-creation' &&
                contestAwards.map((aContestAward: Award, index: number) => (
                  <div className="row mb-4 px-4">
                    <AwardRow
                      key={index}
                      contest={contest}
                      contestAward={aContestAward}
                      image={
                        aContestAward.design
                          ? aContestAward.design.designer.logoAttachment
                            ? aContestAward.design.designer.logoAttachment.s3Path
                            : profileImg
                          : profileImg
                      }
                      designImage={
                        contest?.isPrivate
                          ? designImg
                          : aContestAward.design
                          ? aContestAward.design.image1Attachment
                            ? aContestAward.design.image1Attachment.s3Path
                            : designImg
                          : designImg
                      }
                    />
                  </div>
                ))
              : // <NoContent />
                contest?.contestType !== 'Co-creation' &&
                contestAwards.map((aContestAward: Award, index: number) => (
                  <div className="row mb-4 px-4">
                    {aContestAward.design ? (
                      <AwardRow
                        key={index}
                        contest={contest}
                        contestAward={aContestAward}
                        image={
                          aContestAward.design
                            ? aContestAward.design.designer.logoAttachment
                              ? aContestAward.design.designer.logoAttachment.s3Path
                              : profileImg
                            : profileImg
                        }
                        designImage={designImg}
                      />
                    ) : (
                      <AwardRow
                        key={index}
                        contest={contest}
                        contestAward={aContestAward}
                        image={fullPathLogoUser('')}
                        designImage={contest?.isPrivate ? fullPathLogoDesign('') : fullPathLogoDesign('')}
                      />
                    )}
                  </div>
                ))}
            {contest?.contestType === 'Co-creation' &&
              cocreationcontests.map((item: CoCreationContest) =>
                cocreationAwards.map((aContestAward: Award, index: number) => (
                  <div className="row mb-4  px-4">
                    {(console.log(item), console.log(aContestAward))}
                    {item.id === aContestAward.contest?.id
                      ? (console.log(aContestAward),
                        (
                          <>
                            <span className="text-16 ">
                              <b>
                                {item.contestType.name.toUpperCase()} - {aContestAward.pos}&deg; AWARD
                              </b>
                            </span>
                            <span className="pb-3 text">{aContestAward.name}</span>
                            {!item.isPrivate ? (
                              <AwardRow
                                key={index}
                                contest={contest}
                                contestAward={aContestAward}
                                image={
                                  aContestAward.design
                                    ? aContestAward.design.designer.logoAttachment &&
                                      aContestAward.design.designer.logoAttachment !== null
                                      ? aContestAward.design.designer.logoAttachment.s3Path
                                      : profileImg
                                    : profileImg
                                }
                                designImage={
                                  aContestAward.design
                                    ? aContestAward.design.image1Attachment
                                      ? aContestAward.design.image1Attachment.s3Path
                                      : designImg
                                    : designImg
                                }
                              />
                            ) : loggedUser ? (
                              <AwardRow
                                key={index}
                                contest={contest}
                                contestAward={aContestAward}
                                image={
                                  aContestAward.design
                                    ? aContestAward.design.designer.logoAttachment &&
                                      aContestAward.design.designer.logoAttachment !== null
                                      ? aContestAward.design.designer.logoAttachment.s3Path
                                      : profileImg
                                    : profileImg
                                }
                                designImage={
                                  aContestAward.design
                                    ? aContestAward.design.image1Attachment
                                      ? aContestAward.design.image1Attachment.s3Path
                                      : designImg
                                    : designImg
                                }
                              />
                            ) : (
                              <AwardRow
                                key={index}
                                contest={contest}
                                contestAward={aContestAward}
                                image={
                                  aContestAward.design
                                    ? aContestAward.design.designer.logoAttachment &&
                                      aContestAward.design.designer.logoAttachment !== null
                                      ? aContestAward.design.designer.logoAttachment.s3Path
                                      : profileImg
                                    : profileImg
                                }
                                designImage={designImg}
                              />
                            )}
                          </>
                        ))
                      : ''}
                  </div>
                ))
              )}
          </div>
        </div>
      </main>
      {!loggedUser && <PopUpLogin close={() => setPopup(false)} popup={popup} login={(e: boolean) => setPopup(e)} />}
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};

export default Awards;
