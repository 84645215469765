import React from 'react';

interface IHorizontalSection {
  bgColor?: string;
}

const HorizontalSection: React.FC<IHorizontalSection> = (props) => {
  return (
    <section
      className="container-fluid "
      style={{
        backgroundColor: `${props.bgColor ?? 'transparent'}`,
      }}
    >
      <div className="container ">
        <div className="row">
          <div className="col-12 px-0">{props.children}</div>
        </div>
      </div>
    </section>
  );
};

export default HorizontalSection;
