import React, { useEffect, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import footerImg from '../../assets/footer_placeholder_transparent.png';
import ContestCard from '../../components/Contest/ContestCard/ContestCard';
import ContestRow from '../../components/Contest/ContestRow/ContestRow';
import ContestRowBig from '../../components/Contest/ContestRowBig/ContestRowBig';
import { Button, Modal } from 'react-bootstrap';
import {
  getContests,
  getLayoutContest,
  getContestPageNumber,
  getFooterMenu,
  getHeaderMenu,
  getContestsFiltered,
  getAllCoCreationContests,
  getContestCategories,
  getSearchResultContest,
  getContestSearchPageNumber,
  getSearchResultContestFiltered,
} from '../../store/Selectors';
import DslStore, {
  fetchContest,
  setLayoutContest,
  setContestPageNumber,
  fetchCoCreationContest,
  setCategories,
} from '../../store/DslStore';
import { useStoreState } from 'pullstate';
import { choseUserName, numberVotes, startDateString, typeCategoryString } from '../../helpers/utils';
import ContestsNavigation, { PaginationSwitch } from '../../components/Contest/ContestsNavigation/ContestsNavigation';
import { Award, CoCreationContest, Contest, ContestStatus, ItemLiked } from '../../global';
import { NoContent } from '../../components/Reusable/NoContent';
import ContestSearchNavigation from '../../components/SearchNavigation/ContestSearchNavigation';

// const mockGalleryImages = [
//   'https://source.unsplash.com/_HqHX3LBN18/800x680',
//   'https://source.unsplash.com/OzqieLcs464/800x680',
//   'https://source.unsplash.com/RFP4D5hGTB0/800x680',
//   'https://source.unsplash.com/rvftn4hX7AY/800x680',
//   'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
// ];

// const mockGalleryImagesHalf = [
//   'https://source.unsplash.com/OzqieLcs464/800x680',
//   'https://source.unsplash.com/rvftn4hX7AY/800x680',
//   'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
// ];

const ContestSearch: React.FC = () => {
  const cocreationcontests = useStoreState(DslStore, getAllCoCreationContests);
  const contestCategories = useStoreState(DslStore, getContestCategories);
  const allContests = useStoreState(DslStore, getSearchResultContestFiltered);
  //   const sortedContests = useStoreState(DslStore, getContestsFiltered);
  const layoutContest = useStoreState(DslStore, getLayoutContest);
  const pageNumber = useStoreState(DslStore, getContestSearchPageNumber);
  const [popup, setPopup] = useState(false);
  const [popupLink, setPopupLink] = useState('');
  const date = new Date().toISOString();

  const [visibleContests, setVisibleContests] = useState<Contest[]>([]);

  const arraysfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: Contest[] = [];
    const chunk: Contest[] = allContests.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < allContests.length; i += chunkSize) {
      const chunk: Contest[] = allContests.slice(startchunk, startchunk + chunkSize);
    }
    for (let i = 0; i < chunkSize; i++) {
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    setVisibleContests(tempArray);
  };

  useEffect(() => {
    // console.log('sono dentro contest');
    // console.log(allContests);
    // fetchContestMini.run().then((data) => {
    //   console.log(data);
    // });
    // fillContests((pageNumber -1));

    arraysfill();
  }, []);

  useEffect(() => {
    arraysfill();
  }, [allContests]);

  useEffect(() => {
    // console.log(pageNumber);
    setVisibleContests(allContests);
    // fillContests(pageNumber -1);
    arraysfill();
  }, [pageNumber]);

  const handleClick = (e: any) => {
    let url: string = e.split('/')[2];
    let linkto = e.split('/')[3];
    if (url.includes('bit.ly')) {
      // console.log(url);
      // setPopupLink(e);
      // setPopup(!popup);
      // console.log(linkto);
      //redirect hidden menu
    } else {
      setPopupLink(e);
      setPopup(!popup);
    }
  };

  const statusText = (contest: Contest) => {
    // console.log(date);
    // console.log(endDate);

    // console.log(date > endDate);
    if (contest.contestType !== 'Co-creation') {
      if (contest.isClosed && contest.endVotingDate) {
        return `closed: ${startDateString(contest.endVotingDate)}`;
      } else if (contest.isClosed && contest.endDate) {
        return `closed: ${startDateString(contest.endDate)}`;
      } else if (date < contest.startDate) {
        return 'coming soon';
      } else if (date < contest.endDate) {
        return `ends:  ${startDateString(contest.endDate)}`;
      } else if (contest.endVotingDate && date < contest.endVotingDate) {
        return `community vote`;
        // console.log(endVotingDate);
        // console.log(date > endVotingDate);
      } else if (contest.endVotingDate && date > contest.endVotingDate && !contest.isClosed) {
        return `client vote`;
      } else if (date > contest.endDate && !contest.isClosed) {
        return `client vote`;
      }
    } else {
      const contestAttached: CoCreationContest[] = [];
      let stat: ContestStatus = 'ongoing';
      cocreationcontests.map((item: CoCreationContest) => {
        if (item.mainContest.id === contest.id) {
          contestAttached.push(item);
        }
      });

      contestAttached.map((item: CoCreationContest, index: number) => {
        // console.log(item);
        // console.log(item);
        // console.log(stat);
        // console.log(contestAttached.length);
        if (stat !== 'closed' && index < contestAttached.length)
          if (item.isClosed) {
            stat = 'closed';
          } else if (date < item.startUploadDate) {
            stat = 'coming-soon';
          } else if (date < item.endUploadDate) {
            stat = 'ongoing';
          } else if (item.endVotingDate && date < item.endVotingDate) {
            stat = 'community-vote';
          } else if (item.endVotingDate && date > item.endVotingDate && !item.isClosed) {
            stat = 'client-vote';
          } else if (date > item.endVotingDate && !item.isClosed) {
            stat = 'client-vote';
          }
      });
      return stat;
    }
    return `standby`;
  };

  const checkStatus = (contest: Contest) => {
    // console.log(date);
    // console.log(endDate);
    if (contest.contestType !== 'Co-creation') {
      // console.log(date > endDate);
      if (contest.isClosed) {
        return 'closed';
      } else if (date < contest.startDate) {
        return 'coming-soon';
      } else if (date < contest.endDate) {
        return 'ongoing';
      } else if (contest.endVotingDate && date < contest.endVotingDate) {
        return 'community-vote';
        // console.log(endVotingDate);
        // console.log(date > endVotingDate);
      } else if (contest.endVotingDate && date > contest.endVotingDate && !contest.isClosed) {
        return 'client-vote';
      } else if (date > contest.endDate && !contest.isClosed) {
        return 'client-vote';
      }
      return 'standby';
    } else {
      const contestAttached: CoCreationContest[] = [];
      let stat: ContestStatus = 'ongoing';
      cocreationcontests.map((item: CoCreationContest) => {
        if (item.mainContest.id === contest.id) {
          contestAttached.push(item);
        }
      });

      contestAttached.map((item: CoCreationContest, index: number) => {
        // console.log(item);
        // console.log(item);
        // console.log(stat);
        // console.log(contestAttached.length);
        if (stat !== 'closed' && index < contestAttached.length)
          if (item.isClosed) {
            stat = 'closed';
          } else if (date < item.startUploadDate) {
            stat = 'coming-soon';
          } else if (date < item.endUploadDate) {
            stat = 'ongoing';
          } else if (item.endVotingDate && date < item.endVotingDate) {
            stat = 'community-vote';
          } else if (item.endVotingDate && date > item.endVotingDate && !item.isClosed) {
            stat = 'client-vote';
          } else if (date > item.endVotingDate && !item.isClosed) {
            stat = 'client-vote';
          }
      });
      return stat;
    }
  };

  useEffect(() => {
    if (cocreationcontests.length === 0) {
      fetchCoCreationContest.run();
    }

    if (contestCategories) setCategories.run();
  }, []);

  //FIXME FIXME FIXME CONTEST LIKED

  return (
    <div className="bg-light-gray">
      {/* <PageNavigation bgColor="#E3E3E3" links={topNavPages} /> */}
      <main className="container-md   px-sm-5 px-lg-0  mb-5">
        <div className="row ">
          <div className="col-12 px-xl-4 px-lg-3 mb-3 ">
            <ContestSearchNavigation onChangeLayout={setLayoutContest} />
          </div>
        </div>
        <Modal show={popup} onHide={() => setPopup(!popup)} className={`d-flex flex-column justify-content-center`}>
          <div className="d-flex flex-column justify-content-center text-center">
            <Modal.Header className="d-flex justify-content-center">
              <h3>
                <b> You are leaving desall.com </b>
              </h3>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
              <h5>You will be redirected to the Artsana platform innovation.artsana.com</h5>
            </Modal.Body>
          </div>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button className="btn-red " onClick={() => setPopup(!popup)}>
              Close
            </Button>
            <a href={popupLink} target="_self">
              <Button className="btn-success" onClick={() => setPopup(!popup)}>
                Continue
              </Button>
            </a>
          </Modal.Footer>
        </Modal>

        {/* <div className="row mt-lg-3 px-sm-2 px-lg-3 px-xl-4 ">
          {visibleContests?.map(
            (aContest: Contest, index: number) =>
              // console.log(aContest),
              // console.log(checkStatus(aContest.isClosed, aContest.startDate, aContest.endDate, aContest.endVotingDate)),
              checkStatus(aContest) === 'ongoing' &&
              aContest.isPublished && (
                // console.log(aContest),
                <div className=" px-sm-1 px-md-0">
                  <ContestRowBig
                    key={index}
                    contest={aContest}
                    image={aContest?.image}
                    contestTitle={aContest?.name}
                    entries={aContest?.entries}
                    award={aContest?.prices}
                    category={aContest.category}
                    endDate={startDateString(aContest?.endDate)}
                    clientName={choseUserName(aContest)}
                    private={aContest.isPrivate}
                    accesscode={aContest.accessCode}
                    nda={aContest.nda}
                    nameforurl={aContest.nameForUrl}
                    statusText={statusText(aContest)}
                    status={checkStatus(aContest)}
                    progress={60}
                    contestType={aContest.contestType}
                    linkToAward={aContest.linkToAward}
                    linkToUrl={aContest.linkToUrl}
                    click={handleClick}
                  ></ContestRowBig>
                </div>
              )
          )}
        </div> */}

        <div className="row  px-lg-1 ">
          {layoutContest === 1
            ? visibleContests?.map(
                (aContest: Contest, index: number) => (
                  checkStatus(aContest),
                  // console.log(aContest.accessCode),
                  //   checkStatus(aContest) !== 'ongoing' && aContest.isPublished && (
                  aContest.isPublished && (
                    // console.log(aContest),
                    <div className=" col-12  col-md-6 col-lg-3 py-3">
                      <ContestCard
                        key={index}
                        // liked={isLiked(aContest.id)}
                        contestId={aContest.id}
                        startDate={aContest.startDate}
                        endVotingDate={aContest.endVotingDate}
                        image={aContest?.image}
                        title={aContest?.name}
                        statusText={statusText(aContest)}
                        entries={numberVotes(aContest?.entries)}
                        category={aContest.category}
                        endDate={startDateString(aContest?.endDate)}
                        name={choseUserName(aContest)}
                        award={aContest?.prices}
                        linkToAward={aContest.linkToAward}
                        linkToUrl={aContest.linkToUrl}
                        private={aContest.isPrivate}
                        accesscode={aContest.accessCode}
                        global={!aContest.accessCode}
                        nda={aContest.nda}
                        status={checkStatus(aContest)}
                        userId={aContest.userId}
                        contestType={aContest.contestType}
                        nameforurl={aContest.nameForUrl}
                        click={handleClick}
                      ></ContestCard>
                    </div>
                  )
                )
              )
            : ''}
        </div>
        <div className="row mt-4   px-lg-4">
          {layoutContest === 2
            ? visibleContests?.map(
                (aContest: Contest, index: number) =>
                  checkStatus(aContest) !== 'ongoing' &&
                  aContest.isPublished && (
                    <ContestRow
                      // liked={isLiked(aContest.id)}
                      key={index}
                      contestId={aContest.id}
                      endVotingDate={aContest.endVotingDate}
                      image={aContest.image}
                      endDate={aContest.endDate}
                      contestTitle={aContest.name}
                      entries={numberVotes(aContest?.entries)}
                      award={aContest.prices}
                      category={aContest.category}
                      startDate={aContest.startDate}
                      clientName={choseUserName(aContest)}
                      galleryImages={[]}
                      statusText={statusText(aContest)}
                      status={checkStatus(aContest)}
                      progress={60}
                      private={aContest.isPrivate}
                      accesscode={aContest.accessCode}
                      nda={aContest.nda}
                      userId={aContest.userId}
                      nameforurl={aContest.nameForUrl}
                      contestType={aContest.contestType}
                      linkToUrl={aContest.linkToUrl}
                      linkToAward={aContest.linkToAward}
                      hideGallery={aContest.hideGalleryTab}
                    ></ContestRow>
                  )
              )
            : ''}
        </div>
        <div className="row mt-4 px-3 ">{visibleContests.length === 0 && <NoContent />}</div>

        <div className="row ">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
            <PaginationSwitch onChangePage={setContestPageNumber} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContestSearch;
