import React, { useEffect, useState, useRef } from 'react';

//Header Data

import { getCommunityUser, getCurrentContest, getCurrentUser, getInspirationToEdit } from '../../store/Selectors';
import DslStore, { setCurrentUser, setUserInspirations, setUserInspirationsCopy } from '../../store/DslStore';
import { useStoreState } from 'pullstate';
import styles from './Profile.module.scss';

import { Contest, ContestTag, LogoAttachment, UserInspiration, UserInspirationUpload } from '../../global';

import { faCircleExclamation, faCircleQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import axiosHelper from '../../helpers/axiosHelper';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DropDownContactUs } from '../../components/Forms/DropDown/DropDownContactUs';
import { TailSpin } from 'react-loader-spinner';

interface IDropDownItem {
  label: string;
  value: string;
  contestId?: number;
}

interface ProfileProps {
  breadcrumbs: (breadcrumbs: string) => void;
  // messageBanner: (message: number) => void;
}

const DesignerInspirationUpload: React.FC<ProfileProps> = (props) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const inspiration = useStoreState(DslStore, getInspirationToEdit);
  const [privacy, setPrivacy] = useState(inspiration ? inspiration.isPrivate : false);
  const [url, setUrl] = useState(inspiration ? inspiration.websiteURL : '');
  const [progress, setProgress] = useState(0);
  const [textDescription, setTextDescription] = useState(inspiration ? inspiration.textDescription : '');
  const [image, setImage] = useState<LogoAttachment>();
  const [activeContestsDDItems, setActiveContestsDDItems] = useState<IDropDownItem[]>([]);
  const [removeImage, setRemoveImage] = useState('false');
  const itemSelected = useRef<IDropDownItem>();
  const [inspirationContest, setInspirationContest] = useState<Contest>();
  const imageError = useRef(false);
  const urlError = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserInspirationUpload>();
  const redirect = useNavigate();
  const tempUpload = useRef('');
  const [uploadedCoverImage, setUploadedCoverImage] = useState<File>();

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Profile');
  }, []);

  const handleDDSelection = (e: IDropDownItem) => {
    // setItemSelected(e);
    itemSelected.current = e;
  };

  useEffect(() => {
    if (inspiration) {
      setImage(inspiration.imageAttachment);
    }
  }, []);

  useEffect(() => {
    console.log(image);
  }, [image]);

  const findActiveContest = (ongoingContests: Contest[]) => {
    const found = ongoingContests.find((element: Contest) => element.id === inspiration?.contest?.id);
    setInspirationContest(found);
  };

  const populateDDItems = (ongoingContests: Contest[]) => {
    const activeDDItems: IDropDownItem[] = [{ label: 'My Wall', value: '0', contestId: 0 }];
    ongoingContests.map((item: Contest) => {
      activeDDItems.push({ label: item.name, value: `${item.id}`, contestId: item.id });
    });
    setActiveContestsDDItems(activeDDItems);
  };

  useEffect(() => {
    axiosHelper.getOngoingContest().then((response) => {
      populateDDItems(response);
      findActiveContest(response);
    });
  }, []);

  const onDropCover = (e: any) => {
    if (!image) {
      console.log('no image');
      setUploadedCoverImage(e?.[0]);
      imagePresignedUrl(e?.[0]);
    } else {
      setImage(undefined);
      setUploadedCoverImage(e?.[0]);
      imagePresignedUrl(e?.[0]);
    }
  };

  const onSubmit = async (data: UserInspirationUpload) => {
    console.log('submit');
    console.log(data);

    data.isPrivate = privacy;
    data.tempUploads = tempUpload.current;
    imageError.current = false;
    urlError.current = false;

    if (inspiration?.contest?.id) {
      data.contestId = inspiration.contest.id;
    }

    if (textDescription) {
      data.textDescription = textDescription;
    }

    console.log('submit', data);
    console.log(itemSelected.current?.contestId);
    if (itemSelected.current?.contestId && itemSelected.current.contestId > 0) {
      console.log('ciao');
      data.contestId = itemSelected.current.contestId;
    }

    if (!data.url) {
      if (!data.tempUploads && !data.url) {
        imageError.current = true;
        urlError.current = true;
      }
    }

    if (!inspiration) {
      if (uploadedCoverImage || data.url) {
        console.log('create');
        setProgress(1);
        await axiosHelper
          .createUserInspiraiton(data)
          ?.then(async (response) => {
            console.log(response);
            setUserInspirations([]);
            setUserInspirationsCopy([]);
            await axiosHelper.getUserInspirations(currentUser?.id)?.then(async (response) => {
              await axiosHelper.retrieveUserById(currentUser?.id)?.then((response) => {
                setCurrentUser(response);
              });
              setUserInspirations(response.inspirations);
              setUserInspirationsCopy(response.inspirations);
              redirect(`/community/${currentUser?.nameForURL}/designer-inspirations`);
            });
          })
          .catch((e) => {
            console.log(e);
            setProgress(0);
          });
      } else {
        if (!uploadedCoverImage) {
          imageError.current = true;
        }
        if (!data.url) {
          urlError.current = true;
        }
      }
    } else {
      if (imageError.current === false && urlError.current === false) {
        console.log('update');
        console.log(removeImage);
        setProgress(1);
        await axiosHelper
          .updateUserInspiration(data, inspiration.id, removeImage)
          ?.then(async (response) => {
            setUserInspirations([]);
            setUserInspirationsCopy([]);
            await axiosHelper.getUserInspirations(currentUser?.id)?.then(async (response) => {
              await axiosHelper.retrieveUserById(currentUser?.id)?.then((response) => {
                setCurrentUser(response);
              });
              setUserInspirations(response.inspirations);
              setUserInspirationsCopy(response.inspirations);
              redirect(`/community/${currentUser?.nameForURL}/designer-inspirations`);
            });
          })
          .catch((e) => {
            console.log(e);
            setProgress(0);
          });
      }
    }
  };

  const handleUrl = (url: string) => {
    urlError.current = false;
    imageError.current = false;
    setUrl(url);
  };

  async function deleteInspiration(inspiration: UserInspiration) {
    await axiosHelper.deleteInspiration(inspiration.id).then((response) => {
      console.log(response);
      setUserInspirations(response);
      setUserInspirationsCopy(response);
      redirect(`/community/${currentUser?.nameForURL}/designer-inspirations`);
    });
  }

  async function imagePresignedUrl(file: File) {
    return await axiosHelper
      .getPresignedUrl(0, file.name, file.size, file.type)
      ?.then(async (response) => {
        console.log('id: ');
        console.log(response.data.id);
        tempUpload.current = response.data.id;
        return await axios
          .put(response.data.payload, file)
          .then((risposta) => {
            console.log(risposta);
            // updateString(response.data.id);
          })
          .catch((error) => {
            setProgress(0);
            throw error;
          });
      })
      .catch((error) => {
        setProgress(0);
        throw error;
      });
  }

  useEffect(() => {
    console.log(privacy);
  }, [privacy]);

  return (
    <div className="bg-light-gray">
      {/* <PageNavigation bgColor="#E3E3E3" links={topNavPages} /> */}
      <main className={`${styles.profileWrapper} container pt-5  mb-5 overflow-hidden`}>
        <div className={`row mb-5 `}></div>
        <div className="col-12 pt-5 px-5  px-sm-0">
          {contest?.contestType !== 'Co-creation' && (
            <div>
              <div className="row col-12 ">
                <div className="col-12  col-md-3  ">
                  {communityUser?.id !== currentUser?.id && (
                    <div className="buttons">
                      <button className="btn-blue rounded full  mb-3">Add to favorite</button>
                      <button className="btn-blue rounded full mb-3">Send message</button>
                      <button className="btn-red rounded big full mb-3">Block</button>
                    </div>
                  )}
                  <div className="tags  d-flex flex-wrap">
                    {communityUser?.tags &&
                      communityUser?.tags.map((item: ContestTag, index: number) => (
                        <>
                          <button className="btn-tag mb-4 ">
                            <span>{item.name.replace(',', '')}</span>
                          </button>
                        </>
                      ))}
                    {/* <button className="btn-tag mb-4 ">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
                  </div>
                </div>
                <div className=" col-md-9 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12   px-0">
                      <div className="row mb-5 ">
                        <div className="col-12 col-md-2   mb-3"></div>
                        {/* <div className="col-12 col-md-2 border mb-3"></div>
                          <div className="col-12 col-md-10 text  py-5">
                            <i>
                              To upload your inspiration, you have to fill the mandatory fields (
                              <span className="color-red">*</span>)
                            </i>
                          </div> */}

                        <div className="col-12 col-md-2 text fw-bold mb-3">
                          <div className=" d-flex justify-content-between  ">
                            Inspiration for
                            <div className="ml-auto">
                              <FontAwesomeIcon icon={faCircleQuestion as IconProp} className=" text color-blue me-0" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-8">
                          {!inspiration ? (
                            <DropDownContactUs
                              options={activeContestsDDItems}
                              // contestName={inspirationContest ? inspirationContest.name : ''}
                              onSelect={(e) => handleDDSelection(e)}
                            />
                          ) : (
                            <DropDownContactUs
                              options={activeContestsDDItems}
                              contestName={inspirationContest ? inspirationContest.name : 'My Wall'}
                              onSelect={(e) => handleDDSelection(e)}
                            />
                          )}
                        </div>
                      </div>

                      <div className="row mb-5 ">
                        <div className="col-12 col-md-2  mb-3"></div>
                        {/* <div className="col-12 col-md-2 mb-3"></div>
                          <div className="col-12 col-md-10 text  py-5">
                            <i>
                              To upload your inspiration, you have to fill the mandatory fields (
                              <span className="color-red">*</span>)
                            </i>
                          </div> */}
                        <div className="col-12 col-md-2 text  fw-bold mb-3">
                          <div className=" d-flex justify-content-between">
                            URL
                            <div className="ml-auto">
                              <FontAwesomeIcon icon={faCircleQuestion as IconProp} className=" text color-blue me-0" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-8 ">
                          {inspiration?.websiteURL ? (
                            <input
                              type="text"
                              {...register('url', { onChange: (e) => handleUrl(e.target.value) })}
                              value={url}
                              placeholder="Please use the form http:// "
                              className="text-start"
                            />
                          ) : (
                            <input
                              type="text"
                              {...register('url')}
                              placeholder="Please use the form https:// "
                              className="text-start"
                            />
                          )}
                          {urlError.current && (
                            <span className="color-dark_red d-flex flex-row align-items-center mt-2 justify-content-center">
                              <FontAwesomeIcon icon={faCircleExclamation} className="color-dark_red me-2" /> please
                              provide an URL or an image
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row mb-5">
                        <div className="col-12 col-md-2  mb-3"></div>
                        <div className="col-12 col-md-2 text fw-bold mb-3">
                          <div className=" d-flex justify-content-between ">
                            <div>Description</div>
                            <div className=" ml-auto">
                              <FontAwesomeIcon icon={faCircleQuestion as IconProp} className=" text color-blue me-0 " />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-8  ">
                          {inspiration?.textDescription ? (
                            <textarea
                              placeholder="maximum 140 characters"
                              required={false}
                              {...register('textDescription', {
                                maxLength: 140,
                                onChange: (e) => setTextDescription(e.target.value),
                              })}
                              value={textDescription}
                            />
                          ) : (
                            <textarea
                              placeholder="maximum 140 characters"
                              required={false}
                              {...register('textDescription', { maxLength: 140 })}
                            />
                          )}
                        </div>
                      </div>

                      <div className="row mb-5">
                        <div className="col-12 col-md-2  mb-3"></div>
                        <div className="col-12 col-md-2 text fw-bold mb-3">
                          <div className=" d-flex justify-content-between ">
                            <div className="mt-3">Image file</div>
                          </div>
                        </div>
                        <div
                          className={`col-12  col-md-8 ${
                            uploadedCoverImage || image ? '' : 'mb-5'
                          } d-flex flex-column align-items-center justify-content-center  ${
                            styles.uploadCoverContainer
                          }`}
                        >
                          {/* <label
                              htmlFor="images"
                              className={`${styles.dropcontainercoverimages} d-flex position-relative flex-column justify-content-center align-items-center text-center px-5`}
                            > */}
                          <Dropzone onDrop={onDropCover} accept="image/png, image/jpg, image/jpeg" maxSize={31457280}>
                            {({ getRootProps, getInputProps }) => (
                              <section
                                className={`${styles.dropzone}  bg-light d-flex align-items-center justify-content-center`}
                                {...getRootProps()}
                              >
                                <div className="col-12 d-flex  flex-column align-items-center justify-content-center text-center">
                                  <input {...getInputProps()} />
                                  <span>
                                    Drag and drop an image or <span className="color-blue">choose a file</span> from
                                    your computer <br />
                                    (allowed formats: .jpg, .png)
                                  </span>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          {imageError.current && (
                            <span className="color-dark_red d-flex flex-row align-items-center mt-2 justify-content-center">
                              <FontAwesomeIcon icon={faCircleExclamation} className="color-dark_red me-2" /> please
                              provide an URL or an image
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={`row mb-5 ${uploadedCoverImage || image ? '' : 'd-none'} `}>
                        <div className="col-12 col-md-2  mb-3"></div>
                        <div className="col-12 col-md-2 text fw-bold mb-3">
                          <div className=" d-flex justify-content-between "></div>
                        </div>
                        <div
                          className={`col-12 col-md-8 mb-5  d-flex flex-column align-items-center justify-content-center  `}
                        >
                          {uploadedCoverImage && (
                            <div className=" d-flex" style={{ width: '100%' }}>
                              <div className={`${styles.previewImg}`}>
                                <img src={URL.createObjectURL(uploadedCoverImage)} className={`${styles.previewImg}`} />
                              </div>
                              <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                <span className="text-xl">{uploadedCoverImage.name}</span>
                              </div>
                              <div className=" d-flex align-items-center justify-content-end ">
                                <div
                                  // className="btn-danger"
                                  className={`${styles.removeButton}`}
                                  style={{ borderRadius: '8px' }}
                                  onClick={() => setUploadedCoverImage(undefined)}
                                >
                                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                </div>
                              </div>
                            </div>
                          )}
                          {image && (
                            <div className=" d-flex " style={{ width: '100%' }}>
                              <div className={`${styles.previewImg}`}>
                                <img src={image.s3Path} className={`${styles.previewImg}`} />
                              </div>
                              <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                <span className="text-xl">{image.originalFileName}</span>
                              </div>
                              <div className=" d-flex align-items-center justify-content-end ">
                                <div
                                  // className="btn-danger"
                                  className={`${styles.removeButton}`}
                                  style={{ borderRadius: '8px' }}
                                  onClick={() => (setImage(undefined), setRemoveImage('true'))}
                                >
                                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-5">
                        <div className="col-12 col-md-2  mb-3"></div>
                        <div className="col-12  justify-content-center col-md-2 text align-middle fw-bold mb-3">
                          Content privacy
                        </div>
                        <div className="col-12 col-md-8">
                          <input
                            type="radio"
                            className="privacy align-middle me-4"
                            onClick={() => setPrivacy(false)}
                            checked={!privacy}
                          />
                          <span className=" me-5"> Public </span>
                          &nbsp;
                          <input
                            type="radio"
                            className="privacy align-middle me-4"
                            onClick={() => (setPrivacy(true), console.log(privacy))}
                            checked={privacy}
                          />
                          <span className="me-5"> Private </span>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col-12 col-md-2  mb-3"></div>
                        <div className="col-12  col-md-2 mb-3"></div>
                        <div className="col-12 col-md-8">
                          {!inspiration && (
                            <div className=" d-flex align-items-center justify-content-center">
                              {/* <button className="btn-blue rounded col-md-3 ">Publish</button> */}
                              <div className="d-flex flex-row justify-content-center col-md-3 ">
                                {progress === 0 && <button className="btn-blue rounded full">Publish</button>}

                                {progress !== 0 && (
                                  <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                                    <TailSpin
                                      height="15"
                                      width="15"
                                      color="#7997c5"
                                      wrapperStyle={{}}
                                      wrapperClass="me-2"
                                      visible={true}
                                      ariaLabel="tail-spin-loading"
                                    />
                                    &nbsp;
                                    <span>Uploading</span>
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                          {inspiration && (
                            <div className=" d-flex align-items-center justify-content-around  ">
                              <div
                                className={`btn-danger rounded col-md-3`}
                                onClick={() => deleteInspiration(inspiration)}
                              >
                                Delete
                              </div>

                              <button className="btn-success rounded col-md-3">Save</button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          ;
        </div>
        <div className="row mt-3 px-4"></div>
      </main>
    </div>
  );
};

export default DesignerInspirationUpload;
