import { useStoreState } from 'pullstate';
import DslStore, { setCustomUsers } from '../../../store/DslStore';
import { getCurrentUser, getCustomUsers } from '../../../store/Selectors';
import { CurstomCommunityProps, User } from '../../../global';
import DesignerSquare from '../../Community/DesignerSquare/DesignerSquare';
import { fullPathLogoCommunity } from '../../../helpers/utils';
import { useEffect } from 'react';
import axiosHelper from '../../../helpers/axiosHelper';
import Skeleton from '@mui/material/Skeleton';

function Custom4Users(props: CurstomCommunityProps) {
  const users = useStoreState(DslStore, getCustomUsers);
  const loggedUser = useStoreState(DslStore, getCurrentUser);

  useEffect(() => {
    if (users.length === 0) {
      fetchUsers();
    }
  });

  const fetchUsers = async () => {
    if (props.username1 && props.username2 && props.username3 && props.username4) {
      await axiosHelper
        .retrieveCustom4Users(props.username1, props.username2, props.username3, props.username4)
        .then((response) => {
          setCustomUsers(response);
        });
    }
  };
  const isLiked = (idUser?: number) => {
    if (idUser) {
      let state: boolean = false;
      loggedUser &&
        loggedUser?.userType !== 'C' &&
        loggedUser.usersILike.findIndex((item) => (item.id === idUser ? (state = true) : null));
      return state;
    }
    return false;
  };

  return (
    <section className="container py-5  ">
      <div className="row color-danger">
        <div className="col-12">
          {/* <CommunityNavigation onChangeLayout={setLayoutCommunity} /> */}
          <h2 className="fw-bold color-dark px-3 mb-3">{props.title} </h2>
        </div>
      </div>
      <div className="row  mb-5 ">
        {users.length > 0 ? (
          users?.map((aUser: User) => (
            // console.log(aUser),

            // <DesignerCard
            //   key={index}
            //   nameForURL={aUser.nameForURL}
            //   user={aUser}
            //   circle
            //   reputation={aUser?.reputationCount}
            //   entries={numberVotes(aUser?.badgeDesignsOut)}
            //   awarded={aUser?.badgeAmountIn}
            //   userType={userTypeString(aUser?.userType)}
            //   role={jobString(aUser?.jobName)}
            //   location={locationString(aUser?.city, aUser?.countryName)}
            //   startDate={aUser?.registrationDate}
            //   name={aUser?.username}
            //   image={fullPathLogoCommunity(aUser?.profilePic)}
            //   liked={isLiked(aUser.id)}
            // ></DesignerCard>
            <div className="col-6 col-md-6  mb-4 col-lg-3 d-flex flex-row align-items-center justify-content-center">
              <DesignerSquare
                image={fullPathLogoCommunity(aUser.profilePic)}
                name={aUser?.username}
                circle
                user={aUser}
                nameForURL={aUser.nameForURL}
                liked={isLiked(aUser.id)}
              />
            </div>
          ))
        ) : (
          <>
            <div className=" col-12 d-flex flex-row mt-3 mb-4">
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
            </div>
            {/* {!props.limit && (
              <div className=" col-12 d-flex flex-row">
                <div className=" col-3 d-flex align-items-center justify-content-center">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
                </div>
                <div className=" col-3 d-flex align-items-center justify-content-center">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
                </div>
                <div className=" col-3 d-flex align-items-center justify-content-center">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
                </div>
                <div className=" col-3 d-flex align-items-center justify-content-center">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
                </div>
              </div>
            )} */}
          </>
        )}
      </div>
    </section>
  );
}

export default Custom4Users;
