import React, { useEffect, useState } from 'react';
import CardInfo, { ICardInfo } from '../CardInfo/CardInfo';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faUser,
  faTrophy,
  faHeart,
  faMapMarkerAlt,
  faDollarSign,
  faPencil,
  faFlask,
  faAward,
} from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../ActionButton/ActionButton';

import styles from './DesignerSquare.module.scss';
import { User } from '../../../global';
import { useNavigate } from 'react-router-dom';
import DslStore, {
  setCommunityUser,
  setCurrentUser,
  setFavoriteUsers,
  setPortfolioDesign,
  setPortfolioDesignCopy,
  setUserAwards,
  setUserInspirations,
} from '../../../store/DslStore';
import axiosHelper from '../../../helpers/axiosHelper';

import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../store/Selectors';
import SquareInfo from '../SquareInfo/SquareInfo';

interface IDesignerSquare {
  user?: User;
  circle?: boolean;
  image?: string;
  name?: string;
  liked: boolean;
  nameForURL?: string;
}

const DesignerSquare: React.FC<IDesignerSquare> = (props) => {
  const redirect = useNavigate();
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const [liked, setLiked] = useState(props.liked);

  const goToDetail = async (userId?: number) => {
    // console.log(user);
    if (userId) {
      let result: User = await axiosHelper.retrieveUserById(userId);
      // console.log(result);
      setCommunityUser(result);
      redirect(`/community/${props.nameForURL}/profile`);
    }
  };

  useEffect(() => {
    setLiked(props.liked);
  }, [props.liked]);

  const goToUserGallery = async () => {
    console.log('gallery');
    if (props.user?.id) {
      console.log('gallery 2');
      await axiosHelper.retrieveUserById(props.user?.id)?.then(async (response) => {
        setCommunityUser(response);
        await axiosHelper.getUserDetails(props.user?.id)?.then(async (design) => {
          await axiosHelper.getUserAwards(props.user?.id)?.then((awards) => {
            setUserAwards(awards.awards);
          });
          await axiosHelper.getUserInspirations(props.user?.id)?.then((inspirations) => {
            setUserInspirations(inspirations.inspirations);
          });
          setPortfolioDesign(design.designs);
          setPortfolioDesignCopy(design.designs);
          redirect(`/community/${props.nameForURL}/portfolio`);
        });
      });
    }
  };

  const userLiked = (userId?: number) => {
    if (userId) {
      loggedUser &&
        loggedUser.id !== userId &&
        axiosHelper.likeUser(userId).then(async (response) => {
          setLiked(true);
          await axiosHelper.retrieveUserById(loggedUser.id)?.then((response) => {
            setCurrentUser(response);
          });
          // alert(`${props.user?.username} added to favorites`);
        });
    }
  };

  const userUnliked = (userId?: number) => {
    if (userId) {
      loggedUser &&
        loggedUser.id !== userId &&
        axiosHelper.unlikeUser(userId).then(async (response) => {
          setLiked(false);
          await axiosHelper.retrieveUserById(loggedUser.id)?.then((response) => {
            setCurrentUser(response);
          });
          // alert(`${props.user?.username} removed from favorites`);
        });
    }
  };

  return (
    <div className={`${styles.square}  position relative`}>
      <SquareInfo image={props.image} circle={props.circle} name={props.name} userId={props.user?.id}>
        <div className="text-center ">
          {!liked && (
            <ActionButton
              label={loggedUser ? 'Save as favorite' : 'Login to save as favorite'}
              color={!loggedUser ? 'light-gray-3' : ''}
              icon="addFavorite"
              position="top-right"
              action={() => loggedUser && userLiked(props.user?.id)}
            />
          )}
          {liked && (
            <ActionButton
              label="Remove from favorites"
              icon="confirm"
              position="top-right"
              color="success"
              action={() => userUnliked(props.user?.id)}
            />
          )}
          {/* <div className={`${styles.badges} d-flex justify-content-end`}>
            <div className={`${styles.badge} bg-red color-light`}>
              <FontAwesomeIcon icon={faFlask as IconProp} />
            </div>
            <div className={`${styles.badge} bg-blue color-light`}>
              <FontAwesomeIcon icon={faAward as IconProp} />
            </div>
          </div> */}
          <div className="container bg-light justify-content-center">
            <div className={`${styles.rowTitle} row  `}>
              <div
                className="col  text-center align-self-center  mb-3 "
                style={{ overflow: 'hidden', cursor: 'pointer' }}
                onClick={() => goToDetail(props.user?.id)}
              >
                <span className="fw-bold text-16 color-blue d-none d-md-block ">{props.name}</span>
                <span className="fw-bold text-16 color-blue d-md-none d-sm-block ">{props.name}</span>
                {/* <h2 className="text-xl fw-bold color-blue mt-4 mb-4 px-3">{props.name}</h2> */}
              </div>
            </div>
          </div>
        </div>
      </SquareInfo>
    </div>
  );
};

export default DesignerSquare;
