import React, { useEffect, useState } from 'react';
import styles from './InspirationsNavigation.module.scss';

import resetFilter from '../../../assets/Reset-Filter.svg';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import DslStore, { setContestInspirations, setContestInspirationsPageNumber } from '../../../store/DslStore';
import {
  getContestInspirations,
  getContestInspirationsCopy,
  getCurrentContest,
  getCurrentUser,
  getInspirationsPageNumber,
} from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { DropDownInspirationCategory, DropDownInspirationSort } from '../../Forms/DropDown/DropDownInspirations';
import { ContestInspirations, InspirationContest, inspirationVote } from '../../../global';

type IProjecNavigation = {
  onChangeLayout?: (val: number) => void;
};

type PaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const PaginationSwitch: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getInspirationsPageNumber);
  const contest = useStoreState(DslStore, getCurrentContest);
  const inspirations = useStoreState(DslStore, getContestInspirations);
  let inspirationsPerPage = 20;
  let inpirationsCount = inspirations.length > 0 ? inspirations.length : 0;

  const pageNumberLimit = Math.ceil(inpirationsCount / inspirationsPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  const arrayCorrectPage = () => {
    let arrayCorrect: Array<number> = [];
    if (pageNumberLimit < 5) {
      for (let i = 1; i <= pageNumberLimit; i++) {
        arrayCorrect[i] = i;
        // console.log(arrayCorrect);
      }
      setArray(arrayCorrect);
    }
  };

  useEffect(() => {
    setContestInspirationsPageNumber(1);
  }, []);

  useEffect(() => {
    console.log('cambiato numero page');

    if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
      setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
    }
  }, [pageNumber]);

  useEffect(() => {
    arrayCorrectPage();
  }, [inspirations]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch} bg-light py-2 px-2 `}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((index: number) => (
        <button
          key={index}
          className={pageNumber === index ? 'btn-icon active' : 'btn-icon'}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>

      {/* 
      <button className={"btn-icon active"} 
      //  onClick={() => handleClick(1)}
      > <b> &lt; </b> </button>
       <button className={pageNumber == 1 ? "btn-icon active" : "btn-icon"} 
      //  onClick={() => handleClick(1)}
      >
        1
      </button>
      <button className={pageNumber == 2 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(2)}
      >
        2
      </button>
      <button className={pageNumber == 3 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(3)}
      >
        3
      </button>
      <button className={pageNumber == 4 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(4)}
      >
        4
      </button>
      <button className={pageNumber == 5 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(5)}
      >
        5
      </button>  */}

      {/* <button className="btn-icon">
         <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
       </button> */}

      {/* <Pagination count={numberOfPages} page={pageNumber} color='primary'  hideNextButton={numberOfPages > 1 ? false : true} hidePrevButton={numberOfPages > 1 ? false : true} size="small" onChange={handleChange} /> */}
    </div>
  );
};

let filterCategories = [
  {
    label: 'See all',
    value: '0',
  },
  {
    label: 'Liked',
    sort: 'liked',
    value: '1',
  },
];

let filterSort = [
  {
    label: 'Title',
    sort: 'title',
    value: '1',
  },
  {
    label: 'Name',
    sort: 'name',
    value: '2',
  },
  {
    label: 'Date',
    sort: 'date',
    value: '3',
  },
  {
    label: 'Votes',
    sort: 'votes',
    value: '4',
  },
];

const InspirationsNavigation: React.FC<IProjecNavigation> = (props) => {
  // const inspirations = useStoreState(DslStore, getContestInspirations);
  const contest = useStoreState(DslStore, getCurrentContest);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const inspirations = useStoreState(DslStore, getContestInspirations);
  const inspirationsCopy = useStoreState(DslStore, getContestInspirationsCopy);
  const [descs, setDescs] = useState(false);
  const [reload, setReload] = useState(false);
  const [typologySort, setTypologySort] = useState('');
  const [sortBySort, setSortBySort] = useState('');
  let inspirationNumber = contest?.inspirationCount ? contest.inspirationCount : 0;
  let inspirationsPerPage = 20;

  const pageNumberLimit = Math.ceil(inspirationNumber / inspirationsPerPage);

  const sortInspirations = () => {
    let sorted: ContestInspirations[] = [...inspirationsCopy];
    console.log(typologySort);
    console.log(sortBySort);
    if (typologySort) {
      if (typologySort === 'liked') {
        let inspirationsLiked: ContestInspirations[] = [];

        if (loggedUser?.inspirationVotes && loggedUser.userType !== 'G') {
          inspirations.map(
            (inspiration: ContestInspirations) =>
              loggedUser.inspirationVotes.some((item: inspirationVote) => item.inspiration.id === inspiration.id) &&
              inspirationsLiked.push(inspiration)
          );
          console.log(inspirationsLiked);
          sorted = inspirationsLiked;
        }
      }
    }
    if (sortBySort) {
      if (sortBySort === 'title') {
        if (descs) {
          sorted.sort((a, b) =>
            b.textDescription.toLowerCase() > a.textDescription.toLowerCase()
              ? 1
              : a.textDescription.toLowerCase() > b.textDescription.toLowerCase()
              ? -1
              : 0
          );
          setContestInspirations(sorted);
        } else {
          sorted.sort((a, b) =>
            b.textDescription.toLowerCase() > a.textDescription.toLowerCase()
              ? -1
              : a.textDescription.toLowerCase() > b.textDescription.toLowerCase()
              ? 1
              : 0
          );
          setContestInspirations(sorted);
        }
      }
      if (sortBySort === 'name') {
        if (descs) {
          sorted.sort((a, b) =>
            b.user.username.toLowerCase() > a.user.username.toLowerCase()
              ? 1
              : a.user.username.toLowerCase() > b.user.username.toLowerCase()
              ? -1
              : 0
          );
          setContestInspirations(sorted);
        } else {
          sorted.sort((a, b) =>
            b.user.username.toLowerCase() > a.user.username.toLowerCase()
              ? -1
              : a.user.username.toLowerCase() > b.user.username.toLowerCase()
              ? 1
              : 0
          );
          setContestInspirations(sorted);
        }
      }
      if (sortBySort === 'date') {
        if (descs) {
          sorted.sort((a, b) => {
            return a.uploadDate.localeCompare(b.uploadDate);
          });
          setContestInspirations(sorted);
        } else {
          sorted.sort((a, b) => {
            return b.uploadDate.localeCompare(a.uploadDate);
          });
          setContestInspirations(sorted);
        }
      }
      if (sortBySort === 'votes') {
        if (descs) {
          sorted.sort((a, b) =>
            b.inspirationVotes.length > a.inspirationVotes.length
              ? 1
              : a.inspirationVotes.length > b.inspirationVotes.length
              ? -1
              : 0
          );
          setContestInspirations(sorted);
        } else {
          sorted.sort((a, b) =>
            b.inspirationVotes.length > a.inspirationVotes.length
              ? -1
              : a.inspirationVotes.length > b.inspirationVotes.length
              ? 1
              : 0
          );
          setContestInspirations(sorted);
        }
      }
    }
    setContestInspirations(sorted);
  };

  useEffect(() => {
    console.log(inspirations);
  }, []);

  useEffect(() => {
    // isLiked();

    sortInspirations();
  }, [sortBySort, typologySort, descs]);

  return (
    <div className="row py-4 mt-4">
      <div className="col-12 col-md-6 col-lg-6 d-flex flex-column flex-lg-row justify-content-between mb-4 mb-md-0">
        <div className="me-0 me-md-5 pe-2 w-100 mb-2 mb-lg-0">
          <DropDownInspirationCategory
            label="Typology"
            options={filterCategories}
            reset={reload}
            onSelect={(option) => (option.sort ? setTypologySort(option.sort) : setTypologySort(''))}
            thin
          />
        </div>
        <div className="w-100 pe-4 me-3">
          <DropDownInspirationSort
            label="Sort by"
            options={filterSort}
            reset={reload}
            descs={(descs) => setDescs(descs)}
            onSelect={(option) => option.sort && setSortBySort(option.sort)}
            thin
          />
        </div>
        <div className=" d-flex flex-row align-items-center justify-content-center h-100 w-100">
          <div
            // className="btn-blue rounded"
            className=" d-flex flex-row align-items-center justify-content-center btn-reset-blue   "
            onClick={() => (setReload(!reload), setContestInspirations(inspirationsCopy))}
            style={{ width: '3rem', borderRadius: '8px' }}
          >
            <img src={resetFilter} style={{ height: '25px' }} />
          </div>
        </div>
      </div>

      <div className={`col-12 col-md-6 d-flex   justify-content-sm-start justify-content-md-end `}>
        <div>{pageNumberLimit > 0 ? <PaginationSwitch onChangePage={setContestInspirationsPageNumber} /> : ''}</div>
        {/* <div >
          <LayoutInspirationSwitch onChangeLayout={props.onChangeLayout} />
        </div> */}
      </div>
    </div>
  );
};

export default InspirationsNavigation;
