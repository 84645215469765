import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './AppHeader.module.scss';
import { fullPathLogo, fullPathLogoUser, hexToRGB } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose, faBars, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import UserPic from '../Community/UserPic/UserPic';
import { FooterMenuData, SearchForm } from '../../global';
import { useStoreState } from 'pullstate';
import DslStore, {
  logout,
  setSearchResultContest,
  setSearchResultContestCopy,
  setSearchResultContestFiltered,
  setSearchResultDesign,
  setSearchResultDesignCopy,
  setSearchResultUser,
  setSearchResultUserCopy,
} from '../../store/DslStore';
import {
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getHiddenMenu,
  getStrapiPaths,
  getUserLogged,
} from '../../store/Selectors';
import axiosHelper from '../../helpers/axiosHelper';
import { useForm } from 'react-hook-form';

interface IHeader {
  links: FooterMenuData[];
  bgColor: string;
  bgImage?: string;
  alpha?: number;
  logoImg?: string;
  pageTitle?: string;
  breadcrumbs?: string;
}

const TopNav = ({ links }: { links: FooterMenuData[] }) => {
  return (
    <ul className={`${styles.headerTopNav}  d-block d-lg-flex justify-content-start pt-5 mt-2 pt-lg-0 mt-lg-2 mb-0`}>
      {links.map((navLink, index) => (
        <li
          key={'topnavlink_' + index}
          onClick={() => {
            // setNavLinkActive(navLink);
          }}
          className="mb-5 mb-lg-0"
        >
          <Link className="text-16 color-light font-heading d-block d-lg-flex" to={navLink.path}>
            {navLink.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

interface BreadcrumbsProps {
  title: string;
}

const BreadCrumbs = (props: BreadcrumbsProps) => {
  const redirect = useNavigate();
  const hiddenMenus = useStoreState(DslStore, getHiddenMenu);
  const footerMenus = useStoreState(DslStore, getFooterMenu);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const verify = useRef(false);
  const [key, setKey] = useState(0);

  const array = useRef<string[]>([]);
  const arrayTitle = useRef<string[]>([]);
  const currentPath = window.location.pathname;

  useEffect(() => {
    console.log(props.title);

    if (props.title) {
      array.current = props.title.split('/');
      arrayTitle.current = currentPath.split('/');
      console.log(arrayTitle.current);
    }
  }, [props.title]);

  const goToPage = (breadcrumbIndex: number) => {
    let string = '';
    console.log(arrayTitle.current[0]);
    console.log(breadcrumbIndex);
    if (breadcrumbIndex === 0 && arrayTitle.current[0] === '') {
      console.log('ciao');
      redirect(`/`);
    }
    arrayTitle.current.map(
      (item: string, index: number) => (
        index > 0 && index <= breadcrumbIndex + 1 && (string += '/' + item),
        //se stringa contiene contest aggiungo /brief alla fine, se stringa contiene community aggiungo profile
        // index === breadcrumbIndex && (string += item),
        console.log(string)
      )
    );
    // console.log(string.includes('Contest/')),
    const includeContest: string[] = string.split('/');
    if (includeContest.length > 1) {
      if (string.includes('contest/') && includeContest.length < 4) {
        string += '/brief';
        redirect(string);
      }
      // console.log(string)
      redirect(string);
    }
    const includeCommunity: string[] = string.split('/');
    if (includeCommunity.length > 1) {
      if (string.includes('community/') && includeCommunity.length < 4) {
        {
          currentUser && string.includes(`${currentUser.username}`)
            ? (string += '/profile-editable')
            : (string += '/profile');
        }
        console.log(string);
        redirect(string);
      }
      console.log(string);
      redirect(string);
    }

    // if (includeContest || includeCommunity) {
    //   redirect(string);
    // }
  };

  const verifyPath = (stringa: string, index: number) => {
    console.log(verify.current);
    footerMenus.map((item: FooterMenuData) => {
      if (item.title === stringa) {
        verify.current = true;
      }
    });
    hiddenMenus.map((item: FooterMenuData) => {
      if (item.title === stringa) {
        verify.current = true;
      }
    });

    console.log(verify.current);
    if (verify.current === false) {
      goToPage(index);
    } else {
      // console.log('TROVATA XDVR');
      verify.current = false;
    }
  };

  const cursorDetector = (stringa: string) => {
    let trovata: boolean = false;
    footerMenus.map((item: FooterMenuData) => {
      if (item.title === stringa) {
        trovata = true;
      }
    });
    hiddenMenus.map((item: FooterMenuData) => {
      if (item.title === stringa) {
        trovata = true;
      }
    });
    return trovata;
  };

  useEffect(() => {
    setKey(key + 1);
  }, []);
  return (
    <div key={key} className={`col-12 text-16 color-light fw-bold " ${styles.headerBreadCrumbs}`}>
      {/* <Link className="text color-light font-heading px-1" to={'/'}> */}
      {/* <span className="text-16 color-light font-heading  px-1">{props.title + '/'}</span> */}
      {array.current.map((item: string, index: number) =>
        item === 'Home' ? (
          <Link className="text-16 fw-normal color-light font-heading px-1" to={'/'}>
            Home/
          </Link>
        ) : (
          <span
            className="text-16 color-light font-heading  px-1"
            style={{ cursor: cursorDetector(item) ? 'auto' : 'pointer' }}
            onClick={() => verifyPath(item, index)}
          >
            {item + '/'}
          </span>
        )
      )}
      {/* </Link> */}

      {/* <Link className="text color-light font-heading" to={'/'}>
        Breadcrumbs Example
      </Link> */}
    </div>
  );
};

const ToggleButton = (props: { opened: boolean; onToggle: () => void }) => {
  return (
    <button className={`${styles.openCloseButton}`} onClick={() => props.onToggle()}>
      {props.opened ? (
        <FontAwesomeIcon icon={faClose as IconProp} className="bigger-text color-light" />
      ) : (
        <FontAwesomeIcon icon={faBars as IconProp} className="bigger-text color-light" />
      )}
    </button>
  );
};

interface IUserArea {
  isLogged: boolean;
  user: any;
  onSignUp: () => void;
  onSignIn: () => void;
}

const UserArea: React.FC<IUserArea> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const userLogged = useStoreState(DslStore, getUserLogged);

  const logoutFunction = () => {
    logout();
    axiosHelper.logout();
  };

  const goToProfileEditable = async () => {
    // await axiosHelper.retrieveUserById(userProfile?.id)?.then((response: User) => {
    //   setCurrentUser(response);
    // });
    // redirect(`${currentUser?.username}/profile-editable`);
  };

  return userLogged ? (
    <div className="col-6 col-md-3 d-flex flex-column justify-content-start  justify-content-md-center pt-5 pt-md-0">
      <div
        className={`${styles.profileWrapper} d-flex flex-column flex-md-row justify-content-end align-items-center align-items-md-start position-relative `}
      >
        <div className="d-flex justify-content-end align-items-center position-relative ">
          <div className="text fw-bold color-light pe-3 ">Welcome {currentUser?.username}!</div>
          <div className={`${styles.profilePic} position-relative  `}>
            <UserPic image={currentUser?.logoAttachment ? currentUser?.logoAttachment.s3Path : ''} />
          </div>
        </div>

        <div
          className={`${styles.profileActions} pt-3 pt-md-0 ps-0 ps-md-4 px-lg-5 
          `}
        >
          <div className={`${styles.profileName} text-xxl fw-bold pb-3`}>
            {/* {currentUser?.firstName + ' ' + (currentUser?.lastName ? currentUser.lastName : ' ')} */}
            {currentUser?.username}
          </div>
          <div className={`${styles.profileMail} text mb-5 mb-md-4`}>{currentUser?.email}</div>
          <div className="pb-3" onClick={goToProfileEditable}>
            <Link
              to={`/community/${currentUser?.nameForURL}/profile-editable`}
              className={`${styles.profileLink} text d-block`}
            >
              Profile
            </Link>
          </div>
          <div>
            <Link
              to={'/'}
              onClick={() => {
                logoutFunction();
              }}
              className={`${styles.profileLink} text d-block`}
            >
              Logout
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="col-12 col-md-3 d-flex flex-column justify-content-center ">
      <div className="d-flex justify-content-center  justify-content-md-end my-5 my-lg-0">
        <button className="btn-dark rounded me-5" onClick={() => props.onSignUp()}>
          Signup
        </button>
        <button className="btn-dark rounded" onClick={() => props.onSignIn()}>
          Login
        </button>
      </div>
    </div>
  );
};

//TODO add breadcrumbs (https://www.npmjs.com/package/use-react-router-breadcrumbs)
const AppHeader: React.FC<IHeader> = (props) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [opened, setOpened] = useState<boolean>(false);
  let navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchForm>();

  const userLogged = useStoreState(DslStore, getUserLogged);
  const currentuser = useStoreState(DslStore, getCurrentUser);
  const redirect = useNavigate();

  const handleScroll = () => {
    let position = window.pageYOffset;
    setScrollPosition(position);
  };

  const mockUser = {
    username: currentuser?.firstName + ' ' + currentuser?.lastName,
    profilePic: currentuser?.logoAttachment ? currentuser?.logoAttachment.s3Path : '',
    email: currentuser?.email,
    id: currentuser?.id,
    firstName: currentuser?.firstName,
    lastName: currentuser?.lastName,
    userType: currentuser?.userType,
    dslToken: currentuser?.dslToken,
  };

  useEffect(() => {
    console.log(userLogged);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onSubmit = async (data: SearchForm) => {
    //TODO CONTEST, USERS, DESIGNS

    await axiosHelper
      .searchContest(data)
      .then((response) => {
        setSearchResultContest(response);
        setSearchResultContestFiltered(response);
        setSearchResultContestCopy(response);
      })
      .catch((error) => {
        console.log(error);
      });
    await axiosHelper
      .searchUser(data)
      .then((response) => {
        setSearchResultUser(response);
        setSearchResultUserCopy(response);
      })
      .catch((error) => {
        console.log(error);
      });
    await axiosHelper
      .searchDesign(data)
      .then((response) => {
        setSearchResultDesign(response);
        setSearchResultDesignCopy(response);
      })
      .catch((error) => {
        console.log(error);
      });

    redirect('/Search/Community');
  };

  let c = hexToRGB(props.bgColor);

  const handleOpenCloseHeader = () => {
    console.log(opened);
    setOpened(!opened);
  };

  const handleSignUp = () => {
    let path = `/signup`;
    navigate(path);
    // alert('Signup');
  };

  const handleSignIn = () => {
    let path = `/login`;
    // alert('Signin');
    navigate(path);
  };

  return (
    <div className={`${styles.headerWrapper}  container-fluid px-0`} style={{ backgroundColor: props.bgColor }}>
      {/* Top Row ============================================================================== */}
      <div
        className={`container-fluid  ${styles.headerTopRowContainer} pt-3 pb-3 ${opened ? styles.opened : ''}`}
        // style={{ backgroundColor:  'transparent' }}
        style={{ backgroundColor: scrollPosition > 50 || opened ? props.bgColor : 'transparent' }}
      >
        <div className="container-md  d-flex flex-column justify-content-center  ">
          <div className={`row ${styles.headerTopRow}  pt-3 pb-3 ${opened ? styles.opened : ''}`}>
            <div className={`col-10  col-lg-2 ${styles.headerLogo}`}>
              <img src={props.logoImg} alt="Desall" onClick={() => navigate('/')} className="cursor-pointer ps-1" />
            </div>
            <div className="col-2 d-flex d-lg-none justify-content-end">
              <ToggleButton opened={opened} onToggle={handleOpenCloseHeader} />
            </div>
            <div className="col-12 col-lg-10">
              <div className="row d-flex align-items-center justify-content-between  h-100">
                <div className={`${styles.navigationWrapper} col-6 col-md-7 d-flex flex-column justify-content-center`}>
                  <TopNav links={props.links} />
                </div>
                <UserArea isLogged={userLogged} onSignIn={handleSignIn} onSignUp={handleSignUp} user={mockUser} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Row ============================================================================== */}
      <div
        className={`${styles.headerContentWrapper}  container-md pb-7`}
        style={{
          backgroundImage: `
          linear-gradient(rgba(${c[0]},${c[1]},${c[2]},${props.alpha ?? 0.8}), 
          rgba(${c[0]},${c[1]},${c[2]},${props.alpha ?? 0.8})), 
          url(${props.bgImage})
          `,
        }}
      >
        {/* Bottom Row */}
        <div className="row  mt-5 ">{props.breadcrumbs && <BreadCrumbs title={props.breadcrumbs} />} </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row  ">
            <div className="col-6  light-title color-light">{props.pageTitle}</div>
            <div className="col-6  d-flex flex-row-reverse align-items-center light-title color-light">
              <div className="col-6 d-flex flex-row-reverse">
                <input
                  type="text "
                  className="text fw-bold text-uppercase"
                  placeholder="search"
                  {...register('search')}
                />
                <button className="btn-reset-blue rounded d-flex flex-row align-items-center justify-content-center position-absolute">
                  <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} className=" text color-light " />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AppHeader;
