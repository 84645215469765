import React, { useState } from 'react';
import styles from './PopUpRedirectContest.module.scss';

import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface PopUpRedirectContestProps {
  popup: boolean;
  //   image: string;
  close: () => void;
  description: string;
  link: string;
}

const PopUpRedirectContest: React.FC<PopUpRedirectContestProps> = (props) => {
  const [popup, setPopup] = useState(props.popup);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close && props.close())}
        className={`d-flex flex-column justify-content-center`}
        contentClassName={` ${styles.rounded}`}
      >
        <div className={`d-flex flex-column justify-content-center text-center `}>
          {/* <Modal.Header className="d-flex justify-content-center">
            
          </Modal.Header> */}
          <Modal.Body className={`d-flex flex-column justify-content-center ${styles.rounded}`}>
            <h3>
              <b> You are leaving desall.com </b>
            </h3>
            <h5 className="mt-4">{props.description}</h5>
            <div className=" d-flex flex-row align-items-center justify-content-between mt-5">
              <div className="d-flex justify-content-center align-items-center ">
                <div
                  className={` ${styles.closeButton}`}
                  onClick={() => {
                    setPopup(!popup);
                    props.close();
                  }}
                >
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light " />
                </div>
                <span className="ps-3">Close</span>
              </div>
              {/* <Button className="btn-red rounded" onClick={() => (setPopup(!popup), props.close && props.close())}>
                Close
              </Button> */}
              <a href={props.link} target="_self">
                <Button
                  className="btn-success rounded"
                  onClick={() => (setPopup(!popup), props.close && props.close())}
                >
                  Continue
                </Button>
              </a>
            </div>
          </Modal.Body>
        </div>

        {/* <Modal.Footer className="d-flex flex-row justify-content-between"></Modal.Footer> */}
      </Modal>
    </>
  );
};

export default PopUpRedirectContest;
