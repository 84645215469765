import { useStoreState } from 'pullstate';
import { useEffect, useState } from 'react';
import AppFooter from '../components/AppFooter/AppFooter';
import HomeHeader from '../components/HomeHeader/HomeHeader';
import QueryComponent from '../components/Query/QueryComponent';
import { STRAPI_QUERY } from '../queries/pages/pages';
import DslStore, { setDesignChargingError, setStrapiPage } from '../store/DslStore';
import { getCurrentDesign, getDesignSearchError, getFooterMenu, getHeaderMenu } from '../store/Selectors';
import footerImg from '../assets/footer_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';
import axiosHelper from '../helpers/axiosHelper';
import { StrapiPageData } from '../global';

const Home = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const designError = useStoreState(DslStore, getDesignSearchError);

  useEffect(() => {
    console.log(designError);
    if (designError !== '') {
      // alert(`Access denied to the contest ${designError}  gallery area`);
      setDesignChargingError('');
    }
  }, []);
  const [page, setPage] = useState<StrapiPageData>();

  const fetchPage = async () => {
    await axiosHelper.fetchStrapiPage('/')?.then((response) => {
      // console.log(response.data);
      setPage(response.data[0]);
      setStrapiPage(response.data[0]);
      // window.scrollTo(0, 100);
    });
  };

  useEffect(() => {
    // console.log(process.env.REACT_APP_API_ENDPOINT);
    fetchPage();
  }, []);

  return (
    <>
      <HomeHeader
        links={headerMenu}
        bgColor={page?.attributes.headercolor ? page?.attributes.headercolor : '#7997c5'}
        logoImg={headerLogo}
        transparent={true}
      />
      
      <QueryComponent page={page} />
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default Home;
