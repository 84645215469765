import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Country, Job } from './global';
import { useStoreState } from 'pullstate';
import DslStore from './store/DslStore';
import { getCurrentUser } from './store/Selectors';

export type UserContext = {
  setContextfirstname: (val: string) => any;
  setContextlastname: (val: string) => any;
  setContexthaspublicname: (val: boolean) => any;
  setContextbio: (val: string) => any;
  setContextcity: (val: string) => any;
  setContexthaspubliccity: (val: boolean) => any;
  setContexthaspublicfirstschool: (val: boolean) => any;
  setContexthaspublicsecondschool: (val: boolean) => any;
  setContextcountry: (val: Country) => any;
  setContexthaspubliccountry: (val: boolean) => any;
  setContextjob: (val: Job) => any;
  setContextjobname: (val: string) => any;
  setContextgender: (val: string) => any;
  setContextbirthdate: (val: string) => any;
  setContexthaspublicbirthdate: (val: boolean) => any;
  setContextwebsite: (val: string) => any;
  setContexttags: (val: string) => any;
  setContextcontactlanguage: (val: string) => any;
  setContextphonenumber: (val: string) => any;
  setContextzipcode: (val: string) => any;
  setContextaddressline1: (val: string) => any;
  setContextcompany: (val: string) => any;
  setContextschool1: (val: string) => any;
  setContextschool2: (val: string) => any;
  setContextfreelancelicense: (val: string) => any;
  setContextcompanydescription: (val: string) => any;
  setContextsector: (val: string) => any;
  setContextcompanyname: (val: string) => any;
  Contextfirstname: string;
  Contextlastname: string;
  Contexthaspublicname: boolean;
  Contextbio: string;
  Contextcity: string;
  Contexthaspubliccity: boolean;
  Contextcountry?: Country;
  Contexthaspubliccountry: boolean;
  Contextjob?: Job;
  Contextjobname: string;
  Contextgender: string;
  ContextbirthDate: string;
  Contexthaspublicbirthdate: boolean;
  Contextwebsite: string;
  Contexttags: string;
  Contextcontactlanguage: string;
  Contextphonenumber: string;
  Contextzipcode: string;
  Contextaddressline1: string;
  Contextcompany: string;
  Contextschool1: string;
  Contextschool2: string;
  Contextfreelancelicense: string;
  Contexthaspublicfirstschool: boolean;
  Contexthaspublicsecondschool: boolean;
  Contextcompanydescription?: string;
  Contextsector: string;
  Contextcompanyname: string;
};

export const MyUserContext = createContext<UserContext>({} as UserContext);

type Props = {
  children: JSX.Element;
};

export const UserContextProvider = ({ children }: Props) => {
  const currentuser = useStoreState(DslStore, getCurrentUser);
  const [Contextfirstname, setFirstName] = useState('');
  const [Contextlastname, setLastName] = useState('');
  const [Contexthaspublicname, setHasPublicName] = useState(true);
  const [Contextbio, setBiography] = useState('');
  const [Contextcity, setCity] = useState('');
  const [Contexthaspubliccity, setHasPublicCity] = useState(true);
  const [Contexthaspublicfirstschool, setHasPublicFirstSchool] = useState(true);
  const [Contexthaspublicsecondschool, setHasPublicSecondSchool] = useState(true);
  const [Contextcountry, setCountry] = useState<Country | undefined>(undefined);
  const [Contexthaspubliccountry, setHasPublicCountry] = useState(true);
  const [Contextjob, setJob] = useState<Job | undefined>(undefined);
  const [Contextjobname, setJobname] = useState('');
  const [Contextgender, setGender] = useState('');
  const [ContextbirthDate, setBirthDate] = useState('');
  const [Contexthaspublicbirthdate, setHasPublicBirthDate] = useState(true);
  const [Contextwebsite, setWebsite] = useState('');
  const [Contexttags, setTags] = useState('');
  const [Contextcontactlanguage, setContactLanguage] = useState('');
  const [Contextphonenumber, setPhoneNumber] = useState('');
  const [Contextzipcode, setZipCode] = useState('');
  const [Contextaddressline1, setAddressLine1] = useState('');
  const [Contextcompany, setCompany] = useState('');
  const [Contextschool1, setFirstSchool] = useState('');
  const [Contextschool2, setSecondSchool] = useState('');
  const [Contextfreelancelicense, setFreelanceLicense] = useState('N');
  const [Contextcompanydescription, setContextCompanyDescription] = useState('');
  const [Contextsector, setContextSector] = useState('');
  const [Contextcompanyname, setContextCompanyName] = useState('');

  useEffect(() => {
    console.error('CONTEXT USE EFFECT');
  }, []);

  const setContextfirstname = (name: string) => {
    setFirstName(name);
  };

  const setContextlastname = (surname: string) => {
    setLastName(surname);
  };

  const setContexthaspublicname = (val: boolean) => {
    setHasPublicName(val);
  };

  const setContexthaspublicfirstschool = (val: boolean) => {
    setHasPublicFirstSchool(val);
  };

  const setContexthaspublicsecondschool = (val: boolean) => {
    setHasPublicSecondSchool(val);
  };

  const setContextbio = (bio: string) => {
    setBiography(bio);
  };

  const setContextcity = (city: string) => {
    setCity(city);
  };

  const setContexthaspubliccity = (val: boolean) => {
    setHasPublicCity(val);
  };

  const setContextcountry = (country: Country) => {
    setCountry(country);
  };

  const setContexthaspubliccountry = (val: boolean) => {
    setHasPublicCountry(val);
  };

  const setContextjob = (job: Job) => {
    setJob(job);
  };

  const setContextgender = (gender: string) => {
    setGender(gender);
  };

  const setContextbirthdate = (birth: string) => {
    setBirthDate(birth);
  };

  const setContexthaspublicbirthdate = (val: boolean) => {
    setHasPublicBirthDate(val);
  };

  const setContextwebsite = (website: string) => {
    setWebsite(website);
  };

  const setContexttags = (tags: string) => {
    setTags(tags);
  };

  const setContextjobname = (jobname: string) => {
    setJobname(jobname);
  };

  const setContextcontactlanguage = (val: string) => {
    setContactLanguage(val);
  };

  const setContextphonenumber = (val: string) => {
    setPhoneNumber(val);
  };

  const setContextzipcode = (val: string) => {
    setZipCode(val);
  };

  const setContextaddressline1 = (val: string) => {
    setAddressLine1(val);
  };

  const setContextcompany = (val: string) => {
    setCompany(val);
  };

  const setContextschool1 = (val: string) => {
    setFirstSchool(val);
  };

  const setContextschool2 = (val: string) => {
    setSecondSchool(val);
  };

  const setContextfreelancelicense = (val: string) => {
    setFreelanceLicense(val);
  };

  const setContextcompanydescription = (val: string) => {
    setContextCompanyDescription(val);
  };
  const setContextsector = (val: string) => {
    setContextSector(val);
  };

  const setContextcompanyname = (val: string) => {
    setContextCompanyName(val);
  };

  useEffect(() => {
    if (currentuser) {
    }

    // console.log(
    //   Contextfirstname,
    //   Contextlastname,
    //   Contexthaspublicname,
    //   Contextbio,
    //   Contextcity,
    //   Contexthaspubliccity,
    //   Contextcountry,
    //   Contexthaspubliccountry,
    //   Contextjob,
    //   Contextgender,
    //   ContextbirthDate,
    //   Contexthaspublicbirthdate,
    //   Contextwebsite,
    //   Contexttags,
    //   Contextcontactlanguage,
    //   Contextphonenumber,
    //   Contextzipcode,
    //   Contextaddressline1,
    //   Contextcompany,
    //   Contextschool1,
    //   Contextschool2,
    //   Contextfreelancelicense,
    //   Contextcompanyname
    // );
  }, [
    Contextfirstname,
    Contextlastname,
    Contexthaspublicname,
    Contextbio,
    Contextcity,
    Contexthaspubliccity,
    Contextcountry,
    Contexthaspubliccountry,
    Contextjob,
    Contextgender,
    ContextbirthDate,
    Contexthaspublicbirthdate,
    Contextwebsite,
    Contexttags,
    Contextcompanyname,
  ]);

  useEffect(() => {
    let tagstring: string = '';
    currentuser?.userType === 'D' &&
      currentuser.tags.map((item) => {
        tagstring = tagstring + item.name + ' ';
      });
    if (currentuser) {
      currentuser.firstName && setContextfirstname(currentuser?.firstName);
      currentuser.lastName && setContextlastname(currentuser?.lastName);
      currentuser.hasPublicName && setContexthaspublicname(currentuser.hasPublicName);
      currentuser.biography && setContextbio(currentuser?.biography);
      currentuser.city && setContextcity(currentuser?.city);
      currentuser.hasPublicCity && setContexthaspubliccity(currentuser?.hasPublicCity);
      currentuser.country && setContextcountry(currentuser?.country);
      currentuser.hasPublicCountry && setContexthaspubliccountry(currentuser?.hasPublicCountry);
      currentuser.website && setContextwebsite(currentuser?.website);
      currentuser.job && setContextjob(currentuser?.job);
      currentuser.jobName && setContextjobname(currentuser?.jobName);
      currentuser.birthDate && setContextbirthdate(currentuser?.birthDate);
      currentuser.hasPublicBirthDate && setContexthaspublicbirthdate(currentuser?.hasPublicBirthDate);
      currentuser.hasPublicFirstSchool && setContexthaspublicfirstschool(currentuser?.hasPublicFirstSchool);
      currentuser.hasPublicSecondSchool && setContexthaspublicsecondschool(currentuser?.hasPublicSecondSchool);
      currentuser.contactLanguage && setContextcontactlanguage(currentuser?.contactLanguage);
      currentuser.phoneNumber && setContextphonenumber(currentuser?.phoneNumber);
      currentuser.zipCode && setContextzipcode(currentuser?.zipCode);
      currentuser.addressLine1 && setContextaddressline1(currentuser?.addressLine1);
      currentuser.company && setContextcompany(currentuser.company);
      currentuser.school1 && setContextschool1(currentuser.school1);
      currentuser.school2 && setContextschool2(currentuser.school2);
      currentuser.freelanceLicense && setContextfreelancelicense(currentuser.freelanceLicense);
      currentuser.description && setContextcompanydescription(currentuser.description);
      currentuser.sector && setContextsector(currentuser.sector);
      currentuser.companyName && setContextcompanyname(currentuser.companyName);
    }
  }, []);

  useEffect(() => {
    console.log(Contexthaspublicname);
  }, [Contexthaspublicname]);

  return (
    <MyUserContext.Provider
      value={{
        setContextfirstname,
        setContextlastname,
        setContexthaspublicname,
        setContextbio,
        setContextcity,
        setContexthaspubliccity,
        setContexthaspublicfirstschool,
        setContexthaspublicsecondschool,
        setContextcountry,
        setContexthaspubliccountry,
        setContextjob,
        setContextgender,
        setContextbirthdate,
        setContexthaspublicbirthdate,
        setContextwebsite,
        setContexttags,
        setContextjobname,
        setContextaddressline1,
        setContextcompany,
        setContextcontactlanguage,
        setContextfreelancelicense,
        setContextphonenumber,
        setContextschool1,
        setContextschool2,
        setContextzipcode,
        setContextcompanydescription,
        setContextsector,
        setContextcompanyname,
        Contextfirstname,
        Contextlastname,
        Contexthaspublicname,
        Contextbio,
        Contextcity,
        Contexthaspubliccity,
        Contexthaspublicfirstschool,
        Contexthaspublicsecondschool,
        Contextcountry,
        Contexthaspubliccountry,
        Contextjob,
        Contextgender,
        ContextbirthDate,
        Contexthaspublicbirthdate,
        Contextwebsite,
        Contexttags,
        Contextjobname,
        Contextaddressline1,
        Contextcompany,
        Contextcontactlanguage,
        Contextfreelancelicense,
        Contextphonenumber,
        Contextschool1,
        Contextschool2,
        Contextzipcode,
        Contextcompanydescription,
        Contextsector,
        Contextcompanyname,
      }}
    >
      {children}
    </MyUserContext.Provider>
  );
};
