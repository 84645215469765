import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import SquaredImage from '../../SquaredImage/SquaredImage';
import StatusBar from '../StatusBar/StatusBar';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faTrophy, faIndustry, faEuroSign, faPen } from '@fortawesome/free-solid-svg-icons';

import styles from './ContestCard.module.scss';
import ActionButton, { MultiActionButtonsWrapper } from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';
import { CoCreationContest, Contest, ContestStatus, User } from '../../../global';
import DslStore, {
  setCoCreationContest,
  setCommunityUser,
  setContestGuidelines,
  setContestTags,
  setContestsILike,
  setCurrentContest,
} from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import axiosHelper from '../../../helpers/axiosHelper';
import { useStoreState } from 'pullstate';
import { getAllCoCreationContests, getCurrentContest, getCurrentUser } from '../../../store/Selectors';
import { Datestr, finalSlicedString } from '../../../helpers/utils';
import { response } from 'express';

interface IContestCard extends ICardInfo {
  contestId: number;
  startDate: string;
  endVotingDate: string;
  title: string;
  category: string;
  endDate: string;
  name?: string;
  entries: number;
  status: ContestStatus;
  progress?: number;
  award?: number;
  linkToUrl?: string;
  linkToAward?: string;
  private?: boolean;
  accesscode?: boolean;
  nda?: boolean;
  global?: boolean;
  userId: number;
  contestType: string;
  nameforurl?: string;
  statusText: string;
  liked?: boolean;
  click?: (link: string) => void;
}

const ContestCard: React.FC<IContestCard> = (props) => {
  const redirect = useNavigate();
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const contest = useStoreState(DslStore, getCurrentContest);
  const allCocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const [liked, setLiked] = useState(false);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const [dateColor, setDateColor] = useState('');

  const cocreationTotalAward = () => {
    let awards = 0;
    // console.log('cocreation award');
    allCocreationContests.map((item) => {
      if (item.mainContest.id === props.contestId) {
        // console.log(item.realTotalAwards);
        awards += item.realTotalAwards;
        // item.awards.map((award) => {
        //   console.log(award.price);
        //   awards += award.price;
        // });
      }
    });
    if (props.contestType === 'Co-creation') {
      return awards;
    } else {
      return;
    }
  };

  const cocreationTotalEntries = () => {
    let entries = 0;
    // console.log('cocreation award');
    allCocreationContests.map((item) => {
      if (item.mainContest.id === props.contestId) {
        // console.log(item.publicDesignsOrderedCount);
        entries += item.publicDesignsOrdered.length;
        // item.awards.map((award) => {
        //   console.log(award.price);
        //   awards += award.price;
        // });
      }
    });
    return entries;
  };

  const checkColor = () => {
    props.status === 'ongoing' && setDateColor('success');
    props.status === 'closed' && setDateColor('danger');
    props.status === 'client-vote' && setDateColor('dark_yellow');
    props.status === 'community-vote' && setDateColor('dark_yellow');
    props.status === 'coming-soon' && setDateColor('light_grey_40');
  };

  const date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  const startDateNumber = Math.trunc(Datestr(props.startDate) / 86400000);
  const endDateNumber = Math.trunc(Datestr(props.endDate) / 86400000);
  const endVotingDateNumber = Math.trunc(Datestr(props.endVotingDate) / 86400000);

  const progressNumber = () => {
    // console.log(endDateNumber);
    // console.log(startDateNumber);
    let progressNumber = 100;
    if (props.status === 'ongoing') {
      console.log('CONTEST CARD ENDDATEN: ', endDateNumber )
      console.log('CONTEST CARD TOT: ', startDateNumber)
      console.log('CONTEST CARD TOT: ', endDateNumber - startDateNumber)
      console.log('CONTEST CARD PASSED: ', date - startDateNumber)
      let tot = endDateNumber - startDateNumber;
      let passed = date - startDateNumber;
      progressNumber = (passed * 100) / tot;
      // console.log(tot, passed, progressNumber);
    } else if (props.status === 'community-vote') {
      let tot = endVotingDateNumber - endDateNumber;
      let passed = date - endDateNumber;
      progressNumber = (passed * 100) / tot;
    } else {
      const cocreationSons: CoCreationContest[] = [];
      allCocreationContests.map((cocreation) => {
        if (cocreation.mainContest.id === props.contestId) {
          cocreationSons.push(cocreation);
        }
      });
      cocreationSons.map((item) => {
        if (props.status === 'ongoing' && Math.trunc(new Date(item.endUploadDate).getTime()) / 86400000 > date) {
          let tot =
            Math.trunc(Datestr(item.endUploadDate) / 86400000) - Math.trunc(Datestr(item.startUploadDate) / 86400000);
          let passed = Math.trunc(date) - Math.trunc(Datestr(item.startUploadDate) / 86400000);
          console.log(passed);
          // console.log(Math.trunc(Datestr(item.endUploadDate) / 86400000));
          // console.log(Math.trunc(Datestr(item.startUploadDate) / 86400000));
          console.log(Math.trunc(date));
          console.log(Math.trunc(Datestr(item.startUploadDate) / 86400000));
          console.log(
            Math.trunc(Datestr(item.endUploadDate) / 86400000) - Math.trunc(Datestr(item.startUploadDate) / 86400000)
          );
          console.log(Math.trunc(date) - Math.trunc(Datestr(item.startUploadDate) / 86400000));

          console.log((passed * 100) / tot);

          // console.log(tot);

          progressNumber = Math.trunc((passed * 100) / tot);
        } else if (
          props.status === 'community-vote' &&
          Math.trunc(new Date(item.endVotingDate).getTime()) / 86400000 > date
        ) {
          let tot =
            new Date(item.endVotingDate).getTime() / 86400000 - new Date(item.endUploadDate).getTime() / 846000000;
          let passed = date - new Date(item.endUploadDate).getTime() / 846000000;
          progressNumber = (passed * 100) / tot;
        }
      });
    }
    return progressNumber;
  };

  const isLiked = () => {
    // console.log(props.contestId);
    let like: boolean;

    if (!loggedUser) {
      like = false;
      setLiked(like);
    }

    if (loggedUser?.contestsILike) {
      like = loggedUser.contestsILike.some((item: any) => item.id === props.contestId);

      setLiked(like);
    }
  };

  useEffect(() => {
    isLiked();
    checkColor();
  }, [props.contestId]);

  const contestLiked = (contestId: number) => {
    axiosHelper.likeContest(contestId).then((response) => {
      setLiked(true);
      if (currentUser?.username === response.data.username) {
        setContestsILike(response.data.contestsILike);
      }
      // alert(`${props.title} added to favorites`);
    });
  };

  const contestUnliked = (contestId: number) => {
    axiosHelper.unlikeContest(contestId).then((response) => {
      setLiked(false);
      // alert(`${props.title} removed from favorites`);
    });
  };

  const goToDetail = async (userid: number) => {
    console.log(userid);
    let result: User = await axiosHelper.retrieveUserById(userid);
    console.log(result);
    if (props.contestType !== 'Link to other') {
      setCommunityUser(result);
      redirect(`/community/${result.nameForURL}/profile`);
    } else {
      // alert('link to other');
    }
  };

  const setTags = async (contestId: number) => {
    let contestTags = await axiosHelper.getContestTags(contestId);
    if (contestTags) {
      console.log(contestTags);
      setContestTags(contestTags.tags);
    }
  };

  const getCoCreationContestDetails = async (contestId: number) => {
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);
    }
  };

  const getContestDetails = async (contestId?: number) => {
    console.log(props.nameforurl);
    console.log(props.category);
    if (contestId) {
      if (props.category !== 'Link to Other' && props.contestType !== 'Link to other') {
        const result = await axiosHelper.retrieveCompleteContest(contestId);

        setTags(contestId);
        if (props.contestType === 'Co-creation') {
          getCoCreationContestDetails(contestId);
        }
        // await axiosHelper.getContestGuidelines(contestId).then((response) => {
        //   console.log(response);
        //   setContestGuidelines(response);
        // }).catch((error) => {
        //   console.log(error)
        // });
        // console.log(result.data.client);
        // console.log(contest);
        // console.log(result);
        setCurrentContest(result.data);

        redirect('/contest/' + props.nameforurl + '/brief');
      } else {
        props.linkToUrl && props.click && props.click(props.linkToUrl);
        // props.linkToUrl && props.click && props.click('/Enel_Challenges/Smart_Meter');
        // console.log('ciaoo');
        // props.linkToUrl && window.location.replace(props.linkToUrl);
      }
    }
  };

  const goToAwards = async () => {
    if (props.contestId) {
      await axiosHelper.retrieveCompleteContest(props.contestId).then((response) => {
        setCurrentContest(response.data);
        redirect(`/contest/${props.nameforurl}/awards`);
      });
    }
  };

  const goToContestGallery = async () => {
    await axiosHelper.retrieveCompleteContest(props.contestId).then(async (response) => {
      setCurrentContest(response.data);
      redirect(`/contest/${response.data.nameForUrl}/gallery`);
    });
  };
  return (
    <>
      <div
        className={`${styles.card}    col-12  d-flex flex-row align-items-center justify-content-center     position-relative`}
      >
        <div className={`${styles.cardInfoWrapper}  w-sm-75  d-flex flex-column  bg-light`}>
          <div
            className={`${props.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light ${
              props.circle && 'px-5 pt-4'
            } position-relative`}
            style={{ cursor: 'pointer' }}
          >
            <div
              onClick={() => {
                getContestDetails(props.contestId);
              }}
            >
              {props.image ? (
                <SquaredImage image={props.image} altText={props.altText} circle={props.circle} />
              ) : (
                <div className={`${styles.squaredImageWrapper}`}>
                  <div className={`${styles.imageInner}`}>
                    <Spinner />
                  </div>
                </div>
                // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
              )}
            </div>
            {!liked && (
              <ActionButton
                label={loggedUser ? 'Save as favorite' : 'Login to save as favorite'}
                color={!loggedUser ? 'light-gray-3' : ''}
                icon="addFavorite"
                position="top-right"
                action={() => loggedUser && contestLiked(props.contestId)}
              />
            )}
            {liked && (
              <ActionButton
                label="Remove from favorites"
                icon="confirm"
                position="top-right"
                color="success"
                action={() => contestUnliked(props.contestId)}
              />
            )}
            <MultiActionButtonsWrapper position="bottom-left">
              {props.private && <ActionButton label="Private gallery" icon="private" />}
              {props.accesscode && <ActionButton label="By invitation code contest" icon="accesscode" />}
              {props.nda && <ActionButton label="Accept nda" icon="acceptnda" />}
              {props.global && <ActionButton label="Global contest" icon="global" />}
            </MultiActionButtonsWrapper>
          </div>
          <StatusBar status={props.status} progress={progressNumber()} />
          <div className={`${styles.cardInfoContent} container bg-light `}>
            <div className="row h-25 mt-3 mb-3 mx-1 " onClick={() => getContestDetails(props.contestId)}>
              <div className="col text-center align-self-center  ">
                <h3 className="fw-bold color-blue  d-none d-sm-block " style={{ cursor: 'pointer' }}>
                  {props.title}
                </h3>
                <h1 className="fw-bold color-blue  d-sm-none d-sm-block">{props.title}</h1>
              </div>
            </div>

            <div className=" col align-self-center text-center mb-3 ">
              {props.contestType === 'Co-creation'
                ? 'Co-creation'
                : props.category === 'Link to Other'
                ? 'Partnership'
                : props.category}
            </div>

            <div className={`${styles.infoContainer} position-static  mt-2 pt-3 `}>
              <div
                className="justify-content-left mb-3   "
                style={{ height: '20px', overflow: 'hidden', cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faIndustry as IconProp} className=" text-left color-blue me-3" />
                <span className="justify-content-left  color-blue " onClick={() => goToDetail(props.userId)}>
                  {props.name}
                </span>
              </div>
              <div className=" text  justify-content-left mb-3 align-items-center d-flex flex-row">
                <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />

                <span className={`mx-1 justify-content-left text  color-${dateColor}`}>{props.statusText}</span>
              </div>
              <div className=" text  justify-content-left mb-3 align-items-center d-flex flex-row  ">
                <div className=" d-flex align-items-center flex-row" onClick={() => console.log(contest?.isPrivate)}>
                  <FontAwesomeIcon icon={faPen as IconProp} className={`text  me-3 color-blue`} />

                  <span
                    className={`mx-1 justify-content-left text ${
                      props.private || props.contestType === 'Link to other' ? '' : 'color-blue cursor-pointer'
                    }`}
                    onClick={() => !props.private && props.contestType !== 'Link to other' && goToContestGallery()}
                  >
                    {props.contestType === 'Co-creation' ? cocreationTotalEntries() : props.entries} entries
                  </span>
                </div>
              </div>
              {(props.award !== null && props.award !== -1) || cocreationTotalAward() ? (
                <div className="d-flex justify-content-left mb-4 ">
                  <div
                    className="d-flex align-items-center flex-row "
                    onClick={() => goToAwards()}
                    style={{ cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon icon={faTrophy as IconProp} className="text color-blue me-3" />

                    <span className="text color-blue">
                      €{props.award}
                      {cocreationTotalAward()}
                    </span>
                  </div>
                </div>
              ) : null}
              {props.linkToAward !== null && props.award === null ? (
                <div className="d-flex justify-content-left mb-4 ">
                  <div className="d-flex align-items-center  ">
                    <FontAwesomeIcon icon={faTrophy as IconProp} className="text color-blue me-3" />

                    <span className="text "> {props.linkToAward} </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContestCard;
