import React, { useEffect, useRef, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import ActionButton from '../../ActionButton/ActionButton';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusBar from '../StatusBar/StatusBar';
import desImg from '../../../assets/Design.png';

import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  faPencil,
  faTrophy,
  faIndustry,
  faEarthAmericas,
  faEyeSlash,
  faKey,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

import styles from './ContestRowBig.module.scss';
import { CoCreationContest, Contest, ContestStatus } from '../../../global';
import DslStore, {
  setCoCreationContest,
  setCommunityUser,
  setContestAwards,
  setContestGuidelines,
  setContestTags,
  setCurrentContest,
} from '../../../store/DslStore';
import axiosHelper from '../../../helpers/axiosHelper';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import { getAllCoCreationContests, getCurrentUser } from '../../../store/Selectors';
import { Datestr, registrationDateString, setTimelines } from '../../../helpers/utils';

interface IContestRowBig extends ICardInfo {
  category: string;
  contestTitle: string;
  endDate?: string;
  clientName?: string;
  entries: number;
  award: number;
  contest: Contest;
  status: ContestStatus;
  statusText: string;
  progress?: number;
  nda: boolean;
  private: boolean;
  accesscode: boolean;
  nameforurl?: string;
  contestType: string;
  linkToUrl?: string;
  linkToAward?: string;
  click?: (link: string) => void;
}

const ContestRowBig: React.FC<IContestRowBig> = (props) => {
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const allCocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const [liked, setLiked] = useState(false);
  const redirect = useNavigate();
  const [dateColor, setDateColor] = useState('');
  const endvotingdate = useRef('');
  const contestEndDate = useRef('');

  const cocreationTotalAward = () => {
    let awards = 0;
    console.log('cocreation award');
    allCocreationContests.map((item) => {
      if (item.mainContest.id === props.contest.id) {
        console.log(item.realTotalAwards);
        awards += item.realTotalAwards;
        // item.awards.map((award) => {
        //   console.log(award.price);
        //   awards += award.price;
        // });
      }
    });
    if (!props.contest.prices) {
      return awards;
    } else {
      return;
    }
  };
  const cocreationTotalEntries = () => {
    let entries = 0;
    console.log('cocreation award');
    allCocreationContests.map((item) => {
      if (item.mainContest.id === props.contest.id) {
        console.log(item.publicDesignsOrderedCount);
        entries += item.publicDesignsOrdered.length;
        // item.awards.map((award) => {
        //   console.log(award.price);
        //   awards += award.price;
        // });
      }
    });
    return entries;
  };

  const checkColor = () => {
    props.status === 'ongoing' && setDateColor('success');
    props.status === 'closed' && setDateColor('danger');
    props.status === 'client-vote' && setDateColor('dark_yellow');
    props.status === 'community-vote' && setDateColor('dark_yellow');
    props.status === 'coming-soon' && setDateColor('light_grey_40');
  };

  const date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  const startDateNumber = Math.trunc(Datestr(props.contest.startDate) / 86400000);
  const endDateNumber = Math.trunc(Datestr(props.contest.endDate) / 86400000);
  const endVotingDateNumber = Math.trunc(Datestr(props.contest.endVotingDate) / 86400000);

  const progressNumber = () => {
    // console.log(endDateNumber);
    // console.log(startDateNumber);
    let progressNumber = 100;
    if (props.contest.contestType !== 'Co-creation') {
      if (props.status === 'ongoing') {
        let tot = endDateNumber - startDateNumber;
        let passed = date - startDateNumber;
        progressNumber = (passed * 100) / tot;
        // console.log(tot, passed, progressNumber);
      } else if (props.status === 'community-vote') {
        let tot = endVotingDateNumber - endDateNumber;
        let passed = date - endDateNumber;
        progressNumber = (passed * 100) / tot;
      }
    } else {
      const cocreationSons: CoCreationContest[] = [];
      allCocreationContests.map((cocreation) => {
        if (cocreation.mainContest.id === props.contest.id) {
          cocreationSons.push(cocreation);
        }
      });
      cocreationSons.map((item) => {
        if (props.status === 'ongoing' && Math.trunc(new Date(item.endUploadDate).getTime()) / 86400000 > date) {
          let tot =
            Math.trunc(Datestr(item.endUploadDate) / 86400000) - Math.trunc(Datestr(item.startUploadDate) / 86400000);
          let passed = Math.trunc(date) - Math.trunc(Datestr(item.startUploadDate) / 86400000);
          // console.log(passed);
          // console.log(Math.trunc(Datestr(item.endUploadDate) / 86400000));
          // console.log(Math.trunc(Datestr(item.startUploadDate) / 86400000));
          // console.log(Math.trunc(date));
          // console.log(Math.trunc(Datestr(item.startUploadDate) / 86400000));
          // console.log(
          //   Math.trunc(Datestr(item.endUploadDate) / 86400000) - Math.trunc(Datestr(item.startUploadDate) / 86400000)
          // );
          // console.log(Math.trunc(date) - Math.trunc(Datestr(item.startUploadDate) / 86400000));

          // console.log((passed * 100) / tot);

          // console.log(tot);

          progressNumber = Math.trunc((passed * 100) / tot);
        } else if (
          props.status === 'community-vote' &&
          Math.trunc(new Date(item.endVotingDate).getTime()) / 86400000 > date
        ) {
          let tot =
            new Date(item.endVotingDate).getTime() / 86400000 - new Date(item.endUploadDate).getTime() / 846000000;
          let passed = date - new Date(item.endUploadDate).getTime() / 846000000;
          progressNumber = (passed * 100) / tot;
        }
      });
    }
    return progressNumber;
  };

  useEffect(() => {
    getCorrectEndDate();
  }, []);

  const getCorrectEndDate = () => {
    // console.log('correctDate');

    const date = new Date().getTime();

    // console.log('GET CORRECT END DATE : ');

    if (props.contest.contestType === 'Co-creation') {
      let contestAttached: CoCreationContest[] = [];
      // console.log('GET CORRECT END DATE : CO-CREATION');
      allCocreationContests.map((item) => {
        if (props.contest.id === item.mainContest.id) {
          contestAttached.push(item);
        }
      });
      // console.log('GET CORRECT END DATE : contestattached fatto' + JSON.stringify(contestAttached));
      contestAttached.map((item) => {
        console.log(item);
        if (item.endVotingDate) {
          endvotingdate.current = item.endVotingDate;
        }
        if (!item.isClosed) {
          if (date > new Date(item.startUploadDate).getTime() && date < new Date(item.endUploadDate).getTime()) {
            // console.log('CO-CREATION UPLOAD');
            contestEndDate.current = `Ends ${registrationDateString(item.endUploadDate)}`;
            setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
            return;
          } else if (item.endVotingDate) {
            if (date > new Date(item.endUploadDate).getTime() && date < new Date(item.endVotingDate).getTime()) {
              // console.log(date);
              // console.log(new Date(item.endUploadDate).getTime());
              // console.log(new Date(item.endVotingDate).toLocaleDateString());
              // console.log(new Date(item.endVotingDate).getTime());
              // console.log('CO-CREATION COMMUNITY');
              setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
              contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
            } else if (date > new Date(item.endUploadDate).getTime() && date > new Date(item.endVotingDate).getTime()) {
              // console.log('CO-CREATION CLIENT');
              setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
              contestEndDate.current = `Ends: ${registrationDateString(item.hypotheticalEndDate)}`;
            }
          } else if (date > new Date(item.endUploadDate).getTime()) {
            // console.log('CO-CREATION CLIENT VOTE');
            setTimelines(item.startDate, item.endDate, item.endVotingDate, item.hypotheticalEndDate, item);
            contestEndDate.current = `Ends: ${registrationDateString(item.hypotheticalEndDate)}`;
          }
        } else {
          // console.log('CO-CREATION CLOSED');
          contestEndDate.current = `Closed: ${registrationDateString(item.endDate)}`;
        }
      });
    } else {
      if (props.contest.endVotingDate) {
        endvotingdate.current = props.contest.endVotingDate;
      }
      // console.log('GET CORRECT END DATE : NORMAL CONTEST');
      if (date < new Date(props.contest.endDate).getTime() && !props.contest.isClosed) {
        // console.log('GET CORRECT END DATE : UPLOAD PHASE');
        contestEndDate.current = `Ends: ${registrationDateString(props.contest.endDate)}`;
        return;
      } else if (
        props.contest.endVotingDate &&
        date < new Date(props.contest.endVotingDate).getTime() &&
        !props.contest.isClosed
      ) {
        // console.log('GET CORRECT END DATE : COMMUNITY VOTE PHASE');
        contestEndDate.current = `Ends: ${registrationDateString(props.contest.endVotingDate)}`;
        return;
      } else if (
        (props.contest.endVotingDate &&
          date > new Date(props.contest.endVotingDate).getTime() &&
          date < new Date(props.contest.hypotheticalEndDate).getTime() &&
          !props.contest.isClosed) ||
        (date > new Date(props.contest.endDate).getTime() &&
          date < new Date(props.contest.hypotheticalEndDate).getTime() &&
          !props.contest.isClosed)
      ) {
        // console.log('GET CORRECT END DATE : CLIENT VOTE PHASE');
        contestEndDate.current = `Ends: ${registrationDateString(props.contest.hypotheticalEndDate)}`;
        return;
      } else {
        console.log('GET CORRECT END DATE : CLOSED PHASE');
        contestEndDate.current = `Closed: ${registrationDateString(
          props.contest.assignmentDate ? props.contest.assignmentDate : props.contest.endDate
        )}`;
        return;
      }
    }
    // return contest?.endDate;
  };

  const isLiked = () => {
    // console.log(props.contestId);
    let like: boolean;

    if (!loggedUser) {
      like = false;
      setLiked(like);
    }

    if (loggedUser?.contestsILike) {
      like = loggedUser.contestsILike.some((item) => item.id === props.contest.id);

      setLiked(like);
    }
  };

  useEffect(() => {
    isLiked();
    checkColor();
  }, []);

  const goToClientPage = async () => {
    await axiosHelper.retrieveUserById(props.contest.userId)?.then((response) => {
      setCommunityUser(response);
      redirect(`/community/${response.nameForUrl}/profile`);
    });
  };

  const goToContestAwards = async () => {
    await axiosHelper.retrieveCompleteContest(props.contest.id).then(async (response) => {
      setCurrentContest(response.data);
      await axiosHelper.getContestAwards(props.contest.id)?.then((awards) => {
        setContestAwards(awards.awards);
        redirect(`/contest/${response.data.nameForUrl}/awards`);
      });
    });
  };

  const goToContestGallery = async () => {
    await axiosHelper.retrieveCompleteContest(props.contest.id).then(async (response) => {
      setCurrentContest(response.data);
      redirect(`/contest/${response.data.nameForUrl}/gallery`);
    });
  };

  const getContestUser = async (contest: Contest) => {
    const result = await axiosHelper.retrieveCompleteContest(contest.id);
    setCurrentContest(contest);

    redirect('/contest/' + props.nameforurl + '/brief');
  };

  const contestLiked = (contestId: number) => {
    axiosHelper.likeContest(contestId).then((response) => {
      setLiked(true);
      // alert(`${props.contestTitle} added to favorites`);
    });
  };

  const contestUnliked = (contestId: number) => {
    axiosHelper.unlikeContest(contestId).then((response) => {
      setLiked(false);
      // alert(`${props.contestTitle} removed from favorites`);
    });
  };

  const getCoCreationContestDetails = async (contestId: number) => {
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);
    }
  };

  const setTags = async (contestId: number) => {
    let contestTags = await axiosHelper.getContestTags(contestId);
    if (contestTags) {
      console.log(contestTags);
      setContestTags(contestTags.tags);
    }
  };

  const getContestDetails = async (contestId?: number) => {
    console.log(props.nameforurl);
    console.log(props.category);
    console.log(props.contestType);
    if (contestId) {
      if (props.category !== 'Link to Other' && props.contestType !== 'Link to other') {
        const result = await axiosHelper.retrieveCompleteContest(contestId);
        setTags(contestId);
        if (props.contestType === 'Co-creation') {
          getCoCreationContestDetails(contestId);
        }
        // await axiosHelper.getContestGuidelines(contestId).then((response) => {
        //   console.log(response);
        //   setContestGuidelines(response);
        // }).catch((error) => {
        //   console.log(error)
        // });
        // console.log(result.data);
        // console.log(contest);
        // console.log(result);
        setCurrentContest(result.data);

        redirect('/contest/' + props.nameforurl + '/brief');
      } else {
        props.linkToUrl && props.click && props.click(props.linkToUrl);
        // props.linkToUrl && props.click && props.click('/Enel_Challenges/Smart_Meter');
        // console.log('ciaoo');
        // props.linkToUrl && window.location.replace(props.linkToUrl);
      }
    }
  };

  return (
    <div className={`${styles.ContestRowBigWrapper} col-12 mb-5  bg-light position-relative`}>
      {!liked && (
        <ActionButton
          position="top-right"
          label={loggedUser ? 'Save as favorite' : 'Login to save as favorite'}
          color={!loggedUser ? 'light-gray-3' : ''}
          action={() => loggedUser && contestLiked(props.contest.id)}
          icon="addFavorite"
        />
      )}
      {liked && (
        <ActionButton
          label="Remove from favorites"
          icon="confirm"
          position="top-right"
          color="success"
          action={() => contestUnliked(props.contest.id)}
        />
      )}
      <div className="row">
        {/* Cover Image  ====================================================================*/}
        <div className="col-3 ps-0  d-flex ">
          <div
            // onClick={() => getContestUser(props.contest)}
            onClick={() => getContestDetails(props.contest.id)}
            className={`  ${
              props.image ? styles.ContestRowBigImage : styles.ContestRowBigImagePlaceholder
            } bg-light-gray  w-100  position-relative `}
          >
            {props.image ? <img src={props.image} style={{ cursor: 'pointer' }} alt="Project Cover" /> : null}
            <div className={styles.statusBar}>
              <StatusBar status={props.status} progress={progressNumber()} />
            </div>
          </div>
        </div>
        {/* Content Info  ====================================================================*/}
        <div className="col-9 p-5 ps-4">
          <div className="row">
            <div className="col-12">
              <h2
                className="text-xl fw-bold color-blue"
                style={{ cursor: 'pointer' }}
                onClick={() => getContestDetails(props.contest.id)}
              >
                {props.contestTitle}
              </h2>
              <div className="mt-3 text">
                {props.contestType === 'Co-creation'
                  ? 'Co-creation'
                  : props.category === 'Link to Other'
                  ? 'Partnership'
                  : props.category}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 " style={{ cursor: 'pointer' }} onClick={() => goToClientPage()}>
              <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-3" />
              <span className="text text-start color-blue">{props.clientName}</span>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex flex-wrap">
              {!props.contest.accessCode && (
                <div className="pe-5 me-3 mb-3">
                  <button
                    className="btn-icon squared bg-blue color-light me-3"
                    style={{ height: '30px', width: '30px' }}
                  >
                    <FontAwesomeIcon icon={faEarthAmericas as IconProp} className="text" />
                  </button>
                  <span className="text text-start">global contest</span>
                </div>
              )}
              {props.private ? (
                <div className="pe-5 me-3 mb-3">
                  <button
                    className="btn-icon squared bg-blue color-light me-3"
                    style={{ height: '30px', width: '30px' }}
                  >
                    <FontAwesomeIcon icon={faEyeSlash as IconProp} className="text" />
                  </button>
                  <span className="text text-start">private gallery</span>
                </div>
              ) : null}
              {props.accesscode ? (
                <div className="pe-5 me-3 mb-3">
                  <button
                    className="btn-icon squared bg-blue color-light me-3"
                    style={{ height: '30px', width: '30px' }}
                  >
                    <FontAwesomeIcon icon={faKey as IconProp} className="text" />
                  </button>
                  <span className="text text-start">by invitation code contest</span>
                </div>
              ) : null}
              {props.nda ? (
                <div className="pe-5 me-3 mb-3">
                  <button
                    className="btn-icon squared bg-blue color-light me-3"
                    style={{ height: '30px', width: '30px' }}
                  >
                    <FontAwesomeIcon icon={faLock as IconProp} className="text" />
                  </button>
                  <span className="text text-start">accept nda</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex flex-wrap">
              <div className="pe-5 me-3 mb-3 ">
                <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                <span className={`text-xl  text-start color-${dateColor}`}>{props.statusText}</span>
              </div>
              <div className="pe-5 me-3 mb-3 ">
                <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                <span
                  className={`text-xl text-start ${
                    props.private || props.contestType == 'Link to other' ? '' : 'color-blue'
                  } `}
                  onClick={() => !props.private && props.contestType !== 'Link to other' && goToContestGallery()}
                >
                  {props.contestType === 'Co-creation'
                    ? cocreationTotalEntries() + ' entries '
                    : props.entries
                    ? props.entries + ' entries'
                    : ' 0 entries '}
                </span>
              </div>
              <div className=" pe-5 me-3 mb-3" style={{ cursor: 'pointer' }} onClick={() => goToContestAwards()}>
                <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                <span className="text-xl  text-start color-blue">
                  € {props.award} {cocreationTotalAward()} award
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestRowBig;
