import React, { useEffect, useRef } from 'react';
import styles from './DesignUserCard.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCalendar,
  faDollar,
  faFlask,
  faHeart,
  faMapMarkerAlt,
  faPencil,
  faTrophy,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { ProfilePicDesign } from '../../Profile/ProfilePic/ProfilePic';
import axiosHelper from '../../../helpers/axiosHelper';
import { User } from '../../../global';
import DslStore, { setCommunityUser, setContestAwards, setCurrentContest } from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import { getCurrentDesign, getCurrentUser } from '../../../store/Selectors';
import { fullPathLogo, fullPathLogoUser } from '../../../helpers/utils';
import { response } from 'express';

interface IDesignerCard {
  designTitle?: string;
  contestType?: string;
  contestCategory?: string;
  contestTitle?: string;
  uploadDate?: string;
  votes?: number;
  firstname?: string;
  lastname?: string;
  userImage?: string;
  userBadge?: string;
  username?: string;
  userId?: number;
  contestId?: number;
  awardsWon?: string;
  contestNameForUrl?: string;
}
export const DesignUserCard: React.FC<IDesignerCard> = (props) => {
  const redirect = useNavigate();
  const design = useStoreState(DslStore, getCurrentDesign);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const userImg = useRef(fullPathLogoUser(design?.designer.logoAttachment?.s3Path));

  const goToDesignerDetail = async (userid?: number) => {
    console.log(userid);
    if (userid) {
      let result: User = await axiosHelper.retrieveUserById(userid);
      console.log(result);

      setCommunityUser(result);
      currentUser && currentUser.id === userid
        ? redirect(`/community/${result.nameForURL}/profile-editable`)
        : redirect(`/community/${result.nameForURL}/profile`);
    }
  };

  useEffect(() => {
    console.log(props.userImage);
    userImg.current = fullPathLogoUser(props.userImage);
    console.log(userImg.current);
  }, [props]);

  const goToContestDetail = async (contestId?: number) => {
    if (contestId) {
      const result = await axiosHelper.retrieveCompleteContest(contestId);
      console.log(result.data.client);

      console.log(result);
      setCurrentContest(result.data);

      redirect(`/contest/${result.data.nameForUrl}/brief`);
    }
  };

  const goToAwards = async () => {
    if (props.contestId) {
      await axiosHelper.retrieveCompleteContest(props.contestId).then(async (contest) => {
        await axiosHelper.getContestAwards(props.contestId)?.then((response) => {
          setContestAwards(response.awards);
        });
        redirect(`/contest/${contest.data.nameForURL}/awards`);
      });
    }
  };

  useEffect(() => {
    console.log(props.contestCategory);
  }, []);

  return (
    <div className={`col-12 text-center bg-light radius-m p-5   ${styles.designUserCardWrapper}`}>
      <div className="row">
        <div className="col-12 col-md-12  col-lg-12 text-center  text-lg-center">
          <div style={{ height: '60px', overflow: 'hidden' }}>
            <span className="sub-title color-blue mb-2  ">{props.designTitle}</span>
          </div>
          <div className="text mb-7">{props.contestCategory}</div>
        </div>
        <div className="col-12 col-md-12 col-lg-12 ">
          <div className={` ${styles.profilePic} mb-5`}>
            {/* {console.log(userImg.current)} */}
            <ProfilePicDesign image={fullPathLogo(userImg.current)} editable={false}>
              {/* <div className={`${styles.badges} `}>
                <div className={`${styles.badge} bg-red color-light`}>
                  <FontAwesomeIcon icon={faFlask as IconProp} />
                </div>
                <div className={`${styles.badgeOver} bg-green color-light`}>
                  <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} />
                </div>
              </div> */}
            </ProfilePicDesign>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="info mt-7  d-block d-md-flex flex-md-column d-lg-block align-content-center ">
          <div className="d-flex justify-content-start  mb-3 me-0 me-md-3 me-lg-0">
            <div className="d-flex flex-column col-3 justify-content-center">
              <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-4" />
            </div>
            <div
              className="text text-start align-self-center color-blue cursor-pointer "
              onClick={() => goToDesignerDetail(props.userId)}
            >
              {props.firstname || props.lastname ? props.firstname + ' ' + props.lastname : props.username}
            </div>
          </div>
          <div className="d-flex justify-content-start mb-3 me-0 me-md-3 me-lg-0">
            <div className="d-flex flex-column col-3  justify-content-center">
              <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-4" />
            </div>
            <div className="text text-start align-self-center color-dark">Posted: {props.uploadDate}</div>
          </div>
          <div className="d-flex justify-content-start mb-3 me-0 me-md-3 me-lg-0">
            <div className="d-flex flex-column col-3  justify-content-center">
              <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-4" />
            </div>
            <div
              className="text text-start align-self-center color-blue cursor-pointer"
              onClick={() => goToContestDetail(props.contestId)}
            >
              {props.contestTitle}
            </div>
          </div>
          {/* <div className="d-flex justify-content-start mb-3">
            <div className="d-flex flex-column col-3  justify-content-center">
              <FontAwesomeIcon icon={faHeart as IconProp} className=" text color-blue me-4" />
            </div>
            <div className="text text-start align-self-center ">{props.votes} votes</div>
          </div> */}
          {props.contestCategory && (
            <div className="d-flex justify-content-start mb-3">
              <div className="d-flex flex-column col-3  justify-content-center">
                <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-4" />
              </div>
              <div
                className="text text-start color-blue cursor-pointer align-self-center "
                onClick={() => goToContestDetail(props.contestId)}
              >
                {props.contestCategory}
              </div>
            </div>
          )}

          <div className="d-flex justify-content-start mb-3">
            <div className="d-flex flex-column col-3  justify-content-center">
              <FontAwesomeIcon icon={faDollar as IconProp} className=" text color-blue me-4" />
            </div>
            <div className="text text-start align-self-center d-flex flex-row align-items-center justify-content-center">
              Award won:
              <span className={` ms-1 text color-blue cursor-pointer`} onClick={() => goToAwards()}>
                {`${props.awardsWon !== '' ? `${props.awardsWon}` : 'None'}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
