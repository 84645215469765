import React, { HTMLInputTypeAttribute, useCallback, useEffect, useRef, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import xLogo from '../../assets/x-twitter-01.png';

import styles from './UploadContest.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircleExclamation, faCircleQuestion, faCloudArrowUp, faXmark } from '@fortawesome/free-solid-svg-icons';

//Header Data

//Footer Data
import MiniTimeLine from '../../components/TimeLine/MiniTimeLine';
import SideActionsCard from '../../components/SideActionsCard/SideActionsCard';
import {
  getCoCreationContestStats,
  getContestTags,
  getCurrentContest,
  getCurrentUser,
  getTimelinePassedUpload,
  getTimelinePassedCommunityVote,
  getTimelineTotUpload,
  getTimelineTotCommunityVote,
  getTimelineTotClientVote,
  getTimelinePassedClientVote,
  getContestInspirationToEdit,
  getAllCoCreationContests,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setCommunityUser, setContestInspirations, setContestInspirationsCopy } from '../../store/DslStore';
import axiosHelper from '../../helpers/axiosHelper';
import { faFacebook, faPinterest, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  ContestTag,
  InspirationUpload,
  LogoAttachment,
  UpdateContestInspiration,
  User,
  UserInspiration,
  UserInspirationUpload,
} from '../../global';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import axios from 'axios';

interface ContestProps {
  breadcrumbs: (breadcrumbs: string) => void;
}

const EditContestInspiration: React.FC<ContestProps> = (props) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const allcocreations = useStoreState(DslStore, getAllCoCreationContests);
  const contestTag = useStoreState(DslStore, getContestTags);
  const userLogged = useStoreState(DslStore, getCurrentUser);
  const inspirationToEdit = useStoreState(DslStore, getContestInspirationToEdit);
  const timelineTotUpload = useStoreState(DslStore, getTimelineTotUpload);
  const timelinePassedUpload = useStoreState(DslStore, getTimelinePassedUpload);
  const timelineTotCommunityVote = useStoreState(DslStore, getTimelineTotCommunityVote);
  const timelinePassedCommunityVote = useStoreState(DslStore, getTimelinePassedCommunityVote);
  const timelineTotClientVote = useStoreState(DslStore, getTimelineTotClientVote);
  const timelinePassedClientVote = useStoreState(DslStore, getTimelinePassedClientVote);
  const cocreationstats = useStoreState(DslStore, getCoCreationContestStats);
  const [privacy, setPrivacy] = useState(inspirationToEdit?.isPrivate);
  const [popup, setPopup] = useState(false);
  const [popupURL, setPopupURL] = useState(false);
  const [popupDescription, setPopupDescription] = useState(false);
  const [popupPrivacy, setPopupPrivacy] = useState(false);
  const [websiterURL, setWebsiteUrl] = useState(inspirationToEdit?.websiteURL);
  const [description, setDescription] = useState(inspirationToEdit?.textDescription);
  const [image, setImage] = useState(inspirationToEdit?.imageAttachment);
  const urlError = useRef(false);
  const imageError = useRef(false);
  const [removeImage, setRemoveImage] = useState('false');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateContestInspiration>();
  const redirect = useNavigate();
  const tempUpload = useRef('');
  const [uploadedCoverImage, setUploadedCoverImage] = useState<File>();
  let tlUC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let tlUCC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let minitl1 = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
  };

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Edit Inspiration');
  }, []);

  const onDropCover = (e: any) => {
    // console.log(e?.[0]);
    setUploadedCoverImage(e?.[0]);
    imagePresignedUrl(e?.[0]);
  };

  const onSubmit = async (data: UpdateContestInspiration) => {
    imageError.current = false;
    urlError.current = false;
    data.isPrivate = privacy;

    if (description) {
      data.textDescription = description;
    }
    if (websiterURL) {
      data.url = websiterURL;
    } else {
      data.url = '';
    }

    data.tempUploads = tempUpload.current;
    console.log(data);

    if (!websiterURL) {
      if (!uploadedCoverImage && !image) {
        imageError.current = true;
        urlError.current = true;
      }
    }

    if (contest && inspirationToEdit?.id) {
      if (!imageError.current && !urlError.current) {
        await axiosHelper.updateContestInspiration(data, inspirationToEdit?.id, removeImage)?.then((response) => {
          console.log(response);
          console.log(response);
          setContestInspirations(response.allMainInspirationsOrdered);
          // setContestInspirationsCopy(response.allMainInspirationsOrdered);
          redirect(`/contest/${contest.nameForUrl}/inspirations`);
        });
      }
    }
  };

  async function imagePresignedUrl(file: File) {
    return await axiosHelper
      .getPresignedUrl(0, file.name, file.size, file.type)
      ?.then(async (response) => {
        console.log('id: ');
        console.log(response.data.id);
        tempUpload.current = response.data.id;
        return await axios
          .put(response.data.payload, file)
          .then((risposta) => {
            console.log(risposta);
            // updateString(response.data.id);
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  }

  const goToDetail = async (userid: number) => {
    console.log(userid);

    let result: User = await axiosHelper.retrieveUserById(userid);
    console.log(result);

    setCommunityUser(result);
    redirect(`/community/${result.nameForURL}/profile`);
  };

  const isUpperCase = (string: string) => /^[AZ]*$/.test(string);

  return (
    <div className="bg-light-gray">
      <main className={`container pt-5 mb-5 px-sm-0 overflow-hidden`}>
        {/* Head ====================================================================== */}

        {/* Body ====================================================================== */}
        {userLogged && (
          <div className="row ">
            <div className="col-12 col-md-3 px-5 px-sm-3 position-relative">
              <SideActionsCard>
                <button className="btn-blue rounded full mb-3">Add to favorite</button>
                <button className="btn-blue rounded full mb-3">Have a question?</button>
                <MiniTimeLine progress={minitl1} contest={contest} cocreationcontest={allcocreations} />

                <button
                  className="btn-success big full  rounded"
                  style={{ height: '45px' }}
                  onClick={() => redirect(`/contest/${contest?.nameForUrl}/upload-contest`)}
                >
                  <div className="btn-icon  ">
                    <FontAwesomeIcon
                      icon={faCloudArrowUp as IconProp}
                      style={{ fontSize: '3rem' }}
                      className="text color-light "
                    />
                  </div>
                  <div className="">Upload</div>
                </button>
              </SideActionsCard>
              <div className="tags mt-7 d-flex flex-wrap">
                {contestTag &&
                  contestTag.map((item: ContestTag, index: number) => (
                    // console.log(item),
                    <>
                      <button className="btn-tag mb-4 me-4" key={index}>
                        <span>{item.name.replace(',', '')}</span>
                      </button>
                    </>
                  ))}
                {/* <button className="btn-tag mb-4 me-4">
                <span>Even more Looooong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
              </div>

              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://desall.com/contest/${contest?.nameForUrl}/brief`}
                  target="_blank"
                >
                  <button className="btn-social__facebook full rounded mb-3 ">
                    <div className="btn-icon">
                      <FontAwesomeIcon icon={faFacebook as IconProp} className="text color-light" />
                    </div>
                    <span className="text-lowercase">like</span>
                  </button>
                </a>
              </div>
              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`http://twitter.com/share?url=https://desall.com/contest/${contest?.nameForUrl}/brief`}
                  target="_blank"
                >
                  <button className="btn-social__twitter full rounded mb-3">
                    <div className="btn-icon">
                      {/* <img src={xLogo} className="" style={{ height: '15px', width: '15px' }} /> */}
                      <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                    </div>
                    <span className="text-lowercase">post</span>
                  </button>
                </a>
              </div>
              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`http://pinterest.com/pin/create/button/?url=beta.altera.it/contest/${contest?.name}/brief&media=${contest?.image}&description=${contest?.userName}. Check it out on Desall.`}
                  target="_blank"
                >
                  <button className="btn-social__pinterest full rounded mb-3">
                    <div className="btn-icon">
                      <FontAwesomeIcon icon={faPinterest as IconProp} className="text color-light" />
                    </div>
                    <span className="text-lowercase">save</span>
                  </button>
                </a>
              </div>
            </div>

            <div className="col-12 col-md-9 pt-5 px-5 px-sm-4">
              <div className="row">
                <div className="col-12 px-4">
                  <div className="row-mb-5">
                    <div className="col-12 col-md-2 "></div>
                    <div className="col-12 col-md-10"></div>
                  </div>
                </div>
              </div>
              {/* {contest?.contestType !== 'Co-creation' && ( */}
              <div>
                <div className="row">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12 px-4">
                      <div className="row mb-5">
                        {/* <div className="col-12 col-md-2 mb-3"></div>
                          <div className="col-12 col-md-10 text  py-5">
                            <i>
                              To upload your inspiration, you have to fill the mandatory fields (
                              <span className="color-red">*</span>)
                            </i>
                          </div> */}
                        <div className="col-12 col-md-2 text fw-bold mb-3 ">
                          <div className=" d-flex justify-content-between" style={{ position: 'relative', zIndex: 10 }}>
                            URL
                            <div className="ml-auto">
                              <FontAwesomeIcon
                                icon={faCircleQuestion as IconProp}
                                className=" text color-blue me-0"
                                onMouseEnter={() => setPopupURL(true)}
                                onMouseLeave={() => setPopupURL(false)}
                              />
                              <div className={`${popupURL ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} text p-4`}>
                                  <i>no text</i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-10">
                          <input
                            type="text"
                            value={websiterURL}
                            {...(register('url'), { onChange: (e) => setWebsiteUrl(e.target.value) })}
                            placeholder="Please use the form http:// "
                            className="text-start "
                          />
                          {urlError.current && (
                            <span className="color-dark_red d-flex flex-row align-items-center mt-2 justify-content-center">
                              <FontAwesomeIcon icon={faCircleExclamation} className="color-dark_red me-2" /> please
                              provide an URL or an image
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row mb-5">
                        <div className="col-12 col-md-2 text fw-bold mb-3 ">
                          <div className=" d-flex justify-content-between" style={{ position: 'relative', zIndex: 10 }}>
                            Description
                            <div className=" ml-auto">
                              <FontAwesomeIcon
                                icon={faCircleQuestion as IconProp}
                                className=" text color-blue me-0 "
                                onMouseEnter={() => setPopupDescription(true)}
                                onMouseLeave={() => setPopupDescription(false)}
                              />
                              <div className={`${popupDescription ? styles.message : styles.noMessage}`}>
                                <div className={`${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} text p-4`}>
                                  <i>no text</i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-10  ">
                          <textarea
                            placeholder="maximum 140 characters"
                            required={false}
                            value={description}
                            {...register('textDescription', {
                              maxLength: 140,
                              onChange: (e) => setDescription(e.target.value),
                            })}
                          />
                        </div>
                      </div>

                      <div className="row mb-5">
                        <div className="col-12 col-md-2 text fw-bold mb-3">
                          <div className=" d-flex justify-content-between ">
                            <div className="mt-3">Image file</div>
                          </div>
                        </div>
                        <div
                          className={`col-12  col-md-10 ${
                            uploadedCoverImage ? '' : 'mb-5'
                          } d-flex flex-column align-items-center justify-content-center  ${
                            styles.uploadCoverContainer
                          }`}
                        >
                          {/* <label
                              htmlFor="images"
                              className={`${styles.dropcontainercoverimages} d-flex position-relative flex-column justify-content-center align-items-center text-center px-5`}
                            > */}
                          <Dropzone onDrop={onDropCover} accept="image/png, image/jpg, image/jpeg" maxSize={31457280}>
                            {({ getRootProps, getInputProps }) => (
                              <section
                                className={`${styles.dropzone}  bg-light d-flex align-items-center justify-content-center`}
                                {...getRootProps()}
                              >
                                <div className="col-12 d-flex  flex-column align-items-center justify-content-center text-center">
                                  <input {...getInputProps()} />
                                  <span>
                                    Drag and drop an image or <span className="color-blue">choose a file</span> from
                                    your computer <br />
                                    (allowed formats: .jpg, .png)
                                  </span>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          {imageError.current && (
                            <span className="color-dark_red d-flex flex-row align-items-center mt-2 justify-content-center">
                              <FontAwesomeIcon icon={faCircleExclamation} className="color-dark_red me-2" /> please
                              provide an URL or an image
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={`row mb-5 ${uploadedCoverImage || image ? '' : 'd-none'} `}>
                        <div className="col-12 col-md-2 text fw-bold mb-3">
                          <div className=" d-flex justify-content-between "></div>
                        </div>
                        <div
                          className={`col-12 col-md-10 mb-5 d-flex flex-column align-items-center justify-content-center  `}
                        >
                          {uploadedCoverImage && (
                            <div className=" d-flex" style={{ width: '100%' }}>
                              <div className={`${styles.previewImg}`}>
                                <img src={URL.createObjectURL(uploadedCoverImage)} className={`${styles.previewImg}`} />
                              </div>
                              <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                <span className="text-xl">{uploadedCoverImage.name}</span>
                              </div>
                              <div className=" d-flex align-items-center justify-content-end ">
                                <div
                                  // className="btn-danger"
                                  className={`${styles.removeButton}`}
                                  style={{ borderRadius: '8px' }}
                                  onClick={() => setUploadedCoverImage(undefined)}
                                >
                                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                </div>
                              </div>
                            </div>
                          )}
                          {image && (
                            <div className=" d-flex" style={{ width: '100%' }}>
                              <div className={`${styles.previewImg}`}>
                                <img src={image.s3Path} className={`${styles.previewImg}`} />
                              </div>
                              <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                <span className="text-xl">{image.originalFileName}</span>
                              </div>
                              <div className=" d-flex align-items-center justify-content-end ">
                                <div
                                  // className="btn-danger"
                                  className={`${styles.removeButton}`}
                                  style={{ borderRadius: '8px' }}
                                  onClick={() => (setImage(undefined), setRemoveImage('true'))}
                                >
                                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row mb-5">
                        {/* <div className="col-12  justify-content-center col-md-2 text align-middle fw-bold mb-3"> */}
                        <div className="col-12 col-md-2 text fw-bold mb-3 ">
                          <div style={{ position: 'relative', zIndex: 10 }}>
                            Content privacy
                            <div className="ml-auto">
                              <FontAwesomeIcon
                                icon={faCircleQuestion as IconProp}
                                className=" text color-blue me-0"
                                onMouseEnter={() => setPopupPrivacy(true)}
                                onMouseLeave={() => setPopupPrivacy(false)}
                              />
                              <div className={`${popupPrivacy ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} text p-4`}>
                                  <i>
                                    This option allows you to decide if your content will be private or available also
                                    for other users. If you want to boost your reputation, choose the public option.
                                  </i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-10">
                          <input
                            type="radio"
                            className="privacy align-middle me-4"
                            onClick={() => setPrivacy(!privacy)}
                            checked={!privacy}
                          />
                          <span className=" me-5"> Public </span>
                          &nbsp;
                          <input
                            type="radio"
                            className="privacy align-middle me-4"
                            onClick={() => setPrivacy(!privacy)}
                            checked={privacy}
                          />
                          <span className="me-5"> Private </span>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col-12  col-md-2 mb-3"></div>
                        <div className="col-12 col-md-10">
                          <div className=" d-flex align-items-center justify-content-center">
                            <button className="btn-blue rounded col-md-3 ">Publish</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default EditContestInspiration;
