import { BigCardComponent, BigCardSection } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import CardLarge from '../../CardLarge/CardLarge';
import Slider from '../../Slider/Slider';

function BigCardSectionComponent(props: BigCardSection) {
  return (
    <section key={props.id} className="py-3" style={{ backgroundColor: `${props.bgcolor}` }}>
      <div className="container my-5 ">
        <div className="row">
          <div className="col-12 text-center mb-3">
            {props.title && <h3 className={`bold-title color-${props.titlecolor} text-center mb-3`}>{props.title}</h3>}
            {props.subtitle && (
              <div className={`col-12 text-center sub-title color-${props.subtitlecolor}`}>{props.subtitle}</div>
            )}
          </div>
        </div>
      </div>
      <div className="mb-5">
        <Slider timeInterval={800} slides={props.bigcardcomponent}>
          {/* {props.bigcardcomponent &&
            props.bigcardcomponent.map((item: BigCardComponent, index: number) => {
              return (
                <CardLarge
                  key={index}              
                  onClick={() => item.link && window.open(item.link, `${item.target ? item.target : "_self"}`)}
                  topText={item.toptext}
                  middleText={item.middletext}
                  bottomText={item.bottomtext}
                  backGround={pathStrapiImage(item.image.data.attributes.url)}
                ></CardLarge>
              );
            })} */}
        </Slider>
      </div>
    </section>
  );
}

export default BigCardSectionComponent;
