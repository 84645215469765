import React, { useEffect, useState } from 'react';

import styles from './Profile.module.scss';

import {
  getCommunityUser,
  getCurrentUser,
  getUserContestsFavorites,
  getUserDesignsFavorites,
  getUserFavoritesPageNumber,
  getUserFavoritesType,
  getUserUserFavorited,
  getUserUserFavoritedCopy,
  getUserUsersFavorites,
} from '../../store/Selectors';
import DslStore, {
  setFavoriteContests,
  setFavoriteContestsCopy,
  setFavoriteDesigns,
  setFavoriteDesignsCopy,
  setFavoriteUsers,
  setFavoriteUsersCopy,
  setUserFavoritesPageNumber,
  setUserFavoritesType,
} from '../../store/DslStore';
import { useStoreState } from 'pullstate';

import {
  ContestFavorites,
  ContestsILike,
  DesignFavorites,
  DesignsILike,
  UserFavorited,
  UserFavorites,
  UsersILike,
} from '../../global';

import UserFavoriteNavigation, {
  PaginationSwitchFavoriteUsers,
  PaginationSwitchFavoriteContests,
  PaginationSwitchFavoriteDesigns,
} from '../../components/Profile/UserFavoritesNavigation/UserFavoritesNavigation';

import {
  designUploadDateString,
  fullPathLogo,
  fullPathLogoCommunity,
  fullPathLogoUser,
  locationString,
  registrationDateString,
  startDateString,
} from '../../helpers/utils';
import DesignCard from '../../components/Profile/ProfileDesign/DesignCard/DesignCard';
import axiosHelper from '../../helpers/axiosHelper';
import ContestCard from '../../components/Contest/ContestCard/ContestCard';
import DesignerCard from '../../components/Community/DesignerCard/DesignerCard';
import DefaultDesignImg from '../../assets/Design.png';
import { NoContent } from '../../components/Reusable/NoContent';
import { response } from 'express';

interface ProfileProps {
  breadcrumbs: (breadcrumbs: string) => void;
}

const UserFavoritesPage: React.FC<ProfileProps> = (props) => {
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const favoriteDesigns = useStoreState(DslStore, getUserDesignsFavorites);
  const pageNumber = useStoreState(DslStore, getUserFavoritesPageNumber);
  const userFavoritesType = useStoreState(DslStore, getUserFavoritesType);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const contestFavorites = useStoreState(DslStore, getUserContestsFavorites);
  const userFavorites = useStoreState(DslStore, getUserUsersFavorites);
  const userFavorited = useStoreState(DslStore, getUserUserFavorited);
  const userFavoritedCopy = useStoreState(DslStore, getUserUserFavoritedCopy);
  const date = new Date().toISOString();

  const [visibledesignsFavorite, setVisibleDesignsFavorite] = useState<DesignsILike[]>([]);
  const [usersFavorites, setUserFavorites] = useState<UserFavorites>();

  const [visibleContestsFavorite, setVisibleContestsFavorite] = useState<ContestsILike[]>([]);
  const [visibleUsersFavorite, setVisibleUsersFavorite] = useState<UsersILike[]>(userFavorites);
  const [visibleUsersFavorited, setVisibleUsersFavorited] = useState<UserFavorited[]>(userFavorited);
  const [key, setKey] = useState(0);

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Favorites');
  }, []);

  useEffect(() => {
    getUserFavorites();
  }, [communityUser]);

  const getUserFavorites = async () => {
    await axiosHelper.getFavoriteUsers(communityUser?.id)?.then((response) => {
      setFavoriteUsers(response.usersILike);
      setFavoriteUsersCopy(response.usersILike);
    });
  };

  const arraysDesignsfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: DesignsILike[] = [];
    const chunk: DesignsILike[] = favoriteDesigns.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < favoriteDesigns.length; i += chunkSize) {
      const chunk: DesignsILike[] = favoriteDesigns.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    // console.log(tempArray);
    setVisibleDesignsFavorite(tempArray);
  };

  const arraysContestsfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: ContestsILike[] = [];
    const chunk: ContestsILike[] = contestFavorites.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < contestFavorites.length; i += chunkSize) {
      const chunk: ContestsILike[] = contestFavorites.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    // console.log(tempArray);
    setVisibleContestsFavorite(tempArray);
  };

  useEffect(() => {
    arraysContestsfill();
  }, [contestFavorites]);

  const arraysUsersfill = () => {
    // console.log(userFavorites);
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: UsersILike[] = [];
    const chunk: UsersILike[] = userFavorites.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < userFavorites.length; i += chunkSize) {
      const chunk: UsersILike[] = userFavorites.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    // console.log(tempArray);
    setVisibleUsersFavorite(tempArray);
  };

  const arrayUserFavoritedFill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: UserFavorited[] = [];
    const chunk: UserFavorited[] = userFavorited.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < userFavorites.length; i += chunkSize) {
      const chunk: UserFavorited[] = userFavorited.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      // console.log(chunk[i]);
      // console.log(chunk[i] === undefined);
      if (chunk[i] !== undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log("array1:  ");
    // console.log(tempArray);
    setVisibleUsersFavorited(tempArray);
  };

  const getContest = async () => {
    if (communityUser) {
      setFavoriteContests([]);
      let result: ContestFavorites | undefined = await axiosHelper.getFavoriteContest(communityUser.id);
      // console.log(result);
      if (result) {
        arraysContestsfill();
        setFavoriteContests(result.contestsILike);
        setFavoriteContestsCopy(result.contestsILike);
      }
    }
  };

  const designFavorites = async () => {
    setFavoriteDesigns([]);
    if (communityUser) {
      const result: DesignFavorites | undefined = await axiosHelper.getFavoriteDesign(communityUser.id);
      // console.log(result);
      if (result) {
        setFavoriteDesigns(result.designsILike);
        setFavoriteDesignsCopy(result.designsILike);
      }
    }
  };

  const getuserFavorites = async () => {
    setUserFavorites(undefined);
    const result: UserFavorites | undefined = await axiosHelper.getFavoriteUsers(communityUser?.id);
    // console.log(result);
    if (result) {
      setFavoriteUsers(result.usersILike);
      setFavoriteUsersCopy(result.usersILike);
      setUserFavorites(result);
    }
  };

  useEffect(() => {
    arraysContestsfill();
  }, [contestFavorites]);

  useEffect(() => {
    setKey(key + 1);
    arraysUsersfill();
    arraysContestsfill();
    arraysDesignsfill();
    arrayUserFavoritedFill();
    designFavorites();
    getContest();
    getuserFavorites();
  }, [communityUser]);

  useEffect(() => {
    setVisibleUsersFavorite(userFavorites);
    arraysUsersfill();
    // console.log(userFavorites);
  }, [userFavorites]);

  useEffect(() => {
    setVisibleUsersFavorited(userFavorited);
    arrayUserFavoritedFill();
    // console.log(userFavorites);
  }, [userFavorited]);

  useEffect(() => {
    // console.log(Users);
    setUserFavoritesType(0);
    arraysUsersfill();
    arrayUserFavoritedFill();
    getContest();
    designFavorites();
    getuserFavorites();
    // console.log(favoriteDesigns);
  }, []);

  useEffect(() => {
    // getContest();
    arraysDesignsfill();
  }, [favoriteDesigns]);

  useEffect(() => {
    // console.log(pageNumber);
    // fillContests(pageNumber -1);
    arraysDesignsfill();
    arraysContestsfill();
    arraysUsersfill();
    arrayUserFavoritedFill();
  }, [pageNumber]);

  useEffect(() => {
    if (userFavoritesType === 0) {
      arraysDesignsfill();
    } else if (userFavoritesType === 1) {
      arraysContestsfill();
    } else if (userFavoritesType === 2) {
      arraysUsersfill();
    } else if (userFavoritesType === 3) {
      arraysUsersfill();
    }
  }, [userFavoritesType]);

  const isLikedUser = (idUser?: number) => {
    if (idUser) {
      console.log(communityUser);
      console.log(idUser);
      let state: boolean = false;
      communityUser && communityUser.usersILike.findIndex((item: any) => (item.id === idUser ? (state = true) : null));
      return state;
    }
    return false;
  };

  const isLikedContest = (idContest?: number) => {
    if (idContest) {
      let state: boolean = false;
      loggedUser && loggedUser.contestsILike.findIndex((item) => (item.id === idContest ? (state = true) : null));
      return state;
    }
    return false;
  };

  const statusText = (isClosed: boolean, startDate: string, endDate: string, endVotingDate?: string) => {
    // console.log(date);
    // console.log(endDate);

    // console.log(date > endDate);
    if (isClosed) {
      return `closed ${startDateString(endVotingDate)}`;
    } else if (date < startDate) {
      return 'coming soon';
    } else if (date < endDate) {
      return `ends ${startDateString(endDate)}`;
    } else if (endVotingDate && date < endVotingDate) {
      return `community vote`;
    } else if (endVotingDate && date > endVotingDate && !isClosed) {
      return `client vote`;
    } else if (date > endDate && !isClosed) {
      return `client-vote`;
    }
    return `standby`;
  };

  const checkStatus = (isClosed: boolean, startDate: string, endDate: string, endVotingDate?: string) => {
    if (isClosed) {
      return 'closed';
    } else if (date < startDate) {
      return 'ongoing';
    } else if (date < endDate) {
      return 'ongoing';
    } else if (endVotingDate && date < endVotingDate) {
      return 'community-vote';
    } else if (endVotingDate && date > endVotingDate && !isClosed) {
      return 'client-vote';
    } else if (date > endDate && !isClosed) {
      return 'client-vote';
    }
    return 'standby';
  };

  return (
    <div className="bg-light-gray" key={key}>
      <main className={`${styles.profileWrapper} container  px-sm-0 ps-2 `}>
        <div className={`row `}>
          <UserFavoriteNavigation array={visibleUsersFavorite} />

          <div className={`row mt-2  px-md-4 px-lg-0`} style={{ minHeight: '100px' }}>
            {userFavoritesType === 2 ? (
              visibleUsersFavorite.length > 0 ? (
                visibleUsersFavorite.map(
                  (aUserFavorite: UsersILike, index: number) => (
                    console.log(aUserFavorite),
                    (
                      <div className=" col-12  col-md-6 col-lg-3 py-4 ">
                        <DesignerCard
                          key={index}
                          nameForURL={aUserFavorite.nameForURL}
                          liked={isLikedUser(aUserFavorite.id)}
                          circle={true}
                          reputation={aUserFavorite.reputationCount}
                          name={aUserFavorite.username}
                          location={
                            aUserFavorite.country
                              ? locationString(aUserFavorite.city, aUserFavorite.country.name)
                              : locationString(aUserFavorite.city)
                          }
                          userType={aUserFavorite.type}
                          awarded={aUserFavorite.badgeAmountIn}
                          userId={aUserFavorite.id}
                          image={
                            aUserFavorite.logoAttachment
                              ? fullPathLogoUser(aUserFavorite.logoAttachment.s3Path)
                              : fullPathLogoCommunity()
                          }
                          entries={aUserFavorite.badgeDesignsOut}
                          startDate={registrationDateString(aUserFavorite.registrationDate)}
                        ></DesignerCard>
                      </div>
                    )
                  )
                )
              ) : (
                <NoContent />
              )
            ) : (
              ''
            )}

            {userFavoritesType === 3 ? (
              visibleUsersFavorited.length > 0 ? (
                visibleUsersFavorited.map((aUserFavorite: UserFavorited, index: number) => (
                  <div className=" col-12  col-md-6 col-lg-3 py-4 ">
                    <DesignerCard
                      key={index}
                      nameForURL={aUserFavorite.nameForURL}
                      liked={isLikedUser(aUserFavorite.id)}
                      circle={true}
                      reputation={aUserFavorite.reputationCount}
                      name={aUserFavorite.username}
                      location={
                        aUserFavorite.countryName
                          ? locationString(aUserFavorite.city, aUserFavorite.countryName)
                          : locationString(aUserFavorite.city)
                      }
                      userType={aUserFavorite.type === 'D' ? 'Designer' : 'Client'}
                      awarded={aUserFavorite.badgeAmountIn}
                      userId={aUserFavorite.id}
                      image={aUserFavorite.logo ? fullPathLogoUser(aUserFavorite.logo) : fullPathLogoCommunity()}
                      entries={aUserFavorite.badgeDesignsOut}
                      startDate={registrationDateString(aUserFavorite.registrationDate)}
                    ></DesignerCard>
                  </div>
                ))
              ) : (
                <NoContent />
              )
            ) : (
              ''
            )}
            {userFavoritesType === 1 ? (
              visibleContestsFavorite.length > 0 ? (
                visibleContestsFavorite.map(
                  (aContestFavorite: ContestsILike, index: number) => (
                    console.log(aContestFavorite),
                    (
                      <div className=" col-12 col-md-6 col-lg-4 col-xl-3 py-4 d-flex flex-row ps-lg-3 ">
                        <ContestCard
                          liked={isLikedContest(aContestFavorite.id)}
                          contestId={aContestFavorite.id}
                          title={aContestFavorite.name}
                          startDate={aContestFavorite.startUploadDate}
                          endVotingDate={aContestFavorite.endVotingDate}
                          category={aContestFavorite.category ? aContestFavorite.category.name : ''}
                          entries={aContestFavorite.publicDesignsOrderedCount}
                          statusText={statusText(
                            aContestFavorite.isClosed,
                            aContestFavorite.startUploadDate,
                            aContestFavorite.endUploadDate,
                            aContestFavorite.endVotingDate
                          )}
                          name={aContestFavorite.client.username}
                          userId={aContestFavorite.client.id}
                          status={checkStatus(
                            aContestFavorite.isClosed,
                            aContestFavorite.startUploadDate,
                            aContestFavorite.endUploadDate,
                            aContestFavorite.endVotingDate
                          )}
                          award={aContestFavorite.realTotalAwards}
                          image={fullPathLogo(aContestFavorite.logoAttachment.s3Path)}
                          private={aContestFavorite.isPrivate}
                          endDate={aContestFavorite.hypotheticalEndDate}
                          contestType={aContestFavorite.category ? aContestFavorite.category.name : ''}
                        />
                      </div>
                    )
                  )
                )
              ) : (
                <NoContent />
              )
            ) : (
              ''
            )}

            {userFavoritesType === 0 ? (
              visibledesignsFavorite.length > 0 ? (
                visibledesignsFavorite.map((aDesignFavorite: DesignsILike, index: number) => (
                  <div className="col-12 col-md-6 col-lg-3 py-4 ps-lg-3">
                    <DesignCard
                      key={index}
                      name={aDesignFavorite.name}
                      uploadDate={designUploadDateString(aDesignFavorite.uploadDate)}
                      votes={aDesignFavorite.votesCount}
                      image={
                        aDesignFavorite.image1Attachment ? aDesignFavorite.image1Attachment.s3Path : DefaultDesignImg
                      }
                      awarded={aDesignFavorite.hasAwards}
                      idDesign={aDesignFavorite.id}
                      contest={aDesignFavorite.contest}
                      draft={false}
                      hidden={aDesignFavorite.isHidden}
                    ></DesignCard>
                  </div>
                ))
              ) : (
                <NoContent />
              )
            ) : (
              ''
            )}
          </div>
          <div className="row mt-7  px-4"></div>
          <div className="row mt-7 px-4"></div>
          <div className="row ">
            <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
              {userFavoritesType === 0 ? (
                visibleUsersFavorite.length > 0 ? (
                  <div className=" mb-5">
                    <PaginationSwitchFavoriteUsers onChangePage={setUserFavoritesPageNumber} />
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {userFavoritesType === 1 ? (
                visibleContestsFavorite.length > 0 ? (
                  <div className=" mb-5">
                    <PaginationSwitchFavoriteContests onChangePage={setUserFavoritesPageNumber} />
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {userFavoritesType === 2 ? (
                visibledesignsFavorite.length > 0 ? (
                  <div className=" mb-5">
                    <PaginationSwitchFavoriteDesigns onChangePage={setUserFavoritesPageNumber} />
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};

export default UserFavoritesPage;
