import { useStoreState } from 'pullstate';
import { CoCreationContest, Contest } from '../global';
import DslStore, {
  setTimelinePassedClientVote,
  setTimelinePassedCommunityVote,
  setTimelinePassedUpload,
  setTimelineTotClientVote,
  setTimelineTotCommunityVote,
  setTimelineTotUpload,
  setTimerHours,
  setTimerMinutes,
  setTimerSeconds,
} from '../store/DslStore';
import { getTimerHours, getTimerMinutes, getTimerSeconds } from '../store/Selectors';
import designImg from '../assets/Design.png';
import inspirationImg from '../assets/Inspiration.png';

/**
 * converts hex color to rgb color
 * @param hex hex color string
 * @returns [r,g,b] values array
 */
export const hexToRGB = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

export const generateUniqueID = (prefix: string) => {
  let ts = new Date();
  let seed = Math.round(Math.random() * (5000 - 1000) + 1000);
  let timestamp =
    seed +
    ts.getUTCDate().toString() +
    ts.getUTCHours().toString() +
    ts.getUTCMinutes().toString() +
    ts.getUTCSeconds().toString() +
    ts.getUTCMilliseconds().toString();

  //    console.log('TIME_ID:' + timestamp)
  return `${prefix}${timestamp}`;
};

export const startDateString = (startDateString?: string) => {
  if (startDateString) {
    var startDate: Date = new Date(Date.parse(startDateString));
    // console.log("scheduleDateString: ");
    // console.log(startDateString, endDateString);
    return startDate.toLocaleString('it', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      // hour: '2-digit',
      // minute: '2-digit'
    });
  } else {
    return ' ';
  }
};

export const formatDateFromNumbers = (day: number, month: number, year: number) => {
  let startDate: string;
  startDate = year + '-' + month + '-' + day;
  console.log(startDate);
};

export const birthDateString = (date?: string) => {
  if (date) {
    console.log(date);
    var startDate: Date = new Date(Date.parse(date));
    console.log(
      startDate.toLocaleDateString('it', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
    );
    return startDate.toLocaleDateString('it', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  } else {
    return '-';
  }
};

export const postedDateString = (startDateString: string) => {
  var startDate: Date = new Date(Date.parse(startDateString));
  // console.log("scheduleDateString: ");
  // console.log(startDateString, endDateString);
  return startDate.toLocaleString('it', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    // hour: '2-digit',
    // minute: '2-digit'
  });
};

export const registrationDateString = (startDateString?: string) => {
  if (startDateString) {
    var startDate: Date = new Date(Date.parse(startDateString));
    // console.log("scheduleDateString: ");
    // console.log(startDateString, endDateString);
    return startDate.toLocaleDateString('en', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      // hour: '2-digit',
      // minute: '2-digit'
    });
  }
};

export const quadrettoDateString = (startDateString?: string) => {
  if (startDateString) {
    var startDate: Date = new Date(Date.parse(startDateString));
    // console.log("scheduleDateString: ");
    // console.log(startDateString, endDateString);
    return startDate.toLocaleDateString('en', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      // hour: '2-digit',
      // minute: '2-digit'
    });
  }
};

export const quadrettoDateHours = (startDateString?: string) => {
  if (startDateString) {
    // console.log(startDateString);
    var hours: number | string = new Date(startDateString).getHours();
    // console.log(hours);
    var minutes: number | string = new Date(startDateString).getMinutes();
    // console.log(minutes);
    var ampm = hours >= 12 ? 'PM' : 'AM';
    // console.log(ampm);
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm + ' UTC';
    // console.log(strTime);
    return strTime;
  }
};

export const designUploadDateString = (startDateString?: string) => {
  // console.log(startDateString);
  if (startDateString) {
    var startDate: Date = new Date(Date.parse(startDateString));
    // console.log("scheduleDateString: ");
    // console.log(startDateString, endDateString);
    return startDate.toLocaleDateString('en', {
      day: 'numeric',
      month: 'short',
      year: '2-digit',
      // hour: '2-digit',
      // minute: '2-digit'
    });
  } else {
    return '-';
  }
};

export const revisionDateString = (revisionDateString?: string) => {
  // console.log(startDateString);
  if (revisionDateString) {
    var startDate: Date = new Date(Date.parse(revisionDateString));
    // console.log("scheduleDateString: ");
    // console.log(startDateString, endDateString);
    return startDate.toLocaleDateString('en', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    return '-';
  }
};

export const designCommentHourString = (startDateString?: string) => {
  if (startDateString) {
    var startDate: Date = new Date(Date.parse(startDateString));
    // console.log("scheduleDateString: ");
    // console.log(startDateString, endDateString);
    return startDate.toLocaleTimeString('en', {
      // day: 'numeric',
      // month:'short',
      // year:'2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    return '-';
  }
};

export const fullPathLogoCommunity = (logoPath?: string) => {
  var link;
  logoPath !== null || logoPath !== undefined ? (link = logoPath) : (link = '');
  return link;
};

export const fullPathLogoTestUpload = (filename?: string) => {
  if (filename) {
    var link = 'https://test-upload-for-desall.s3.eu-west-1.amazonaws.com/' + filename;
    return link;
  }
};

export const fullPathLogoUser = (logoPath?: string) => {
  var link;
  logoPath ? (link = logoPath) : (link = '');
  return link;
};

export const fullPathLogoDesign = (logoPath?: string) => {
  var link;
  // console.log(logoPath);
  logoPath ? (link = logoPath) : (link = designImg);
  return link;
};

export const pathAttachment = (attPath?: string) => {
  let link;
  attPath !== null || attPath !== undefined ? (link = attPath) : (link = '#');
  return link;
};

export const pathInspirations = (logoPath: string) => {
  var link;
  logoPath !== null || logoPath !== undefined ? (link = logoPath) : (link = inspirationImg);
  return link;
};

export const pathStrapiImage = (path?: string) => {
  if (path) {
    // var link = `${Constants.STRAPI_LOCAL}` + path;
    var link = `${process.env.REACT_APP_BACKEND_URL}` + path;
    return link;
  }
};

export const bgcomponent = (bgcolor?: string) => {
  let value = '#ffffff';
  // console.log(bgcolor);
  if (bgcolor) {
    if (bgcolor === 'white') {
      value = '#ffffff';
    }
    if (bgcolor === 'lightgrey') {
      value = '#ededed';
    }
    if (bgcolor === 'desallblue') {
      value = '#7997c5';
    }
    if (bgcolor === 'darkgrey') {
      value = '#3b3c3c';
    }
  }
  // console.log(value);
  return value;
};

export const fullPathLogo = (logoPath?: string) => {
  var link;
  // console.log(logoPath);
  logoPath ? (link = logoPath) : (link = '');
  // console.log(link);
  return link;
};

export const userTypeString = (usertype?: string) => {
  var finalString;
  if (usertype === 'G') {
    finalString = 'God';
  } else if (usertype === 'C') {
    finalString = 'Client';
  } else if (usertype === 'D') {
    finalString = 'Designer';
  }

  return finalString;
};

export const finalString = (firstString?: string, secondString?: string) => {
  let finalString: string;
  firstString && secondString
    ? (finalString = firstString + ' ' + secondString)
    : firstString
    ? (finalString = firstString)
    : (finalString = '-');

  return finalString;
};

export const awardTitleString = (title?: string, pos?: number) => {
  let finalAwardTitle: string = '';
  if (title != null) {
    finalAwardTitle = title;
  } else {
    finalAwardTitle = pos + '\u00B0 Award';
  }
  return finalAwardTitle;
};

export const finalBirthYear = (birthYear?: number) => {
  let finalString: string;
  if (birthYear) {
    finalString = birthYear.toString();
  } else {
    finalString = '-';
  }
  // console.log(finalString);
  return finalString;
};

export const finalSlicedString = (initialString?: string) => {
  let finalString: string;
  let stringLength: number | undefined = initialString?.length;
  // console.log('lunghezza stringa' + stringLength);
  if (stringLength) {
    if (stringLength >= 16) {
      finalString = initialString!.replace(/([A-Z])/g, ' $1').trim();
      // console.log(finalString);
    } else {
      finalString = initialString!;
    }
  } else {
    finalString = ' ';
  }
  return finalString;
};

export const finalSlicedString15 = (initialString?: string) => {
  let finalString: string;
  let finalFirstString: string | undefined;
  let stringLength: number | undefined = initialString?.length;
  let finalLastString: string | undefined;
  // console.log('lunghezza stringa' + stringLength);
  if (stringLength) {
    if (stringLength >= 15) {
      finalFirstString = initialString?.slice(0, 14);
      finalLastString = initialString?.slice(15, stringLength);
      finalString = finalFirstString + ' ' + finalLastString;
      // console.log(finalString);
    } else {
      finalString = initialString!;
    }
  } else {
    finalString = ' ';
  }
  return finalString;
};

export const socialString = (socialString: string) => {
  if (socialString != null) {
    let finalString = socialString.substring(0, socialString.length - 1);
    return finalString;
  } else {
    return;
  }
};

export const locationString = (city?: string, country?: string) => {
  let string: string;
  if (city && country) {
    string = city + ', ' + country;
  } else if (city) {
    string = city;
  } else if (country) {
    string = country;
  } else {
    string = '-';
  }

  return string;
};

export const jobString = (job?: string) => {
  let stringa: string;
  if (job) {
    stringa = job.charAt(0).toUpperCase() + job.slice(1);
  } else {
    stringa = '-';
  }
  return stringa;
};

export const choseUserName = (contestMini: Contest | undefined) => {
  // console.log(contestMini?.userName);
  // console.log(contestMini?.linkToClient);

  if (contestMini?.userName != null) {
    return contestMini.userName;
  } else {
    return contestMini?.linkToClient;
  }
};

export const numberVotes = (number: number | undefined) => {
  let finalNumber;
  number != null ? (finalNumber = number) : (finalNumber = 0);
  return finalNumber;
};

export function Datestr(date: string) {
  return new Date(date).getTime();
}

export function setTimerValues(endDate: string, currentHours: number, currentMinutes: number, currentSeconds: number) {
  console.log(endDate);
  let dateiso = new Date().toISOString();
  let dateHours = Number(endDate.slice(11, 13));
  let nowHours = Number(dateiso.slice(11, 13));
  let dateMins = Number(endDate.slice(14, 16));
  let nowMins = Number(dateiso.slice(14, 16));
  let dateSec = Number(endDate.slice(17, 19));
  let nowSec = Number(dateiso.slice(17, 19));
  console.log(endDate);
  console.log(dateiso);
  console.log(dateHours);
  console.log(dateMins);
  console.log(dateSec);
  if (endDate > dateiso) {
    if (dateHours - nowHours !== currentHours) {
      setTimerHours(dateHours - nowHours);
    }
    if (dateMins - nowMins !== currentMinutes) {
      setTimerMinutes(dateMins - nowMins);
    }
    if (dateSec - nowSec !== currentSeconds) {
      setTimerSeconds(dateSec - nowSec);
    }
  }
}

export function setTimelines(
  startDate: string,
  endDate: string,
  endVotingDate: string,
  hypotheticalEndDate: string,
  contest: Contest | CoCreationContest
) {
  const date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  const startDateNumber = Math.trunc(Datestr(startDate) / 86400000);
  const endDateNumber = Math.trunc(Datestr(endDate) / 86400000);
  let dateNowNumber = Math.trunc(Datestr(date.toString()) / 86400000);
  const endVotingDateNumber = Math.trunc(Datestr(endVotingDate) / 86400000);
  const hypotheticalEndDateNumber = Math.trunc(Datestr(hypotheticalEndDate) / 86400000);
  let endstart = endDateNumber - startDateNumber;

  let startDateFromString = new Date(startDate);
  let endDateFromString = new Date(endDate);
  let endVotingDateFromString = new Date(endVotingDate);
  let dateNowUtcString = new Date().toUTCString();
  let dateNow = new Date(dateNowUtcString);

  if (startDateNumber && endDateNumber) {
    // console.log(endVotingDateFromString.getTime());
    let deltaDays = Math.trunc(endDateNumber - startDateNumber);

    // console.log(dateNow.getTime());
    // console.log(startDateFromString.getTime());
    // console.log(endDateFromString.getTime());
    // console.log(endVotingDateFromString.getTime());
    // console.log((endDateFromString.getTime() - startDateFromString.getTime()) / 86400000);
    // console.log((endDateFromString.getTime() - dateNow.getTime()) / 86400000);
    // console.log((dateNow.getTime() - startDateFromString.getTime()) / 86400000);

    // console.log(endDateNumber);
    // console.log(startDateNumber);
    // console.log(endDateNumber - startDateNumber);
    // console.log(contest.endVotingDate);

    // console.log(endVotingDateNumber - endDateNumber);
    // console.log(deltaDays);
    setTimelineTotUpload(endDateNumber - startDateNumber);
    if (date > endDateNumber) {
      // console.log(dateNowNumber);
      setTimelinePassedUpload(endDateNumber - startDateNumber);
    } else {
      setTimelinePassedUpload(date - startDateNumber);
    }

    if (endVotingDate) {
      // console.log('ciao ci sono pure io ');
      setTimelineTotCommunityVote(endVotingDateNumber - endDateNumber);
      setTimelinePassedCommunityVote(0);
      if (endVotingDateNumber > date) {
        // console.log('siamo in fase community');
        setTimelinePassedCommunityVote(date - endDateNumber);
      } else if (date > endVotingDateNumber) {
        // console.log('siamo fuori da community');
        setTimelinePassedCommunityVote(endVotingDateNumber - endDateNumber);
        // setTimelineTotClientVote(endVotingDateNumber - endDateNumber + 1);
        setTimelineTotClientVote(hypotheticalEndDateNumber - endVotingDateNumber + 1);
        setTimelinePassedClientVote(date - endVotingDateNumber);
        if (contest.isClosed) {
          setTimelineTotClientVote(100);
          setTimelinePassedClientVote(100);
        }
      }
    } else {
      setTimelineTotClientVote(hypotheticalEndDateNumber - endDateNumber + 1);
      setTimelinePassedClientVote(date - endDateNumber);
      if (date >= endDateNumber) {
        setTimelineTotClientVote(endDateNumber - startDateNumber + 1);
        setTimelineTotClientVote(hypotheticalEndDateNumber - endDateNumber + 1);
        setTimelinePassedClientVote(date - endDateNumber);
      }
      if (contest.isClosed) {
        setTimelineTotClientVote(100);
        setTimelinePassedClientVote(100);
      }
    }
  }
}

export const typeCategoryString = (type: string, category: string) => {
  var typeCategory: string;
  if (category == null) {
    typeCategory = type;
  } else {
    typeCategory = type + ' - ' + category;
  }
  return typeCategory;
};
