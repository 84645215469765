import React, { useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import xLogo from '../../assets/square-x-twitter_azure.png';

//Header Data
//Footer Data
//Styles
import styles from './Profile.module.scss';

import { birthDateString, finalString } from '../../helpers/utils';
import { getCommunityUser, getCurrentUser, getUserBlackList } from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, { setClientContest, setClientContestNumber, setBlackList } from '../../store/DslStore';
import {
  faBehanceSquare,
  faDribbbleSquare,
  faFacebookSquare,
  faFlickr,
  faGooglePlusSquare,
  faInstagramSquare,
  faLinkedin,
  faPinterestSquare,
  faSquareXTwitter,
  faTumblrSquare,
  faVimeoSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { Contest, ContestTag, SocialNetwork, UserDetails, UserFavorites } from '../../global';
import axiosHelper from '../../helpers/axiosHelper';
import { useNavigate, useParams } from 'react-router-dom';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import PopUpSendMessage from '../../components/PopUp/PopUpSendMessage/PopUpSendMessage';

interface ProfileProps {
  breadcrumbs: (breadcrumbs: string) => void;
  messageBanner: (message: number) => void;
}

const Profile: React.FC<ProfileProps> = (props) => {
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const blackList = useStoreState(DslStore, getUserBlackList);
  const { username } = useParams();
  const [usersFavorites, setUserFavorites] = useState<UserFavorites>();
  const [liked, setLiked] = useState(false);
  const [popUpLogin, setPopupLogin] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState(false);
  const path = window.location.pathname;
  const [black, setBlack] = useState(false);
  const redirect = useNavigate();

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Profile');
    isLiked();
  }, []);

  const isLiked = () => {
    if (currentUser && communityUser) {
      let state: boolean = false;

      currentUser?.userType !== 'C' &&
        currentUser.usersILike.findIndex((item) => (item.id === communityUser.id ? (state = true) : null));
      setLiked(state);
    }
  };

  const setClientContests = async () => {
    let result = await axiosHelper.getContestsByClientId(communityUser?.id);
    const clientContests: Contest[] = result.data;
    if (clientContests) {
      setClientContest(clientContests);
    }
  };

  const userContestNumber = () => {
    if (communityUser) {
      let clientContestLength: number = communityUser.contests.length;
      console.log(clientContestLength);
      setClientContestNumber(clientContestLength);
    }
  };

  const goTo = (link?: string) => {
    if (link) window.open(link, '_blank');
  };

  useEffect(() => {
    if (blackList) {
      isInBlackList();
    }
  }, [blackList]);

  const banUser = async () => {
    if (currentUser?.userType === 'G' && communityUser?.id) {
      await axiosHelper
        .banUser(communityUser.id)
        .then((response) => {
          console.log(response);
          // redirect('/');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const removeFromBlackList = async () => {
    communityUser?.id &&
      (await axiosHelper
        .removeUserFromBlackList(communityUser.id)
        .then((response) => {
          console.log(response);
          setBlackList(response);
        })
        .catch((error) => {
          console.log(error);
        }));
  };

  const addToBlackList = async () => {
    communityUser?.id &&
      (await axiosHelper
        .addUserToBlackList(communityUser.id)
        .then((response) => {
          console.log(response);
          setBlackList(response);
        })
        .catch((error) => {
          console.log(error);
        }));
  };

  useEffect(() => {
    // console.log(communityUser);
    isInBlackList();
    if (communityUser?.userType === 'C') {
      userContestNumber();
      setClientContests();
    }
    // console.log();
    // setUserDetails(communityUser?.id);

    // userFavorites();
  }, []);

  // useEffect(() => {
  //   if (usersFavorites) {
  //     console.log('userfavoriteset');
  //     setFavoriteUsers(usersFavorites.usersILike);
  //   }
  // }, [usersFavorites]);

  const userLiked = () => {
    if (communityUser?.id && currentUser) {
      currentUser.id !== communityUser.id &&
        axiosHelper.likeUser(communityUser.id).then((response) => {
          setLiked(true);
          // alert(`${props.user?.username} added to favorites`);
        });
    }
  };

  const userUnliked = () => {
    if (communityUser?.id) {
      axiosHelper.unlikeUser(communityUser.id).then((response) => {
        setLiked(false);
        // alert(`${props.user?.username} removed from favorites`);
      });
    }
  };

  // useEffect(() => {

  //   setUserDetails(communityUser?.id);
  //   setClientContests();
  //   userFavorites();
  // }, [communityUser]);

  const isInBlackList = () => {
    let blacklist: boolean = false;
    if (communityUser?.id) {
      blacklist = blackList.some((item) => item.id === communityUser.id);
      setBlack(blacklist);
    }
  };

  // useEffect(() => {
  //   let pathSplitted = path.split('/');

  //   axiosHelper.retrieveUserByName(pathSplitted[2])?.then((response) => {
  //     setCommunityUser(response.data);
  //   });

  //   currentUser &&
  //     communityUser &&
  //     currentUser.id === communityUser?.id &&
  //     redirect(`/community/${currentUser.nameForURL}/profile-editable`);
  // }, []);

  return (
    <div className="bg-light-gray px-md-0 ">
      <main className={`${styles.profileWrapper}  container pt-5 mb-5 px-sm-0  overflow-hidden `}>
        <div className={`row  `}>
          {/* Left column  ====================================================== */}
          <div className="col-12  col-md-3 ">
            {currentUser?.id !== communityUser?.id && (
              <div className="buttons ">
                <button
                  className={`${liked ? 'btn-danger' : 'btn-blue'} rounded full  mb-3`}
                  onClick={() => (currentUser ? (liked ? userUnliked() : userLiked()) : setPopupLogin(true))}
                >
                  {liked ? 'Remove from favorites' : 'Add to favorite'}
                </button>
                {currentUser && communityUser?.userType === 'D' && communityUser?.hasPrivateMessagesNotification && (
                  <button className="btn-blue rounded full mb-3" onClick={() => setPopUpMessage(true)}>
                    Send message
                  </button>
                )}
                {currentUser && communityUser?.userType === 'D' && !communityUser?.hasPrivateMessagesNotification && (
                  <button className="btn-blue rounded full mb-3">Do not disturb</button>
                )}
                {currentUser && (
                  <button
                    className={`${black ? `btn-warning` : `btn-red`} rounded big full mb-3`}
                    onClick={() => (black ? removeFromBlackList() : addToBlackList())}
                  >
                    {black ? 'Unblock user' : 'Block user'}
                  </button>
                )}
                {currentUser?.userType === 'G' && (
                  <button className="btn-red rounded big full mb-3" onClick={() => banUser()}>
                    Ban user
                  </button>
                )}
                {popUpMessage && communityUser && (
                  <PopUpSendMessage
                    popup={popUpMessage}
                    close={() => setPopUpMessage(false)}
                    message={(message) => (props.messageBanner(message), setPopUpMessage(false))}
                    recipient={communityUser.username}
                  />
                )}

                {popUpLogin && (
                  <PopUpLogin
                    popup={popUpLogin}
                    close={() => setPopupLogin(false)}
                    login={(e: boolean) => setPopupLogin(e)}
                  />
                )}
              </div>
            )}
            <div className={`tags ${currentUser?.id !== communityUser?.id && 'mt-5'}  d-flex flex-wrap`}>
              {communityUser?.tags &&
                communityUser?.tags.map((item: ContestTag, index: number) => (
                  <>
                    <button className="btn-tag mb-4 px-0 me-5">
                      <span>{item.name.replace(',', '')}</span>
                    </button>
                  </>
                ))}
              {/* <button className="btn-tag mb-4 ">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
            </div>
          </div>
          <div className="col-12 col-md-9 ">
            <div className="row">
              <div className="col-12 ps-4 ps-sm-5">
                {/*                 
                <div className="row mb-5 mt-4">
                  <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Username</div>
                  <div className="col-12 col-md-10">{finalString(communityUser?.username)}</div>
                </div> */}
                {communityUser?.hasPublicName && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Name</div>
                    <div className="col-12 col-md-10">
                      {finalString(communityUser?.firstName, communityUser?.lastName)}
                    </div>
                  </div>
                )}
                {/* {communityUser?.hasPublicBirthDate && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">BirthDate</div>
                    <div className="col-12 col-md-10">{communityUser.birthDate}</div>
                  </div>
                )} */}
                {/* {communityUser?.addressLine1 && communityUser.hasPublicCity && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Address</div>
                    <div className="col-12 col-md-10">{communityUser.addressLine1}</div>
                  </div>
                )}
                {communityUser?.zipCode && communityUser.hasPublicCity && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Zip code</div>
                    <div className="col-12 col-md-10">{communityUser.zipCode}</div>
                  </div>
                )} */}
                {communityUser?.phoneNumber && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Phone number</div>
                    <div className="col-12 col-md-10">{communityUser.phoneNumber}</div>
                  </div>
                )}
                {communityUser?.contactLanguage && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Contact language</div>
                    <div className="col-12 col-md-10">{communityUser.contactLanguage}</div>
                  </div>
                )}
                {communityUser?.description && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Description</div>
                    <div className="col-12 col-md-10">{communityUser?.description}</div>
                  </div>
                )}
                {/* {communityUser?.sector && (
                  <div className="row mb-5 ">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Sector</div>
                    <div className="col-12 col-md-10">{communityUser?.sector}</div>
                  </div>
                )} */}
                {communityUser?.userType === 'D' && communityUser.biography && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Biography</div>
                    <div className="col-12 col-md-10">
                      <p>{finalString(communityUser?.biography)}</p>
                    </div>
                  </div>
                )}
                {communityUser?.userType === 'D' && (
                  <>
                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Who are you</div>

                      {communityUser?.jobTitle && (
                        <div className="col-12 col-md-10">
                          {finalString(communityUser?.jobTitle)},&nbsp; {finalString(communityUser?.job?.name)}
                        </div>
                      )}
                    </div>
                    {communityUser?.company && (
                      <div className="row mb-5">
                        <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Who are you</div>

                        <div className="col-12 col-md-10">{communityUser.company}</div>
                      </div>
                    )}
                  </>
                )}
                {communityUser?.school1 && communityUser.hasPublicFirstSchool && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">School</div>
                    <div className="col-12 col-md-10">{communityUser?.school1}</div>
                  </div>
                )}
                {communityUser?.school2 && communityUser.hasPublicSecondSchool && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">School</div>
                    <div className="col-12 col-md-10">{communityUser?.school2}</div>
                  </div>
                )}
                {communityUser?.freelanceLicense && communityUser.freelanceLicense !== 'N' && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Freelance license</div>
                    <div className="col-12 col-md-10">{communityUser?.freelanceLicense === 'Y' ? 'yes' : 'no'}</div>
                  </div>
                )}

                {communityUser?.gender && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Gender</div>
                    <div className="col-12 col-md-10">
                      {communityUser?.gender === 'M'
                        ? 'He/Him'
                        : communityUser?.gender === 'F'
                        ? 'She/Her'
                        : communityUser?.gender === 'N'
                        ? 'N/A'
                        : 'other'}
                    </div>
                  </div>
                )}
                {communityUser?.userType === 'D' && communityUser.hasPublicBirthDate && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Birth date</div>

                    <div className="col-12 col-md-10">{birthDateString(communityUser?.birthDate)}</div>
                  </div>
                )}
                {communityUser?.socialNetworks && communityUser.socialNetworks.length > 0 && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Social network</div>
                    <div className="col-12 col-md-10 d-flex flex-row align-items-center">
                      {communityUser?.socialNetworks && communityUser.socialNetworks.length < 1 ? (
                        <span> - </span>
                      ) : null}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'facebook.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faFacebookSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'twitter.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faSquareXTwitter as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'behance.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faBehanceSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'dribbble.' ? (
                            <span>
                              <FontAwesomeIcon
                                className=" text color-blue me-3"
                                icon={faDribbbleSquare as IconProp}
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'flickr.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faFlickr as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'google.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faGooglePlusSquare as IconProp}
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                className=" text  color-blue me-3"
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'linkedin.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faLinkedin as IconProp}
                                className=" text  color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'pinterest.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faPinterestSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'tumblr.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faTumblrSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'vimeo.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faVimeoSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'instagram.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faInstagramSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                      {communityUser?.socialNetworks &&
                        communityUser.socialNetworks.map((aSocialNetwork: SocialNetwork) =>
                          aSocialNetwork.socialNetworkFinder.comparisonString === 'youtube.' ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faYoutubeSquare as IconProp}
                                className=" text color-blue me-3"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => goTo(aSocialNetwork.url)}
                              />
                            </span>
                          ) : null
                        )}
                    </div>
                  </div>
                )}
                {communityUser?.website && (
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Website</div>
                    <div className="col-12 col-md-10">
                      <a href={communityUser?.website}>{finalString(communityUser?.website)}</a>
                    </div>
                  </div>
                )}

                {/* <div className="row mb-5">
                  <div className="col-12 col-md-2 text text-left mb-2 fw-bold">Groups</div>
                  <div className="col-12 col-md-10">
                    <a href="#">Dream Team - Shenkar College</a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};

export default Profile;
