import React from 'react';
import styles from './CardLarge.module.scss';

interface ICardLarge {
  backGround?: string;
  topText?: string;
  bottomText?: string;
  target?: string;
  link?: string;
  middleText?: string;
  showContent?: boolean;
  onClick?: () => void;
}

/**
 * Card 270x270
 * @param param0
 * @returns
 */
const CardLarge: React.FC<ICardLarge> = (props) => {
  const handleOnClick = () => {
    props.onClick && props.onClick();
  };

  return (
    <div
      onClick={handleOnClick}
      className={`${
        props.topText || props.middleText || props.bottomText ? styles.cardWrapper : styles.cardWrapperNoHover
      } ${props.showContent && styles.visibleContent}  rounded `}
    >
      <img className="img-370" src={props.backGround} alt="Placeholder" />
      <div className={`${styles.cardOverlay} d-flex flex-column`}>
        {props.children && (
          <div className={`flex-grow-1 d-flex flex-column justify-content-center align-items-center`}>
            {props.children}
          </div>
        )}
        {!props.children && props.topText && (
          <div className={`color-light sub-title flex-grow-1 d-flex justify-content-center align-items-center`}>
            {props.topText}
          </div>
        )}
        {!props.children && props.middleText && (
          <div className={`color-light m-5 text-center flex-grow-1 d-flex justify-content-center align-items-center`}>
            {props.middleText}
          </div>
        )}

        {!props.children && props.bottomText && (
          <div className={`color-light sub-title flex-grow-1 d-flex justify-content-center align-items-center`}>
            {props.bottomText}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardLarge;
