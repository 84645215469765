import React from 'react';

import { CommunityStrapiComponent, CurstomCommunityProps, ImageSliderProps } from '../../global';

import { useStoreState } from 'pullstate';
import DslStore from '../../store/DslStore';
import { getStrapiCommunity } from '../../store/Selectors';
import Top4Week from '../Strapi/Top4Week/Top4Week';
import LastMembers from '../Strapi/LastMembers/LastMembers';
import TopAllTime from '../Strapi/TopAllTime/TopAllTime';
import Custom4Users from '../Strapi/Custom4Users/Custom4Users';
import CommunitySlider from '../Strapi/CommunitySlider/CommunitySlider';

const QueryCommunity = () => {
  const strapiPage = useStoreState(DslStore, getStrapiCommunity);

  return (
    <>
      {strapiPage.map((item: CommunityStrapiComponent) => {
        console.log(item.__component);
        if (item.__component === 'sections.image-slider') {
          let props: ImageSliderProps = {
            images: item.image.data,
          };
          return React.createElement(CommunitySlider, props);
        }
        if (item.__component === 'sections.top-5-of-the-week') {
          let props: CurstomCommunityProps = {
            title: item.title,
            limit: 4,
          };
          return React.createElement(Top4Week, props);
        }
        if (item.__component === 'sections.top-10-of-the-week') {
          let props: CurstomCommunityProps = {
            title: item.title,
          };
          return React.createElement(Top4Week, props);
        }
        if (item.__component === 'sections.last-5-members') {
          let props: CurstomCommunityProps = {
            title: item.title,
            limit: 4,
          };
          return React.createElement(LastMembers, props);
        }
        if (item.__component === 'sections.last-10-members') {
          let props: CurstomCommunityProps = {
            title: item.title,
          };
          return React.createElement(LastMembers, props);
        }
        if (item.__component === 'sections.top-5-all-times') {
          let props: CurstomCommunityProps = {
            title: item.title,
            limit: 4,
          };
          return React.createElement(TopAllTime, props);
        }
        if (item.__component === 'sections.top-10-all-times') {
          let props: CurstomCommunityProps = {
            title: item.title,
          };
          return React.createElement(TopAllTime, props);
        }
        if (item.__component === 'sections.5-custom-users') {
          let props: CurstomCommunityProps = {
            title: item.title,
            username1: item.username1,
            username2: item.username2,
            username3: item.username3,
            username4: item.username4,
            limit: 4,
          };
          return React.createElement(Custom4Users, props);
        }
        return null;
      })}
    </>
  );
};

export default QueryCommunity;
