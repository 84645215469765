import React, { useEffect, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

//Header Data

import { getCommunityUser, getCurrentUser, getFooterMenu, getHeaderMenu, getUserAwards } from '../../store/Selectors';
import DslStore, { setCommunityUser, setCurrentUser, setCurrentUserHunterRequests } from '../../store/DslStore';
import { useStoreState } from 'pullstate';

import styles from './Profile.module.scss';

import { Award, ContestTag, HunterRequest, HunterRequestData } from '../../global';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faIndustry,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import axiosHelper from '../../helpers/axiosHelper';
import { useForm } from 'react-hook-form';

interface ProfileProps {
  breadcrumbs: (breadcrumbs: string) => void
};

const Ambassador: React.FC<ProfileProps> = (props) => {
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [hunterAnimation, setHunterAnimation] = useState(false);
  const [hunterPopUp, setHunterPopUp] = useState(false);
  const [fullName, setFullName] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [acceptedInvites, setAcceptedInvites] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HunterRequestData>();

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Ambassador'); 
  })

  useEffect(() => {
    if (!currentUser?.hunters) {
      if (currentUser?.userType === 'D') {
        axiosHelper.createDesignerHunter(currentUser.id)?.then((response) => {
          setCurrentUser(response);
          setCommunityUser(response);
        });
      } else if (currentUser?.userType === 'C') {
        axiosHelper.createClientHunter(currentUser.id)?.then((response) => {
          setCurrentUser(response);
          setCommunityUser(response);
        });
      }
    } else {
      console.log(currentUser?.hunters);
      axiosHelper.getHunterRequests().then((response) => {
        setCurrentUserHunterRequests(response);
        haveHunterRequestsAccepted();
      });
    }
  }, []);

  const haveHunterRequestsAccepted = () => {
    // console.log(currentUser?.hunterRequests);
    currentUser?.hunterRequests.length !== undefined &&
      currentUser?.hunterRequests.map((item: HunterRequest) => {
        if (item.isAccepted) {
          setAcceptedInvites(true);
        }
      });
  };

  const inviteFriend = async () => {
    setEmailError('');
    await axiosHelper.inviteFriendByEmail(email, currentUser?.id)?.then((response) => {
      console.log(response);
      if (response !== 200) {
        if (response === 409) {
          setEmailError('A user with the same Email already exists');
        }
        if (response === 400) {
          setEmailError('This is not a valid email address');
        }
        if (response === 500) {
          setEmailError('Please retry');
        }
      } else {
        setEmail('');
      }
    });
    // .catch((error) => console.log(error));
  };

  const onSubmit = async (data: HunterRequestData) => {
    if (data.fullName && data.reason) {
      await axiosHelper.createClientHunterRequest(data)?.then((response) => {
        console.log(response);
        // alert('richiesta inoltrata');
      });
    }
  };

  return (
    <div className="bg-light-gray">
      
      <main className={`${styles.profileWrapper} container pt-5 mb-5 px-sm-0 overflow-hidden`}>
        

        {/* <div className={`${styles.picUpPage}`}> */}
        <div className={`row  `}>
          {/* Left column  ====================================================== */}
          <div className="col-12  col-md-3  ">
            {communityUser?.id !== currentUser?.id && (
              <div className="buttons">
                <button className="btn-blue rounded full  mb-3">Add to favorite</button>
                <button className="btn-blue rounded full mb-3">Send message</button>
                <button className="btn-red rounded big full mb-3">Block</button>
              </div>
            )}
            <div className="tags  d-flex flex-wrap">
              {communityUser?.tags &&
                communityUser?.tags.map((item: ContestTag, index: number) => (
                  <>
                    <button className="btn-tag mb-4 ">
                      <span>{item.name.replace(',', '')}</span>
                    </button>
                  </>
                ))}
              {/* <button className="btn-tag mb-4 ">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
            </div>
          </div>
          <div className="col-12 col-md-9   px-5 px-sm-4 ">
            <div
              className="col-12  bg-blue  mb-5 align-items-center justify-content-center  d-flex"
              style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
              onClick={() => (setHunterPopUp(!hunterPopUp), setHunterAnimation(true))}
            >
              <div className="col-2"></div>
              <div className="col-8  justify-content-center d-flex ">
                <span
                  className="text-xxl fw-bold color-light d-flex align-items-center justify-content-center"
                  style={{ fontSize: '2rem' }}
                >
                  What's an ambassador &nbsp; {hunterPopUp && <span className="text-16"> (read less) </span>}
                  {!hunterPopUp && <span className="text-16"> (read more)</span>}
                </span>
              </div>

              <div className={`col-2 d-flex justify-content-end align-items-center ${styles.toggle}`}>
                {hunterPopUp ? (
                  <div
                    className={`${styles.arrow} ${hunterAnimation ? styles.transitionIn : null}`}
                    onAnimationEnd={() => setHunterAnimation(false)}
                  >
                    <span
                      className=" d-flex flex-row align-items-center"
                      // className={`${yourProfileAnimation ? styles.transitionIn : styles.transitionOut}`}
                      onClick={() => (setHunterAnimation(!hunterPopUp), setHunterAnimation(true))}
                    >
                      <FontAwesomeIcon icon={faAngleUp as IconProp} className={`text color-light ${styles.angle}`} />
                    </span>
                  </div>
                ) : (
                  <div
                    className={`${styles.arrow} ${hunterAnimation ? styles.transitionIn : null}`}
                    onAnimationEnd={() => setHunterAnimation(false)}
                  >
                    <span
                      className=" d-flex flex-row align-items-center"
                      onClick={() => (setHunterPopUp(!hunterPopUp), setHunterAnimation(true))}
                    >
                      <FontAwesomeIcon icon={faAngleDown as IconProp} className={`text color-light ${styles.angle}`} />
                    </span>
                  </div>
                )}
              </div>
            </div>
            {hunterPopUp && (
              <>
                <div className="  mb-7 px-5">
                  <p className="text-16 mb-5 ">
                    An ambassdor is a user with special powers! You can become an ambassador yourself by inviting your
                    friends to join our great community of talents and - as a User ambassador - you will earn extra
                    reputation points on Desall.com, to stand out from the crowd and have access to exclusive
                    opportunities.
                  </p>

                  <p className="text-16  ">
                    Wanna do even more? If you apply as a Client ambassador you can also earn money and become an
                    official promoter of Desall.com from wherever you are and whenever you want, bringing new business
                    contacts and contest opportunities for all!
                  </p>
                </div>
              </>
            )}
            <div className=""></div>
            <div className="col-12 d-flex mb-5  ">
              <div className="col-12 col-md-3 col-sm-3 ">
                <div className={`${styles.ambassadorLeftImgWrapper} `}>
                  <div className={`${styles.ambassadorLeftImg}`}>
                    <FontAwesomeIcon icon={faUser as IconProp} className={`color-light ${styles.item}`} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-9 col-sm-9 ps-5    d-flex flex-column ">
                <div className="text-16  mb-3 ">
                  <span>
                    Share your creative-ambassador link: &nbsp;
                    <a href={`https://beta.altera.it/signup/${currentUser?.hunters[0].code}`} target="_blank">
                      beta.altera.it/Signup/{currentUser?.hunters[0].code}
                    </a>
                  </span>
                </div>
                <div className="sub-title  mt-2  mb-2 ">
                  <span>OR </span>
                </div>
                <div className="text-16  mb-3 mt-3 ">
                  <div className="w-100   mb-3 ">
                    <span className="">Invite your friends by email: </span>
                  </div>
                  <input
                    type="text"
                    className="text "
                    placeholder="please insert here the e-mail address you want to invite"
                    value={email !== '' ? email : ''}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {emailError !== '' && <span className="text-center color-danger">{emailError}</span>}
                <div className="text-16  mb-5 mt-3  d-flex align-items-center justify-content-center ">
                  <div className="btn-success rounded me-3" onClick={() => inviteFriend()}>
                    send invitation
                  </div>
                  {acceptedInvites && <div className="btn-dark_blue rounded ">accepted invites</div>}
                </div>
              </div>
            </div>
            <div className="col-12 d-flex mb-5">
              <div className="col-12 col-md-3 col-sm-3  ">
                <div className={`${styles.ambassadorLeftImgWrapper} `}>
                  <div className={`${styles.ambassadorLeftImg}`}>
                    <FontAwesomeIcon icon={faIndustry as IconProp} className={`color-light ${styles.item}`} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-9 col-sm-9 ps-5   d-flex flex-column ">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="text-16  mb-5  ">
                    <span>Request your client-ambassador code </span>
                  </div>
                  <div className="sub-title text-center mt-2  mb-3 ">
                    <input
                      type="text"
                      className="text "
                      value={fullName ? fullName : ''}
                      placeholder="name and surname "
                      {...register('fullName', {
                        required: true,
                        onChange: (e) => {
                          setFullName(e.target.value);
                        },
                      })}
                    />
                  </div>
                  <div className="text-16  mb-3 mt-3 ">
                    <textarea
                      className="text "
                      placeholder="let us know something about you to support your request"
                      value={reason ? reason : ''}
                      {...(register('reason'),
                      {
                        required: true,
                        onChange: (e) => {
                          setReason(e.target.value);
                        },
                      })}
                    />
                  </div>
                  <div className="text-16  mb-5 mt-3  d-flex align-items-center justify-content-center ">
                    <button className="btn-success rounded  ">request code</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row mt-3 px-4"></div>
        </div>
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};
export default Ambassador;
