export enum ROUTEPATHS {
  HOME = '/',
  SIGNUP = '/signup',
  CHOOSEUSERNAME= 'choose-username',
  COMMUNITY = 'community',
  SIGNIN = '/login',
  TEST = '/test',
  TEST2 = '/test2',
  TEST3 = '/test3',
  CONTACT_US = '/contactUs',

  CONTEST = 'contest',
  CONTEST_BRIEF = 'brief',
  CONTEST_INSPIRATIONS = 'inspirations',
  CREATE_INSPIRATION = 'inspirations/new',
  CONTEST_AWARDS = 'awards',
  CONTEST_GALLERY = 'gallery',
  CONTEST_UPLOAD = 'upload-contest',

  DESIGN_DETAILS = '/design-details',

  INFO_COMPANIES = 'companies',
  INFO_CREATIVES = 'creatives',
  INFO_CREATIVES_ADDITIONAL = 'creatives-additional-info',
  INFO_CREATIVES_DREAMTEAM = 'creatives-dream-team',
  INFO_CREATIVES_ABOUT = 'about-us',
  INFO_CREATIVES_PARTNERS = 'partners',

  COMPANIES_MARKET = 'market',
  COMPANIES_CASE_STUDIES = 'case-studies',

  COMMUNITY_PROFILE = 'profile',
  COMMUNITY_PROFILE_PREVIEW = 'profile-preview',
  COMMUNITY_PROFILE_EDITABLE = 'profile-editable',
  COMMUNITY_PORTFOLIO = 'portfolio',
  COMMUNITY_DESIGN = 'design',
  COMMUNITY_SEARCH = '/community-search',

  CLIENT_CONTESTS = 'client-contests',

  DESIGNER_INSPIRATIONS = 'designer-inspirations',
  DESIGNER_CONTEST_PORTFOLIO = 'contest-portfolio',
  USER_FAVORITES = 'user-favorites',
  USER_AMBASSADOR = 'ambassador',
  USER_AWARDS = 'user-awards',
  USER_BADGE = 'user-badge',

  LIBRARY = 'library',
  TYPOGRAPHY = 'typography',
  IMAGES = 'images',
  BUTTONS = 'buttons',
  CARDS = 'cards',
  FORMS = 'forms',
  COMPONENTS = 'components',
}
