import React, { useEffect, useState } from 'react';
import { useStoreState } from 'pullstate';
//Header Data
import headerLogo from '../assets/desall_logo.png';
import { useNavigate} from 'react-router-dom';
//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
//Assets
import factory from '../assets/factory_placeholder.png';
//Components
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import PageNavigation from '../components/PageNavigation/PageNavigation';
import QueryComponent from '../components/Query/QueryComponent';
import DslStore, { setFooterMenuSelected, setStrapiPage } from '../store/DslStore';
import { getFooterMenu, getFooterMenuSelected, getHeaderMenu, getHiddenMenu } from '../store/Selectors';
import { FooterMenuData, StrapiPageData } from '../global';
import axiosHelper from '../helpers/axiosHelper';

const GenericStrapi: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const hiddenMenu = useStoreState(DslStore, getHiddenMenu);
  const navigation = useStoreState(DslStore, getFooterMenuSelected);
  const [page, setPage] = useState<StrapiPageData>();
  const [breadcrumbs, setBreadcrumbs] = useState('');
  const [pagePath, setPagePath] = useState('');
  const path = window.location.pathname;
  const redirect = useNavigate();

  useEffect(() => {
    console.log(path);
    let firstLevel = '/' + path.split('/')[1];
    let secondLevel = '/' + path.split('/')[2];
    let thirdLevel = '/' + path.split('/')[3];
    let fourthLevel = '/' + path.split('/')[4];

    console.log(firstLevel);
    console.log(secondLevel);
    console.log(thirdLevel);
    console.log(fourthLevel);
    if (fourthLevel !== '/undefined') {
      setPagePath(fourthLevel);
    } else if (thirdLevel !== '/undefined') {
      setPagePath(fourthLevel);
    } else if (secondLevel !== '/undefined') {
      setPagePath(secondLevel);
    } else {
      setPagePath(firstLevel);
    }
  }, [path]);

  useEffect(() => {
    // console.log(path);
    let pathLength = path.split('/').length - 1;
    let first = path.split('/')[1].replace(/_|-/gi, ' ');

    if (pathLength === 2) {
      setBreadcrumbs(first);
      let ciccio: FooterMenuData[] = footerMenu.filter((menu) => menu.title === first);
      if (ciccio.length === 0) {
        ciccio = hiddenMenu.filter((menu) => menu.path === '/' + first.replace(/ /g, '_'));
        // console.log('ciccio hidden' + JSON.stringify(ciccio));
        setFooterMenuSelected(ciccio[0]);
      } else {
        setFooterMenuSelected(ciccio[0]);
      }
    } else if (pathLength === 3) {
      let second = path.split('/')[2].replace(/_|-/gi, ' ');
      console.log(second);
      let ciccio: FooterMenuData[] = footerMenu.filter((menu) => menu.title === second);
      if (ciccio.length === 0) {
        ciccio = hiddenMenu.filter((menu) => menu.path === '/' + second.replace(/ /g, '_'));
        // console.log('ciccio hidden' + JSON.stringify(ciccio));
        setFooterMenuSelected(ciccio[0]);
      } else {
        setFooterMenuSelected(ciccio[0]);
      }
      setBreadcrumbs(first + '/' + second);
    } else if (pathLength === 4) {
      let second = path.split('/')[2].replace(/_|-/gi, ' ');
      let third = path.split('/')[3].replace(/_|-/gi, ' ');
      console.log(third);
      let ciccio: FooterMenuData[] = footerMenu.filter((menu) => menu.title === third);
      if (ciccio.length === 0) {
        ciccio = hiddenMenu.filter((menu) => menu.path === '/' + third.replace(/ /g, '_'));
        // console.log('ciccio hidden' + JSON.stringify(ciccio));
        setFooterMenuSelected(ciccio[0]);
      } else {
        setFooterMenuSelected(ciccio[0]);
      }
      setBreadcrumbs(first + '/' + second + '/' + third);
    }
  }, [path]);

  // useEffect(() => {
  //   // console.log(footerMenu);
  //   // console.log(headerMenu);
  //   // console.log(navigation);
  //   // console.log(hiddenMenu);
  //   if (breadcrumbs) {
  //     // console.log(breadcrumbs);

  //     hiddenMenu.map((item: any, index: number) => {
  //       // console.log('hidden');
  //       // console.log(breadcrumbs.replace(/ /g, '_'));
  //       // console.log(item.path === '/' + breadcrumbs.replace(/ /g, '_'));
  //     });
  //     let ciccio: FooterMenuData[] = footerMenu.filter((menu) => menu.title === breadcrumbs);
  //     console.log(ciccio);
  //     console.log('ciccio length: ' + ciccio.length);
  //     console.log(breadcrumbs);
  //     if (ciccio.length === 0) {
  //       ciccio = hiddenMenu.filter((menu) => menu.path === '/' + breadcrumbs.replace(/ /g, '_'));
  //       // console.log('ciccio hidden' + JSON.stringify(ciccio));
  //       setFooterMenuSelected(ciccio[0]);
  //     } else {
  //       setFooterMenuSelected(ciccio[0]);
  //     }
  //     // console.log(ciccio[0].data);
  //   }
  // });

  const fetchPage = async () => {
    axiosHelper.fetchStrapiPage(pagePath)?.then((response) => {
      // console.log(response.data);
      if (response.data.length > 0) {
        // console.log(response.data[0])
        setPage(response.data[0]);
        setStrapiPage(response.data[0]);
      } else {
        redirect('/PageNotFound');
      }
    });
  };

  useEffect(() => {
    // console.log(title);
    // console.log(pagePath);
    fetchPage();
  }, [pagePath]);

  return (
    <>
      <AppHeader
        links={headerMenu}
        bgColor={page?.attributes.headercolor ? page?.attributes.headercolor : '#7997c5'}
        logoImg={headerLogo}
        
        pageTitle={page?.attributes.footertitle}
        breadcrumbs={breadcrumbs}
      />

      {navigation&& navigation.data.length > 1  && <PageNavigation bgColor="#E3E3E3" links={navigation.data} />}

      <QueryComponent page={page} />

      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default GenericStrapi;
