import React from 'react';

import {
  bgCardComponent,
  BigCardSection,
  BigImgProps,
  CaseStudiesHalfImgProps,
  CaseStudiesSmallRowProps,
  CreativesCenteredProps,
  FourElementSectionProps,
  FreeHtmlSectionProps,
  GifSectionProps,
  HorizontalComponentProps,
  ImgProps,
  RowProps,
  SlicedComponentProps,
  SmallCardSection,
  SmallHalfImgProps,
  StrapiBlock,
  StrapiPageData,
  TwoElementsComponent,
} from '../../global';

import FourElementComponent from '../../components/Strapi/FourElementSection/FourElementSection';
import ThreeElementComponent from '../../components/Strapi/ThreeElementSection/ThreeElementComponent';
import TwoElementComponent from '../../components/Strapi/TwoElementSection/TwoElementComponent';
import SplittedComponent from '../../components/Strapi/SplittedSection/SplittedComponent';
import BigCardSectionComponent from '../../components/Strapi/BigCardSection/BigCardSection';
import SmallCardSectionComponent from '../../components/Strapi/SmallCardSection/SmallCardSection';
import HorizontalSectionComponent from '../../components/Strapi/HorizontalSection/HorizontalSection';
import BigImgComponent from '../../components/Strapi/BigImgSection/BigImgComponent';
import RowComponent from '../../components/Strapi/RowSection/RowComponent';
import ImgComponent from '../../components/Strapi/DreamTeamComponents/ImgSection/ImgComponent';
import GifSectionComponent from '../Strapi/HomeComponents/GifSection/GifSection';
import TwoElementsSection from '../Strapi/TwoElementsSection/TwoElementsSection';
import SlicedSection from '../Strapi/SlicedSection/SlicedSection';
import CaseStudiesRowComponent from '../Strapi/CaseStudiesComponents/CaseStudiesRowSection/CaseStudiesRowComponent';
import OnTheMarketCardComponent from '../Strapi/OnTheMarketComponents/OnTheMarketCadSection/OnTheMarketCardComponent';
import CaseStudiesBigHalfImgComponent from '../Strapi/CaseStudiesComponents/CaseStudiesBigHalfImgSection/CaseStudiesBigHalfImgComponent';
import CaseStudiesSmallRowComponent from '../Strapi/CaseStudiesComponents/CaseStudiesSmallRowSection/CaseStudiesSmallRowComponent';
import CreativesCenteredComponent from '../Strapi/CreativesComponents/CreativesCentredSection/CreativesCentredSection';
import SmallHalfImgComponent from '../Strapi/AdditionalInfoComponents/SmallHalfImgSection/SmallHalfImgComponent';
import SmallCardSliderComponent from '../Strapi/SmallCardSlider/SmallCardSliderComponent';
import { useStoreState } from 'pullstate';
import DslStore from '../../store/DslStore';
import { getStrapiPage } from '../../store/Selectors';
import FreeHtmlSection from '../Strapi/FreeHtmlComponent/FreeHtmlSection';
import SmallBackgroundImage from '../Strapi/SmallBackgroundImage/SmallBackgroundImage';
import BigImageSquare from '../Strapi/BigImageSquare/BigImageSquare';
import BigImageVertical from '../Strapi/BigImageVertical/BigImageVertical';

interface QueryProps {
  //  headercolor?: (headercolor: string | undefined) => void;
  //  pagetitle?: (title: string | undefined) => void;
  //   pagenav?: (navigation: any[] | undefined) => void;
  //   menu?: (menu: string | undefined) => void;
  //   query?: any;
  //   pageid?: string;
  page?: StrapiPageData;
}

const QueryComponent = (props: QueryProps) => {
  const strapiPage = useStoreState(DslStore, getStrapiPage);

  // useEffect(() => {
  //   // console.log(props.page?.attributes.testblock.length);
  // }, [props.page]);

  // useEffect(() => {
  //   // console.log(strapiPage);
  // }, [strapiPage]);

  // const Components: any = {
  //   ComponentBlockFourElementsSection: FourElementComponent,
  //   ComponentBlockSplittedSection: SplittedSection,
  //   ComponentBlockBigcardsection: BigCardSectionComponent,
  //   ComponentBlockSmallcardsection: SmallCardSectionComponent,
  //   ComponentBlockHorizontalsection: HorizontalSectionComponent,
  //   ComponentBlockBigImgSection: BigImgComponent,
  //   ComponentBlockRowSection: RowComponent,
  //   ComponentBlockImageOnlySection: ImgComponent,
  //   ComponentBlockGif: GifSectionComponent,
  //   ComponentBlockSplitTwoSection: TwoElementsSection,
  //   ComponentBlockSlicedcomponent: SlicedSection,
  //   ComponentBlockComprowsection: CaseStudiesRowComponent,
  //   ComponentBlockOnthemarketcardsection: OnTheMarketCardComponent,
  //   ComponentBlockBighalfimgsection: CaseStudiesBigHalfImgComponent,
  //   ComponentBlockSmallrowsection: CaseStudiesSmallRowComponent,
  //   ComponentBlockCreativerowsection: CreativesCenteredComponent,
  //   ComponentBlockSmallhalfimgsection: SmallHalfImgComponent,
  //   ComponentBlockSmallCardSlider: SmallCardSliderComponent,
  // };

  return (
    <>
      {/* <div className="border d-flex flex-row justify-content-center  align-items-center">
        <div className="border bg-danger d-flex flex-row justify-content-center  align-items-center" style={{ height: '200px', width: '1170px', zIndex:0 }}>
        </div>
      </div> */}
      {strapiPage.attributes.testblock.map((block: StrapiBlock) => {
        // console.log(block);
        if (block.__component === 'block.four-elements-section') {
          // console.log('four-element-section');
          // console.log(block);
          if (block.elementcomponent) {
            let props: FourElementSectionProps = {
              id: block.id,
              title: block.title,
              titlecolor: block.titcolor,
              subtitle: block.subtitle,
              subtitlecolor: block.subcolor,
              text: block.text,
              textcolor: block.coltxt,
              bgcolor: block.bgcolor,
              elementcomponent: block.elementcomponent,
            };
            if (props.elementcomponent.length === 4) {
              return React.createElement(FourElementComponent, props);
            } else if (props.elementcomponent.length === 3) {
              return React.createElement(ThreeElementComponent, props);
            } else if (props.elementcomponent.length === 2) {
              return React.createElement(TwoElementComponent, props);
            }
          }
        }
        if (block.__component === 'block.bigcardsection') {
          // console.log('bigcardsection');
          if (block.bigcardcomponent) {
            let props: BigCardSection = {
              id: block.id,
              title: block.title,
              bgcolor: block.bgcolor,
              titlecolor: block.titcol,
              subtitlecolor: block.subcol,
              subtitle: block.subtitle,
              bigcardcomponent: block.bigcardcomponent,
            };

            return React.createElement(BigCardSectionComponent, props);
          }
        }
        if (block.__component === 'block.smallcardsection') {
          // console.log('smallcardsection');
          if (block.smallcardcomponent) {
            let props: SmallCardSection = {
              id: block.id,
              title: block.title,
              subtitle: block.subtitle,
              bgcolor: block.bgcolor,
              titlecolor: block.titcolr,
              subtitlecolor: block.subcolr,
              smallcardcomponent: block.smallcardcomponent,
            };

            return React.createElement(SmallCardSectionComponent, props);
          }
        }
        if (block.__component === 'block.onthemarketcardsection') {
          // console.log('onthemarketcardsection');
          let props: BigCardSection = {
            id: block.id,
            bgcolor: block.bgcolor,
            bigcardcomponent: block.onthemarketcardcomponent,
          };
          return React.createElement(OnTheMarketCardComponent, props);
        }
        if (block.__component === 'block.horizontalsection') {
          // console.log('horizontalsection');
          console.log(block);
          let props: HorizontalComponentProps = {
            id: block.id,
            title: block.title,
            subtitle: block.subtitle,
            subtitlecolor: block.subtitlecol,
            textcolor: block.colortext,
            boldtextcolor: block.boldtextcolor,
            bigtexcolor: block.bigtextcolor,
            boldtext: block.boldtext,
            titlecolor: block.titleclr,
            bigtext: block.bigtext,
            buttontext: block.buttontext,
            buttoncolor: block.buttoncolor,
            text: block.text,
            link: block.link,
            bgcolor: block.bgcolor,
            target: block.target,
            textalign: block.textalign,
            boldtextalign: block.boldtextalign,
            subtitlealign: block.subtitlealign,
            titlealign: block.titlealign,
            bigtextalign: block.bigtextalign,
          };
          return React.createElement(HorizontalSectionComponent, props);
        }
        if (block.__component === 'block.creativerowsection') {
          // console.log('creativerowsection');
          if (block.image) {
            let props: CreativesCenteredProps = {
              id: block.id,
              bgcolor: block.bgcolor,
              title: block.title,
              titlecolor: block.colTitle,
              boldtext: block.boldtext,
              boldtextcolor: block.boldtextColor,
              text: block.text,
              textcolor: block.txtcol,
              buttontext: block.buttontext,
              buttoncolor: block.btncolorr,
              image: block.image,
              link: block.link,
              target: block.target,
              titlealign: block.titlealign,
              textalign: block.textalign,
              boldtextalign: block.boldtextalign,
            };
            return React.createElement(CreativesCenteredComponent, props);
          }
        }
        if (block.__component === 'block.row-section') {
          // console.log('row-section');
          if (block.image) {
            let props: RowProps = {
              id: block.id,
              title: block.title,
              boldtext: block.boldtext,
              image: block.image,
              text: block.text,
              link: block.link,
              bgcolor: block.bgcolor,
              titlecolor: block.colortitle,
              boldtextcolor: block.boldtxtcolor,
              textcolor: block.colortxt,
              target: block.target,
              boldtextalign: block.boldtextalign,
              titlealign: block.titlealign,
              textalign: block.textalign,
            };
            return React.createElement(RowComponent, props);
          }
        }
        if (block.__component === 'block.smallrowsection') {
          // console.log('smallrowsection');
          let props: CaseStudiesSmallRowProps = {
            id: block.id,
            bgcolor: block.bgcolor,
            title: block.title,
            titlecol: block.titlecol,
            text: block.text,
            textcolor: block.textcol,
            image1: block.image1,
            image2: block.image2,
            titlealign: block.titlealign,
            textalign: block.textalign,
          };
          return React.createElement(CaseStudiesSmallRowComponent, props);
        }
        if (block.__component === 'block.comprowsection') {
          // console.log('comprowsection');
          if (block.image && block.imagepos) {
            let props: bgCardComponent = {
              id: block.id,
              bgcolor: block.bgcolor,
              title: block.title,
              titlecolor: block.titleClr,
              text: block.text,
              textcolor: block.txtcolor,
              buttontext: block.buttontext,
              buttoncolor: block.btncolr,
              image: block.image,
              imagepos: block.imagepos,
              link: block.link,
              target: block.target,
              boldtext: block.boldtext,
              boldtextcolor: block.boldtextcolor,
              boldtextalign: block.boldtextalign,
              titlealign: block.titlealign,
              textalign: block.textalign,
            };
            return React.createElement(CaseStudiesRowComponent, props);
          }
        }
        if (block.__component === 'block.big-img-section') {
          // console.log('big-img-section');
          if (block.image && block.imagefloat) {
            let props: BigImgProps = {
              id: block.id,
              title: block.title,
              titlecolor: block.colorTitle,
              boldtext: block.boldtext,
              boldtextcolor: block.boldtxtcol,
              textcolor: block.coltext,
              buttontext: block.buttontext,
              btncolor: block.btncolor,
              image: block.image,
              text: block.text,
              link: block.link,
              bgcolor: block.bgcolor,
              imagefloat: block.imagefloat,
              textalign: block.textalign,
              titlealign: block.titlealign,
              boldtextalign: block.boldtextalign,
            };
            return React.createElement(BigImgComponent, props);
          }
        }
        if (block.__component === 'block.splitted-section') {
          // console.log('splitted-section');
          if (block.leftside && block.rightside) {
            let props: TwoElementsComponent = {
              id: block.id,
              leftside: block.leftside,
              rightside: block.rightside,
            };

            return React.createElement(SplittedComponent, props);
          }
        }
        if (block.__component === 'block.split-two-section') {
          // console.log('split-two-section');
          if (block.leftside && block.rightside) {
            let props: TwoElementsComponent = {
              id: block.id,
              leftside: block.leftside,
              rightside: block.rightside,
            };
            return React.createElement(TwoElementsSection, props);
          }
        }
        if (block.__component === 'block.slicedcomponent') {
          // console.log('slicedcomponent');
          if (block.image) {
            let props: SlicedComponentProps = {
              id: block.id,
              bgcolor: block.bgcolor,
              title: block.title,
              titlecolor: block.titColor,
              text: block.text,
              textcolor: block.textcolor,
              buttontext: block.buttontext,
              buttoncolor: block.butncolor,
              image: block.image,
              link: block.link,
              target: block.target,
              titlealign: block.titlealign,
              textalign: block.textalign,
            };
            return React.createElement(SlicedSection, props);
          }
        }
        if (block.__component === 'block.smallhalfimgsection') {
          // console.log('smallhalfimgsection');
          if (block.image) {
            let props: SmallHalfImgProps = {
              id: block.id,
              color: block.bgcolor,
              title: block.title,
              titlecolor: block.colrtitle,
              text: block.text,
              imagefloat: block.imageFloat,
              textcolor: block.textCol,
              image: block.image,
              buttontext: block.buttontext,
              buttoncolor: block.buttoncolor,
              link: block.link,
              target: block.target,
              titlealign: block.titlealign,
              textalign: block.textalign,
            };

            return React.createElement(SmallHalfImgComponent, props);
          }
        }
        if (block.__component === 'block.bighalfimgsection') {
          // console.log('bighalfimgsection');
          if (block.image) {
            let props: CaseStudiesHalfImgProps = {
              id: block.id,
              background: block.bgcolor,
              title: block.title,
              titlecolor: block.titlecolorr,
              text: block.text,
              textcolor: block.textcolorr,
              buttontext: block.buttontext,
              butncolor: block.butncolorr,
              image: block.image,
              imagepos: block.imgfloat,
              link: block.link,
              target: block.target,
              textalign: block.textalign,
              titlealign: block.titlealign,
            };
            return React.createElement(CaseStudiesBigHalfImgComponent, props);
          }
        }
        if (block.__component === 'block.image-only-section') {
          // console.log('image-only-section');
          if (block.image) {
            let props: ImgProps = {
              id: block.id,
              image: block.image,
              bgcolor: block.bgcolor,
            };
            return React.createElement(ImgComponent, props);
          }
        }
        if (block.__component === 'block.gif') {
          // console.log(block);
          if (block.image) {
            let props: GifSectionProps = {
              id: block.id,
              image: block.image,
              title: block.title,
              subtitle: block.subtitle,
              titlecolor: block.titleCol,
              subtitlecolor: block.colsub,
              buttoncolor: block.buttoncolor,
              buttontext: block.buttontext,
              link: block.link,
              target: block.target,
            };
            return React.createElement(GifSectionComponent, props);
          }
        }
        if (block.__component === 'block.small-card-slider') {
          // console.log('small-card-slider');
          if (block.smallcardslidercomponent) {
            let props: SmallCardSection = {
              id: block.id,
              bgcolor: block.bgcolor,
              title: block.title,
              subtitle: block.subtitle,
              titlecolor: block.coltitle,
              subtitlecolor: block.colsubtitle,
              smallcardcomponent: block.smallcardslidercomponent,
            };
            return React.createElement(SmallCardSliderComponent, props);
          }
        }
        if (block.__component === 'block.free-html-section') {
          // console.log('small-card-slider');
          if (block.html) {
            let props: FreeHtmlSectionProps = {
              id: block.id,
              html: block.html,
              bgcolor: block.bgcolor,
            };
            return React.createElement(FreeHtmlSection, props);
          }
        }
        if (block.__component === 'block.small-background-image') {
          // console.log('small-card-slider');
          if (block.image) {
            let props: GifSectionProps = {
              id: block.id,
              image: block.image,
              title: block.title,
              subtitle: block.subtitle,
              titlecolor: block.titlecolor,
              subtitlecolor: block.subtitlecolor,
              buttoncolor: block.buttoncolor,
              buttontext: block.buttontext,
              link: block.link,
              target: block.target,
            };
            return React.createElement(SmallBackgroundImage, props);
          }
        }
        if (block.__component === 'block.big-image-square') {
          // console.log('image-only-section');
          if (block.image) {
            let props: ImgProps = {
              id: block.id,
              image: block.image,
              bgcolor: block.bgcolor,
            };
            return React.createElement(BigImageSquare, props);
          }
        }
        if (block.__component === 'block.big-image-vertical') {
          // console.log('image-only-section');
          if (block.image) {
            let props: ImgProps = {
              id: block.id,
              image: block.image,
              bgcolor: block.bgcolor,
            };
            return React.createElement(BigImageVertical, props);
          }
        }

        return null;
      })}
    </>
  );
};

export default QueryComponent;
