import React, { useEffect, useContext, useState } from 'react';
import styles from './YourProfile.module.scss';
import { set, useForm } from 'react-hook-form';
import {
  ContestTag,
  Country,
  DSLTag,
  SocialNetworkFinder,
  UpdateProfessionalProfile,
  UpdateUserProfile,
} from '../../../global';
import { DropDownYears } from '../../Forms/DropDown/DropDownBirthYear';

import { startDateString } from '../../../helpers/utils';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import { DropDownCountry } from '../../Forms/DropDown/DropDownCountry';
import axiosHelper from '../../../helpers/axiosHelper';
import DslStore, { setCurrentUser } from '../../../store/DslStore';
import { useStoreState } from 'pullstate';
import { getCurrentUser, getTags } from '../../../store/Selectors';
import { MyUserContext } from '../../../UserContext';

interface YourProfileProps {
  upload: (n: number) => void;
  preview: boolean;
  sendPreview: (send: boolean) => void;
  open: (open: number) => void;
  isOpen: boolean;
}

const YourProfile: React.FC<YourProfileProps> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const tags = useStoreState(DslStore, getTags);
  const [yourProfilePopUp, setYourProfilePopUp] = useState(props.isOpen);
  const { register, handleSubmit, getValues } = useForm<UpdateUserProfile>();
  const [firstName, setFirstName] = useState(currentUser?.firstName);
  const [lastName, setLastName] = useState(currentUser?.lastName);
  const [hasPublicName, setHasPublicName] = useState(currentUser?.hasPublicName);
  const [biography, setBiography] = useState(currentUser?.biography);
  const [years, setYears] = useState<number[]>([]);
  const [yearSelected, setYearSelected] = useState<number>();
  const [hasPublicBirthDate, setHasPublicBirthDate] = useState(currentUser?.hasPublicBirthDate);
  const [birthDate, setBirthDate] = useState<any>();
  const [userBirthDate, setUserBirthDate] = useState(currentUser?.birthDate);
  const [gender, setGender] = useState(currentUser?.gender);
  const [mobileNumber, setMobileNumber] = useState(currentUser?.mobileNumber);
  const [phoneNumber, setPhoneNumber] = useState(currentUser?.phoneNumber);
  const [addressLine1, setAddressLine1] = useState(currentUser?.addressLine1);
  const [city, setCity] = useState(currentUser?.city);
  const [hasPublicCity, setHasPublicCity] = useState(currentUser?.hasPublicCity ? currentUser.hasPublicCity : false);
  const [state, setState] = useState(currentUser?.state);
  const [zipCode, setZipCode] = useState(currentUser?.zipCode);
  const [countries, setCountries] = useState<Country[]>([]);
  const [country, setCountry] = useState(currentUser?.country);
  const [hasPublicCountry, setHasPublicCountry] = useState(currentUser?.hasPublicCountry);
  const [contactLanguage, setContactLanguage] = useState(currentUser?.contactLanguage);
  const [tagString, setTagString] = useState('');
  const [suggestionArray, setSuggestionArray] = useState<DSLTag[]>([]);
  const [socialNetworkFinder, setSocialNetworkFinder] = useState<SocialNetworkFinder[]>([]);
  const [tagsArray, setTagsArray] = useState<string[]>([]);
  const [getPreview, setGetPreview] = useState(false);
  const [dataRequested, setDataRequested] = useState(false);
  const [clientDescr, setClientDescr] = useState(currentUser?.description);
  const [key, setKey] = useState(1);
  const {
    setContextfirstname,
    setContextlastname,
    setContexthaspublicname,
    setContextbio,
    setContextcity,
    setContexthaspubliccity,
    setContextcountry,
    setContexthaspubliccountry,
    setContextgender,
    setContextbirthdate,
    setContexthaspublicbirthdate,
    setContexttags,
    setContextaddressline1,
    setContextcontactlanguage,
    setContextphonenumber,
    setContextzipcode,
    setContextcompanydescription,
    Contextjob,
    Contextwebsite,
    Contextjobname,
    Contextcompany,
    Contextschool1,
    Contextschool2,
    Contextfreelancelicense,
    Contextsector,
    Contextcompanyname,
    Contexthaspublicfirstschool,
    Contexthaspublicsecondschool,
  } = useContext(MyUserContext);

  const formatInputValue = () => {
    if (!birthDate) return '';
    return `${birthDate.day}/${birthDate.month}/${birthDate.year}`;
  };

  function containsOnlyNumbers(str: string) {
    if (str !== '') {
      return /^\d+$/.test(str);
    }
    return true;
  }

  const getCountries = async () => {
    let result: Country[] = await axiosHelper.getCountries();
    if (result) {
      // console.log(result);
      setCountries(result);
    }
  };

  useEffect(() => {
    setDataRequested(true);
    handleSubmit(onSubmit);
    props.sendPreview(true);
  }, [props.preview]);

  const tagStringCheck = () => {
    let string = tagString;
    let index = string.split(' ').length - 1;
    // console.log(string.split(' ')[index]);
    if (string.split(' ')[index] !== '') {
      return true;
    } else {
      return false;
    }
  };
  const getSocialNetworkFinder = async () => {
    let result: SocialNetworkFinder[] = await axiosHelper.getSocialNetworkFinder();
    if (result) {
      // console.log(result);
      setSocialNetworkFinder(result);
    }
  };
  const initialTagString = () => {
    let tagStr: string = '';
    currentUser?.tags &&
      currentUser.tags.map(
        (item: ContestTag, index: number) =>
          // console.log(item.name),

          // console.log(tagsArr),
          (tagStr = tagStr + item.name + ' ')
        // console.log(tagStr),
      );
    setTagString(tagStr);
  };

  useEffect(() => {
    if (birthDate) {
      console.log(birthDate);
      setContextbirthdate(birthDate.year + '-' + birthDate.month + '-' + birthDate.day);
    }
  }, [birthDate]);

  useEffect(() => {
    initialTagString();
    getSocialNetworkFinder();
    getCountries();
    let date = new Date().getFullYear();
    let numbers: number[] = [];
    for (let i = 0; i < 100; i++) {
      numbers.push(date - 18 - i);
    }
    if (numbers) {
      setYears(numbers);
    }
  }, []);

  const addTag = (tag: string) => {
    // console.log(tag);
    let string = tagString;
    let index = string.split(' ').length - 1;
    // console.log(string.split(' ')[index]);
    let stringWithSuggestion = string.replace(new RegExp(string.split(' ')[index] + '$'), tag + ' ');
    setTagString(stringWithSuggestion);
  };

  const gotoProfilePreview = (data: UpdateUserProfile) => {
    fillData(data);
    // setGetPreview(true);
    setProfessionalProfileDataOnLocalStorage();

    console.log(data);
    window.open(`/community/${currentUser?.nameForURL}/profile-preview`, 'blank');

    // redirect(`/community/${currentUser?.username}/profile-preview`);
  };

  const onSubmit = async (data: UpdateUserProfile) => {
    fillData(data);
    console.log(data);
    if (data && currentUser?.id) {
      // ACTIVE THAT BEFORE COMMIT
      if (currentUser.userType === 'D') {
        await axiosHelper
          .updateUserProfile(data, currentUser.id)
          ?.then((response) => {
            console.log(response);
            // console.log(result);
            props.upload(1);
            window.scrollTo(0, 0);
            setCurrentUser(response);
          })
          .catch((error) => {
            props.upload(2);
          });
      } else {
        await axiosHelper
          .updateClientProfile(data, currentUser.id)
          .then((response) => {
            console.log(response);
            props.upload(1);
            window.scrollTo(0, 0);
            setCurrentUser(response);
          })
          .catch((error) => {
            props.upload(2);
          });
      }
    }
  };

  const fillData = (data: UpdateUserProfile) => {
    data.birthYear = yearSelected;

    data.gender = gender;
    data.countryName = country?.name;
    data.country = country;
    data.contactLanguage = contactLanguage;
    if (birthDate) {
      data.birthDate = `${birthDate.year}-${birthDate.month}-${birthDate.day}T00:00:00Z`;
    }
    // data.birthDate = birthDate;

    if (!data.addressLine1) {
      data.addressLine1 = currentUser?.addressLine1;
    }
    if (!data.firstName) {
      data.firstName = currentUser?.firstName;
    }
    if (!data.lastName) {
      data.lastName = currentUser?.lastName;
    }
    if (!data.city) {
      data.city = currentUser?.city;
    }
    if (!data.mobileNumber) {
      data.mobileNumber = currentUser?.mobileNumber;
    }
    if (!data.biography) {
      data.biography = biography;
    }
    if (!data.phoneNumber) {
      data.phoneNumber = currentUser?.phoneNumber;
    }
    if (!data.state) {
      data.state = currentUser?.state;
    }
    if (!data.zipCode) {
      data.zipCode = currentUser?.zipCode;
    }
    if (!data.birthDate) {
      data.birthDate = currentUser?.birthDate;
    }
    data.hasPublicCity = hasPublicCity;
    data.hasPublicName = hasPublicName;
    data.hasPublicBirthDate = hasPublicBirthDate;
    data.hasPublicCountry = hasPublicCountry;

    localStorage.setItem('editYourProfileData', JSON.stringify(data));
  };

  const setProfessionalProfileDataOnLocalStorage = () => {
    console.log('jobname : ' + Contextjobname);
    console.log('job' + JSON.stringify(Contextjob));
    console.log('website: ' + Contextwebsite);
    const data: UpdateProfessionalProfile = {
      website: Contextwebsite !== currentUser?.website ? Contextwebsite : currentUser.website,
      job: Contextjob !== undefined && Contextjob !== currentUser?.job ? Contextjob : currentUser?.job,
      jobTitle:
        Contextjobname !== '' && Contextjobname !== currentUser?.jobName ? Contextjobname : currentUser?.jobName,
      company: Contextcompany !== '' && Contextcompany !== currentUser?.company ? Contextcompany : currentUser?.company,
      freelanceLicense:
        Contextfreelancelicense !== currentUser?.freelanceLicense
          ? Contextfreelancelicense
          : currentUser.freelanceLicense,
      school1: Contextschool1 !== '' && Contextschool1 !== currentUser?.school1 ? Contextschool1 : currentUser?.school1,
      school2: Contextschool2 !== '' && Contextschool2 !== currentUser?.school2 ? Contextschool2 : currentUser?.school2,
      sector:
        Contextsector !== ''
          ? Contextsector !== currentUser?.sector
            ? Contextsector
            : currentUser?.sector
          : Contextsector,
      companyName:
        Contextcompanyname !== ''
          ? Contextcompanyname != currentUser?.companyName
            ? Contextcompanyname
            : currentUser.companyName
          : Contextcompanyname,
      hasPublicFirstSchool: Contexthaspublicfirstschool,
      hasPublicSecondSchool: Contexthaspublicsecondschool,
    };
    localStorage.setItem('editProfessionalProfileData', JSON.stringify(data));
  };

  const suggestedTagsString = () => {
    let tagsArr: string[] = [];
    // console.log(e);
    tagsArr = tagString.split(' ');

    setTagsArray(tagsArr);
    let suggestions: DSLTag[] = [];
    tags.map((item: DSLTag, index: number) => {
      if (item.name.includes(tagsArr[tagsArr.length - 1])) {
        suggestions.push(item);
      }
    });
    setSuggestionArray(suggestions);
  };

  useEffect(() => {
    suggestedTagsString();
  }, [tagString]);

  useEffect(() => {
    // if (notificationsPopUp) {
    //   props.open(true);
    // } else {
    //   setNotificationsPopUp(false);
    //   props.open(false);
    // }
    setYourProfilePopUp(props.isOpen);
  }, [props.isOpen]);

  return (
    <>
      <div
        className="col-12  bg-blue mb-5 align-items-center justify-content-center  d-flex"
        style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
        onClick={() => (setYourProfilePopUp(!yourProfilePopUp), props.open(yourProfilePopUp ? 0 : 1), setKey(key + 1))}
      >
        <div className="col-2"></div>
        <div className="col-8  justify-content-center d-flex ">
          <span className="text-xxl fw-bold color-light" style={{ fontSize: '2rem' }}>
            Your profile
          </span>
        </div>

        <div
          className={`col-2 d-flex  justify-content-end align-items-center ${styles.toggle} ${
            yourProfilePopUp && styles.open
          }`}
          onClick={() => setYourProfilePopUp(!yourProfilePopUp)}
        >
          <div className={`${styles.arrow}  `}> </div>
        </div>
      </div>
      {yourProfilePopUp && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-5 mt-7">
            <div className="row mb-7 mt-4">
              <div className="col-12 col-md-2 text fw-bold"></div>
              <div className="col-12 col-md-10">
                <span className="text color-blue">
                  <i>
                    <b>
                      <span className="color-red">*</span> &nbsp; Mandatory fields (required to{' '}
                      {currentUser?.userType === 'D' ? 'upload your design' : 'launch a contest'})
                    </b>
                  </i>
                </span>
              </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold">Username</div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  style={{ border: '0px ' }}
                  id="username"
                  disabled={true}
                  // placeholder={currentUser?.username}
                  value={currentUser?.username}
                />
              </div>
            </div>
            <div className="row mb-5 mt-4">
              <div className="col-12 col-md-2 text fw-bold">Email</div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  style={{ border: '0px ' }}
                  id="username"
                  disabled={true}
                  // placeholder={currentUser?.email}
                  value={currentUser?.email}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                First name <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={firstName}
                  {...register('firstName', {
                    onChange: (e) => (
                      console.log(e.target.value), setFirstName(e.target.value), setContextfirstname(e.target.value)
                    ),
                  })}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Last name <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={lastName}
                  {...register('lastName', {
                    onChange: (e) => (setLastName(e.target.value), setContextlastname(e.target.value)),
                  })}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={hasPublicName}
                  onClick={() => (setHasPublicName(!hasPublicName), setContexthaspublicname(!hasPublicName))}
                  className="flex-shrink-none"
                  {...register('hasPublicName')}
                />
                <span className="align-self-middle ps-3 ">Public (your full name visible in your profile page)</span>
              </div>
            </div>
            {currentUser?.userType === 'D' && (
              <>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Biography</div>
                  <div className="col-12 col-md-10">
                    <textarea
                      placeholder={biography ? biography : 'Please write here a short bio'}
                      value={biography ? biography : ''}
                      rows={7}
                      className="d-flex flex-col justify-content-center align-items-center "
                      {...register('biography', {
                        onChange: (e) => (setBiography(e.target.value), setContextbio(e.target.value)),
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Birth year</div>
                  <div className="col-12 col-md-4">
                    <DropDownYears options={years} onSelect={(e: number) => setYearSelected(e)} />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Birth date</div>
                  <div className="col-12 col-md-4">
                    <div>
                      <DatePicker
                        value={birthDate}
                        onChange={(e) => setBirthDate(e)}
                        formatInputText={formatInputValue}
                        inputPlaceholder={userBirthDate ? startDateString(userBirthDate) : 'Select your birthDate'}
                        wrapperClassName={`col-12 `}
                        inputClassName={`${styles.birthDateInput}`}
                        colorPrimary="#7997c5"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2"></div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                    <input
                      checked={hasPublicBirthDate}
                      type="checkbox"
                      onClick={() => (
                        setHasPublicBirthDate(!hasPublicBirthDate), setContexthaspublicbirthdate(!hasPublicBirthDate)
                      )}
                      className="flex-shrink-none"
                      {...register('hasPublicBirthDate')}
                    />
                    <span className="align-self-middle ps-3 ">Public (birth date visible in your profile page)</span>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Gender</div>
                  <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                    <div>
                      <input
                        value={'N'}
                        type="radio"
                        onChange={(e) => (setGender(e.target.value), setContextgender(e.target.value))}
                        checked={gender === 'N' ? true : false}
                      />
                      <label className="align-self-middle fw-normal ">N/A</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value={'M'}
                        onChange={(e) => (setGender(e.target.value), setContextgender(e.target.value))}
                        checked={gender === 'M' ? true : false}
                      />
                      <label className="align-self-middle fw-normal ">Male</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value={'F'}
                        onChange={(e) => (setGender(e.target.value), setContextgender(e.target.value))}
                        checked={gender === 'F' ? true : false}
                      />
                      <label className="align-self-middle fw-normal ">Female</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value={'O'}
                        onChange={(e) => (setGender(e.target.value), setContextgender(e.target.value))}
                        checked={gender === 'O' ? true : false}
                      />
                      <label className="align-self-middle fw-normal ">Other</label>
                    </div>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">
                    Mobile
                    <br /> number <span className="color-red">*</span>
                  </div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      value={mobileNumber}
                      {...register('mobileNumber', {
                        onChange: (e) => (containsOnlyNumbers(e.target.value) ? setMobileNumber(e.target.value) : null),
                      })}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-2 text fw-bold">Phone number</div>
                  <div className="col-12 col-md-10">
                    <input
                      type="text"
                      value={phoneNumber}
                      {...register('phoneNumber', {
                        required: false,
                        onChange: (e) =>
                          containsOnlyNumbers(e.target.value)
                            ? (setPhoneNumber(e.target.value), setContextphonenumber(e.target.value))
                            : null,
                      })}
                    />
                  </div>
                </div>
              </>
            )}
            {currentUser?.userType === 'C' && (
              <div className="row mb-5">
                <div className="col-12 col-md-2 text fw-bold">
                  Description <span className="color-red">*</span>
                </div>
                <div className="col-12 col-md-10">
                  <textarea
                    placeholder={clientDescr ? clientDescr : 'Please write here a company description'}
                    value={clientDescr ? clientDescr : ''}
                    rows={7}
                    {...register('description', {
                      required: true,
                      onChange: (e) => (setClientDescr(e.target.value), setContextcompanydescription(e.target.value)),
                    })}
                  />
                </div>
              </div>
            )}

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Address <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={addressLine1}
                  {...register('addressLine1', {
                    required: currentUser?.addressLine1 ? false : true,
                    onChange: (e) => (setAddressLine1(e.target.value), setContextaddressline1(e.target.value)),
                  })}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                City <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={city}
                  {...register('city', {
                    required: true,
                    onChange: (e) => (setCity(e.target.value), setContextcity(e.taget.value)),
                  })}
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={hasPublicCity}
                  onClick={() => (setHasPublicCity(!hasPublicCity), setContexthaspubliccity(!hasPublicCity))}
                  className="flex-shrink-none"
                />

                <span className="align-self-middle ps-3 ">Public (city visible in your profile page)</span>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                State <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={state}
                  {...register('state', { required: true, onChange: (e) => setState(e.target.value) })}
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Zip Code <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={zipCode}
                  {...register('zipCode', {
                    required: true,
                    onChange: (e) => (setZipCode(e.target.value), setContextzipcode(e.target.value)),
                  })}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">
                Your current country <span className="color-red">*</span>
              </div>
              <div className="col-12 col-md-4 d-flex flex-row justify-content-start align-items-center ">
                <DropDownCountry options={countries} onSelect={(e: Country) => (setCountry(e), setContextcountry(e))} />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-10 d-flex flex-row justify-content-start align-items-center ">
                <input
                  type="checkbox"
                  checked={hasPublicCountry}
                  onClick={() => (
                    setHasPublicCountry(!hasPublicCountry), setContexthaspubliccountry(!hasPublicCountry)
                  )}
                  className="flex-shrink-none"
                  {...register('hasPublicCountry')}
                />

                <span className="align-self-middle ps-3 ">Public (country visible in your profile page)</span>
              </div>
            </div>
            {currentUser?.userType === 'D' && (
              <div className="row mb-5">
                <div className="col-12 col-md-2 text fw-bold">Contact language</div>
                <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                  <div>
                    <input
                      type="radio"
                      value={'N'}
                      onChange={(e) => (setContactLanguage(e.target.value), setContextcontactlanguage(e.target.value))}
                      checked={contactLanguage === 'N' ? true : false}
                    />
                    <label className="align-self-middle fw-normal ">N/A</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value={'E'}
                      onChange={(e) => (setContactLanguage(e.target.value), setContextcontactlanguage(e.target.value))}
                      checked={contactLanguage === 'E' ? true : false}
                    />
                    <label className="align-self-middle fw-normal ">English</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value={'I'}
                      onChange={(e) => (setContactLanguage(e.target.value), setContextcontactlanguage(e.target.value))}
                      checked={contactLanguage === 'I' ? true : false}
                    />
                    <label className="align-self-middle fw-normal ">Italian</label>
                  </div>
                </div>
              </div>
            )}
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold">Tags</div>
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  value={tagString}
                  {...register('tempTags', {
                    required: false,
                    onChange: (e) => (setTagString(e.target.value), setContexttags(e.target.value)),
                  })}
                />
                {suggestionArray.length > 0 && tagStringCheck() && (
                  <div className={`${styles.suggestionWrapper}`}>
                    {suggestionArray.map((item: DSLTag, index: number) => (
                      <div className={`text ${styles.suggestionRow}`} key={index} onClick={() => addTag(item.name)}>
                        {item.name}
                      </div>
                    ))}
                  </div>
                )}
                <span className=" ms-2 color-dark_grey_40">please separate each tag with spaces</span>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 col-md-2 text fw-bold"></div>
              <div className="col-12 col-md-10">
                {tagsArray.map(
                  (item: string, index: number) =>
                    item !== '' && (
                      <button disabled className="btn-tag mb-4 " key={index}>
                        <span>{item}</span>
                      </button>
                    )
                )}
              </div>
            </div>

            <div className="row  d-flex justify-content-center align-items-center mb-7 mt-7">
              <div
                className={`btn-dark_blue col-md-2 mb-sm-3 mb-md-0 rounded mx-5 ${styles.button} `}
                onClick={handleSubmit(gotoProfilePreview)}
              >
                preview
              </div>
              <button className={`btn-success rounded col-md-2 mx-5 ${styles.button}  `}>update</button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default YourProfile;
