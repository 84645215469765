import React, { useEffect, useRef, useState } from 'react';
import styles from './DesignComment.module.scss';
import userImg from '../../../assets/Profile.png';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentDesign, Design, DesignDetails, DesignUpload } from '../../../global';

import { faCommentDots, faFlag, faXmark } from '@fortawesome/free-solid-svg-icons';

import { designCommentHourString, designUploadDateString, fullPathLogo } from '../../../helpers/utils';
import { useStoreState } from 'pullstate';
import DslStore, { setCurrentDesign } from '../../../store/DslStore';
import { getCurrentDesign, getCurrentUser, getUserLogged } from '../../../store/Selectors';
import { useForm } from 'react-hook-form';
import axiosHelper from '../../../helpers/axiosHelper';
import userImagePlaceholder from '../../../assets/Profile.png';
import cacheHelper from '../../../helpers/cacheHelper';
import { response } from 'express';

interface IDesignComments {
  comments?: CommentDesign[];
}

interface IDesignComment {
  comment?: CommentDesign;
  update?: () => void;
  flag: (flagged: number) => void;
  delete: (flagged: number) => void;
  userImage?: string;
}

export const DesignCommentMaker: React.FC<IDesignComments> = (props) => {
  const userLogged = useStoreState(DslStore, getCurrentUser);
  const currentdesign = useStoreState(DslStore, getCurrentDesign);
  const [userLogo, setUserLogo] = useState('');

  useEffect(() => {
    if (userLogged?.logoAttachment != null) {
      setUserLogo(fullPathLogo(userLogged.logoAttachment.s3Path));
    } else {
      setUserLogo(userImagePlaceholder);
    }
  }, []);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<CommentUpload>();

  const onSubmit = (data: any) => {
    console.log(data.text);
    if (data.text) {
      console.log('ao');
      sendComment(data.text);
    }
  };

  const sendComment = async (text: string) => {
    console.log('send');
    console.log(currentdesign?.id);
    await axiosHelper.postComment(text, currentdesign?.id)?.then((response) => {
      console.log(response);
      setCurrentDesign(response);
      // props.update && props.update();
      reset(
        {
          text: '',
        },
        { keepErrors: true, keepDirty: true }
      );
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-2">
          <div className="col-2 col-md-1 fw-bold  text-center text-md-start ">
            <img alt="" className={`${styles.smallerImg}`} src={userLogo} />
          </div>

          <div className="col-10   col-md-11 ps-5   ">
            <textarea
              className="mb-5 align-items-center"
              rows={10}
              cols={10}
              {...register('text', { required: true })}
            />
          </div>
        </div>
        <div className="row mb-5 ">
          <div className="col-2  col-md-1 "></div>
          <div className="col-7  col-md-8 me-md-5  row ">
            <div className="col-9  col-md-4 ps-5  ">
              <button className="btn-dark-blue-materials submit full mb-4 rounded">
                <div>POST</div>
              </button>
            </div>
            <div className="col-12 col-md-8   mt-2  ">
              {!userLogged && <span className="ps-4">(you must be logged in to comment)</span>}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export interface CommentUpload {
  text: string;
}

export const ReplyCommentMaker: React.FC<IDesignComment> = (props) => {
  const userLogged = useStoreState(DslStore, getCurrentUser);
  const design = useStoreState(DslStore, getCurrentDesign);
  const [userLogo, setUserLogo] = useState('');

  useEffect(() => {
    if (userLogged?.logoAttachment != null) {
      setUserLogo(fullPathLogo(userLogged.logoAttachment.s3Path));
    } else {
      setUserLogo(userImagePlaceholder);
    }
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<CommentUpload>();

  const onSubmit = (data: any) => sendReply(data.text);

  const sendReply = async (text: string) => {
    if (props.comment) {
      await axiosHelper.replyComment(text, props.comment.id, design?.id)?.then((response) => {
        // console.log(response);
        setCurrentDesign(response);
        props.update && props.update();
        reset(
          {
            text: '',
          },
          { keepErrors: true, keepDirty: true }
        );
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-2">
          <div className="col-2 col-md-1 fw-bold  text-center text-md-start ">
            <img alt="" className={`${styles.smallerImg}`} src={userLogo} />
          </div>

          <div className="col-10   col-md-9 ps-5   ">
            <textarea
              className="mb-5 align-items-center"
              rows={10}
              cols={10}
              {...register('text', { required: true })}
            />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-1 "></div>
          <div className="col-12 col-md-8 me-md-5  row ">
            <div className="col-12 col-md-4 ps-5  ">
              <button className="btn-dark-blue-materials submit full mb-4 rounded">
                <div>REPLY</div>
              </button>
            </div>
            <div className="col-12 col-md-8   mt-2  ">
              {!userLogged && <span className="ps-4">(you must be logged in to comment)</span>}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export const DesignComment: React.FC<IDesignComment> = (props) => {
  const currentDesign = useStoreState(DslStore, getCurrentDesign);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const [reply, setReply] = useState(false);
  const [commentUserImage, setCommentUserImage] = useState('');
  const [key, setKey] = useState('');

  const deleteComment = async () => {
    if (props.comment) {
      await axiosHelper
        .deleteComment(props.comment?.id, currentDesign?.id)
        ?.then((response) => {
          // console.log(response);
          props.delete(1);
          setCurrentDesign(response);
        })
        .catch((error) => {
          console.log(error);
          props.delete(2);
        });
    }
  };

  const flagComment = async () => {
    if (props.comment?.id) {
      await axiosHelper
        .flagComment(props.comment.id)
        ?.then((response) => {
          console.log(response);
          props.flag(1);
        })
        .catch((error) => (console.log(error), props.flag(2)));
    }
  };

  const flagResponse = (flag: number) => {
    if (props.comment?.childrenComments) {
      props.flag(flag);
    }
  };

  const deleteResponse = (deleted: number) => {
    if (props.comment?.childrenComments) {
      props.delete(deleted);
    }
  };

  const userImage = () => {
    if (props.comment?.username) {
      const username = localStorage.getItem(props.comment.username);
      if (username) {
        const parsed = JSON.parse(username);
        return parsed.profilePic;
      } else {
        return userImg;
      }
    }
  };

  return (
    <>
      <div className={`row  ${props.comment?.childrenComments.length ? 'mb-3' : 'mb-5'}`}>
        <div className="col-12 col-md-10 row ">
          <div className="col-3 col-md-2 text-start align-self-start  ">
            <img alt="" className={`${styles.smallerImg}`} src={userImage()} />
          </div>
          <div className="col-9 col-md-10 mt-2   text-start">
            <p>
              <span className="color-blue fw-bold">{props.comment?.username} </span>
              <span>
                {designUploadDateString(props.comment?.commentDate) +
                  ' at ' +
                  designCommentHourString(props.comment?.commentDate)}
              </span>
            </p>
            <p>{props.comment?.commentText}</p>
            {loggedUser && (
              <div className="row ">
                <div className="col-lg-5 col-sm-7  d-flex flex-row align-items-center">
                  <span
                    className="d-flex align-items-center col-4  text color-dark_grey_50 cursor-pointer"
                    onClick={() => setReply(!reply)}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: '1.5rem' }}
                      icon={faCommentDots as IconProp}
                      className="text color-blue pe-2"
                    />
                    Reply
                  </span>

                  <span
                    className="d-flex align-items-center col-3 me-2 text color-dark_grey_50 cursor-pointer"
                    onClick={flagComment}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: '1.5rem' }}
                      icon={faFlag as IconProp}
                      className="text color-yellow me-2 "
                    />
                    Flag
                  </span>
                  {props.comment?.dslUser.id === loggedUser?.id && (
                    <span
                      className="d-flex align-items-center  col-4 text color-dark_grey_50 cursor-pointer"
                      onClick={() => deleteComment()}
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: '1.5rem' }}
                        icon={faXmark as IconProp}
                        className="text color-red me-2"
                      />
                      Delete
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.comment?.childrenComments
        ? props.comment.childrenComments.map((aComment: CommentDesign, index: number) => (
            <>
              {aComment.isCensored === false && aComment.isPublished === true && (
                <CommentResponse
                  delete={(deleted) => deleteResponse(deleted)}
                  flag={(flag) => flagResponse(flag)}
                  comment={aComment}
                  key={index}
                />
              )}
            </>
          ))
        : ''}
      {reply === true && (
        <ReplyCommentMaker
          delete={() => console.log('delete')}
          flag={() => console.log('flag')}
          update={() => setReply(false)}
          comment={props.comment}
        />
      )}
    </>
  );
};

const CommentResponse: React.FC<IDesignComment> = (props) => {
  const currentDesign = useStoreState(DslStore, getCurrentDesign);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const [commentUserImage, setCommentUserImage] = useState('');
  const [key, setKey] = useState('');

  const deleteComment = async () => {
    if (props.comment) {
      await axiosHelper
        .deleteComment(props.comment?.id, currentDesign?.id)
        ?.then((response) => {
          console.log(response);
          setCurrentDesign(response);
          props.delete(1);
        })
        .catch((error) => {
          console.log(error);
          props.delete(2);
        });
    }
  };

  const flagComment = async () => {
    console.log('ciao');
    if (props.comment) {
      console.log('no comment');
      await axiosHelper
        .flagComment(props.comment.id)
        ?.then((response) => {
          console.log(response);
          props.flag(1);
        })
        .catch((error) => (console.log(error), props.flag(2)));
    }
  };


  const userImage = () => {
    if (props.comment?.username) {
      const username = localStorage.getItem(props.comment.username);
      if (username) {
        const parsed = JSON.parse(username);
        return parsed.profilePic;
      } else {
        return userImg;
      }
    }
  };

  return (
    <>
      <div className="row mb-5 " key={key}>
        <div className="col-1  col-md-1 "></div>
        <div className="col-9 col-md-9 mt-2 row   text-start ">
          <div className="row">
            <div className="col-4 col-md-2  text-center align-self-start">
              <img
                key={key}
                alt=""
                className={`${styles.smallerImg}`}
                src={userImage()}
              />
            </div>
            <div className=" col-8 col-md-10 mt-2 ">
              <span className="align-top ">
                <span className="color-blue fw-bold">{props.comment?.username}</span>{' '}
                <span>
                  {designUploadDateString(props.comment?.commentDate) +
                    ' at ' +
                    designCommentHourString(props.comment?.commentDate)}
                </span>
              </span>
              <p className="mt-3">{props.comment?.commentText}</p>
              {loggedUser && (
                <div className="row ">
                  <div className="col-lg-5 col-sm-7 d-flex flex-row align-items-center">
                    <span
                      className="d-flex align-items-center col-3 me-3 text color-dark_grey_50 cursor-pointer"
                      onClick={flagComment}
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: '1.5rem' }}
                        icon={faFlag as IconProp}
                        className="text color-yellow me-2 "
                      />
                      Flag
                    </span>
                    {props.comment?.dslUser.id === loggedUser?.id && (
                      <span
                        className="d-flex align-items-center  col-4 text color-dark_grey_50 cursor-pointer"
                        onClick={deleteComment}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: '1.5rem' }}
                          icon={faXmark as IconProp}
                          className="text color-red me-2"
                        />
                        Delete
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
