import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import SquaredImage from '../../SquaredImage/SquaredImage';

import styles from './InspirationCard.module.scss';
import ActionButton from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';

import DslStore, {
  addLikeToInspiration,
  setCommunityUser,
  setContestInspirationToEdit,
  setContestInspirations,
  setCurrentContest,
  setCurrentUser,
  setFavoriteInspirations,
  setFavoriteInspirationsCopy,
  setInsrpirationToEdit,
  setUserInspirationVotes,
  setUserInspirations,
} from '../../../store/DslStore';
import { Link, useNavigate } from 'react-router-dom';
import axiosHelper from '../../../helpers/axiosHelper';
import { fullPathLogo, pathInspirations } from '../../../helpers/utils';

import { ContestInspirationToEdit, LogoAttachment, User, UserInspiration, inspirationVote } from '../../../global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useStoreState } from 'pullstate';
import { getCommunityUser, getContestInspirations, getCurrentContest, getCurrentUser } from '../../../store/Selectors';
import inspirationImg from '../../../assets/Inspiration.png';
import { response } from 'express';
import PopUpLogin from '../../PopUp/PopUpLogin/PopUpLogin';
import PopUpInspirationImage from '../../PopUp/PopUpInspirationImage/PopUpInspirationImage';

interface IInspirationCard extends ICardInfo {
  text: string;
  image: string;
  fullImage?: LogoAttachment;
  name?: string;
  userId?: number;
  url?: string;
  id: number;
  inspirationVotes?: number;
  contestId?: number;
  profile?: boolean;
  private: boolean;
  contestName?: string;
  liked?: () => void;
  clicked?: () => void;
}

interface IInspirationCardProfile extends ICardInfo {
  text: string;
  image: string;
  name?: string;
  userId?: number;
  contestId?: number;
  url?: string;
  private: boolean;
  inspiration: UserInspiration;
}

export const InspirationCard: React.FC<IInspirationCard> = (props) => {
  const redirect = useNavigate();
  const contest = useStoreState(DslStore, getCurrentContest);
  const contestInspirations = useStoreState(DslStore, getContestInspirations);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const [liked, setLiked] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [actionClick, setActionClick] = useState(false);

  const isLiked = () => {
    // console.log(props.contestId);
    // console.log(loggedUser?.inspirationVotes);

    if (!loggedUser || loggedUser.userType === 'G') {
      setLiked(false);
    }

    if (loggedUser?.inspirationVotes && loggedUser.userType !== 'G') {
      loggedUser.inspirationVotes.some((item: inspirationVote) => item.inspiration.id === props.id && setLiked(true));
    }
  };

  // useEffect(() => {
  //   console.log(props);
  // }, []);

  useEffect(() => {
    isLiked();
    setActionClick(false);
  }, [props.id, contestInspirations]);

  const inspirationLiked = (inspirationId: number) => {
    axiosHelper.inspirationLiked(inspirationId).then(async (response) => {
      // console.log(response.allMainInspirationsOrdered);

      // setCurrentUser(response);
      setUserInspirationVotes(response.inspirationVotes);
      await axiosHelper.getUserInspirations(communityUser?.id)?.then((designerInspirations) => {
        //todo assegna inspiration dell'user a cui ho appena messo like alla inspiration
        setUserInspirations(designerInspirations.inspirations);
      });

      await axiosHelper.getDesignerFavoriteInspirations(communityUser?.id)?.then((inspirationfavorites) => {
        //todo assegna inspiration favorites e  dell'user a cui ho appena messo like alla inspiration
        setFavoriteInspirations(inspirationfavorites.inspirationVotes);
        setFavoriteInspirationsCopy(inspirationfavorites.inspirationVotes);
      });
      addLikeToInspiration(inspirationId);
      setLiked(true);
      // alert('Vote added');
      props.liked && props.liked();
    });
  };

  const editInspiration = () => {
    setActionClick(true);
    let inspiration: ContestInspirationToEdit = {
      id: props.id,
      user: { id: props.userId },
      contest: { id: props.contestId, name: props.contestName },
      websiteURL: props.url,
      isPrivate: props.private,
      textDescription: props.text,
      imageAttachment: props.fullImage,
    };

    setContestInspirationToEdit(inspiration);
    redirect(`/contest/${contest?.nameForUrl}/inspirations/edit`);
  };

  const inspirationUnliked = (inspirationId: number) => {
    axiosHelper.inspirationUnliked(inspirationId).then(async (response) => {
      // console.log(response.allMainInspirationsOrdered);
      await axiosHelper.getUserInspirations(communityUser?.id)?.then((designerInspirations) => {
        //todo assegna inspiration dell'user a cui ho appena messo like alla inspiration
        setUserInspirations(designerInspirations.inspirations);
      });

      await axiosHelper.getDesignerFavoriteInspirations(communityUser?.id)?.then((inspirationfavorites) => {
        //todo assegna inspiration favorites e  dell'user a cui ho appena messo like alla inspiration
        setFavoriteInspirations(inspirationfavorites.inspirationVotes);
        setFavoriteInspirationsCopy(inspirationfavorites.inspirationVotes);
      });
      setLiked(false);
      // setCurrentUser(response);
      setUserInspirationVotes(response.inspirationVotes);
      props.liked && props.liked();
      // alert('Vote removed');
    });
  };

  const goOut = () => {
    if (!actionClick) {
      if (props.url) {
        // redirect(props.url);z
      } else {
        if (props.image && loggedUser) setPopUp(true);
      }
    }
  };

  const goToContest = async (contestId?: number) => {
    if (contestId) {
      const result = await axiosHelper.retrieveCompleteContest(contestId);
      console.log(result.data.client);

      console.log(result);
      setCurrentContest(result.data);

      redirect(`/contest/${result.data.nameForUrl}/brief`);
    } else {
      await axiosHelper.retrieveUserById(props.userId)?.then((response) => {
        console.log(response);
        setCommunityUser(response);
        redirect(`/community/${response.nameForURL}/profile`);
      });
    }
  };

  const goToDetail = async (userid?: number) => {
    // console.log(userid);
    if (userid) {
      let result: User = await axiosHelper.retrieveUserById(userid);
      // console.log(result);

      setCommunityUser(result);
      setTimeout(() => {
        // console.log('This will run after 1 second!');
        loggedUser && loggedUser.id === result.id
          ? redirect(`/community/${result.nameForURL}/profile-editable`)
          : redirect(`/community/${result.nameForURL}/profile`);
        // redirect(`/community/${result.nameForURL}/profile`);
      }, 1000);
    }
  };

  useEffect(() => {
    // console.log(isLiked);
    isLiked();
  }, []);

  return (
    <>
      <div className={`${styles.card} col-12  col-md-6 col-lg-3 position-relative `}>
        <div className={`${styles.cardInfoWrapper}  d-flex flex-column  bg-light`}>
          <div
            //   onClick={() => (getContestUser(props.contest.id), console.log('click'))}
            className={`${props.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light ${
              props.circle && 'px-5 pt-4'
            } position-relative ${((props.image && loggedUser) || props.url) && 'cursor-pointer'}`}
            onClick={() => goOut()}
          >
            {props.image ? (
              props.url ? (
                <div style={{ cursor: 'pointer' }} onClick={() => {}}>
                  <a href={props.url} target="_blank" onClick={() => console.log(props.url)}>
                    <SquaredImage image={fullPathLogo(props.image)} altText={props.altText} circle={props.circle} />
                  </a>
                </div>
              ) : (
                <SquaredImage image={fullPathLogo(props.image)} altText={props.altText} circle={props.circle} />
              )
            ) : props.url ? (
              <div className={`${styles.squaredImageWrapper}`}>
                <div className={`${styles.imageInner}`}>
                  <div style={{ cursor: 'pointer' }}>
                    <SquaredImage image={inspirationImg} altText={props.altText} circle={props.circle} />
                  </div>
                </div>
              </div>
            ) : (
              // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
              <div className={`${styles.squaredImageWrapper}`}>
                <div className={`${styles.imageInner}`}>
                  <SquaredImage image={inspirationImg} altText={props.altText} circle={props.circle} />
                </div>
              </div>
            )}

            {!liked &&
              (loggedUser ? (
                loggedUser.id !== props.userId ? (
                  <ActionButton
                    icon="heart"
                    label={loggedUser ? `${props.inspirationVotes} like` : 'Login to save as favorite'}
                    color={!loggedUser ? 'light-gray-3' : ''}
                    position="top-right"
                    action={() => loggedUser && inspirationLiked(props.id)}
                  />
                ) : (
                  <ActionButton
                    icon="edit"
                    label={`Edit inspiration`}
                    color={!loggedUser ? 'light-gray-3' : ''}
                    position="top-right"
                    action={() => loggedUser && editInspiration()}
                  />
                )
              ) : (
                <ActionButton
                  icon="heart"
                  label={loggedUser ? `${props.inspirationVotes} like` : 'Login to save as favorite'}
                  color={!loggedUser ? 'light-gray-3' : ''}
                  position="top-right"
                  action={() => loggedUser && inspirationLiked(props.id)}
                />
              ))}
            {liked && (
              <ActionButton
                label={`${props.inspirationVotes} like`}
                icon="solidheart"
                position="top-right"
                action={() => inspirationUnliked(props.id)}
              />
            )}
            {props.private && <ActionButton label={`Private inspiration`} icon="private" position="bottom-left" />}
          </div>

          <div className={`${styles.cardInfoContent} container bg-light`}>
            <div className="row h-25 mt-2 mb-3 mx-1">
              {!props.profile && (
                <div className="col text-center align-self-center  " onClick={() => goToDetail(props.userId)}>
                  <h3 className="fw-bold color-blue d-sm-none d-md-block " style={{ cursor: 'pointer' }}>
                    {props.name}
                  </h3>
                  <h1 className="fw-bold color-blue d-md-none d-sm-block">{props.name}</h1>
                </div>
              )}
              {props.profile && (
                <div className="col text-center  align-self-center  " onClick={() => goToContest(props.contestId)}>
                  <h3 className="fw-bold color-blue d-sm-none d-md-block " style={{ cursor: 'pointer' }}>
                    {props.contestName ? props.contestName : props.name}
                  </h3>
                  <h1 className="fw-bold color-blue d-md-none d-sm-block">
                    {props.contestName ? props.contestName : props.name}
                  </h1>
                </div>
              )}
            </div>

            <div className=" col align-self-center text-justify py-3 px-4">{props.text}</div>
          </div>
        </div>
        <PopUpInspirationImage image={props.image} popup={popUp} close={() => setPopUp(false)} />
      </div>
    </>
  );
};

export const AddInspirationCard: React.FC<IInspirationCard> = (props) => {
  const redirect = useNavigate();
  const contest = useStoreState(DslStore, getCurrentContest);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [popup, setPopup] = useState(false);

  const goToDetail = async (userid?: number) => {
    console.log(userid);
    if (userid) {
      let result: User = await axiosHelper.retrieveUserById(userid);
      console.log(result);

      setCommunityUser(result);
      setTimeout(() => {
        console.log('This will run after 1 second!');
        console.log(currentUser?.id, result.id);
        currentUser && currentUser.id === result.id
          ? redirect(`/community/${result.nameForURL}/profile-editable`)
          : redirect(`/community/${result.nameForURL}/profile`);
        // redirect(`/community/${result.nameForURL}/profile`);
      }, 1000);
    }
  };

  const addInspiration = () => {
    if (currentUser) {
      console.log(contest?.nameForUrl);
      console.log(`/contest/${contest?.nameForUrl}/inspirations/new`);
      if (contest) redirect(`/contest/${contest?.nameForUrl}/inspirations/new`);
    } else {
      setPopup(true);
    }
  };

  return (
    <div className={`${styles.card} col-12  col-md-6 col-lg-3 position-relative`}>
      <div className={`${styles.cardInfoWrapper} d-flex flex-column  bg-light`}>
        <div className={`${styles.imageWrapper}`}>
          <div className={` ${styles.addButton}`} style={{ cursor: 'pointer' }} onClick={() => addInspiration()}>
            <FontAwesomeIcon icon={faPlus as IconProp} style={{ fontSize: '4rem' }} className="color-light" />
          </div>
          {/* <ActionButton
            label="Save as favorite"
            icon="heart"
            position="top-right"
            action={() => alert('Added as favorite')}
          /> */}
        </div>

        <div className={`${styles.cardInfoContent} container bg-light`}>
          <div className="row h-25 mt-2 mb-3 mx-1">
            <div className="col text-center align-self-center  " onClick={() => goToDetail(props.userId)}>
              <h3 className="fw-bold color-blue d-sm-none d-md-block ">{props.name}</h3>
              <h1 className="fw-bold color-blue d-md-none d-sm-block">{props.name}</h1>
            </div>
          </div>

          <div className=" col align-self-center text-justify py-3 px-4">{props.text}</div>
        </div>
      </div>
      {popup && <PopUpLogin close={() => setPopup(false)} popup={popup} login={(e: boolean) => setPopup(e)} />}
    </div>
  );
};

export const InspirationCardProfile: React.FC<IInspirationCardProfile> = (props) => {
  const redirect = useNavigate();
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [liked, setLiked] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const goToContest = async (contestId?: number) => {
    if (contestId) {
      const result = await axiosHelper.retrieveCompleteContest(contestId);
      console.log(result.data.client);

      console.log(result);
      setCurrentContest(result.data);

      redirect(`/contest/${result.data.nameForUrl}/brief`);
    }
  };

  useEffect(() => {
    console.log(props);
  }, []);

  const editInspiration = async () => {
    if (currentUser) {
      if (currentUser.id === props.userId) {
        setInsrpirationToEdit(props.inspiration);
        redirect(`/community/${currentUser?.nameForURL}/designer-inspirations/edit`);
      }
    }
  };

  const inspirationLiked = () => {
    axiosHelper.inspirationLiked(props.inspiration.id).then(async (response) => {
      // console.log(response.allMainInspirationsOrdered);

      // setCurrentUser(response);

      await axiosHelper.getDesignerInspirations(props.userId)?.then((designerInspirations) => {
        // console.log("INSPIRATIONNNN DSIOCSAOCO");
        // console.log(designerInspirations);
        setUserInspirations(designerInspirations.inspirations);
        setLiked(true);
      });
      // alert('Vote added');
    });
  };

  const isLiked = () => {
    // console.log(props.contestId);
    // console.log(loggedUser?.inspirationVotes);

    if (!currentUser || currentUser.userType === 'G') {
      setLiked(false);
    }

    if (currentUser?.inspirationVotes && currentUser.userType !== 'G') {
      currentUser.inspirationVotes.some(
        (item: inspirationVote) => item.inspiration.id === props.inspiration.id && setLiked(true)
      );
    }
  };

  const inspirationUnliked = () => {
    axiosHelper.inspirationUnliked(props.inspiration.id).then(async (response) => {
      // console.log(response.allMainInspirationsOrdered);

      // setCurrentUser(response);
      await axiosHelper.getDesignerInspirations(props.userId)?.then((designerInspirations) => {
        // console.log("INSPIRATIONNNN DSIOCSAOCO");
        // console.log(designerInspirations);
        setUserInspirations(designerInspirations.inspirations);
        setLiked(false);
      });
      // setUserInspirationVotes(response.inspirationVotes);
      // alert('Vote removed');
    });
  };

  const goOut = () => {
    if (!props.url && props.image) {
      setPopUp(true);
    }
  };

  useEffect(() => {
    isLiked();
  }, [props.inspiration.id]);

  return (
    <div
      className={`${styles.card} col-12 d-flex flex-row align-items-center justify-content-center position-relative`}
    >
      <div className={`${styles.cardInfoWrapper} d-flex flex-column   bg-light`}>
        <div
          className={`${props.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light  ${
            props.circle && 'px-5 pt-4'
          } position-relative `}
          onClick={() => goOut()}
        >
          <a href={props.url} target="_blank" rel="noreferrer">
            {props.image ? (
              <SquaredImage image={pathInspirations(props.image)} altText={props.altText} circle={props.circle} />
            ) : (
              <div className={`${styles.squaredImageWrapper}`}>
                <div className={`${styles.imageInner}`}>
                  <SquaredImage image={inspirationImg} altText={props.altText} circle={props.circle} />
                </div>
              </div>
              // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
            )}
          </a>
          {props.userId === currentUser?.id && (
            <ActionButton label="Edit inspiration" icon="edit" position="top-right" action={() => editInspiration()} />
          )}
          {props.private && <ActionButton label={`Private inspiration`} icon="private" position="bottom-left" />}
          {props.userId !== currentUser?.id &&
            (!liked ? (
              <ActionButton
                icon="heart"
                label={currentUser ? `${props.inspiration.votesCount} like` : 'Login to save as favorite'}
                color={!currentUser ? 'light-gray-3' : ''}
                position="top-right"
                action={() => currentUser && inspirationLiked()}
              />
            ) : (
              liked && (
                <ActionButton
                  label={`${props.inspiration.votesCount} like`}
                  icon="solidheart"
                  position="top-right"
                  action={() => inspirationUnliked()}
                />
              )
            ))}
        </div>

        <div className={`${styles.cardInfoContent} container bg-light`}>
          <div className="row h-25 mt-2 mb-3 mx-1">
            <div
              className="col text-center align-self-center  "
              style={{ cursor: 'pointer' }}
              onClick={() => goToContest(props.contestId)}
            >
              <h3 className="fw-bold color-blue d-sm-none d-md-block ">{props.name ? props.name : 'MY WALL'}</h3>
              <h1 className="fw-bold color-blue d-md-none d-sm-block">{props.name ? props.name : 'MY WALL'}</h1>
            </div>
          </div>

          <div className=" col align-self-center text-justify py-3 px-4">{props.text}</div>
        </div>
      </div>
      <PopUpInspirationImage image={props.image} popup={popUp} close={() => setPopUp(false)} />
    </div>
  );
};

export const AddInspirationCardProfile: React.FC<IInspirationCard> = (props) => {
  const redirect = useNavigate();
  const currentUser = useStoreState(DslStore, getCurrentUser);

  const addInspiration = () => {
    setInsrpirationToEdit(undefined);
    redirect(`/community/${currentUser?.nameForURL}/designer-inspirations/new`);
  };

  return (
    <div
      className={`${styles.card} col-12  d-flex flex-row align-items-center justify-content-center position-relative`}
    >
      <div className={`${styles.cardInfoWrapper} d-flex flex-column  bg-light`}>
        <div className={`${styles.imageWrapper}`}>
          <div className={` ${styles.addButton}`} style={{ cursor: 'pointer' }} onClick={addInspiration}>
            <FontAwesomeIcon icon={faPlus as IconProp} style={{ fontSize: '4rem' }} className="color-light" />
          </div>
          {/* <ActionButton
            label="Save as favorite"
            icon="heart"
            position="top-right"
            action={() => alert('Added as favorite')}
          /> */}
        </div>

        <div className={`${styles.cardInfoContent} container bg-light`}>
          <div className="row h-25 mt-2 mb-3 mx-1">
            <div className="col text-center align-self-center  ">
              <h3 className="fw-bold color-blue d-sm-none d-md-block  ">{props.name}</h3>
              <h1 className="fw-bold color-blue d-md-none d-sm-block">{props.name}</h1>
            </div>
          </div>

          <div className=" col align-self-center text-justify py-3 px-4">{props.text}</div>
        </div>
      </div>
    </div>
  );
};
