import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DslStore, {
  setCommunityUser,
  setCurrentContest,
  setCurrentUser,
  setSocialUser,
  setUserLogged,
} from '../../../store/DslStore';

import styles from './PopUpLogin.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { FacebookTokenLogin, LoginData, TwitterTokenLogin, User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import { Modal } from 'react-bootstrap';
import { faFacebook, faGoogle, faLinkedin, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialLinkedin,
  LoginSocialTwitter,
} from 'reactjs-social-login';
import xLogo from '../../../assets/x-twitter-01.png';
import { useStoreState } from 'pullstate';
import { getCurrentContest } from '../../../store/Selectors';

interface PopUpLoginProps {
  popup: boolean;
  login: (popup: boolean) => void;
  close: () => void;
}

const PopUpLogin: React.FC<PopUpLoginProps> = (props) => {
  const currentContest = useStoreState(DslStore, getCurrentContest);
  const [checkbox, setCheckbox] = useState(false);
  const [popup, setPopup] = useState(props.popup);
  const [responseError, setResponseError] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>();

  useEffect(() => {
    setPopup(props.popup);
  }, [props.popup]);

  const onSubmit = (data: any) => {
    console.log(data.e);
    console.log(data.p);
    realLogin(data.e, data.p);
  };

  const realLogin = async (email: string, password: string) => {
    if (email && password) {
      await axiosHelper
        .login<User>(email, password)
        .then(async (response) => {
          if (response.data) {
            axiosHelper.setAuthToken(response.data.dslToken, true);
            setCurrentUser(response.data);
            axiosHelper.saveUserProfile(response.data);
            setUserLogged(true);
            if (currentContest) {
              await axiosHelper.retrieveCompleteContest(currentContest.id).then((response) => {
                setCurrentContest(response.data);
                setPopup(!popup);
              });
            }
            props.login(false);
          }
        })
        .catch((error) => {
          setResponseError(error.response.status);
          setUserLogged(false);
        });
    }
  };

  const facebookLogin = async (token: string, uid: string, email: string) => {
    // console.log(image);

    const ftl: FacebookTokenLogin = {
      email: email,
      token: token,
      uid: uid,
    };

    console.log(ftl);

    await axiosHelper.facebookTokenLogin(ftl).then(async (response) => {
      axiosHelper.setAuthToken(response.dslToken, true);

      setCurrentUser(response);
      setCommunityUser(response);
      axiosHelper.saveUserProfile(response);
      setUserLogged(true);
      if (currentContest) {
        await axiosHelper.retrieveCompleteContest(currentContest.id).then((response) => {
          setCurrentContest(response.data);
          setPopup(!popup);
        });
      }
    });
  };

  const linkedinLogin = async (token: string, uid: string) => {
    //TODO

    const ttl: TwitterTokenLogin = {
      token: token,
      uid: uid,
    };
    await axiosHelper.linkedinTokenLogin(ttl).then((response) => {
      console.log(response);
      axiosHelper.setAuthToken(response.dslToken, true);

      setCurrentUser(response);
      setCommunityUser(response);
      axiosHelper.saveUserProfile(response);
      setUserLogged(true);
      setPopup(!popup);
    });
  };

  const twitterLogin = async (token: string, uid: string) => {
    //TODO

    const ttl: TwitterTokenLogin = {
      token: token,
      uid: uid,
    };
    await axiosHelper.twitterTokenLogin(ttl).then(async (response) => {
      console.log(response);
      axiosHelper.setAuthToken(response.dslToken, true);

      setCurrentUser(response);
      setCommunityUser(response);
      setUserLogged(true);

      axiosHelper.saveUserProfile(response);
      if (currentContest) {
        await axiosHelper.retrieveCompleteContest(currentContest.id).then((response) => {
          setCurrentContest(response.data);
          setPopup(!popup);
        });
      }
    });
  };

  const googleLogin = async (token: string, uid: string, email: string) => {
    // console.log(image);

    const ftl: FacebookTokenLogin = {
      email: email,
      token: token,
      uid: uid,
    };

    console.log(ftl);

    await axiosHelper.googleTokenLogin(ftl).then(async (response) => {
      axiosHelper.setAuthToken(response.dslToken, true);

      setCurrentUser(response);
      setCommunityUser(response);
      axiosHelper.saveUserProfile(response);
      setUserLogged(true);
      if (currentContest) {
        await axiosHelper.retrieveCompleteContest(currentContest.id).then((response) => {
          setCurrentContest(response.data);
          setPopup(!popup);
        });
      }
    });
  };

  return (
    <>
      <Modal
        show={popup}
        onHide={() => (setPopup(!popup), props.close && props.close())}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <div className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}>
          <Modal.Body className="d-flex justify-content-center">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row justify-content-center align-items-center">
                <div className="col-12 mb-4">
                  <span className="bigger-text-bold ">Please login</span>
                </div>
                <div className="col-12 mb-6">
                  <span className="text ">
                    Not registered yet? &nbsp;
                    <a href="/signup" target="_self" className="me-1">
                      signup
                    </a>
                    now!
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center mb-4">
                  <div className="col-5 pe-3">
                    <LoginSocialGoogle
                      client_id={'571721759610-a952df14qdtva6cbusfsv46sqqg5aja1.apps.googleusercontent.com'}
                      redirect_uri={'/'}
                      onReject={() => console.log('error')}
                      onResolve={({ provider, data }: IResolveParams) => {
                        console.log(data);
                        googleLogin(data?.access_token, data?.sub, data?.email);
                      }}
                    >
                      <button className="btn-social__google full rounded ">
                        <div className="btn-icon ">
                          <FontAwesomeIcon icon={faGoogle as IconProp} className="text color-light" />
                        </div>
                        google
                      </button>
                    </LoginSocialGoogle>
                  </div>
                  <div className="col-5 ps-3">
                    <LoginSocialFacebook
                      // redirect_uri={REDIRECT_URI}
                      // scope="email, public_profile, gender, username"
                      // xfbml={false}
                      appId={process.env.REACT_APP_FB_APP_ID || ''}
                      // onLoginStart={onLoginStart}
                      onResolve={({ provider, data }: IResolveParams) => {
                        // setProvider(provider);
                        // setProfile(data);
                        console.log(data);
                        console.log(data?.accessToken);
                        facebookLogin(data?.accessToken, data?.userID, data?.email);
                      }}
                      onReject={(err: any) => {
                        console.log(err);
                      }}
                    >
                      <button className="btn-social__facebook full rounded">
                        <div className="btn-icon">
                          <FontAwesomeIcon icon={faFacebook as IconProp} className="text color-light" />
                        </div>
                        facebook
                      </button>
                    </LoginSocialFacebook>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center mb-4">
                  <div className="col-5  pe-3">
                    <LoginSocialTwitter
                      client_id="MWNPQmc5NHRQdE5sZ1RTczRyLTQ6MTpjaQ"
                      redirect_uri="https://beta.altera.it/login"
                      // redirect_uri="http://localhost:3000/login"
                      onReject={(error) => console.log(error)}
                      onResolve={({ provider, data }: IResolveParams) => {
                        console.log(provider);
                        console.log(data);
                        console.log(data?.access_token);
                        console.log(data?.id);
                        twitterLogin(data?.access_token, data?.id);
                      }}
                      onLoginStart={() => console.log('start twitter login')}
                    >
                      <button className="btn-social__twitter full rounded">
                        <div className="btn-icon">
                          {/* <img src={xLogo} style={{ height: '15px', width: '15px' }} /> */}
                          <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                        </div>
                        x-twitter
                      </button>
                    </LoginSocialTwitter>
                  </div>
                  <div className="col-5  ps-3">
                    <LoginSocialLinkedin
                      client_id="78ic7x2w9maoqp"
                      redirect_uri="https://beta.altera.it/login"
                      scope="profile email"
                      client_secret="ioKQM8Z2vJzcnj7H"
                      onLoginStart={() => console.log('start login')}
                      onReject={(error: any) => {
                        console.log(error);
                      }}
                      onResolve={({ provider, data }: IResolveParams) => {
                        // setProvider(provider);
                        // setProfile(data);
                        console.log(data);
                        console.log(data?.accessToken);
                        linkedinLogin(data?.access_token, data?.id_token);
                      }}
                    >
                      <button className="btn-social__linkedin full rounded ">
                        <div className="btn-icon">
                          <FontAwesomeIcon icon={faLinkedin as IconProp} className="text color-light" />
                        </div>
                        linkedin
                      </button>
                    </LoginSocialLinkedin>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-10 mb-4 ">
                    <input id="email" type="text" placeholder="username" className=" text-center " {...register('e')} />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="col-10 mb-4">
                    <input
                      id="pwd"
                      type="password"
                      placeholder="password"
                      className=" text-center "
                      {...register('p')}
                    />
                  </div>
                </div>
                <div className="row  ">
                  <div className="col-10 ">
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox-1"
                      checked={checkbox}
                      onChange={() => setCheckbox(!checkbox)}
                      className=""
                    />
                    <label htmlFor="checkbox-1" className=" text-start text">
                      Remember me username and password
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12  d-flex justify-content-between ">
                    <div className="mt-5 col-3 ms-5">
                      <button className="btn-dark-blue rounded full submit">Send</button>
                    </div>
                    <div className="mt-5 col-3 me-4">
                      <div className="d-flex justify-content-center align-items-center ">
                        <span className="px-3">Cancel</span>
                        <div
                          className={` ${styles.closeButton}`}
                          onClick={() => {
                            setPopup(!popup);
                            props.login(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {/* <h5>You will be redirected to the Artsana platform innovation.artsana.com</h5> */}
          </Modal.Body>
        </div>
        {/* <Modal.Footer className="d-flex flex-row justify-content-between">
              <Button className="btn-red " onClick={() => setPopup(!popup)}>
                Close
              </Button>
              <a href={`/contest/${contest?.nameForUrl}/brief`} target="_self">
                <Button className="btn-success" onClick={() => setPopup(!popup)}>
                  Login
                </Button>
              </a>
            </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default PopUpLogin;
