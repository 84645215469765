import { useRef, useState } from 'react';
import { NamingUpload } from '../../global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useStoreState } from 'pullstate';
import { faCircleExclamation, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import styles from './UploadContest.module.scss';
import axiosHelper from '../../helpers/axiosHelper';
import DslStore, {
  setCommunityUser,
  setPortfolioDesign,
  setUserAwards,
  setUserInspirations,
} from '../../store/DslStore';
import { getCurrentContest, getCurrentDesign, getCurrentUser } from '../../store/Selectors';
import { useNavigate } from 'react-router-dom';

import { TailSpin } from 'react-loader-spinner';

interface NamingProps {
  id: number;
  name?: string;
  description?: string;
  payoff?: string;
}

export const EditDraftNaming: React.FC<NamingProps> = (props) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const currentdesign = useStoreState(DslStore, getCurrentDesign);
  const [designDescriptionLength, setDesignDescriptionLength] = useState(0);
  const [designPayoffLength, setdesignPayoffLength] = useState(0);
  const [popupName, setPopupName] = useState(false);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const [name, setName] = useState(currentdesign ? currentdesign.name : '');
  const [description, setDescription] = useState(currentdesign ? currentdesign.textDescription : '');
  const [payoff, setPayoff] = useState(currentdesign ? currentdesign.payoff : '');
  const nameError = useRef(false);
  const descriptionError = useRef(false);
  const payoffError = useRef(false);
  const [popupDescription, setPopupDescription] = useState(false);
  const [popupPayoff, setPopupPayoff] = useState(false);
  const [progress, setProgress] = useState(0);
  const [draft, setDraft] = useState(false);
  const redirect = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NamingUpload>();

  const handleDescription = (e: any) => {
    let descr = e.target.value;
    setDescription(descr);
    setDesignDescriptionLength(descr.length);
  };

  const handlePayoff = (e: any) => {
    let descr = e.target.value;
    setPayoff(descr);
    setdesignPayoffLength(descr.length);
  };

  const onSubmit = (data: NamingUpload) => {
    console.log(data);
    nameError.current = false;
    descriptionError.current = false;
    payoffError.current = false;
    if (description && name && payoff && !draft) {
      console.log(draft);
      sendData(data);
    } else {
      if (draft) {
        if (data.designName) {
          console.log(draft);
          sendData(data);
        }
      } else {
        if (!name) {
          nameError.current = true;
        }
        if (!description && !draft) {
          descriptionError.current = true;
        }
        if (!payoff && !draft) {
          payoffError.current = true;
        }
      }
    }
  };

  const sendData = async (data: NamingUpload) => {
    console.log(draft);
    setCommunityUser(loggedUser);
    if (contest) {
      if (!draft) {
        console.log('non draft');
        setProgress(20);
        await axiosHelper
          .updateDraftNaming(props.id, data, false)
          ?.then(async (response) => {
            console.log(response);
            await axiosHelper
              .getDesignerDetails(loggedUser?.id)
              ?.then(async (designs) => {
                await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                  setUserAwards(awards.awards);
                });
                await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                  setUserInspirations(inspirations.inspirations);
                });
                setPortfolioDesign(designs.designs);
                setTimeout(() => {
                  redirect(`/community/${loggedUser?.nameForURL}/portfolio`);
                }, 2000);
              })
              .catch((error) => {
                setProgress(0);
              });
          })
          .catch((e) => {
            console.log(e);
            setProgress(0);
          });
      } else {
        console.log('draft');
        setProgress(20);
        await axiosHelper
          .updateDraftNaming(props.id, data, true)
          ?.then(async (response) => {
            console.log(response);
            await axiosHelper
              .getDesignerDetails(loggedUser?.id)
              ?.then(async (designs) => {
                await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                  setUserAwards(awards.awards);
                });
                await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                  setUserInspirations(inspirations.inspirations);
                });
                setPortfolioDesign(designs.designs);
                setTimeout(() => {
                  redirect(`/community/${loggedUser?.nameForURL}/portfolio`);
                }, 2000);
              })
              .catch((error) => {
                setProgress(0);
              });
          })
          .catch((e) => {
            console.log(e);
            setProgress(0);
          });
      }
    }
  };

  return (
    <>
      <div className="row mb-5  px-sm-2">
        <div className="col-12 col-md-2 mb-3"></div>
        <div className="col-12 col-md-10 text color-blue py-5">
          <i>
            To upload your design, you have to fill the mandatory fields (<span className="color-red">*</span>)
          </i>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-5">
            <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
              <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                <div className="col-11">
                  Name <span className="color-red">*</span>
                </div>
                <div className=" ml-auto">
                  <FontAwesomeIcon
                    icon={faCircleQuestion as IconProp}
                    className=" text color-blue me-0 "
                    onMouseEnter={() => setPopupName(true)}
                    onMouseLeave={() => setPopupName(false)}
                  />
                </div>
                <div className={`${popupName ? styles.message : styles.noMessage}`}>
                  <div className={`  ${styles.freccetta} `}></div>
                  <div className={`${styles.infoActions} p-4 text`}>
                    <i></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-10  ">
              <input
                className="text-start"
                type="text"
                value={name}
                placeholder="Identify with a name your project"
                {...register('designName', { onChange: (e) => setName(e.target.value) })}
              />
              {nameError.current && (
                <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                  <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" />
                  &nbsp; Please provide a &nbsp; <b> Project name. </b>
                </span>
              )}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
              <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                <div className="col-11">
                  Description <span className="color-red">*</span>
                </div>
                <div className=" ml-auto">
                  <FontAwesomeIcon
                    icon={faCircleQuestion as IconProp}
                    className=" text color-blue me-0 "
                    onMouseEnter={() => setPopupDescription(true)}
                    onMouseLeave={() => setPopupDescription(false)}
                  />
                </div>
                <div className={`${popupDescription ? styles.message : styles.noMessage}`}>
                  <div className={`  ${styles.freccetta} `}></div>
                  <div className={`${styles.infoActions} p-4 text`}>
                    <i>Use the description to motivate your 'Project name' choice.</i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10  ">
              <textarea
                placeholder="Explain in some world your project"
                value={description}
                {...register('description', { onChange: handleDescription, maxLength: 250 })}
              />
              <div className="row mt-2 ">
                <div className="col-1"></div>
                <div className="col-10 ">
                  {descriptionError.current && (
                    <span className=" d-flex flex-row justify-content-center align-items-center text color-dark_red">
                      <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp; Please
                      provide a &nbsp;<b>Description</b>
                    </span>
                  )}
                </div>
                <div className=" col-1 d-flex justify-content-end">
                  <span>{designDescriptionLength}/250</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
              <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                <div className="col-11">
                  Payoff <span className="color-red">*</span>
                </div>
                <div className=" ml-auto">
                  <FontAwesomeIcon
                    icon={faCircleQuestion as IconProp}
                    className=" text color-blue me-0 "
                    onMouseEnter={() => setPopupPayoff(true)}
                    onMouseLeave={() => setPopupPayoff(false)}
                  />
                </div>
                <div className={`${popupPayoff ? styles.message : styles.noMessage}`}>
                  <div className={`  ${styles.freccetta} `}></div>
                  <div className={`${styles.infoActions} text p-4`}>
                    <i>
                      Write a Payoff / Slogan to give more relevance to your Project name and say something more about
                      the brand. Remember to use a few words. Example. Project name: Desall , Payoff: Design on-demand.
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10  ">
              <textarea
                placeholder="Write a short payoff for your project"
                value={payoff}
                {...register('payoff', { onChange: handlePayoff, maxLength: 250 })}
              />

              <div className="row mt-2 ">
                <div className="col-1"></div>
                <div className="col-10 ">
                  {payoffError.current && (
                    <span className=" d-flex flex-row justify-content-center align-items-center text color-dark_red">
                      <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp; Please
                      provide a &nbsp;<b>Payoff</b>
                    </span>
                  )}
                </div>
                <div className=" col-1 d-flex justify-content-end">
                  <span>{designPayoffLength}/250</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-3 mb-4 px-5 align-items-center ">
              <div className="d-flex flex-row justify-content-center ">
                {progress === 0 && (
                  <button className={`btn-dark-blue rounded full`} onClick={() => setDraft(true)}>
                    Save draft
                  </button>
                )}
                {progress !== 0 && !draft && (
                  <button className={`btn-dark-blue rounded full`} onClick={() => setDraft(true)}>
                    Save draft
                  </button>
                )}
                {progress !== 0 && draft && (
                  <button
                    // className={`${progress === 0 ? `btn-dark-blue` : `btn-light_grey_20`} rounded full `}
                    className={`btn-dark_grey_70 rounded   full `}
                    disabled={true}
                    onClick={() => progress === 0 && setDraft(true)}
                  >
                    Save draft
                  </button>
                )}
              </div>
            </div>

            <div className="col-12 col-md-3 mb-4 px-5 align-items-center">
              <div className="d-flex flex-row justify-content-center ">
                {progress === 0 && <button className="btn-blue rounded full">Publish</button>}
                {progress !== 0 && draft && <button className="btn-blue rounded full">Publish</button>}

                {progress !== 0 && (
                  <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                    <TailSpin
                      height="15"
                      width="15"
                      color="#7997c5"
                      wrapperStyle={{}}
                      wrapperClass="me-2"
                      visible={true}
                      ariaLabel="tail-spin-loading"
                    />
                    &nbsp;
                    <span>Uploading</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
