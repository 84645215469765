import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './Brief.module.scss';
import xLogo from '../../assets/x-twitter-01.png';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faCloudArrowUp,
  faUserPlus,
  faFileArrowDown,
  faPlus,
  faExclamation,
  faRemove,
  faMedal,
  faCalendarCheck,
} from '@fortawesome/free-solid-svg-icons';

//Header Data

//Footer Data

import MiniTimeLine from '../../components/TimeLine/MiniTimeLine';
import SideActionsCard from '../../components/SideActionsCard/SideActionsCard';
import {
  getCoCreationContestStats,
  getCoCreationContests,
  getCommunityUser,
  getContestTags,
  getCurrentContest,
  getCurrentUser,
  getTimelinePassedUpload,
  getTimelinePassedCommunityVote,
  getTimelineTotUpload,
  getTimelineTotCommunityVote,
  getUserLogged,
  getTimelinePassedClientVote,
  getTimelineTotClientVote,
  getAllCoCreationContests,
  getContestGuidelines,
  getContestAwards,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, {
  setCoCreationContest,
  setCoCreationContestGallery,
  setCoCreationContestStats,
  setCommunityUser,
  setContest,
  setContestTags,
  setContestsILike,
  setCurrentContest,
} from '../../store/DslStore';
import {
  finalString,
  fullPathLogo,
  registrationDateString,
  revisionDateString,
  setTimelines,
  quadrettoDateHours,
  quadrettoDateString,
} from '../../helpers/utils';
import axiosHelper from '../../helpers/axiosHelper';

import { faFacebookF, faPinterest, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  CoCreationContest,
  CoCreationContestStats,
  CoCreationDesign,
  ContestGuideline,
  ContestTag,
  GalleryDesign,
  RevisionUpload,
  User,
} from '../../global';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import NDAForm from '../../components/Contest/NDAForm/NDAForm';
import CoCreationBrief from './CoCreationBrief';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import PopUpSendMessage from '../../components/PopUp/PopUpSendMessage/PopUpSendMessage';
import { faCalendarXmark } from '@fortawesome/free-regular-svg-icons';
import remarkRehype from 'remark-rehype';
import rehypeFilter from 'react-markdown/lib/rehype-filter';

interface ContestProps {
  breadcrumbs: (breadcrumbs: string) => void;
  messageBanner: (message: number) => void;
  ndaauth?: (auth: boolean) => void;
  accesscodeauth?: (auth: boolean) => void;
}

const Brief: React.FC<ContestProps> = (props) => {
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const contestTag = useStoreState(DslStore, getContestTags);
  const allCocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const cocreationContests = useStoreState(DslStore, getCoCreationContests);
  const timelineTotUpload = useStoreState(DslStore, getTimelineTotUpload);
  const timelinePassedUpload = useStoreState(DslStore, getTimelinePassedUpload);
  const timelineTotCommunityVote = useStoreState(DslStore, getTimelineTotCommunityVote);
  const timelinePassedCommunityVote = useStoreState(DslStore, getTimelinePassedCommunityVote);
  const timelineTotClientVote = useStoreState(DslStore, getTimelineTotClientVote);
  const timelinePassedClientVote = useStoreState(DslStore, getTimelinePassedClientVote);
  const contestGuidelines = useStoreState(DslStore, getContestGuidelines);
  const contestAwards = useStoreState(DslStore, getContestAwards);

  const contest = useStoreState(DslStore, getCurrentContest);
  const cocreationstats = useStoreState(DslStore, getCoCreationContestStats);
  const [revisionLength, setRevisionLength] = useState(0);
  const userlogged = useStoreState(DslStore, getUserLogged);
  const { nameForUrl } = useParams();
  const [revisionOpen, setRevisionOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [revisionError, setRevisionError] = useState(false);
  const [responseRevision, setResponseRevision] = useState();
  const redirect = useNavigate();
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const [liked, setLiked] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState(false);
  const [popUpLogin, setPopUpLogin] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [ndaauth, setNdaAuth] = useState(true);
  const [accesscodeauth, setAccessCodeAuth] = useState(true);
  const [sideAnchored, setSideAnchored] = useState(false);
  const date = new Date().toISOString();
  const contestEndDate = useRef('');
  const endvotingdate = useRef('');
  const uploadActive = useRef(false);
  const [hiddenDate, setHiddenDate] = useState('');

  const [key, setKey] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RevisionUpload>();

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Brief');
    isLiked();
  }, []);

  // useEffect(() => {
  //   console.log(popup);
  // }, [popup]);

  useEffect(() => {
    // conta();
    // console.log(contest);

    contest &&
      contest.contestType !== 'Co-creation' &&
      setTimelines(contest.startDate, contest.endDate, contest.endVotingDate, contest.hypotheticalEndDate, contest);
    // console.log(allCocreationContests);
  }, []);

  let tlUCC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let tlUC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let minitlUCC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };
  let minitlUC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  const hidden = (startDate: string, endDate?: string) => {
    if (endDate && startDate && !contest?.isPrivate && !contest?.hideGalleryTab) {
      const date = new Date().getTime();
      const startdateTime = new Date(startDate).getTime();
      const endDateTime = new Date(endDate).getTime();

      if (Math.floor((endDateTime - startdateTime) / 2 / 86400000) > Math.floor((date - startdateTime) / 86400000)) {
        let ciccio = startdateTime + Math.floor((endDateTime - startdateTime) / 2);
        // console.log(ciccio);
        // console.log(new Date(ciccio).toUTCString());
        setHiddenDate(new Date(ciccio).toUTCString());
      }
    }
  };

  const openEngFile = () => {
    const element = document.createElement('a');
    element.href = `${contest?.fileAttachment.replace(
      'http://s3-eu-west-1.amazonaws.com:80/',
      'https://s3-eu-west-1.amazonaws.com/'
    )}`;
    element.target = '_blank';
    element.click();
  };

  const openItaFile = () => {
    const element = document.createElement('a');
    element.href = `${contest?.fileAttachmentIta.replace(
      'http://s3-eu-west-1.amazonaws.com:80/',
      'https://s3-eu-west-1.amazonaws.com/'
    )}`;
    element.target = '_blank';
    element.click();
  };

  const openBriefFile = (path: string) => {
    const element = document.createElement('a');
    element.href = `${fullPathLogo(path)}`;
    element.target = '_blank';
    element.click();
  };

  const downloadKit = () => {
    const element = document.createElement('a');
    element.href = `${contest?.kitAttachment.replace(
      'http://s3-eu-west-1.amazonaws.com:80/',
      'https://s3-eu-west-1.amazonaws.com/'
    )}`;
    element.download = `${contest?.kitAttachment.replace(
      'http://s3-eu-west-1.amazonaws.com:80/',
      'https://s3-eu-west-1.amazonaws.com/'
    )}`;
    element.target = '_blank';
    element.click();
  };

  const handleRevisionLength = (e: any) => {
    let revision = e.target.value;
    setRevisionLength(revision.length);
  };

  const publishRevision = async (revision: string) => {
    if (nameForUrl) {
      console.log('publish');
      if (loggedUser?.id === contest?.userId) {
        // console.log('ciao');
        if (revision.length > 0) {
          console.log(contest?.revision1, contest?.revision2);
          if (contest?.revision1 === null) {
            console.log('revision1');
            await axiosHelper
              .revision1Contest(revision, contest?.id)
              ?.then((response) => {
                fetchContestByName(nameForUrl);
                console.log(response);
              })
              .catch((error) => {
                setResponseRevision(error.response.status);
              });
          } else if (contest?.revision2 === null) {
            console.log('revision2');
            await axiosHelper
              .revision2Contest(revision, contest?.id)
              .then((response) => {
                fetchContestByName(nameForUrl);
                console.log(response);
              })
              .catch((error) => {
                setResponseRevision(error.response.status);
              });
          }
        } else {
          console.log('no revision');
        }
      } else {
        console.log('no user');
      }
    }
  };

  const coCreationContestStats = () => {
    let stats: CoCreationContestStats = {
      entries: 0,
      votes: 0,
      inspirations: contest?.inspirationCount ? contest.inspirationCount : 0,
      awards: 0,
    };
    cocreationContests?.map(
      (item: CoCreationContest) => (
        // console.log(item.sumVotesPublicDesigns),
        item.publicDesignsOrderedCount ? (stats.entries = stats.entries + item.publicDesignsOrderedCount) : null,
        item.realTotalAwards ? (stats.awards = stats.awards + item.realTotalAwards) : null,
        item.sumVotesPublicDesigns ? (stats.votes = stats.votes + item.sumVotesPublicDesigns) : null
      )
    );
    setCoCreationContestStats(stats);
  };

  const checkRevision = (revision: string) => {
    console.log(revision.length);
    if (revisionLength > 1000) {
      setRevisionError(true);
    } else {
      publishRevision(revision);
    }
  };

  const onSubmit = (data: any) => {
    console.log(data.revision);
    checkRevision(data.revision);
  };

  const goToDetail = async (userid: number) => {
    console.log(userid);
    try {
      let result: User = await axiosHelper.retrieveUserById(userid);
      console.log(result);
      setCommunityUser(result);
      if (communityUser) {
        redirect(`/community/${result.nameForURL}/profile`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setTags = async (contestId: number) => {
    let contestTags = await axiosHelper.getContestTags(contestId);
    if (contestTags) {
      console.log(contestTags);
      setContestTags(contestTags.tags);
    }
  };

  const fetchContestByName = (contestName: string) => {
    // console.log('BY NAME');
    if (contestName) {
      axiosHelper
        .retrieveContestByName(contestName)
        .then((response) => {
          // console.log(response);
          setCurrentContest(response.data);
          setTags(response.data.tags);
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const sendAccessCode = async () => {
    if (loggedUser) {
      if (contest?.id) {
        if (contest.accessCode) {
          await axiosHelper
            .verifyAccessCode(contest.id, accessCode)
            .then((response) => {
              if (response.data) {
                setCurrentContest(response.data);
              }
              if (response.status === 200) {
                setAccessCodeAuth(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } else {
      setPopUpLogin(true);
    }
  };

  const contestrevision = () => {
    console.log(contest?.revision1, contest?.revision2);
    if (contest?.revision2 !== null) {
      return false;
    } else if (contest?.revision1 !== null) {
      return true;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // console.log(nameForUrl);
    nameForUrl && nameForUrl !== contest?.nameForUrl && fetchContestByName(nameForUrl);
  }, [nameForUrl]);

  useEffect(() => {
    if (contest?.contestType === 'Co-creation') {
      getCoCreationContestDetails(contest.id);
    }
    if (contest?.accessCode) {
      getAuthAccessCode();
    }
    if (contest?.nda) {
      getAuthNda();
    }
    if (contest?.endDate && contest.startDate) {
      hidden(contest.startDate, contest.endDate);
    }
    setKey(key + 1);
  }, [contest]);

  useEffect(() => {
    coCreationContestStats();
  }, cocreationContests);

  const getAuthNda = async () => {
    setNdaAuth(false);
    await axiosHelper
      .getContestNdaAuth(contest?.id)
      ?.then((response) => {
        setNdaAuth(true);
        props.ndaauth && props.ndaauth(true);
      })
      .catch((error) => {
        setNdaAuth(false);
        props.ndaauth && props.ndaauth(false);
      });
  };

  const getAuthAccessCode = async () => {
    setAccessCodeAuth(false);
    await axiosHelper
      .getContestAccessCodeAuth(contest?.id)
      ?.then((response) => {
        setAccessCodeAuth(true);
        props.accesscodeauth && props.accesscodeauth(true);
      })
      .catch((error) => {
        setAccessCodeAuth(false);
        props.accesscodeauth && props.accesscodeauth(false);
      });
  };

  const getCoCreationContestDetails = async (contestId: number) => {
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);

      // cocreationFill();
    }
  };

  // const cocreationFill = () => {
  //   let cocreationDesigns: GalleryDesign[] = [];
  //   cocreationContests?.map(
  //     (item: GalleryDesign, index: number) => (
  //       console.log(item.publicDesignsOrdered),
  //       item.publicDesignsOrdered.map(
  //         (item: GalleryDesign, index: number) => (console.log(item), cocreationDesigns.push(item))
  //       )
  //     )
  //   );
  //   cocreationDesigns.length > 0 && setCoCreationContestGallery(cocreationDesigns);
  // };

  const galleryTab = () => {
    console.log(loggedUser);
    console.log(loggedUser?.userType);

    if (contest) {
      console.log(contest.hideGalleryTab);
      let god: boolean = contest.hideGalleryTab;
      if (loggedUser?.userType === 'G') {
        god = false;
        // return god;
      }
      return god;
    } else {
      return false;
    }
  };

  const contestUnliked = () => {
    if (contest?.id) {
      axiosHelper.unlikeContest(contest?.id).then((response) => {
        setLiked(false);
        // alert(`${props.title} removed from favorites`);
      });
    }
  };
  const contestLiked = () => {
    if (contest?.id) {
      axiosHelper.likeContest(contest?.id).then((response) => {
        setLiked(true);
        if (loggedUser?.username === response.data.username) {
          setContestsILike(response.data.contestsILike);
        }
        // alert(`${props.title} added to favorites`);
      });
    }
  };
  const isLiked = () => {
    // console.log(props.contestId);
    let like: boolean;

    if (!loggedUser) {
      like = false;
      setLiked(like);
    }

    if (loggedUser?.contestsILike) {
      like = loggedUser.contestsILike.some((item: any) => item.id === contest?.id);

      setLiked(like);
    }
  };

  const getCorrectEndDate = () => {
    // console.log('correctDate');

    const date = new Date().toISOString();

    // console.log('GET CORRECT END DATE : ');
    if (contest) {
      if (contest?.contestType === 'Co-creation') {
        let contestAttached: CoCreationContest[] = [];
        // console.log('GET CORRECT END DATE : CO-CREATION');
        allCocreationContests.map((item) => {
          if (contest?.id === item.mainContest.id) {
            contestAttached.push(item);
          }
        });
        // console.log('GET CORRECT END DATE : contestattached fatto' + JSON.stringify(contestAttached));
        contestAttached.map((item) => {
          console.log(item);
          if (item.endVotingDate) {
            endvotingdate.current = item.endVotingDate;
          }
          if (!item.isClosed) {
            if (date > item.startUploadDate && date < item.endUploadDate) {
              // console.log('CO-CREATION UPLOAD');
              contestEndDate.current = `Ends: ${registrationDateString(item.endUploadDate)}`;
              uploadActive.current = true;
              return;
            } else if (item.endVotingDate) {
              if (date > item.endUploadDate && date < item.endVotingDate) {
                // console.log('CO-CREATION COMMUNITY');
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
              } else if (date > item.endUploadDate && date > item.endVotingDate) {
                // console.log('CO-CREATION CLIENT');
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
              }
            } else if (date > item.endUploadDate) {
              // console.log('CO-CREATION CLIENT VOTE');
              contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
            }
          } else {
            // console.log('CO-CREATION CLOSED');
            contestEndDate.current = `Closed: ${registrationDateString(item.endDate)}`;
          }
        });
      } else {
        if (contest.endVotingDate) {
          endvotingdate.current = contest.endVotingDate;
        }
        // console.log('GET CORRECT END DATE : NORMAL CONTEST');
        if (date < contest.endDate && !contest.isClosed) {
          // console.log('GET CORRECT END DATE : UPLOAD PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.endDate)}`;
          uploadActive.current = true;
          return;
        } else if (contest.endVotingDate && date < contest.endVotingDate && !contest.isClosed) {
          // console.log('GET CORRECT END DATE : COMMUNITY VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.endDate)}`;
          return;
        } else if (
          (contest.endVotingDate && date > contest.endVotingDate && !contest.isClosed) ||
          (date > contest.endDate && !contest.isClosed)
        ) {
          // console.log('GET CORRECT END DATE : CLIENT VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(contest.hypotheticalEndDate)}`;
          return;
        } else {
          // console.log('GET CORRECT END DATE : CLOSED PHASE');
          contestEndDate.current = `CLOSED ${registrationDateString(contest.endDate)}`;
          return;
        }
      }
      // return contest?.endDate;
    }
  };

  useEffect(() => {
    // console.log('cambiati cocreation contests');
    getCorrectEndDate();
  }, [allCocreationContests, contest?.name]);

  const isUpperCase = (string: string) => /^[AZ]*$/.test(string);

  return (
    <>
      <head>
        <base target="_blank" />
      </head>
      <div className="bg-light-gray  px-0">
        <main className={`container pt-5 mb-5 px-sm-0 overflow-hidden`}>
          {/* Body ====================================================================== */}
          <div className="row ">
            <div className={`col-12 col-md-3  px-sm-3 position-relative  pe-md-4 ${styles.sideContainer}`}>
              <SideActionsCard anchored={(anchored) => setSideAnchored(anchored)}>
                <button
                  className={`${liked ? 'btn-danger' : 'btn-blue'} rounded full mb-3 `}
                  onClick={() => (loggedUser ? (liked ? contestUnliked() : contestLiked()) : setPopUpLogin(true))}
                >
                  {liked ? 'Remove from favorites' : 'Add to favorites'}
                </button>
                <button
                  className="btn-blue rounded full mb-3"
                  onClick={() => (loggedUser ? setPopUpMessage(true) : setPopUpLogin(true), console.log(popUpMessage))}
                >
                  Have a question?
                </button>
                {popUpMessage && (
                  <PopUpSendMessage
                    message={(message) => (props.messageBanner(message), setPopUpMessage(false))}
                    popup={popUpMessage}
                    close={() => setPopUpMessage(false)}
                    contestId={contest?.id}
                    recipient={'Desall'}
                  />
                )}
                {popUpLogin && (
                  <PopUpLogin
                    popup={popUpLogin}
                    close={() => setPopUpLogin(false)}
                    login={(e: boolean) => setPopUpLogin(false)}
                  />
                )}
                {contest && (
                  <MiniTimeLine
                    progress={endvotingdate.current !== '' ? minitlUCC : minitlUC}
                    endDate={contestEndDate.current}
                    contest={contest}
                    cocreationcontest={allCocreationContests}
                  />
                )}
                {!contest?.isClosed &&
                contest &&
                uploadActive.current &&
                loggedUser &&
                loggedUser?.userType === 'C' &&
                loggedUser.id === contest?.userId ? (
                  <button
                    className="btn-success big full rounded"
                    style={{ height: '45px' }}
                    onClick={() =>
                      !loggedUser ? setPopup(true) : redirect(`/contest/${contest?.nameForUrl}/upload-contest`)
                    }
                  >
                    <div className="btn-icon  ">
                      <FontAwesomeIcon
                        icon={faCloudArrowUp as IconProp}
                        style={{ fontSize: '3rem' }}
                        className="text color-light "
                      />
                    </div>
                    <div>Upload </div>
                  </button>
                ) : (
                  <button
                    className="btn-dark_grey_90 big full mb-4 rounded "
                    style={{ height: '45px', cursor: 'default' }}
                    disabled={true}
                  >
                    <div className="btn-icon " style={{ cursor: 'default' }}>
                      <FontAwesomeIcon
                        icon={faCloudArrowUp as IconProp}
                        style={{ fontSize: '3rem', cursor: 'default' }}
                        className="text color-light"
                      />
                    </div>
                    <div>Upload</div>
                  </button>
                )}
              </SideActionsCard>
              {sideAnchored && <div className=" d-none d-lg-block bg-light-gray" style={{ height: '410px' }}></div>}
              <div className="tags mt-7 d-flex flex-wrap ">
                {contestTag &&
                  contestTag.map((item: ContestTag, index: number) => (
                    // console.log(item),
                    <>
                      <button className="btn-tag mb-4 px-0 me-5 " key={index}>
                        <span>{item.name.replace(',', '')}</span>
                      </button>
                    </>
                  ))}

                {/* <button className="btn-tag mb-4 me-4">
                <span>Even more Looooong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
              </div>

              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://desall.com/contest/${contest?.nameForUrl}/brief`}
                  target="_blank"
                >
                  <button className="btn-social__facebook full rounded mb-3 ">
                    <div className="btn-icon">
                      <FontAwesomeIcon icon={faFacebookF as IconProp} className="text color-light" />
                    </div>
                    <span className="text-lowercase">like</span>
                  </button>
                </a>
              </div>
              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`http://twitter.com/share?hashtags=desallcom&text=${contest?.name} by ${contest?.userName}. Check it out on Desall.&url=https://desall.com/contest/${contest?.nameForUrl}/brief&via=desallcom`}
                  target="_blank"
                >
                  <button className="btn-social__twitter full rounded mb-3">
                    <div className="btn-icon">
                      <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                      {/* <img src={xLogo} className="" style={{ height: '15px', width: '15px' }} /> */}
                    </div>
                    <span className="text-lowercase">post</span>
                  </button>
                </a>
              </div>
              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`http://pinterest.com/pin/create/button/?url=beta.altera.it/contest/${contest?.name}/brief&media=${contest?.image}&description=Check it out on Desall.`}
                  target="_blank"
                >
                  <button className="btn-social__pinterest full rounded mb-3">
                    <div className="btn-icon">
                      <FontAwesomeIcon icon={faPinterest as IconProp} className="text color-light" />
                    </div>
                    <span className="text-lowercase">save</span>
                  </button>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-9 pt-5 px-md-3  px-sm-4 ">
              {contest?.contestType !== 'Co-creation' && (
                <div className="row">
                  <div className="col-12 px-3">
                    <div className="row mb-5">
                      <div className="col-12 col-md-3 text-16 fw-bold mb-3">Contest typology</div>
                      <div className="col-12 col-md-9">
                        <span className="text-16">
                          <span
                            className="color-blue me-2 cursor-pointer"
                            onClick={() => redirect('/For_Creatives/How-to-participate')}
                          >
                            Single phase
                          </span>
                          {contest?.category && <> - {finalString(contest?.category)} contest</>}
                          {/* <a
                          href="https://beta.altera.it/For_Creatives/How-to-participate"
                          target="_blank"
                          rel="noreferrer"
                        >
                          What’s this?
                        </a> */}
                        </span>
                      </div>
                    </div>

                    {loggedUser &&
                    ndaauth &&
                    accesscodeauth &&
                    (contest?.fileAttachment || contest?.kitAttachment || contest?.fileAttachmentIta) ? (
                      <div className="row mb-5">
                        <div className="col-12  justify-content-center col-md-3 text-16 align-middle fw-bold mb-3">
                          Contest materials
                        </div>
                        {contest?.fileAttachmentIta ? (
                          <div className="col-12 col-md-3 ">
                            <button className="btn-dark-blue-materials full mb-4 rounded" onClick={openItaFile}>
                              <div className="btn-icon">
                                <FontAwesomeIcon
                                  icon={faFileArrowDown as IconProp}
                                  style={{ fontSize: '1.75rem' }}
                                  className="text color-light"
                                />
                              </div>
                              <div>BRIEF ITA (.pdf)</div>
                            </button>
                          </div>
                        ) : null}
                        {contest?.fileAttachment ? (
                          <div className="col-12 col-md-3">
                            <button
                              className="btn-dark-blue-materials  full mb-4 rounded "
                              onClick={openEngFile}
                              disabled={contest?.fileAttachment ? false : true}
                            >
                              <div className="btn-icon">
                                <FontAwesomeIcon
                                  icon={faFileArrowDown as IconProp}
                                  style={{ fontSize: '1.75rem' }}
                                  className="text color-light"
                                />
                              </div>
                              <div>BRIEF ENG (.pdf)</div>
                            </button>
                          </div>
                        ) : null}
                        {contest?.kitAttachment ? (
                          <div className="col-12 col-md-3">
                            <button
                              className="btn-dark-blue-materials  full mb-4 rounded "
                              onClick={downloadKit}
                              disabled={contest?.kitAttachment ? false : true}
                            >
                              <div className="btn-icon">
                                <FontAwesomeIcon
                                  icon={faFileArrowDown as IconProp}
                                  style={{ fontSize: '1.75rem' }}
                                  className="text color-light"
                                />
                              </div>
                              <div>MATERIAL FILES (.zip)</div>
                            </button>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    <div className="row mb-5">
                      <div className="col-12 col-md-3 text-16 fw-bold mb-3">Summary</div>
                      <div className="col-12 col-md-9  ">
                        {/* <p>{contest?.textDescription}</p>  */}
                        <ReactMarkdown
                          className="text-16"
                          children={finalString(contest?.textDescription)}
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeFilter]}
                        />
                      </div>
                    </div>

                    {contest?.brief && loggedUser && (
                      <div className="row mb-5">
                        <div className="col-12 col-md-3 text-16 fw-bold mb-3">What we need</div>
                        <div className="col-12 col-md-9">
                          {/* <p>{contest?.textDescription}</p>  */}
                          <ReactMarkdown
                            className="text-16"
                            children={finalString(contest?.brief)}
                            remarkPlugins={[remarkGfm]}
                          />
                        </div>
                      </div>
                    )}

                    {contest?.userDescr && (
                      <div className="row mb-5">
                        <div className="col-12 col-md-3 text-16 fw-bold mb-3">Company description</div>
                        <div className="col-12 col-md-9">
                          <ReactMarkdown
                            className="text-16"
                            children={finalString(contest?.userDescr)}
                            remarkPlugins={[remarkGfm]}
                          />
                          {/* <p className="text-16">{contest?.userDescr}</p> */}
                        </div>
                      </div>
                    )}
                    {contest?.nda && !ndaauth && loggedUser && loggedUser.userType !== 'G' && (
                      <NDAForm auth={(auth) => setNdaAuth(auth)} key={key} />
                    )}

                    {userlogged ? (
                      <>
                        {contestGuidelines.map((item: ContestGuideline) => (
                          <div className="row mb-5">
                            <div className="col-12 col-md-3 text-16 fw-bold mb-3">{item.guideline.name}</div>
                            <div className="col-12 col-md-9">
                              <ReactMarkdown
                                className="text-16"
                                children={finalString(item.textDescription)}
                                remarkPlugins={[remarkGfm]}
                              />
                            </div>
                          </div>
                        ))}
                        {contest?.customName && contest.customDescription && ndaauth && accesscodeauth && (
                          <div className="row mb-5">
                            <div className="col-12 col-md-3 text-16 fw-bold mb-3">{contest.customName}</div>
                            <div className="col-12 col-md-9">
                              <ReactMarkdown
                                className="text-16"
                                children={finalString(contest.customDescription)}
                                remarkPlugins={[remarkGfm]}
                              />
                            </div>
                          </div>
                        )}
                        {ndaauth && accesscodeauth && (
                          <>
                            <div className="row  mb-5">
                              <div className=" col-12  col-md-3 text-16 fw-bold mb-3">Awards</div>
                              <div className="col-12  col-md-9  d-flex flex-column flex-wrap flex-lg-row align-items-center justify-content-between ">
                                {contestAwards.map((item) => (
                                  <div
                                    className=" col-12 col-lg-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-dark-cyan  "
                                    style={{ height: '45px', borderRadius: '8px', maxWidth: '315px' }}
                                  >
                                    <div className="col-2 h-100  d-flex flex-row align-items-center justify-content-center">
                                      <span className="text color-light ">
                                        <FontAwesomeIcon
                                          style={{ fontSize: '2rem' }}
                                          icon={faMedal as IconProp}
                                          color="#ffffff"
                                          className="text"
                                        />
                                      </span>
                                    </div>
                                    <div className="col-4 h-100  d-flex flex-row align-items-center justify-content-end">
                                      <span className="text-16 fw-bold color-light ">
                                        {item.name ? item.name : item.pos}
                                        {!item.name && item.pos === 1
                                          ? 'st award'
                                          : item.pos === 2
                                          ? 'nd award'
                                          : item.pos === 3
                                          ? 'rd award'
                                          : ''}
                                      </span>
                                    </div>
                                    <div className="col-6 h-100  d-flex flex-row align-items-center justify-content-center">
                                      <span className="sub-title  color-light ">
                                        {item.price !== 0 ? `€${item.price}` : null}
                                        {`${item.hasRoyalties ? (item.price !== 0 ? ' + R' : 'ROYALTIES') : ''}`}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="row  mb-5">
                              <div className=" col-12  col-md-3 text-16 fw-bold mb-3">Key dates</div>

                              <div className="col-12  col-md-9  d-flex flex-column flex-wrap flex-lg-row align-items-center justify-content-between ">
                                {contest?.endDate && (
                                  <div
                                    className=" col-12 col-lg-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                    style={{ height: '120px', borderRadius: '8px', maxWidth: '315px' }}
                                  >
                                    <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                      <span className="text color-light ">
                                        <FontAwesomeIcon
                                          style={{ fontSize: '2rem' }}
                                          icon={faCalendarXmark as IconProp}
                                          color="#ffffff"
                                          className="text"
                                        />
                                      </span>
                                    </div>
                                    <div className="col-10 h-100  d-flex flex-column align-items-start justify-content-center">
                                      <div className=" h-25 mb-3  w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="text h-100 fw-bold color-light  pt-2 text-center d-flex align-items-center">
                                          Submission <br />
                                          deadline
                                        </span>
                                      </div>
                                      <div className=" h-50 w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="sub-title fw-bold color-light text-center ">
                                          {quadrettoDateString(contest?.endDate)}
                                          <br />
                                          {/* {new Date(contest?.endDate).toLocaleTimeString()} */}
                                          {quadrettoDateHours(contest.endDate)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {contest?.endDate && hiddenDate !== '' && (
                                  <div
                                    className=" col-12 col-lg-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                    style={{ height: '120px', borderRadius: '8px', maxWidth: '315px' }}
                                  >
                                    <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                      <span className="text color-light ">
                                        <FontAwesomeIcon
                                          style={{ fontSize: '2rem' }}
                                          icon={faCalendarXmark as IconProp}
                                          color="#ffffff"
                                          className="text"
                                        />
                                      </span>
                                    </div>
                                    <div className="col-10 h-100  d-flex flex-column align-items-center justify-content-center">
                                      <div className=" h-25 mb-3 w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="text h-100 fw-bold color-light  pt-2 text-center d-flex align-items-center">
                                          Hidden option <br />
                                          deadline
                                        </span>
                                      </div>
                                      <div className=" h-50 w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="sub-title fw-bold color-light text-center ">
                                          {quadrettoDateString(hiddenDate)}
                                          <br />
                                          {/* {new Date(contest?.endDate).toLocaleTimeString()} */}
                                          {quadrettoDateHours(hiddenDate)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {contest?.conceptDeadlineDate && (
                                  <div
                                    className=" col-12 col-lg-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                    style={{ height: '120px', borderRadius: '8px', maxWidth: '315px' }}
                                  >
                                    <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                      <span className="text color-light ">
                                        <FontAwesomeIcon
                                          style={{ fontSize: '2rem' }}
                                          icon={faCalendarXmark as IconProp}
                                          color="#ffffff"
                                          className="text"
                                        />
                                      </span>
                                    </div>
                                    <div className="col-10 h-100  d-flex flex-column align-items-center justify-content-center">
                                      <div className=" h-25 mb-3 w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="text h-100 fw-bold color-light  pt-2 text-center h-25 mb-3">
                                          Concept review <br />
                                          deadline
                                        </span>
                                      </div>
                                      <div className=" h-50 w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="sub-title fw-bold color-light text-center">
                                          {quadrettoDateString(contest?.conceptDeadlineDate)}
                                          <br />
                                          {/* {new Date(contest?.conceptDeadlineDate).toLocaleTimeString()} */}
                                          {quadrettoDateHours(contest.conceptDeadlineDate)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {contest?.endVotingDate && (
                                  <div
                                    className=" col-12 col-lg-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                    style={{ height: '120px', borderRadius: '8px', maxWidth: '315px' }}
                                  >
                                    <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                      <span className="text color-light ">
                                        <FontAwesomeIcon
                                          style={{ fontSize: '2rem' }}
                                          icon={faCalendarXmark as IconProp}
                                          color="#ffffff"
                                          className="text"
                                        />
                                      </span>
                                    </div>
                                    <div className="col-10 h-100  d-flex flex-column align-items-center justify-content-center">
                                      <div className=" h-25 mb-3 w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="text h-100 fw-bold color-light  pt-2 text-center d-flex align-items-center">
                                          Community vote <br />
                                          deadline
                                        </span>
                                      </div>
                                      <div className=" h-50 w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="sub-title fw-bold color-light text-center">
                                          {quadrettoDateString(contest?.endVotingDate)}
                                          <br />
                                          {/* {new Date(contest?.endVotingDate).toLocaleTimeString()} */}
                                          {quadrettoDateHours(contest.endVotingDate)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {contest?.hypotheticalEndDate && (
                                  <div
                                    className=" col-12 col-lg-6 mb-4   d-flex flex-row align-items-center justify-content-center  bg-blue  "
                                    style={{ height: '120px', borderRadius: '8px', maxWidth: '315px' }}
                                  >
                                    <div className="col-1  h-100  d-flex flex-row align-items-start pt-3 justify-content-center">
                                      <span className="text color-light ">
                                        <FontAwesomeIcon
                                          style={{ fontSize: '2rem' }}
                                          icon={faCalendarXmark as IconProp}
                                          color="#ffffff"
                                          className="text"
                                        />
                                      </span>
                                    </div>
                                    <div className="col-10 h-100  d-flex flex-column align-items-center justify-content-center">
                                      <div className="h-25  w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="text h-100 fw-bold color-light  pt-2 text-center d-flex align-items-center">
                                          Winner <br />
                                          announcment
                                        </span>
                                      </div>
                                      <div className=" h-75 w-100 d-flex flex-row align-items-center justify-content-center">
                                        <span className="text fw-bold color-light text-center ">
                                          approximately by
                                          <br />
                                          <span className="sub-title">
                                            {quadrettoDateString(contest?.hypotheticalEndDate)} &nbsp;
                                            {/* {new Date(contest?.hypotheticalEndDate).toLocaleTimeString()} */}
                                            {quadrettoDateHours(contest.hypotheticalEndDate)}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {/* <div className="row mb-5">
                        <div className="col-12 col-md-3 text-16 fw-bold mb-3">Guidelines</div>
                        <div className="col-12 col-md-9">
                          <ReactMarkdown
                            className="text-16"
                            children={finalString(contest?.contestGuideline)}
                            remarkPlugins={[remarkGfm]}
                          />
                        </div>
                      </div> */}

                        {contest?.revision1 && (
                          <div className="row mb-5">
                            <div className="col-12 col-md-3 text-16 fw-bold mb-3 d-flex">
                              Brief revision #1
                              <div
                                className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                              >
                                <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                              </div>
                            </div>
                            <div className="col-12 col-md-9">
                              <p className="text-16">{revisionDateString(contest.revision1Date)}</p>
                              <span>
                                <ReactMarkdown
                                  className="text-16"
                                  children={finalString(contest?.revision1)}
                                  remarkPlugins={[remarkGfm]}
                                />
                              </span>
                            </div>
                          </div>
                        )}

                        {contest?.revision2 && (
                          <>
                            <div className="row mb-5">
                              <div className="col-12 col-md-3 text-16 fw-bold mb-3 d-flex">
                                Brief revision #2{' '}
                                <div
                                  className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                                  style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                                >
                                  <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                                </div>
                              </div>
                              <div className="col-12 col-md-9">
                                <p className="text-16">{revisionDateString(contest.revision2Date)}</p>
                                <span>
                                  <ReactMarkdown
                                    className="text-16"
                                    children={finalString(contest?.revision2)}
                                    remarkPlugins={[remarkGfm]}
                                  />
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                        {loggedUser?.userType === 'C' && loggedUser.id === contest?.userId && contestrevision() && (
                          <>
                            <div className="row mb-5">
                              <div className="col-12 col-md-8"></div>
                              <div className="col-12 col-md-4 ">
                                <button
                                  className=" btn-dark_blue rounded full  align-items-center d-flex"
                                  onClick={() => setRevisionOpen(true)}
                                >
                                  <span className="me-5">add brief revision</span>
                                  <FontAwesomeIcon
                                    icon={faPlus as IconProp}
                                    style={{ fontSize: '2rem' }}
                                    className="text color-light"
                                  />
                                </button>
                              </div>
                            </div>
                            {revisionOpen && (
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-5">
                                  <div className="col-12 col-md-3 text-16 fw-bold mb-3">Brief revision</div>
                                  <div className="col-12 col-md-9">
                                    <div className="col-12 d-flex justify-content-between mb-4  ">
                                      <div className="d-flex align-items-center">
                                        <span className="text-16">You can add up to 2 revisions to your brief</span>
                                      </div>
                                      <div className="d-flex justify-content-center align-items-center ">
                                        <span className="fw-bold"> Remove</span>
                                        <div
                                          onClick={() => setRevisionOpen(false)}
                                          className={`ms-3 rounded d-flex align-items-center justify-content-center ${styles.removeRevision}`}
                                        >
                                          <FontAwesomeIcon icon={faRemove as IconProp} className="text color-light" />
                                        </div>
                                      </div>
                                    </div>
                                    <textarea
                                      placeholder="Please write here the revision"
                                      {...register('revision', {
                                        onChange: handleRevisionLength,
                                        required: false,
                                        maxLength: 1000,
                                      })}
                                    ></textarea>
                                    <div className="row">
                                      <div className="col-10">
                                        {errors.revision ||
                                          (revisionError && (
                                            <span className="text-xl color-danger">
                                              <b>You have exceeded the limit of 1000 characters</b>
                                            </span>
                                          ))}
                                      </div>
                                      <div className="col-2  d-flex justify-content-end">
                                        <span>{revisionLength}/1000</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-3 "></div>
                                  <div className="col-2 ">
                                    <div className="">
                                      <button className="btn-dark-blue full submit  rounded">publish</button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            )}
                          </>
                        )}
                        {ndaauth && accesscodeauth && (
                          <div className="row justify-content-center ">
                            <div className="col-10 col-md-4 ">
                              {contest &&
                              date < contest.endDate &&
                              loggedUser &&
                              loggedUser?.userType === 'C' &&
                              loggedUser.id === contest?.userId ? (
                                <button
                                  className="btn-success big full mb-4 rounded "
                                  style={{ height: '45px' }}
                                  onClick={() =>
                                    !loggedUser
                                      ? setPopup(true)
                                      : redirect(`/contest/${contest?.nameForUrl}/upload-contest`)
                                  }
                                >
                                  <div className="btn-icon ">
                                    <FontAwesomeIcon
                                      icon={faCloudArrowUp as IconProp}
                                      style={{ fontSize: '3rem' }}
                                      className="text color-light"
                                    />
                                  </div>
                                  <div>Upload </div>
                                </button>
                              ) : (
                                <button
                                  className="btn-dark_grey_90 big full mb-4 rounded "
                                  style={{ height: '45px', cursor: 'default' }}
                                  disabled={true}
                                >
                                  <div className="btn-icon " style={{ cursor: 'default' }}>
                                    <FontAwesomeIcon
                                      icon={faCloudArrowUp as IconProp}
                                      style={{ fontSize: '3rem', cursor: 'default' }}
                                      className="text color-light"
                                    />
                                  </div>
                                  <div>Upload</div>
                                </button>
                              )}
                              {contest && date > contest.endDate && (
                                <button
                                  className="btn-dark_grey_90 big full mb-4 rounded "
                                  style={{ height: '45px', cursor: 'default' }}
                                  disabled={true}
                                >
                                  <div className="btn-icon " style={{ cursor: 'default' }}>
                                    <FontAwesomeIcon
                                      icon={faCloudArrowUp as IconProp}
                                      style={{ fontSize: '3rem', cursor: 'default' }}
                                      className="text color-light"
                                    />
                                  </div>
                                  <div>Upload </div>
                                </button>
                              )}
                              <button
                                className={`${liked ? 'btn-danger' : 'btn-blue'} rounded full mb-3 `}
                                onClick={() =>
                                  loggedUser ? (liked ? contestUnliked() : contestLiked()) : setPopUpLogin(true)
                                }
                              >
                                {liked ? 'Remove from favorites' : 'Add to favorites'}
                              </button>
                              {!contest?.isClosed && (
                                <button
                                  className="btn-blue rounded full mb-3"
                                  onClick={() => (
                                    loggedUser ? setPopUpMessage(true) : setPopUpLogin(true), console.log(popUpMessage)
                                  )}
                                >
                                  Have a question?
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      !contest?.accessCode && (
                        <div className="row justify-content-center ">
                          <div className="col-10 col-md-4 ">
                            <button
                              className="btn-success big full mb-4 rounded "
                              style={{ height: '45px' }}
                              onClick={() => setPopup(!popup)}
                            >
                              <div className="btn-icon ">
                                <FontAwesomeIcon
                                  icon={faUserPlus as IconProp}
                                  style={{ fontSize: '2.5rem' }}
                                  className="text color-light"
                                />
                              </div>
                              <div>Join desall</div>
                            </button>
                          </div>
                        </div>
                      )
                    )}
                    {(contest?.nda || contest?.accessCode) && !loggedUser && (
                      <div className="row justify-content-center  ">
                        <div className="col-10 col-md-4 ">
                          <button
                            className="btn-success big full mb-4 rounded "
                            style={{ height: '45px' }}
                            onClick={() => setPopup(true)}
                          >
                            <div className="btn-icon ">
                              <FontAwesomeIcon
                                icon={faUserPlus as IconProp}
                                style={{ fontSize: '2.5rem' }}
                                className="text color-light"
                              />
                            </div>
                            <div>Join desall </div>
                          </button>
                        </div>
                      </div>
                    )}
                    {contest?.accessCode && !accesscodeauth && userlogged && loggedUser?.userType !== 'G' && (
                      <>
                        <div className="row justify-content-start "></div>
                        <div className="row justify-content-start ">
                          <div className="col-3 col-md-3  ">
                            <span className="text-16 fw-bold">Access code</span>
                          </div>
                          <div className="col-10 col-md-9 mb-2 ">
                            <span className="text d-flex flex-row align-items-center">
                              <div
                                className="bg-warning d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                              >
                                <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                              </div>
                              <span className="ms-3">
                                {userlogged ? (
                                  <span>
                                    You must provide a valid Access code in order to read the full brief and join the
                                    contest.
                                  </span>
                                ) : (
                                  <span>
                                    You must <a href="/login">login</a> (or <a href="/signup">signup</a>) and provide a
                                    valid Access code in order to read the full brief and join the contest.
                                  </span>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="col-3 col-md-3"></div>
                          <div className="col-7 col-md-7  ">
                            <input type="text" onChange={(e) => setAccessCode(e.target.value)} />
                          </div>
                          <div className="col-2 col-md-2  ">
                            <button className="btn-success  full mb-4 rounded " onClick={() => sendAccessCode()}>
                              save
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {contest?.contestType === 'Co-creation' && (
                <>
                  <div className="row">
                    <div className="col-12 px-2 ">
                      <div className="row mb-5">
                        <div className="col-12 col-md-3 text-16 fw-bold mb-3">Contest typology</div>
                        <div className="col-12 col-md-9">
                          <span className="text-16">
                            <span
                              className="color-blue me-2 cursor-pointer"
                              onClick={() => redirect('/For_Creatives/How-to-participate')}
                            >
                              Co-creation
                            </span>
                            contest
                            {/* <a
                            href="https://beta.altera.it/For_Creatives/How-to-participate"
                            target="_blank"
                            rel="noreferrer"
                          >
                            What’s this?
                          </a> */}
                          </span>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col-12 col-md-3 text-16 fw-bold mb-3">Company description</div>
                        <div className="col-12 col-md-9">
                          <span className="text-16">{contest?.userDescr}</span>
                        </div>
                      </div>

                      {cocreationContests?.map((item: CoCreationContest, index: number) => (
                        <CoCreationBrief contest={item} key={index} />
                      ))}
                    </div>

                    {contest?.nda && !contest.isClosed && loggedUser && <NDAForm auth={(auth) => setNdaAuth(auth)} />}
                    {userlogged ? (
                      <>
                        {/* <div className="row mb-5">
                        <div className="col-12 col-md-3 text fw-bold mb-3">Guidelines</div>
                        <div className="col-12 col-md-9">
                          <ReactMarkdown
                            children={finalString(contest?.contestGuideline)}
                            remarkPlugins={[remarkGfm]}
                          />
                        </div>
                      </div> */}

                        {contest?.revision1 && (
                          <div className="row mb-5">
                            <div className="col-12 col-md-3 text fw-bold mb-3 d-flex">
                              Brief revision #1
                              <div
                                className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                              >
                                <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                              </div>
                            </div>
                            <div className="col-12 col-md-9">
                              <p className="text">{revisionDateString(contest.revision1Date)}</p>
                              <span>
                                <ReactMarkdown children={finalString(contest?.revision1)} remarkPlugins={[remarkGfm]} />
                              </span>
                            </div>
                          </div>
                        )}

                        {contest?.revision2 && (
                          <>
                            <div className="row mb-5">
                              <div className="col-12 col-md-3 text fw-bold mb-3 d-flex">
                                Brief revision #2{' '}
                                <div
                                  className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                                  style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                                >
                                  <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                                </div>
                              </div>
                              <div className="col-12 col-md-9">
                                <p className="text">{revisionDateString(contest.revision2Date)}</p>
                                <span>
                                  <ReactMarkdown
                                    children={finalString(contest?.revision2)}
                                    remarkPlugins={[remarkGfm]}
                                  />
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                        {loggedUser?.userType === 'C' &&
                          loggedUser.id === contest?.userId &&
                          contest.contestType !== 'Co-creation' &&
                          contestrevision() && (
                            <>
                              <div className="row mb-5">
                                <div className="col-12 col-md-8"></div>
                                <div className="col-12 col-md-4 ">
                                  <button
                                    className=" btn-dark_blue rounded full  align-items-center d-flex"
                                    onClick={() => setRevisionOpen(true)}
                                  >
                                    <span className="me-5">add brief revision</span>
                                    <FontAwesomeIcon
                                      icon={faPlus as IconProp}
                                      style={{ fontSize: '2rem' }}
                                      className="text color-light"
                                    />
                                  </button>
                                </div>
                              </div>
                              {revisionOpen && (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="row mb-5">
                                    <div className="col-12 col-md-3 text fw-bold mb-3">Brief revision</div>
                                    <div className="col-12 col-md-9">
                                      <div className="col-12 d-flex justify-content-between mb-4  ">
                                        <div className="d-flex align-items-center">
                                          <span className="text">You can add up to 2 revisions to your brief</span>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center ">
                                          <span className="fw-bold"> Remove</span>
                                          <div
                                            onClick={() => setRevisionOpen(false)}
                                            className={`ms-3 rounded d-flex align-items-center justify-content-center ${styles.removeRevision}`}
                                          >
                                            <FontAwesomeIcon icon={faRemove as IconProp} className="text color-light" />
                                          </div>
                                        </div>
                                      </div>
                                      <textarea
                                        placeholder="Please write here the revision"
                                        {...register('revision', {
                                          onChange: handleRevisionLength,
                                          required: false,
                                          maxLength: 1000,
                                        })}
                                      ></textarea>
                                      <div className="row">
                                        <div className="col-10">
                                          {errors.revision ||
                                            (revisionError && (
                                              <span className="text-xl color-danger">
                                                <b>You have exceeded the limit of 1000 characters</b>
                                              </span>
                                            ))}
                                        </div>
                                        <div className="col-2  d-flex justify-content-end">
                                          <span>{revisionLength}/1000</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-3 "></div>
                                    <div className="col-2 ">
                                      <div className="">
                                        <button className="btn-dark-blue full submit  rounded">publish</button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              )}
                            </>
                          )}
                        {loggedUser?.userType === 'D' && !ndaauth && (
                          <div className="row justify-content-center ">
                            <div className="col-10 col-md-4 ">
                              {contest && date < contest.endDate && (
                                <button
                                  className="btn-success big full mb-4 rounded "
                                  style={{ height: '45px' }}
                                  onClick={() =>
                                    !loggedUser
                                      ? setPopup(true)
                                      : redirect(`/contest/${contest?.nameForUrl}/upload-contest`)
                                  }
                                >
                                  <div className="btn-icon ">
                                    <FontAwesomeIcon
                                      icon={faCloudArrowUp as IconProp}
                                      style={{ fontSize: '3rem' }}
                                      className="text color-light"
                                    />
                                  </div>
                                  <div>Upload </div>
                                </button>
                              )}
                              {contest && date > contest.endDate && (
                                <button
                                  className="btn-dark_grey_90 big full mb-4 rounded "
                                  style={{ height: '45px', cursor: 'default' }}
                                  disabled={true}
                                >
                                  <div className="btn-icon " style={{ cursor: 'default' }}>
                                    <FontAwesomeIcon
                                      icon={faCloudArrowUp as IconProp}
                                      style={{ fontSize: '3rem', cursor: 'default' }}
                                      className="text color-light"
                                    />
                                  </div>
                                  <div>Upload </div>
                                </button>
                              )}
                              <button
                                className={`${liked ? 'btn-danger' : 'btn-blue'} rounded full mb-4 `}
                                onClick={() => (liked ? contestUnliked() : contestLiked())}
                              >
                                {liked ? 'Remove from favorites' : 'Add to favorites'}
                              </button>
                              {/* <button className="btn-blue rounded full mb-4">Add to favorite</button> */}
                              {!contest?.isClosed && (
                                <button
                                  className="btn-blue rounded full mb-3"
                                  onClick={() => (
                                    loggedUser ? setPopUpMessage(true) : setPopUpLogin(true), console.log(popUpMessage)
                                  )}
                                >
                                  Have a question?
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      contest.accessCode && (
                        <div className="row justify-content-center  ">
                          <div className="col-10 col-md-4 ">
                            <button
                              className="btn-success big full mb-4 rounded "
                              style={{ height: '45px' }}
                              onClick={() => setPopup(true)}
                            >
                              <div className="btn-icon ">
                                <FontAwesomeIcon
                                  icon={faUserPlus as IconProp}
                                  style={{ fontSize: '2.5rem' }}
                                  className="text color-light"
                                />
                              </div>
                              <div>Join desall </div>
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
        {!loggedUser && <PopUpLogin popup={popup} close={() => setPopup(false)} login={(e: boolean) => setPopup(e)} />}
        {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
      </div>
    </>
  );
};

export default Brief;
