import React, { useEffect, useState } from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import DesignerCard from '../components/Community/DesignerCard/DesignerCard';
import DesignerRow from '../components/Community/DesignerRow/DesignerRow';
import { useStoreState } from 'pullstate';
import DslStore, {
  setLayoutCommunity,
  setCommunityPageNumber,
  setCommunity,
  setCommunityCopy,
} from '../store/DslStore';
import {
  getLayoutCommunity,
  getCommunityPageNumber,
  getUsers,
  getFooterMenu,
  getHeaderMenu,
  getCurrentUser,
  getUsersCopy,
} from '../store/Selectors';
import { User } from '../global';
import {
  fullPathLogoCommunity,
  jobString,
  locationString,
  numberVotes,
  registrationDateString,
  userTypeString,
} from '../helpers/utils';
import CommunityNavigation, {
  CommunityPaginationSwitch,
} from '../components/Community/ProjectsNavigation/CommunityNavigation';
import profilePlaceholder from '../assets/Profile.png';
import axiosHelper from '../helpers/axiosHelper';
import Skeleton from '@mui/material/Skeleton';

const mockGalleryImages = [
  'https://source.unsplash.com/_HqHX3LBN18/800x680',
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/RFP4D5hGTB0/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const mockGalleryImagesHalf = [
  'https://source.unsplash.com/OzqieLcs464/800x680',
  'https://source.unsplash.com/rvftn4hX7AY/800x680',
  'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
];

const CommunitySearch: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const allUsers = useStoreState(DslStore, getUsers);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const layoutCommunity = useStoreState(DslStore, getLayoutCommunity);
  const pageNumber = useStoreState(DslStore, getCommunityPageNumber);
  const communityCopy = useStoreState(DslStore, getUsersCopy);
  const userPerPage = 20;
  const [pageNumberLimit, setPageNumberLimit] = useState(Math.ceil(allUsers.length / userPerPage));

  const [visibleUsers, setVisibleUsers] = useState<User[]>([]);

  const arraysfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: User[] = [];
    const chunk: User[] = allUsers.slice(startchunk, startchunk + chunkSize);

    for (let i = 0; i < allUsers.length; i += chunkSize) {
      const chunk: User[] = allUsers.slice(startchunk, startchunk + chunkSize);
      // console.log('chunk');
      // console.log(chunk);
    }
    for (let i = 0; i < chunkSize; i++) {
      if (chunk[i] != undefined) {
        tempArray.push(chunk[i]);
      } else {
        break;
      }
    }
    // console.log('array1:  ');
    // console.log(tempArray);
    setVisibleUsers(tempArray);
  };

  const fetchUsers = async () => {
    await axiosHelper.getUsers(1).then((response) => {
      setCommunity(response.data);
      setCommunityCopy(response.data);
    });
  };

  useEffect(() => {
    console.log('sono dentro community-search');
    console.log(communityCopy.length);
    if (communityCopy.length > 0) {
      setCommunity(communityCopy);
    } else {
      fetchUsers();
    }
    arraysfill();
    setCommunityPageNumber(1);
  }, []);

  useEffect(() => {
    setVisibleUsers(allUsers);
    setPageNumberLimit(Math.ceil(allUsers.length / userPerPage));
    arraysfill();
  }, [allUsers]);

  useEffect(() => {
    setVisibleUsers(allUsers);
    arraysfill();
  }, [pageNumber]);

  const isLiked = (idUser?: number) => {
    if (idUser) {
      let state: boolean = false;
      loggedUser &&
        loggedUser?.userType !== 'C' &&
        loggedUser.usersILike.length > 0 &&
        loggedUser.usersILike.findIndex((item) => (item.id === idUser ? (state = true) : null));
      return state;
    }
    return false;
  };

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        breadcrumbs="Community"
        pageTitle="Community"
      />
      {/* <PageNavigation bgColor="#E3E3E3" links={topNavPages} /> */}
      <main className="container mb-5  px-4 px-sm-0">
        <div className="row">
          <div className="col-12">
            <CommunityNavigation onChangeLayout={setLayoutCommunity} />
          </div>
        </div>
        <div className="row  px-0  ">
          {layoutCommunity == 1 ? (
            visibleUsers.length > 0 ? (
              visibleUsers?.map(
                (aUser: User, index: number) => (
                  console.log(aUser),
                  (
                    <div className=" px-sm-3 px-md-0 my-3 col-12 col-md-6 col-lg-4 col-xl-3">
                      <DesignerCard
                        key={index}
                        nameForURL={aUser.nameForURL}
                        liked={isLiked(aUser.id)}
                        user={aUser}
                        circle
                        reputation={aUser?.reputationCount}
                        entries={numberVotes(aUser?.badgeDesignsOut)}
                        awarded={aUser?.badgeAmountIn}
                        userType={userTypeString(aUser?.userType)}
                        role={jobString(aUser?.jobName)}
                        location={locationString(aUser?.city, aUser?.countryName)}
                        startDate={registrationDateString(aUser?.registrationDate)}
                        name={aUser?.username}
                        image={fullPathLogoCommunity(aUser?.profilePic)}
                      ></DesignerCard>
                    </div>
                  )
                )
              )
            ) : (
              <>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
                <div className=" col-12 d-flex flex-row mt-3 mb-4">
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                  <div className=" col-3 d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={540} />
                  </div>
                </div>
              </>
              // <NoContent />
            )
          ) : (
            ''
          )}
        </div>

        <div className="row px-lg-1 ">
          {layoutCommunity == 2 ? (
            visibleUsers.length > 0 ? (
              visibleUsers?.map((aUser: User, index: number) => (
                // console.log(aUser),
                <div className="col-12 px-lg-3  d-flex flex-row align-items-center justify-content-center">
                  <DesignerRow
                    key={index}
                    circle
                    nameForURL={aUser.nameForURL}
                    contest={aUser?.contestsCount}
                    entries={numberVotes(aUser?.badgeDesignsOut)}
                    awarded={aUser?.badgeAmountIn}
                    userType={userTypeString(aUser?.userType)}
                    role={aUser.job?.name}
                    location={locationString(aUser?.city, aUser?.countryName)}
                    startDate={registrationDateString(aUser?.registrationDate)}
                    name={aUser?.username}
                    userId={aUser.id}
                    image={aUser.profilePic ? aUser.profilePic : profilePlaceholder}
                    galleryImages={mockGalleryImages}
                  ></DesignerRow>
                </div>
              ))
            ) : (
              <>
                <div className=" col-12 mb-5 ">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
                <div className=" col-12 mb-5">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} height={90} />
                </div>
              </>
              // <NoContent />
            )
          ) : (
            ''
          )}
        </div>
        <div className="row ">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end">
            <CommunityPaginationSwitch onChangePage={setCommunityPageNumber} />
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default CommunitySearch;
