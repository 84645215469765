import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CoCreationContest, DSLTag, DesignUpload, User, UserDetails } from '../../global';

import {
  faAngleDown,
  faAngleUp,
  faCircleExclamation,
  faCircleQuestion,
  faExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useStoreState } from 'pullstate';
import DslStore, {
  setCommunityUser,
  setPortfolioDesign,
  setPortfolioDesignCopy,
  setUserAwards,
  setUserInspirations,
  setUserInspirationsCopy,
} from '../../store/DslStore';
import {
  getCoCreationContestStats,
  getCoCreationContests,
  getCommunityUser,
  getContestTags,
  getCurrentContest,
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getTimelinePassedUpload,
  getTimelinePassedCommunityVote,
  getTimelineTotUpload,
  getTimelineTotCommunityVote,
  getTags,
} from '../../store/Selectors';

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './UploadContest.module.scss';
import { UploadContestNamingCoCreation } from './UploadContestNaming';
import axiosHelper from '../../helpers/axiosHelper';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { Datestr } from '../../helpers/utils';
import { TailSpin } from 'react-loader-spinner';
import { response } from 'express';

interface CoCreationProps {
  contest: CoCreationContest;
}

const UploadContestCoCreation: React.FC<CoCreationProps> = (props) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const cocreationContests = useStoreState(DslStore, getCoCreationContests);

  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const contestTag = useStoreState(DslStore, getContestTags);
  const userLogged = useStoreState(DslStore, getCurrentUser);
  const timelineTotUpload = useStoreState(DslStore, getTimelineTotUpload);
  const timelinePassedUpload = useStoreState(DslStore, getTimelinePassedUpload);
  const timelineTotCommunityVote = useStoreState(DslStore, getTimelineTotCommunityVote);
  const timelinePassedCommunityVote = useStoreState(DslStore, getTimelinePassedCommunityVote);
  const cocreationstats = useStoreState(DslStore, getCoCreationContestStats);
  const tags = useStoreState(DslStore, getTags);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DesignUpload>();
  const redirect = useNavigate();
  const [animation, setAnimation] = useState(false);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const [designNameLength, setDesignNameLength] = useState<number>(0);
  const [designAbstractLength, setDesignAbstractLength] = useState<number>(0);
  const [popup, setPopup] = useState(userLogged ? false : true);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [fileLimit, setFileLimit] = useState(true);
  const [phaseOpen, setPhaseOpen] = useState(false);
  const [archiveFile, setArchiveFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File>();
  const [agreement, setAgreement] = useState(false);
  const uuidString = useRef<string>('');
  const [popUpProjName, setPopUpProjName] = useState(false);
  const [popUpPrivacy, setPopUpPrivacy] = useState(false);
  const [popUpAbstract, setPopUpAbstract] = useState(false);
  const [popUpDescription, setPopUpDescription] = useState(false);
  const [popUpArchive, setPopUpArchive] = useState(false);
  const [popUpVideo, setPopUpVideo] = useState(false);
  const [popUpAgreement, setPopUpAgreement] = useState(false);
  const [popUpCover, setPopUpCover] = useState(false);
  const [popUpImages, setPopUpImages] = useState(false);
  const [popUpTags, setPopUpTags] = useState(false);
  const date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  const startDateNumber = Math.trunc(Datestr(props.contest.startUploadDate) / 86400000);
  const endDateNumber = Math.trunc(Datestr(props.contest.endUploadDate) / 86400000);
  const [uploadedCoverImage, setUploadedCoverImage] = useState<File>();
  const nameError = useRef(false);
  const abstractError = useRef(false);
  const coverError = useRef(false);
  const tagsError = useRef(false);
  const agreementError = useRef(false);
  const filesTot = useRef(0);
  const formData = useRef<DesignUpload>();
  const [draft, setDraft] = useState(false);
  const [progress, setProgress] = useState(0);
  const [suggestionArray, setSuggestionArray] = useState<DSLTag[]>([]);
  const [tagString, setTagString] = useState('Desall ');
  const [tagsArray, setTagsArray] = useState<string[]>([]);
  const [designPrivacy, setDesignPrivacy] = useState(false);

  useEffect(() => {
    console.log(cocreationContests);
  }, []);

  let tl1 = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    client: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
  };

  let minitl1 = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
  };

  useEffect(() => {
    !userLogged && setPopup(true);
    checkUser();
  }, []);

  const handleVideoSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event?.target?.files?.[0]);
    if (event?.target?.files) {
      let sizeMb = event.target.files?.[0].size / 1024 / 1024;
      if (sizeMb < 50) {
        setVideoFile(event.target.files?.[0]);
      } else {
        // alert('Upload file up to 50MB');
      }
    }
  };

  const handleArchiveSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event?.target?.files?.[0]);
    if (event?.target?.files) {
      let sizeMb = event.target.files?.[0].size / 1024 / 1024;
      if (sizeMb < 100) {
        setArchiveFile(event.target.files?.[0]);
      } else {
        // alert('Upload file up to 100MB');
      }
    }
  };

  // useEffect(() => {
  //   if (uploadedImages) {
  //     console.log(uploadedImages);
  //   }
  // }, [uploadedImages]);

  const onDrop = (e: any) => {
    // console.log(e);
    const chosenFiles = Array.prototype.slice.call(e);
    console.log(e?.[0]);
    handleUploadFiles(chosenFiles);
  };

  const onDropCover = (e: any) => {
    // console.log(e?.[0]);
    setUploadedCoverImage(e?.[0]);
    coverError.current = false;
  };

  const handleUploadFiles = (files: File[]) => {
    const uploaded: File[] = [...uploadedImages];
    let limitExceeded = false;
    files.some((file: File) => {
      if (uploaded.findIndex((f: File) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length > 5) setFileLimit(true);
        if (uploaded.length > 5) {
          // alert('You can only add a maximum of 5 files');
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      setUploadedImages(uploaded);
    }
  };

  const deleteImg = (index: number) => {
    const uploaded: File[] = [...uploadedImages];
    uploaded.splice(index, 1);
    setUploadedImages(uploaded);
  };

  const checkUser = () => {
    if (userLogged) {
      // console.log(userLogged?.username);
      // console.log(userLogged.firstName);
      // console.log(userLogged.lastName);
      // console.log(userLogged.mobileNumber);
      // console.log(userLogged.city);
      // console.log(userLogged.state);
      // console.log(userLogged.zipCode);
      // console.log(userLogged.country);
      // console.log(userLogged.job?.name);
      // console.log(userLogged.jobTitle);
      // console.log(userLogged.school1);
      // console.log(userLogged.freelanceLicense);
      if (
        userLogged?.username &&
        userLogged.firstName &&
        userLogged.lastName &&
        userLogged.mobileNumber &&
        userLogged.city &&
        userLogged.state &&
        userLogged.zipCode &&
        userLogged.country &&
        userLogged.job?.name &&
        userLogged.jobTitle &&
        userLogged.school1 &&
        userLogged.freelanceLicense
      ) {
        return true;
      } else {
        console.log('no completo');
        redirect(`/community/${userLogged?.nameForURL}/profile-editable`);
      }
    }
  };

  const hiddenOption = () => {
    if (!contest?.isPrivate && props.contest.startUploadDate && props.contest.endUploadDate) {
      console.log('schifo ');
      const dateNumber = Math.trunc(new Date().getTime() / 86400000);
      const startDateNumber = Math.trunc(new Date(props.contest.startUploadDate).getTime() / 86400000);
      const endDateNumber = Math.trunc(new Date(props.contest.endUploadDate).getTime() / 86400000);
      const halfDate = Math.trunc(endDateNumber - startDateNumber) / 2;
      if (dateNumber - startDateNumber < halfDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    console.log(hiddenOption());
  }, []);

  const componiarray = () => {
    const arrayTot: File[] = [];
    if (uploadedCoverImage) {
      arrayTot.unshift(...arrayTot, uploadedCoverImage);
    }
    if (uploadedImages) {
      uploadedImages.map((item: File) => {
        arrayTot.push(item);
      });
    }
    if (videoFile) {
      arrayTot.push(videoFile);
    }
    if (archiveFile) {
      arrayTot.push(archiveFile);
    }
    filesTot.current = arrayTot.length;
  };

  const onSubmit = async (data: DesignUpload) => {
    console.log(uploadedCoverImage);
    console.log(data);
    console.log('ciao');
    nameError.current = false;
    abstractError.current = false;
    tagsError.current = false;
    agreementError.current = false;
    coverError.current = false;

    if (!data.designName) {
      nameError.current = true;
    }
    if (!data.abstract && !draft) {
      abstractError.current = true;
    }

    if (tagString.length < 1 && !draft) {
      tagsError.current = true;
    }
    if (uploadedCoverImage && !draft) {
      coverError.current = true;
    }

    if (!agreement) {
      agreementError.current = true;
      return;
    }

    if (uploadedCoverImage) {
      setProgress(1);
      componiarray();

      formData.current = data;
      uuidString.current = '';
      const arrayImages: File[] = [];
      if (uploadedCoverImage) {
        arrayImages.unshift(...arrayImages, uploadedCoverImage);
      }
      if (uploadedImages) {
        uploadedImages.map((item: File) => {
          arrayImages.push(item);
        });
      }

      console.log(arrayImages);
      if (arrayImages.length > 0 || videoFile || archiveFile) {
        if (arrayImages.length > 0) {
          await imagesPresignedUrl(arrayImages);
          setProgress(15);
          if (videoFile || archiveFile) {
            if (videoFile) {
              await videoPresignedUrl(videoFile);
              setProgress(40);
              if (archiveFile) {
                await archivePresignedUrl(archiveFile);
                setProgress(70);
              }
            } else if (archiveFile) {
              await archivePresignedUrl(archiveFile);
              setProgress(70);
            }
          }
        } else if (videoFile) {
          await videoPresignedUrl(videoFile);
          setProgress(50);
          if (archiveFile) {
            await archivePresignedUrl(archiveFile);
            setProgress(70);
          }
        }
      } else if (archiveFile) {
        await archivePresignedUrl(archiveFile);
        setProgress(70);
      } else if (props.contest) {
        console.log('create 6');
        const uuids = uuidString.current.slice(0, -1);
        if (uuids.length > 0 && !draft) {
          axiosHelper.createDesign(data, props.contest?.id, uuids).then((response) => {
            setCommunityUser(loggedUser);
          });
        } else if (draft) {
          console.log('ciao');
          axiosHelper
            .saveDraft(data, props.contest?.id, uuids)
            .then(async (response) => {
              console.log(response);
              setCommunityUser(loggedUser);
              setProgress(85);
              setTimeout(async () => {
                await axiosHelper.getDesignerDetails(loggedUser?.id)?.then(async (designs) => {
                  await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                    setUserAwards(awards.awards);
                  });
                  await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                    setUserInspirations(inspirations.inspirations);
                    setUserInspirationsCopy(inspirations.inspirations);
                  });
                  setPortfolioDesign(designs.designs);
                  setTimeout(() => {
                    redirect(`/community/${loggedUser?.nameForURL}/portfolio`);
                  }, 1000);
                });
              }, 1000);
            })
            .catch((e) => {
              // alert('something goes wrong');
              setProgress(0);
            });
        } else if (!draft) {
          coverError.current = true;
        }
      }
      setDraft(false);
    } else {
      console.log('no images');
      console.log(draft);
      console.log(props.contest);

      if (draft && props.contest) {
        console.log('draft senza immagini');
        if (videoFile || archiveFile) {
          if (videoFile) {
            console.log('draft senza immagini ma con video');
            await videoPresignedUrl(videoFile);
            setProgress(50);
          }
          if (archiveFile) {
            console.log('draft senza immagini ma con archive');
            await archivePresignedUrl(archiveFile);
            setProgress(70);
          }
        } else {
          axiosHelper.saveDraft(data, props.contest.id, '').then(async (response) => {
            setProgress(85);
            console.log(response);
            setCommunityUser(loggedUser);
            setPortfolioDesign([]);
            setTimeout(async () => {
              await axiosHelper
                .getDesignerDetails(loggedUser?.id)
                ?.then(async (designs) => {
                  await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                    setUserAwards(awards.awards);
                  });
                  await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                    setUserInspirations(inspirations.inspirations);
                    setUserInspirationsCopy(inspirations.inspirations);
                  });
                  setPortfolioDesign(designs.designs);
                  setTimeout(() => {
                    redirect(`/community/${loggedUser?.nameForURL}/portfolio`);
                  }, 1000);
                })
                .catch((e) => {
                  setProgress(0);
                });
            }, 2000);
          });
        }
      } else {
        coverError.current = true;
      }
    }
  };

  const handleName = (e: any) => {
    let nome = e.target.value;
    setDesignNameLength(nome.length);
  };

  const handleAbstract = (e: any) => {
    let nome = e.target.value;
    setDesignAbstractLength(nome.length);
  };

  const archivePresignedUrl = async (file: File) => {
    await axiosHelper
      .getPresignedUrl(5, file.name, file.size, file.type)
      ?.then(async (response) => {
        await axios
          .put(response.data.payload, file)
          .then((risposta) => {
            console.log(risposta);
            updateString(response.data.id);
            return risposta;
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  };

  const videoPresignedUrl = async (file: File) => {
    await axiosHelper
      .getPresignedUrl(6, file.name, file.size, file.type)
      ?.then(async (response) => {
        await axios
          .put(response.data.payload, file)
          .then((risposta) => {
            console.log(risposta);
            updateString(response.data.id);
            return risposta;
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    suggestedTagsString();
    tagStringCheck();
  }, [tagString]);

  useEffect(() => {
    suggestedTagsString();
    if (tagString == '') {
      initialTagString();
    }
    console.log(contest?.isPrivate);
    // console.log(tags);
  }, []);

  const initialTagString = () => {
    let tagStr: string = 'Desall ';

    setTagString(tagStr);
  };

  const tagstring = (e: string) => {
    setTagString(e);
  };

  const suggestedTagsString = () => {
    let tagsArr: string[] = [];
    // console.log(e);
    tagsArr = tagString.split(' ');

    setTagsArray(tagsArr);
    let suggestions: DSLTag[] = [];

    tags.map((item: DSLTag, index: number) => {
      if (item.name.includes(tagsArr[tagsArr.length - 1])) {
        // console.log(item.name);
        suggestions.push(item);
      }
    });
    setSuggestionArray(suggestions);
  };

  const tagStringCheck = () => {
    let string = tagString;
    let index = string.split(' ').length - 1;
    // console.log(string.split(' ')[index]);
    if (string.split(' ')[index] !== '') {
      return true;
    } else {
      return false;
    }
  };

  const addTag = (tag: string) => {
    // console.log(tag);
    let string = tagString;
    let index = string.split(' ').length - 1;
    // console.log(string.split(' ')[index]);
    let stringWithSuggestion = string.replace(new RegExp(string.split(' ')[index] + '$'), tag + ' ');
    setTagString(stringWithSuggestion);
  };

  // const updateString = (string: string) => {
  //   const stringa = string + ',';
  //   uuidString.current += stringa;
  //   console.log('MI HANNO PASSATO ' + string);
  //   console.log(uuidString.current);
  // };

  const updateString = (string: string) => {
    const stringa = string + ',';
    uuidString.current += stringa;
    console.log('MI HANNO PASSATO ' + string);
    console.log(uuidString.current);
    console.log(filesTot.current);

    const virgole = uuidString.current.split(',').length - 1;
    if (virgole === filesTot.current) {
      if (contest) {
        console.log('create archive');
        const uuids = uuidString.current.slice(0, -1);
        if (!draft) {
          formData.current &&
            axiosHelper
              .createDesign(formData.current, contest?.id, uuids)
              .then(async (response) => {
                setProgress(100);
                setCommunityUser(loggedUser);
                console.log(response);
                // setCurrentDesign(response);
                await axiosHelper
                  .getDesignerDetails(loggedUser?.id)
                  ?.then(async (designs) => {
                    await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                      setUserAwards(awards.awards);
                    });
                    await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                      setUserInspirations(inspirations.inspirations);
                      setUserInspirationsCopy(inspirations.inspirations);
                    });
                    setPortfolioDesign(designs.designs);
                    setTimeout(() => {
                      redirect(`/community/${loggedUser?.nameForURL}/portfolio`);
                    }, 2000);
                  })
                  .catch((error) => {
                    setProgress(0);
                  });

                // redirect(`/contest/${contest.nameForUrl}/gallery/${response.name}`);
              })
              .catch((error) => {
                setProgress(0);
              });
        } else {
          formData.current &&
            axiosHelper.saveDraft(formData.current, contest?.id, uuids).then(async (response) => {
              setProgress(85);
              console.log(response);

              setPortfolioDesign([]);
              setTimeout(async () => {
                await axiosHelper
                  .getDesignerDetails(loggedUser?.id)
                  ?.then(async (designs) => {
                    await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                      setUserAwards(awards.awards);
                    });
                    await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                      setUserInspirations(inspirations.inspirations);
                      setUserInspirationsCopy(inspirations.inspirations);
                    });
                    setPortfolioDesign(designs.designs);
                    setTimeout(() => {
                      redirect(`/community/${loggedUser?.nameForURL}/portfolio`);
                    }, 1000);
                  })
                  .catch((e) => {
                    setProgress(0);
                  });
              }, 2000);
            });
        }
      }
    }
  };

  const imagesPresignedUrl = async (files: File[]) => {
    let filesArr = files;

    console.log(filesArr.length);
    filesArr.map(async (item: File, index: number) => {
      await axiosHelper
        .getPresignedUrl(index, item.name, item.size, item.type)
        ?.then(async (response) => {
          await axios
            .put(response.data.payload, item)
            .then((risposta) => {
              console.log(risposta);
              updateString(response.data.id);
              return risposta;
            })
            .catch((error) => {
              throw error;
            });
        })
        .catch((error) => {
          throw error;
        });
    });
  };

  useEffect(() => {
    console.log(uuidString);
  }, [uuidString]);

  const goToDetail = async (userid: number) => {
    console.log(userid);
    try {
      let result: User = await axiosHelper.retrieveUserById(userid);
      console.log(result);

      setCommunityUser(result);
      if (communityUser) {
        redirect(`/community/${result.nameForURL}/profile`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {console.log(props.contest.contestType.name)}
      {props.contest.contestType.name === 'Naming' && <UploadContestNamingCoCreation contest={props.contest} />}
      {props.contest.contestType.name !== 'Naming' && (
        <>
          <div
            className="col-12 bg-blue   mb-5  align-items-center justify-content-center d-flex"
            style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
            // onClick={() => setPhaseOpen(!phaseOpen)}
            onClick={() => date > startDateNumber && date < endDateNumber && setPhaseOpen(!phaseOpen)}
          >
            <div className="col-2   "></div>
            <div className="col-8 d-flex justify-content-center  ">
              <span className="text-xxl color-light fw-bold" style={{ fontSize: '2rem' }}>
                {props.contest.contestType.name} phase
              </span>
            </div>
            <div
              className={`col-2 d-flex  justify-content-end align-items-center ${styles.toggle} ${
                phaseOpen && styles.open
              }`}
              // onClick={() => setPhaseOpen(!phaseOpen)}
              onClick={() => date > startDateNumber && date < endDateNumber && setPhaseOpen(!phaseOpen)}
            >
              <div className={`${styles.arrow} `}> </div>
            </div>
          </div>
          {phaseOpen && (
            <>
              <div className="row ">
                <div className="col-12 px-4  ">
                  <div className="row-mb-5">
                    <div className="col-12 col-md-2 mb-3"></div>
                    <div className="col-12 col-md-10"></div>
                  </div>
                </div>
              </div>
              <div className="row px-1  ">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-12 px-0">
                    <div className="row mb-5">
                      <div className="col-12 col-md-2 mb-3"></div>
                      <div className="col-12 col-md-10 text color-blue py-5">
                        <i>
                          To upload your design, you have to fill the mandatory fields (
                          <span className="color-red">*</span>)
                        </i>
                      </div>
                      <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
                        <div className=" d-flex justify-content-between" style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">
                            Project name <span className="color-red me-1">*</span>
                          </div>
                          <div className="ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0"
                              onMouseEnter={() => setPopUpProjName(true)}
                              onMouseLeave={() => setPopUpProjName(false)}
                            />
                          </div>
                          <div className={`${popUpProjName ? styles.message : styles.noMessage}`}>
                            <div className={`  ${styles.freccetta} `}></div>
                            <div className={`${styles.infoActions} text p-4`}>
                              <i>no text </i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-10">
                        <input
                          id="designname"
                          type="text"
                          {...register('designName', { onChange: handleName, maxLength: 50 })}
                          placeholder="Identify with a name your project"
                          className="text-start"
                        />
                        <div className="row mt-2">
                          <div className="col-1"></div>
                          <div className="col-10">
                            {nameError.current && (
                              <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                                <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" />
                                &nbsp; Please provide a &nbsp; <b> Project name. </b>
                              </span>
                            )}
                          </div>
                          <div className="col-1  d-flex justify-content-end">{<span>{designNameLength}/50</span>}</div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text fw-bold mb-3 ps-md-1 ps-2">
                        <div className=" d-flex justify-content-between" style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11  ">Design privacy</div>
                          <div className="ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpPrivacy(true)}
                              onMouseLeave={() => setPopUpPrivacy(false)}
                            />
                          </div>
                          <div className={`${popUpPrivacy ? styles.message : styles.noMessage}`}>
                            <div className={`  ${styles.freccetta} `}></div>
                            <div className={`${styles.infoActions} text p-4`}>
                              <i>
                                This option allows you to keep your entry hidden from the other participants until the
                                start of the Community Vote. You can activate this option only if you publish your
                                project within the first half of the Upload phase. This option is not available for
                                private gallery contests.
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`col-12 col-md-10 ${popUpPrivacy && styles.noMessage}`}>
                        <input
                          type="radio"
                          className=" align-middle me-4"
                          checked={!designPrivacy}
                          disabled={!hiddenOption()}
                          onClick={() => hiddenOption() && setDesignPrivacy(false)}
                        />
                        <span className={`me-5 text ${contest?.isPrivate ? 'color-dark_grey_70' : ''}`}>Visible</span>
                        &nbsp;
                        <input
                          type="radio"
                          className=" align-middle me-4"
                          checked={designPrivacy}
                          disabled={!hiddenOption()}
                          onClick={() => hiddenOption() && setDesignPrivacy(true)}
                        />
                        <span className={`me-5 text ${contest?.isPrivate ? 'color-dark_grey_70' : ''}`}>Hidden</span>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">
                            Abstract <span className="color-red">*</span>
                          </div>
                          <div className=" ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpAbstract(true)}
                              onMouseLeave={() => setPopUpAbstract(false)}
                            />
                          </div>
                          <div className={`${popUpAbstract ? styles.message : styles.noMessage}`}>
                            <div className={`  ${styles.freccetta} `}></div>
                            <div className={`${styles.infoActions} text p-4`}>
                              <i>no text</i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-10  ">
                        <textarea
                          placeholder="Explain in some world your project"
                          {...register('abstract', { onChange: handleAbstract, maxLength: 250 })}
                        />
                        <div className="row ">
                          <div className="col-sm"></div>
                          <div className="col-sm">
                            {abstractError.current && (
                              <span className=" d-flex flex-row justify-content-center align-items-center color-dark_red">
                                <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp;
                                Please provide an &nbsp;<b>Abstract</b>
                              </span>
                            )}
                          </div>
                          <div className="col-sm d-flex justify-content-end">
                            {<span>{designAbstractLength}/250</span>}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">Description</div>
                          <div className=" ml-auto ">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpDescription(true)}
                              onMouseLeave={() => setPopUpDescription(false)}
                            />
                          </div>
                          <div className={`${popUpDescription ? styles.message : styles.noMessage}`}>
                            <div className={`  ${styles.freccetta} `}></div>
                            <div className={`${styles.infoActions} text p-4`}>
                              <i>no text</i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-10">
                        <textarea
                          {...register('description')}
                          placeholder="Describe your design in a deep way. Tell us the reason of you choice, motivation and everything you think coul be uwseful to understanf your project"
                        />
                      </div>
                    </div>

                    <div className={`row  ${!coverError.current && 'mb-5'}`}>
                      <div className="col-12 col-md-2 text fw-bold ">
                        <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">
                            Cover Image <br />
                            file <span className="color-red">*</span>
                          </div>
                          <div className="ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpCover(true)}
                              onMouseLeave={() => setPopUpCover(false)}
                            />
                            <div className={`${popUpCover ? styles.message : styles.noMessage}`}>
                              <div className={`  ${styles.freccetta} `}></div>
                              <div className={`${styles.infoActions} text p-4`}>
                                <i>NO TEXT </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-md-10 px-sm-2 d-flex flex-column align-items-center justify-content-center  ${styles.uploadCoverContainer}`}
                      >
                        {/* <label
                              htmlFor="images"
                              className={`${styles.dropcontainercoverimages} d-flex position-relative flex-column justify-content-center align-items-center text-center px-5`}
                            > */}
                        <Dropzone onDrop={onDropCover} accept="image/png, image/jpg, image/jpeg" maxSize={31457280}>
                          {({ getRootProps, getInputProps }) => (
                            <section
                              className={`${styles.dropzone} bg-light d-flex align-items-center  justify-content-center`}
                            >
                              <div
                                className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <span>
                                  Drag and drop an image or <span className="color-blue">choose a file</span> from your
                                  computer <br />
                                  (allowed formats: .jpg, .png)
                                </span>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                      <div className={`d-flex  ${coverError.current && 'mb-5'} `}>
                        <div className="col-2 "></div>
                        <div className="col-10   d-flex flex-row justify-content-center">
                          {coverError.current && (
                            <span className=" mt-2 color-dark_red d-flex align-items-center ">
                              <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp;
                              <b className="me-2 ">Cover image</b> is required
                            </span>
                          )}
                          {/* <span className="color-dark_red ">Cover image is required</span> */}
                        </div>
                      </div>
                    </div>

                    <div className={`row  mb-7 ${uploadedCoverImage ? '' : 'd-none'}`}>
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between "></div>
                      </div>
                      <div
                        className={`col-12 col-md-10  d-flex flex-column align-items-center justify-content-center `}
                      >
                        {uploadedCoverImage && (
                          <div className=" d-flex" style={{ width: '100%' }}>
                            <div className={`${styles.previewImg}`}>
                              <img src={URL.createObjectURL(uploadedCoverImage)} className={`${styles.previewImg}`} />
                            </div>
                            <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                              <span className="text-xl">{uploadedCoverImage.name}</span>
                            </div>
                            <div className=" d-flex align-items-center justify-content-end ">
                              <div
                                className={` ${styles.removeButton}`}
                                onClick={() => setUploadedCoverImage(undefined)}
                              >
                                <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">Image file</div>
                          <div className="ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpImages(true)}
                              onMouseLeave={() => setPopUpImages(false)}
                            />
                            <div className={`${popUpImages ? styles.message : styles.noMessage}`}>
                              <div className={`  ${styles.freccetta} `}></div>
                              <div className={`${styles.infoActions} text p-4`}>
                                <i>NO TEXT </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 col-md-10 px-2 ${
                          uploadedImages.length > 0 ? '' : 'mb-5'
                        } d-flex flex-column align-items-center justify-content-center  ${
                          styles.uploadImagesContainer
                        }`}
                      >
                        {/* <label
                              htmlFor="images"
                              className={`${styles.dropcontainercoverimages} d-flex position-relative flex-column justify-content-center align-items-center text-center px-5`}
                            > */}
                        <Dropzone onDrop={onDrop} accept="image/png, image/jpg, image/jpeg" maxSize={31457280}>
                          {({ getRootProps, getInputProps }) => (
                            <section
                              className={`${styles.dropzone} bg-light d-flex align-items-center justify-content-center`}
                            >
                              <div
                                className="col-12  h-100 d-flex flex-column align-items-center justify-content-center text-center"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <span>
                                  Drag and drop an image or <span className="color-blue">choose a file</span> from your
                                  computer <br />
                                  (allowed formats: .jpg, .png). Max 4 images.
                                </span>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>

                    <div className={`row mb-5 ${uploadedImages.length > 0 ? '' : 'd-none'} `}>
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between "></div>
                      </div>
                      <div
                        className={`col-12 col-md-10 mb-5 d-flex flex-column align-items-center justify-content-center  `}
                      >
                        {uploadedImages?.map((item, index) => (
                          <div className="mt-2 d-flex" style={{ width: '100%' }}>
                            <div className={`${styles.previewImg}`}>
                              <img
                                src={URL.createObjectURL(item)}
                                key={index}
                                className={`${styles.previewImg}`}
                                style={{ height: '100%' }}
                              />
                            </div>
                            <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                              <span className="text-xl">{item.name}</span>
                            </div>
                            <div className=" d-flex align-items-center justify-content-end ">
                              <div
                                className={`${styles.removeButton}`}
                                // style={{ borderRadius: '8px', height: '50px', width: '50px' }}
                                onClick={() => deleteImg(index)}
                              >
                                <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">Archive file</div>
                          <div className=" ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpArchive(true)}
                              onMouseLeave={() => setPopUpArchive(false)}
                            />
                          </div>
                          <div className={`${popUpArchive ? styles.message : styles.noMessage}`}>
                            <div className={`  ${styles.freccetta} `}></div>
                            <div className={`${styles.infoActions} text p-4`}>
                              <i>no text</i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-10 mb-5">
                        <label
                          htmlFor="archive"
                          className={`${styles.dropcontainerarchive} d-flex position-relative flex-column justify-content-center px-5`}
                        >
                          <label className="drop-title  fw-normal">
                            <input
                              type="file"
                              id="archive"
                              accept=".zip"
                              className={`${styles.inputfile}`}
                              required={false}
                              multiple={true}
                              onChange={handleArchiveSelect}
                            />
                            <span className="color-primary fw-normal" style={{ color: '#7997c5' }}>
                              Choose a file&nbsp;
                            </span>
                            <span>from your computer (allowed format: .zip max file size: 100 MB).</span>
                          </label>
                        </label>
                        {archiveFile && (
                          <div className=" d-flex mt-2 ">
                            <div className="d-flex w-100   align-items-center">
                              <div className=" text-center">{archiveFile.name} </div>
                            </div>
                            <div className="d-flex w-100 align-items-center justify-content-end ">
                              <div className=" btn-danger rounded" onClick={() => setArchiveFile(undefined)}>
                                <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">Video file</div>
                          <div className=" ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpVideo(true)}
                              onMouseLeave={() => setPopUpVideo(false)}
                            />
                          </div>
                          <div className={`${popUpVideo ? styles.message : styles.noMessage}`}>
                            <div className={`  ${styles.freccetta} `}></div>
                            <div className={`${styles.infoActions} text p-4`}>
                              <i>no text</i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-10 mb-5">
                        <label
                          className={`${styles.dropcontainerarchive} d-flex position-relative flex-column justify-content-center px-5`}
                        >
                          <label className="drop-title  fw-normal">
                            <input
                              type="file"
                              id="video"
                              accept=".zip"
                              className={`${styles.inputfile}`}
                              required={false}
                              multiple={false}
                              onChange={handleVideoSelect}
                            />
                            <span className="color-primary fw-normal" style={{ color: '#7997c5' }}>
                              Choose a file&nbsp;
                            </span>
                            <span>from your computer (allowed format: .zip max file size: 50 MB).</span>
                          </label>
                        </label>
                        {videoFile && (
                          <div className=" d-flex mt-2 ">
                            <div className="d-flex w-100 align-items-center">
                              <div className=" text-center">{videoFile.name} </div>
                            </div>
                            <div className="d-flex w-100 align-items-center justify-content-end ">
                              <div className=" btn-danger rounded" onClick={() => setVideoFile(undefined)}>
                                <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">
                            Keyword <span className="color-red me-1"> * </span>
                          </div>
                          <div className="ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpTags(true)}
                              onMouseLeave={() => setPopUpTags(false)}
                            />
                            <div className={`${popUpTags ? styles.message : styles.noMessage}`}>
                              <div className={`  ${styles.freccetta} `}></div>
                              <div className={`${styles.infoActions} text p-4`}>
                                <i>NO TEXT </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-10 ">
                        <input
                          type="text"
                          value={tagString}
                          style={{ height: '30px' }}
                          {...register('tempTags', {
                            onChange: (e) => tagstring(e.target.value),
                          })}
                        />
                        {tagsError.current && (
                          <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                            <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp;
                            Please provide &nbsp; <b> keywords. </b>
                          </span>
                        )}
                        {suggestionArray.length > 0 && tagStringCheck() && (
                          <div className={`${styles.suggestionWrapper}`}>
                            {suggestionArray.map((item: DSLTag, index: number) => (
                              <div
                                className={`text ${styles.suggestionRow}`}
                                key={index}
                                onClick={() => addTag(item.name)}
                              >
                                {item.name}
                              </div>
                            ))}
                          </div>
                        )}
                        <div className="mt-3">
                          {/* {console.log(tagsArray)} */}
                          {tagsArray.map(
                            (item: string, index: number) =>
                              item !== '' && (
                                <button disabled className="btn-tag" key={index}>
                                  <span>{item}</span>
                                </button>
                              )
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-12 col-md-2 text fw-bold mb-3">
                        <div className=" d-flex justify-content-between " style={{ position: 'relative', zIndex: 10 }}>
                          <div className="col-11">
                            Agreement <span className="color-red me-1"> * </span>
                          </div>
                          <div className=" ml-auto">
                            <FontAwesomeIcon
                              icon={faCircleQuestion as IconProp}
                              className=" text color-blue me-0 "
                              onMouseEnter={() => setPopUpAgreement(true)}
                              onMouseLeave={() => setPopUpAgreement(false)}
                            />
                          </div>
                          <div className={`${popUpAgreement ? styles.message : styles.noMessage}`}>
                            <div className={`  ${styles.freccetta} `}></div>
                            <div className={`${styles.infoActions} text p-4`}>
                              <i>no text</i>
                            </div>
                          </div>
                          {/* <div className={`${agreementError.current ? styles.messageDown : styles.noMessageDown}`}>
                            <div className={`  ${styles.freccetta} `}></div>
                            <div className={`${styles.infoActions} text p-4`}>
                              <div
                                className="bg-warning d-flex flex-row align-items-center justify-content-center me-3"
                                style={{ height: '30px', width: '30px' }}
                              >
                                <FontAwesomeIcon
                                  icon={faExclamation as IconProp}
                                  className="color-light "
                                  style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}
                                />
                              </div>
                              Seleziona questa casella se intendi procedere.
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-12 col-md-10 mb-5">
                        <div className="d-flex flex-row justify-content-start">
                          <input
                            type="checkbox"
                            checked={agreement}
                            onChange={() => (
                              console.log('ciao'), setAgreement(!agreement), (agreementError.current = false)
                            )}
                          />
                          <div className=" w-100 d-flex flex-row align-items-center justify-content-between">
                            <label className=" ms-3 fw-normal">
                              I'm over 18 and agree to the
                              <a target="_blank" href={contest?.termsAttachment && contest.termsAttachment}>
                                &nbsp; Contest agreement
                              </a>
                            </label>
                            {agreementError.current && (
                              <span className="d-flex flex-row align-items-center justify-content-center color-dark_red">
                                <FontAwesomeIcon icon={faCircleExclamation as IconProp} className="me-1" />
                                You must accept the conditions
                                {/* <b className="ms-1">Contest Agreement</b> */}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* {agreementError.current && (
                          <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                            <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" /> &nbsp;
                            Please accept the &nbsp; <b> contest agreements. </b>
                          </span>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3"></div>
                    <div className="col-12 col-md-3 mb-4 px-5 align-items-center ">
                      <div className="d-flex flex-row justify-content-center ">
                        {progress === 0 && (
                          <button className={`btn-dark-blue rounded full`} onClick={() => setDraft(true)}>
                            Save draft
                          </button>
                        )}
                        {progress !== 0 && !draft && (
                          <button className={`btn-dark-blue rounded full`} onClick={() => setDraft(true)}>
                            Save draft
                          </button>
                        )}
                        {progress !== 0 && draft && (
                          <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                            <TailSpin
                              height="15"
                              width="15"
                              color="#7997c5"
                              wrapperStyle={{}}
                              wrapperClass="me-2"
                              visible={true}
                              ariaLabel="tail-spin-loading"
                            />
                            &nbsp;
                            <span>Uploading </span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-3 mb-4 px-5 align-items-center">
                      <div className="d-flex flex-row justify-content-center ">
                        {progress === 0 && <button className="btn-blue rounded full">Publish</button>}
                        {progress !== 0 && draft && <button className="btn-blue rounded full">Publish</button>}
                        {progress !== 0 && !draft && (
                          <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                            <TailSpin
                              height="15"
                              width="15"
                              color="#7997c5"
                              wrapperStyle={{}}
                              wrapperClass="me-2"
                              visible={true}
                              ariaLabel="tail-spin-loading"
                            />
                            &nbsp;
                            <span>Uploading</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default UploadContestCoCreation;
