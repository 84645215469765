import React, { useState } from 'react';
import styles from './AccordionItem.module.scss';

interface IAccordionItem {
  title: string;
}

const AccordionItem: React.FC<IAccordionItem> = (props) => {
  const [opened, setOpened] = useState<boolean>(false);

  const handleItemToggle = () => {
    setOpened(!opened);
  };

  return (
    <div className={`row mb-3 ${styles.accordionItemWrapper} ${opened ? styles.opened : ''}`}>
      <div
        className={`col-12 ${styles.accordionItemHandle} d-flex flex-column justify-content-center align-items-center fw-bold pe-3`}
        onClick={() => handleItemToggle()}
      >
        <span className={`${styles.label}`}>{props.title}</span>
        <div className={`${styles.arrow}`}></div>
      </div>
      <div className={`col-12 ${styles.accordionItemContent}`}>{props.children}</div>
    </div>
  );
};

export default AccordionItem;
