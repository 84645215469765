import React from "react";
import styles from "./CardSmall.module.scss";

interface ICardSmall {
  backGround?: string;
  topText?: string;
  bottomText?: string;
  link?: string;
  showContent?: boolean;
  onClick?: () => void;
}

/**
 * Card 270x270
 * @param param0
 * @returns
 */
const CardSmall: React.FC<ICardSmall> = (props) => {
  const handleOnClick = () => {
    props.onClick && props.onClick();
  };

  return (
    <div
      onClick={handleOnClick}
      className={`${props.topText || props.bottomText? styles.cardWrapper: styles.cardWrapperNoHover} ${
        props.showContent && styles.visibleContent
      }`}
    >
      <img className="img-270" src={props.backGround} alt="Placeholder" />
      <div className={`${styles.cardOverlay} d-flex flex-column`}>
        {props.children && (
          <div
            className={`flex-grow-1 d-flex flex-column justify-content-center align-items-center`}
          >
            {props.children}
          </div>
        )}
        {!props.children && props.topText && (
          <div
            className={`color-light sub-title flex-grow-1 d-flex justify-content-center align-items-center`}
          >
            {props.topText}
          </div>
        )}
        {!props.children && props.bottomText && (
          <div
            className={`color-light sub-title flex-grow-1 d-flex justify-content-center align-items-center`}
          >
            {props.bottomText}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardSmall;
