import React, { useEffect, useRef, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import {
  clientNavPages,
  designerNavPages,
  personalClientNavPages,
  personalProfileNavPages,
} from '../../mockdata/navPages';
import { PageNavigationProfile } from '../../components/PageNavigation/PageNavigation';
import placeholderProfile from '../../assets/Profile.png';

import footerImg from '../../assets/footer_placeholder_transparent.png';

import {
  getCommunityUser,
  getContestCategories,
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getWelcomeMessage,
} from '../../store/Selectors';
import DslStore, {
  setCategories,
  setClientContest,
  setClientContestNumber,
  setCommunityUser,
  setContestGallery,
  setCurrentUser,
  setFavoriteInspirations,
  setFavoriteInspirationsCopy,
  setFavoriteUsers,
  setFavoriteUsersCopy,
  setPortfolioDesign,
  setPortfolioDesignCopy,
  setUserAwards,
  setUserBadges,
  setUserInspirationVotes,
  setUserInspirations,
  setUserInspirationsCopy,
  setUserUserFavorited,
  setUserUserFavoritedCopy,
  setWelcomeMessage,
} from '../../store/DslStore';
import { useStoreState } from 'pullstate';

import styles from './Profile.module.scss';

import ContestStats, { ProfileStats } from '../../components/Community/ContestStats/ContestStats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faIndustry, faMapMarkerAlt, faPerson, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { ProfilePic } from '../../components/Profile/ProfilePic/ProfilePic';
import { ProfileHeader } from '../../components/Profile/ProfileHeader/ProfileHeader';
import {
  finalSlicedString,
  fullPathLogo,
  fullPathLogoUser,
  locationString,
  registrationDateString,
} from '../../helpers/utils';
import Ambassador from './Ambassador';
import DesignerInspirations from './DesignerInspirations';
import UserFavoritesPage from './UserFavorites';
import UserBadge from './UserBadge';
import Portfolio from './Portfolio';
import UserAward from './UserAward';
import Profile from './Profile';
import axiosHelper from '../../helpers/axiosHelper';
import { Contest, UserFavorites } from '../../global';
import ProfileEditable from './ProfileEditable';
import DesignerInspirationUpload from './DesignerInspirationUpload';

const ProfileSkeleton: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const welcomeMessage = useStoreState(DslStore, getWelcomeMessage);
  const [breadcrumbs, setBreadcurmbs] = useState(' ');
  const contestCategories = useStoreState(DslStore, getContestCategories);
  const [usersFavorites, setUserFavorites] = useState<UserFavorites>();
  const path = window.location.pathname;
  const [key, setkey] = useState(0);
  const [messageBanner, setMessageBanner] = useState(0);
  const [successBanner, setSuccessBanner] = useState(0);
  const [image, setImage] = useState(currentUser?.idLogo);
  const [banner, setBanner] = useState(currentUser?.idBanner);
  const [profileImage, setProfileImage] = useState<string | undefined>(
    fullPathLogo(currentUser?.logoAttachment ? currentUser?.logoAttachment.s3Path : placeholderProfile)
  );
  const [bannerImage, setBannerImage] = useState<string | undefined>(
    fullPathLogo(currentUser?.bannerAttachment ? currentUser.bannerAttachment.s3Path : undefined)
  );

  const imageAdded = useRef<string>('');

  const userContestNumber = () => {
    if (communityUser) {
      let clientContestLength: number = communityUser.contests.length;
      console.log(clientContestLength);
      setClientContestNumber(clientContestLength);
    }
  };

  const userFavorites = async () => {
    const result: UserFavorites | undefined = await axiosHelper.getFavoriteUsers(communityUser?.id);
    // console.log(result);
    if (result) {
      setFavoriteUsers(result.usersILike);
    }
  };

  const setClientContests = async () => {
    let result = await axiosHelper.getContestsByClientId(communityUser?.id);
    const clientContests: Contest[] = result.data;
    if (clientContests) {
      setClientContest(clientContests);
    }
  };

  const setUserDetails = async (userId?: number) => {
    if (communityUser?.userType === 'D') {
      await axiosHelper.getDesignerDetails(userId)?.then(async (response) => {
        // setUserInspirationsCopy(response.inspirations);

        await axiosHelper.getDesignerAwards(userId)?.then((awards) => {
          setUserAwards(awards.awards);
        });
        setPortfolioDesign(response.designs);
        setPortfolioDesignCopy(response.designs);
      });
      await axiosHelper.getDesignerInspirations(userId)?.then((designerInspirations) => {
        // console.log("INSPIRATIONNNN DSIOCSAOCO");
        // console.log(designerInspirations);
        setUserInspirations(designerInspirations.inspirations);
        setUserInspirationsCopy(designerInspirations.inspirations);
      });
      await axiosHelper.getDesignerFavoriteInspirations(userId)?.then((favorite) => {
        console.log('ho tirato giu');
        console.log(favorite);
        setFavoriteInspirations(favorite.inspirationVotes);
        setFavoriteInspirationsCopy(favorite.inspirationVotes);
        if (currentUser) {
          if (communityUser?.id === currentUser.id) {
            setUserInspirationVotes(favorite.inspirationVotes);
          }
        }
      });

      await axiosHelper.getUserBadges(userId)?.then((response) => {
        setUserBadges(response);
      });

      await axiosHelper.getFavoriteUsers(userId)?.then((response) => {
        setFavoriteUsers(response.usersILike);
        setFavoriteUsersCopy(response.usersILike);
        setUserFavorites(response);
      });

      await axiosHelper.getUserFavorited(userId)?.then((response) => {
        setUserUserFavorited(response);
        setUserUserFavoritedCopy(response);
      });
    } else {
      getClientContests();
    }
  };

  const getClientContests = async () => {
    await axiosHelper.getContestsByClientId(communityUser?.id).then((response) => {
      console.log(response);
    });
  };

  const getUserName = () => {
    // console.log(path);
    // console.log(path.split('/'));
    setFavoriteInspirations([]);
    setFavoriteInspirationsCopy([]);
    setUserInspirations([]);
    let pathSplitted = path.split('/');
    console.log(pathSplitted[2]);
    console.log(communityUser);
    if (communityUser?.username !== pathSplitted[2]) {
      axiosHelper
        .retrieveUserByName(pathSplitted[2])
        ?.then(
          (response) => (
            console.log(response),
            setCommunityUser(response.data),
            setUserDetails(response.data.id),
            userFavorites(),
            setkey(key + 1)
          )
        );
    } else {
      setUserDetails(communityUser.id);
    }
  };

  const contestUserGallery = () => {
    axiosHelper.getUserGallery(communityUser?.id)?.then((response) => {
      // setContestGallery(response);
    });
  };

  const handleImageAdd = (e: string) => {
    console.log(e);
    setImage(e);
    imageAdded.current = e;
    console.log(imageAdded.current);
  };

  const handleBannerAdd = (e: any) => {
    console.log(e);
    // setBannerImage(e);
    setBanner(e);
  };

  useEffect(() => {
    if (communityUser?.userType === 'C') {
      // console.log('dio can: ' + communityUser.userType);
      userContestNumber();
      setClientContests();
    }
    getUserName();
    contestUserGallery();
    if (contestCategories) setCategories.run();
    console.log(communityUser);
    if (currentUser) {
      if (communityUser?.id === currentUser?.id && currentUser?.hunters?.length === 0) {
        if (currentUser.userType === 'C') {
          axiosHelper.createClientHunter(currentUser.id)?.then((response) => {
            setCurrentUser(response);
          });
        }
        if (currentUser.userType === 'D') {
          axiosHelper.createDesignerHunter(currentUser.id)?.then((response) => {
            setCurrentUser(response);
          });
        }
        if (currentUser.userType === 'G') {
          axiosHelper.createDSLUserHunter(currentUser.id)?.then((response) => {
            setCurrentUser(response);
          });
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log(communityUser);
  //   setkey(key + 1);
  // });

  useEffect(() => {
    if (communityUser) {
      getUserName();
      setkey(key + 1);
    }
  }, [communityUser]);

  useEffect(() => {
    if (currentUser) {
      setkey(key + 1);
      setUserDetails();
    }
  }, [currentUser]);

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        breadcrumbs={`Community/${communityUser?.username}`}
        pageTitle={breadcrumbs}
      />
      <PageNavigationProfile
        bgColor="#E3E3E3"
        links={
          currentUser !== undefined
            ? currentUser.id === communityUser?.id
              ? currentUser.userType !== 'C'
                ? personalProfileNavPages
                : personalClientNavPages
              : communityUser?.userType === 'D'
              ? designerNavPages
              : clientNavPages
            : communityUser?.userType === 'D'
            ? designerNavPages
            : clientNavPages
        }
      />
      <div
        className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
          welcomeMessage ? `${styles.successBackground}` : 'd-none'
        }
        `}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center ">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light">Welcome to Desall!</span>
              </div>
              <div>
                <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setWelcomeMessage(false)}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
          messageBanner === 1
            ? `${styles.successBackground}`
            : messageBanner === 2
            ? `${styles.errorBackground}`
            : 'd-none'
        }
        ${welcomeMessage ? `${styles.translateY60}` : ''}`}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center ">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light">
                  {messageBanner === 1 ? 'Message sent!' : 'The mailserver is busy, please try again!'}
                </span>
              </div>
              <div>
                <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setMessageBanner(0)}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
          messageBanner === 1
            ? `${styles.successBackground}`
            : messageBanner === 2
            ? `${styles.errorBackground}`
            : 'd-none'
        }`}
        // style={{ opacity: '0.8', position: 'absolute', zIndex: '10', height: '60px' }}
      >
        <div className="container d-flex flex-row align-items-center justify-content-center ">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light">
                  {messageBanner === 1 ? 'Message sent!' : 'The mailserver is busy, please try again!'}
                </span>
              </div>
              <div>
                <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setMessageBanner(0)}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className={`  d-flex flex-row align-items-center w-100 justify-content-center ${
          successBanner === 1
            ? `${styles.successBackground}`
            : successBanner === 2
            ? `${styles.errorBackground}`
            : successBanner === 3
            ? `${styles.successBackground}`
            : 'd-none'
        }
        ${
          welcomeMessage
            ? messageBanner !== 0
              ? `${styles.translateY120}`
              : `${styles.translateY60}`
            : messageBanner !== 0
            ? `${styles.translateY60}`
            : ''
        }`}
        // style={{
        //   opacity: '0.8',
        //   position: 'absolute',
        //   zIndex: '10',
        //   height: '60px',
        //   transform: `${messageBanner !== 0 && 'translateY(60px)'}`,
        // }}
      >
        <div className="container  d-flex flex-row align-items-center justify-content-center">
          <div className="row col-12 ">
            <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <div className="col-12 text-center align-self-end ">
                <span className="sub-title color-light">
                  {successBanner === 1
                    ? 'Updated successfully!'
                    : successBanner === 3
                    ? 'The password has been changed!'
                    : 'Update Problem! '}
                </span>
              </div>
              <div>
                <button className={`${styles.removeBannerButton} bg-blue`} onClick={() => setSuccessBanner(0)}>
                  <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
                </button>
              </div>
            </div>
            {/* <div className="col-12  d-flex flex-row align-items-center justify-content-end ">
              <button className={`${styles.removeButton} bg-danger`} onClick={() => setSuccessBanner(0)}>
                <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
              </button>
            </div>
            <div className="col-12  text-center "></div> */}
          </div>
        </div>
      </div>
      <main className={`${styles.profileWrapper} container  px-md-3 pt-5  px-sm-0 `}>
        {(communityUser?.bannerAttachment || path.includes('profile-editable')) && (
          <div className="row ">
            <div className="col-12 px-md-2 mx-md-1 pe-md-3   ">
              <ProfileHeader
                key={key}
                editable={path.includes('profile-editable')}
                bgImage={fullPathLogo(communityUser?.bannerAttachment ? communityUser?.bannerAttachment.s3Path : '')}
              />
            </div>
          </div>
        )}
        <div className={`row px-4   mt-3 px-sm-0  `}>
          <div className="col-3 "></div>
          <div className="col-md-9 d-sm-none d-md-block">
            <h1 className="bigger-text-bold ms-4 color-blue">{finalSlicedString(communityUser?.username)}</h1>
            <div className="sub-title-editable ms-4 color-dark text-capitalize">
              {' '}
              {communityUser?.userType === 'D' ? communityUser.job?.name : communityUser?.sector}
            </div>
          </div>
          <div
            className={`col-12 col-md-3 d-sm-flex align-items-sm-center justify-content-sm-center text-center position-relative ps-md-3 ${
              styles.profilePic
            } ${communityUser?.bannerAttachment || path.includes('-editable') ? styles.picUp : styles.minipicUp} `}
          >
            <ProfilePic
              key={key}
              editable={path.includes('profile-editable')}
              image={fullPathLogoUser(communityUser?.logoAttachment?.s3Path)}
              onImageAdd={handleBannerAdd}
              onImageRemove={() => {
                setBannerImage(undefined);
              }}
            >
              {/* <div className={`${styles.badges}`}>
                <div className={`${styles.badge} bg-red color-light`}>
                  <FontAwesomeIcon icon={faFlask as IconProp} />
                </div>
                <div className={`${styles.badgeOver} bg-green color-light`}>
                  <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} />
                </div>
              </div> */}
            </ProfilePic>
          </div>
          <div className="col-12 d-md-none  d-flex mt-10 justify-content-center">
            <div className="text-center">
              <h1 className="bigger-text-bold ms-4 color-blue">{finalSlicedString(communityUser?.username)}</h1>
              <div className="sub-title-editable ms-4 color-dark text-capitalize">
                {communityUser?.userType === 'D' ? communityUser.job?.name : communityUser?.sector}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3 mb-lg-3 ">
            <div className="info ms-4   d-flex flex-column-reverse align-items-center h-100  ">
              <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-5 mb-md-4 mb-3 ">
                <div className="me-2  d-flex flex-row align-items-center">
                  {communityUser?.userType === 'C' ? (
                    <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue " />
                  ) : (
                    <FontAwesomeIcon icon={faUser as IconProp} className=" text color-blue me-0" />
                  )}
                </div>
                {/* <div className="text color-green col-11">{Dream Team user}</div> */}
                <div className="text  col-11">{communityUser?.userType === 'C' ? 'Company' : 'User'}</div>
              </div>
              <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-3 mb-md-4 mb-3  ">
                <div className="me-2  d-flex flex-row align-items-center">
                  <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue " />
                </div>
                <div className="text col-11 ">since {registrationDateString(communityUser?.registrationDate)}</div>
              </div>
              <div className=" d-flex flex-row align-items-center  justify-content-center col-12 mb-lg-3 mb-md-4  mb-3">
                {(communityUser?.hasPublicCity || communityUser?.hasPublicCountry) && (
                  <>
                    <div className="me-2  d-flex flex-row align-items-center">
                      <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue " />
                    </div>

                    <div className="text col-11">
                      {communityUser?.hasPublicCity &&
                        communityUser.hasPublicCountry &&
                        locationString(communityUser?.city, communityUser?.country?.name)}
                      {communityUser?.hasPublicCity &&
                        !communityUser.hasPublicCountry &&
                        locationString(communityUser?.city)}
                      {!communityUser?.hasPublicCity &&
                        communityUser?.hasPublicCountry &&
                        locationString(communityUser?.country?.name)}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6  pt-4  ">
            <ProfileStats
              entries={communityUser?.userType === 'D' ? communityUser.badgeDesignsOut : communityUser?.badgeDesignsIn}
              inspirations={communityUser?.badgeInspirationOut}
              awards={communityUser?.userType === 'D' ? communityUser.badgeAmountIn : communityUser?.badgeAmountOut}
              contests={communityUser?.contestsCount}
              reputation={communityUser?.reputationCount}
              favorites={communityUser?.badgeFavoritesCount}
              votes={communityUser?.badgeVotesIn}
              comments={communityUser?.badgeCommentsIn}
            />
          </div>
        </div>

        {/* {path.includes('profile-editable') && <ProfileEditable />} */}
        {path.includes('ambassador') && <Ambassador breadcrumbs={(title) => setBreadcurmbs(title)} />}
        {path.includes('designer-inspirations') && !path.includes('new') && !path.includes('edit') && (
          <DesignerInspirations breadcrumbs={(title) => setBreadcurmbs(title)} />
        )}
        {path.includes('user-favorites') && <UserFavoritesPage breadcrumbs={(title) => setBreadcurmbs(title)} />}
        {path.includes('user-badge') && (
          <UserBadge
            breadcrumbs={(title) => setBreadcurmbs(title)}
            messageBanner={(message) => setMessageBanner(message)}
          />
        )}
        {path.includes('portfolio') && <Portfolio breadcrumbs={(title) => setBreadcurmbs(title)} />}
        {path.includes('profile-editable') && (
          <ProfileEditable breadcrumbs={(title) => setBreadcurmbs(title)} successBanner={(n) => setSuccessBanner(n)} />
        )}
        {path.includes('user-awards') && (
          <UserAward
            breadcrumbs={(title) => setBreadcurmbs(title)}
            messageBanner={(message) => setMessageBanner(message)}
          />
        )}
        {path.includes('new') && <DesignerInspirationUpload breadcrumbs={(title) => setBreadcurmbs(title)} />}
        {path.includes('edit') && !path.includes('editable') && (
          <DesignerInspirationUpload breadcrumbs={(title) => setBreadcurmbs(title)} />
        )}
        {path.includes('profile') &&
          (currentUser ? (
            currentUser.id !== communityUser?.id ? (
              <Profile
                breadcrumbs={(title) => setBreadcurmbs(title)}
                messageBanner={(message) => setMessageBanner(message)}
              />
            ) : (
              ''
            )
          ) : (
            <Profile
              breadcrumbs={(title) => setBreadcurmbs(title)}
              messageBanner={(message) => setMessageBanner(message)}
            />
          ))}
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};
export default ProfileSkeleton;
