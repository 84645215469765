import React, { useEffect, useRef, useState } from 'react';
import styles from './ProfileHeader.module.scss';
import { fullPathLogo, hexToRGB } from '../../../helpers/utils';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import FileUpload from './FileUpload/FileUpload';
import { Attachment } from '../../../global';
import { useStoreState } from 'pullstate';
import DslStore, { setCommunityUser, setCurrentUser } from '../../../store/DslStore';
import { getCurrentUser } from '../../../store/Selectors';
import axiosHelper from '../../../helpers/axiosHelper';
import { height } from '@mui/system';

interface IProfileHeader {
  bgImage?: string;
  bgColor?: string;
  alpha?: number;
  onImageAdd?: (file: any) => void;
  onImageRemove?: () => void;
  minHeight?: string;
  editable?: boolean;
  awsPath?: (e: any) => void;
}

/**
 * Profile header
 * @param {string} bgImage Background image
 * @param {string} bgColor Background color and overlay color in HEX
 * @param {string} alpha Alpha da 0 a 1, riferito all'overlay sopra l'immagine
 * @returns
 */
export const ProfileHeader: React.FC<IProfileHeader> = (props) => {
  const uploadedImage = useRef(props.bgImage);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const uuidImage = useRef<string>('');

  let gradientString = '';
  let colorString = 'transparent';
  if (props.bgColor) {
    let c = hexToRGB(props.bgColor);
    colorString = `rgba(${c[0]},${c[1]},${c[2]}, 1)`;
    gradientString = `linear-gradient(rgba(${c[0]},${c[1]},${c[2]}, ${props.alpha ?? 0}), rgba(${c[0]},${c[1]},${
      c[2]
    }, ${props.alpha ?? 0})),`;
  }

  const handleImageAdd = (e: any) => {
    if (props.onImageAdd) {
      console.log('aggiunto banner');
      console.log(e);

      // return props.onImageAdd(image.webPath);
    } else return false;
  };

  const handleUuidPresigned = (e: any) => {
    console.log('uuid: ' + e);
    uuidImage.current = e;
    setTimeout(async () => {
      if (currentUser?.userType === 'D') {
        await axiosHelper.updateDesignerImages(e, currentUser?.id)?.then((response) => {
          console.log(response);
          // setCurrentUser(response);
          setCommunityUser(response);
          uploadedImage.current = response.bannerAttachment.s3Path;
          props.onImageAdd && props.onImageAdd(e);
        });
      } else if (currentUser?.userType === 'C') {
        await axiosHelper.updateClientImages(e, currentUser?.id)?.then((response) => {
          // console.log(response);
          setCurrentUser(response);
          setCommunityUser(response);
          uploadedImage.current = response.bannerAttachment.s3Path;
          props.onImageAdd && props.onImageAdd(e);
        });
      }
    }, 1500);
  };

  const handleImageRemove = async () => {
    if (currentUser?.userType === 'D') {
      await axiosHelper.removeBannerDesigner(currentUser?.id)?.then((response) => {
        console.log(response);
        setCurrentUser(response);
        setCommunityUser(response);
        uploadedImage.current = '';
      });

      props.onImageRemove && props.onImageRemove();
    } else if (currentUser?.userType === 'C') {
      await axiosHelper.removeBannerClient(currentUser?.id)?.then((response) => {
        console.log(response);
        setCurrentUser(response);
        uploadedImage.current = '';
      });

      props.onImageRemove && props.onImageRemove();
    }
  };

  const AddButton = () => {
    return (
      <button className={`${styles.addButton} bg-blue`} onClick={handleImageAdd}>
        <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light" />
      </button>
    );
  };

  const AddArea = () => {
    return (
      <div className="w-100 h-100">
        <FileUpload onImageAdd={handleImageAdd} uuidPresigned={handleUuidPresigned} />
      </div>
    );
  };

  const RemoveButton = () => {
    return (
      <button className={`${styles.removeButton} bg-danger`} onClick={() => handleImageRemove()}>
        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
      </button>
    );
  };

  return (
    <div
      className={`${styles.profileHeaderWrapper} container px-0`}
      style={{
        // minHeight: `${props.minHeight ?? '25rem'}`,
        // backgroundImage: `${gradientString} url(${(imagePath)})`,
        backgroundColor: `${colorString}`,
      }}
    >
      <div
        className={` ${styles.profileHeaderImageContainer}  container d-flex flex-row  justify-content-center align-items-center position-relative px-0 `}
      >
        {uploadedImage.current && <img src={uploadedImage.current} alt="" />}
        {!uploadedImage.current && props.editable && <AddArea />}
        {uploadedImage.current && props.editable && <RemoveButton />}
      </div>
    </div>
  );
};

export const ProfileHeaderContest: React.FC<IProfileHeader> = (props) => {
  const [imagePath, setImagePath] = useState(props.bgImage);
  const s3BasePath = 'http://s3-eu-west-1.amazonaws.com:80/';
  const bucketName = 'test-upload-for-desall';
  const [fullAttachment, setFullAttachment] = useState<Attachment>();
  let gradientString = '';
  let colorString = 'transparent';
  if (props.bgColor) {
    let c = hexToRGB(props.bgColor);
    colorString = `rgba(${c[0]},${c[1]},${c[2]}, 1)`;
    gradientString = `linear-gradient(rgba(${c[0]},${c[1]},${c[2]}, ${props.alpha ?? 0}), rgba(${c[0]},${c[1]},${
      c[2]
    }, ${props.alpha ?? 0})),`;
  }

  const image: Attachment = {
    webPath: '',
    size: 0,
    mimeType: '',
    originalFileName: '',
    s3Path: '',
  };

  const handleImageAdd = (e: any) => {
    if (props.onImageAdd) {
      console.log('aggiunto banner');
      console.log(e);
      image.mimeType = e.type;
      image.originalFileName = e.name;
      image.size = e.size;
      console.log(image);
      setFullAttachment(image);

      return props.onImageAdd(image.webPath);
    }
  };

  const handleAwsPath = (e: any) => {
    console.log('aws: ' + e);
    image.s3Path = s3BasePath + bucketName + '/' + e;
    console.log(image);
    image.webPath = '/' + bucketName + '/' + e;

    setTimeout(() => {
      setImagePath(image.webPath);
      if (props.awsPath) return props.awsPath(fullAttachment);
    }, 2000);
  };

  // useEffect(() => {
  //   if (imagePath) {
  //     console.log(imagePath);
  //   }
  // }, [imagePath]);

  const handleImageRemove = () => {
    if (props.onImageRemove) {
      setImagePath('');
      return props.onImageRemove();
    } else return false;
  };

  const AddButton = () => {
    return (
      <button className={`${styles.addButton} bg-blue`} onClick={handleImageAdd}>
        <FontAwesomeIcon icon={faPlus as IconProp} className=" text color-light" />
      </button>
    );
  };

  const AddArea = () => {
    return <div className="w-100 h-100">{/* <FileUpload onImageAdd={handleImageAdd} uuidPresined={h} /> */}</div>;
  };

  const RemoveButton = () => {
    return (
      <button className={`${styles.removeButton} bg-blue`} onClick={() => handleImageRemove()}>
        <FontAwesomeIcon icon={faXmark as IconProp} className=" text color-light" />
      </button>
    );
  };

  return (
    <div
      className={`${styles.profileHeaderWrapper} container overflow-hidden d-flex flex-row justify-content-center align-items-stretch  position-relative px-0`}
      style={{
        minHeight: `${props.minHeight ?? '25rem'}`,
        // backgroundImage: `${gradientString} url(${(imagePath)})`,
        backgroundImage: `${gradientString} url(${imagePath})`,
        backgroundColor: `${colorString}`,
      }}
    >
      <div className="container d-flex flex-row justify-content-center align-items-stretch position-relative px-0 ">
        {/* {!imagePath && props.editable && <AddArea />} */}
        {/* {imagePath && props.editable && <RemoveButton />} */}
      </div>
    </div>
  );
};

// export default ProfileHeader;
