import React, { useEffect, useState } from 'react';
//Header Data

//Footer Data

//Styles
import styles from './Profile.module.scss';
import { useStoreState } from 'pullstate';
import DslStore, {
  setContestGallery,
  setLayoutPortfolio,
  setPortfolioDesign,
  setPortfolioPageNumber,
} from '../../store/DslStore';
import {
  getAllCoCreationContests,
  getClientContests,
  getCommunityUser,
  getCurrentUser,
  getLayoutPortfolio,
  getPortfolioDesigns,
  getPortfolioPageNumber,
} from '../../store/Selectors';
import { choseUserName, designUploadDateString, numberVotes, startDateString } from '../../helpers/utils';
import {
  PortfolioNavigation,
  PaginationSwitch,
} from '../../components/Profile/PortfolioNavigation/PortfolioNavigation';
import { CoCreationContest, Contest, ContestStatus, UserDesigns } from '../../global';

import DesignCard from '../../components/Profile/ProfileDesign/DesignCard/DesignCard';
import DesignRow from '../../components/Profile/ProfileDesign/DesignRow/DesignRow';
import DesignDefaultImg from '../../assets/Design.png';
import DesignNamingDefaultImg from '../../assets/Naming.png';

import { NoContent } from '../../components/Reusable/NoContent';
import ContestCard from '../../components/Contest/ContestCard/ContestCard';
import { Button, Modal } from 'react-bootstrap';

// const mockGalleryImages = [
//   'https://source.unsplash.com/_HqHX3LBN18/800x680',
//   'https://source.unsplash.com/OzqieLcs464/800x680',
//   'https://source.unsplash.com/RFP4D5hGTB0/800x680',
//   'https://source.unsplash.com/rvftn4hX7AY/800x680',
//   'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
// ];

// const mockGalleryImagesHalf = [
//   'https://source.unsplash.com/OzqieLcs464/800x680',
//   'https://source.unsplash.com/rvftn4hX7AY/800x680',
//   'https://source.unsplash.com/YnLJ3rM4VtI/800x680',
// ];

interface ProfileProps {
  breadcrumbs: (breadcrumbs: string) => void;
}

const Portfolio: React.FC<ProfileProps> = (props) => {
  const layoutPortfolio = useStoreState(DslStore, getLayoutPortfolio);
  const pageNumber = useStoreState(DslStore, getPortfolioPageNumber);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const portfolioDesigns = useStoreState(DslStore, getPortfolioDesigns);
  const clientContests = useStoreState(DslStore, getClientContests);
  const cocreationcontests = useStoreState(DslStore, getAllCoCreationContests);
  const [popup, setPopup] = useState(false);
  const [popupLink, setPopupLink] = useState('');
  const date = new Date().toISOString();

  const [visibleDesign, setVisibleDesign] = useState<UserDesigns[]>([]);
  const [visibleContests, setVisibleContests] = useState<Contest[]>([]);
  const [key, setKey] = useState(0);

  // useEffect(() => {
  //   console.log(communityUser);
  // },[])

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs(`${communityUser?.userType === 'D' ? 'Portfolio' : 'Contests'}`);
    console.log(clientContests);
  }, []);

  const arraysfill = () => {
    let chunkSize = 20;
    let startchunk = (pageNumber - 1) * chunkSize;
    let tempArray: UserDesigns[] = [];
    let rightArray: UserDesigns[] = [];
    console.log(portfolioDesigns);

    if (currentUser?.id !== communityUser?.id ) {
      portfolioDesigns &&
      portfolioDesigns.map((item) => {
        console.log(item.isPublished);
        if (item.isPublished) {
          rightArray.push(item);
        }
      });
    }

    if (currentUser?.id === communityUser?.id || currentUser?.userType === 'G') {
      portfolioDesigns &&
        portfolioDesigns.map((item) => {
          console.log(item);
          rightArray.push(item);
        });
    }
    // console.log(communityUser?.id);
    console.log(rightArray);
    if (rightArray && rightArray.length > 0) {
      // console.log('ue dio bestia');
      let chunk: UserDesigns[] = [];
      let correctArray: UserDesigns[] = [];

      for (let i = 0; i < rightArray.length; i += chunkSize) {
        chunk = rightArray.slice(startchunk, startchunk + chunkSize);
        // console.log('chunk');
        // console.log(chunk);
      }
      for (let i = 0; i < chunkSize; i++) {
        // console.log(chunk[i]);
        // console.log(chunk[i] === undefined);
        if (chunk[i] !== undefined) {
          tempArray.push(chunk[i]);
        } else {
          break;
        }
      }
    }
    // console.log("array1:  ");
    console.log(tempArray);
    setVisibleDesign(tempArray);
  };

  const arrayContestsFill = () => {
    let chunkSize = 20;
    let startChunk = (pageNumber - 1) * chunkSize;
    let tempArray: Contest[] = [];

    if (clientContests && clientContests.length > 0) {
      let chunk: Contest[] = clientContests.slice(startChunk, startChunk + chunkSize);
      for (let i = 0; i < clientContests.length; i += chunkSize) {
        chunk = clientContests.slice(startChunk, startChunk + chunkSize);
      }
      for (let i = 0; i < chunkSize; i++) {
        if (chunk[i] !== undefined) {
          tempArray.push(chunk[i]);
        } else {
          break;
        }
      }
    }
    setVisibleContests(tempArray);
  };

  useEffect(() => {
    console.log('visible desisngs');
    console.log(visibleDesign);
  }, [visibleDesign]);

  useEffect(() => {
    setKey(key + 1);
    communityUser?.userType === 'D' && arraysfill();
    communityUser?.userType === 'C' && arrayContestsFill();
    // setPortfolioDesign([]);

    // setContestGallery([]);
  }, [communityUser]);

  useEffect(() => {
    arraysfill();
    // console.log(portfolioDesigns);
    // setContestGallery(portfolioDesigns);
  }, [portfolioDesigns]);

  useEffect(() => {
    // console.log(pageNumber);

    // console.log(visibleDesign);
    // fillContests(pageNumber -1);
    arraysfill();
  }, [pageNumber]);

  useEffect(() => {
    // contestUserGallery();
    arraysfill();
  }, []);

  const statusText = (contest: Contest) => {
    // console.log(date);
    // console.log(endDate);

    // console.log(date > endDate);
    if (contest.contestType !== 'Co-creation') {
      if (contest.isClosed && contest.endVotingDate) {
        return `closed: ${startDateString(contest.endVotingDate)}`;
      } else if (contest.isClosed && contest.endDate) {
        return `closed: ${startDateString(contest.endDate)}`;
      } else if (date < contest.startDate) {
        return 'coming soon';
      } else if (date < contest.endDate) {
        return `ends:  ${startDateString(contest.endDate)}`;
      } else if (contest.endVotingDate && date < contest.endVotingDate) {
        return `community vote`;
        // console.log(endVotingDate);
        // console.log(date > endVotingDate);
      } else if (contest.endVotingDate && date > contest.endVotingDate && !contest.isClosed) {
        return `client vote`;
      } else if (date > contest.endDate && !contest.isClosed) {
        return `client vote`;
      }
    } else {
      const contestAttached: CoCreationContest[] = [];
      let stat: ContestStatus = 'ongoing';
      cocreationcontests.map((item: CoCreationContest) => {
        if (item.mainContest.id === contest.id) {
          contestAttached.push(item);
        }
      });

      contestAttached.map((item: CoCreationContest, index: number) => {
        // console.log(item);
        // console.log(item);
        // console.log(stat);
        // console.log(contestAttached.length);
        if (stat !== 'closed' && index < contestAttached.length)
          if (item.isClosed) {
            stat = 'closed';
          } else if (date < item.startUploadDate) {
            stat = 'coming-soon';
          } else if (date < item.endUploadDate) {
            stat = 'ongoing';
          } else if (item.endVotingDate && date < item.endVotingDate) {
            stat = 'community-vote';
          } else if (item.endVotingDate && date > item.endVotingDate && !item.isClosed) {
            stat = 'client-vote';
          } else if (date > item.endVotingDate && !item.isClosed) {
            stat = 'client-vote';
          }
      });
      return stat;
    }
    return `standby`;
  };

  const checkStatus = (contest: Contest) => {
    // console.log(date);
    // console.log(endDate);
    if (contest.contestType !== 'Co-creation') {
      // console.log(date > endDate);
      if (contest.isClosed) {
        return 'closed';
      } else if (date < contest.startDate) {
        return 'coming-soon';
      } else if (date < contest.endDate) {
        return 'ongoing';
      } else if (contest.endVotingDate && date < contest.endVotingDate) {
        return 'community-vote';
        // console.log(endVotingDate);
        // console.log(date > endVotingDate);
      } else if (contest.endVotingDate && date > contest.endVotingDate && !contest.isClosed) {
        return 'client-vote';
      } else if (date > contest.endDate && !contest.isClosed) {
        return 'client-vote';
      }
      return 'standby';
    } else {
      const contestAttached: CoCreationContest[] = [];
      let stat: ContestStatus = 'ongoing';
      cocreationcontests.map((item: CoCreationContest) => {
        if (item.mainContest.id === contest.id) {
          contestAttached.push(item);
        }
      });

      contestAttached.map((item: CoCreationContest, index: number) => {
        // console.log(item);
        // console.log(item);
        // console.log(stat);
        // console.log(contestAttached.length);
        if (stat !== 'closed' && index < contestAttached.length)
          if (item.isClosed) {
            stat = 'closed';
          } else if (date < item.startUploadDate) {
            stat = 'coming-soon';
          } else if (date < item.endUploadDate) {
            stat = 'ongoing';
          } else if (item.endVotingDate && date < item.endVotingDate) {
            stat = 'community-vote';
          } else if (item.endVotingDate && date > item.endVotingDate && !item.isClosed) {
            stat = 'client-vote';
          } else if (date > item.endVotingDate && !item.isClosed) {
            stat = 'client-vote';
          }
      });
      return stat;
    }
  };

  const handleClick = (e: any) => {
    let url: string = e.split('/')[2];
    let linkto = e.split('/')[3];
    if (url.includes('bit.ly')) {
      // console.log(url);
      // setPopupLink(e);
      // setPopup(!popup);
      // console.log(linkto);
      //redirect hidden menu
    } else {
      setPopupLink(e);
      setPopup(!popup);
    }
  };

  return (
    <div className="bg-light-gray" key={key}>
      <main className={`${styles.profileWrapper} container pt-5  mb-5 px-sm-0    `}>
        <Modal show={popup} onHide={() => setPopup(!popup)} className={`d-flex flex-column justify-content-center`}>
          <div className="d-flex flex-column justify-content-center text-center">
            <Modal.Header className="d-flex justify-content-center">
              <h3>
                <b> You are leaving desall.com </b>
              </h3>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
              <h5>You will be redirected to the Artsana platform innovation.artsana.com</h5>
            </Modal.Body>
          </div>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button className="btn-red " onClick={() => setPopup(!popup)}>
              Close
            </Button>
            <a href={popupLink} target="_self">
              <Button className="btn-success" onClick={() => setPopup(!popup)}>
                Continue
              </Button>
            </a>
          </Modal.Footer>
        </Modal>
        <div className={` row `}>
          {/* Projecst ============================================================================= */}
          <PortfolioNavigation onChangeLayout={setLayoutPortfolio} />
          {communityUser?.userType === 'D' && (
            <div className={`row mt-2  px-md-4 px-lg-2 ps-lg-2`} style={{ minHeight: '100px' }}>
              {visibleDesign.length > 0 ? (
                layoutPortfolio === 1 ? (
                  visibleDesign.map((aDesign: UserDesigns, index: number) =>
                    // console.log(aDesign),
                    !aDesign.isTemporary
                      ? (console.log(aDesign),
                        !aDesign.isCensored && aDesign.isPublished && aDesign.contest.name && (
                          <div className={`col-12   col-md-6 col-lg-4 col-xl-3 py-3  `}>
                            <DesignCard
                              key={index}
                              userId={communityUser?.id}
                              name={aDesign.name}
                              uploadDate={designUploadDateString(aDesign.uploadDate)}
                              contest={aDesign.contest}
                              awarded={aDesign.hasAwards}
                              idDesign={aDesign.id}
                              image={
                                aDesign.contest.contestType.name !== 'Naming'
                                  ? aDesign.image1Attachment
                                    ? aDesign.image1Attachment.s3Path
                                    : DesignDefaultImg
                                  : DesignNamingDefaultImg
                              }
                              votes={aDesign.votesCount}
                              draft={aDesign.isTemporary}
                              hidden={aDesign.isHidden}
                              mainContest={aDesign.contest.mainContest && aDesign.contest.mainContest.id}
                            ></DesignCard>
                          </div>
                        ))
                      : !aDesign.isCensored &&
                        aDesign.isPublished &&
                        aDesign.contest.name &&
                        currentUser?.id === communityUser?.id && (
                          <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-3 ">
                            <DesignCard
                              key={index}
                              userId={communityUser?.id}
                              name={aDesign.name}
                              uploadDate={designUploadDateString(aDesign.uploadDate)}
                              contest={aDesign.contest}
                              awarded={aDesign.hasAwards}
                              idDesign={aDesign.id}
                              image={
                                aDesign.contest.contestType.name !== 'Naming'
                                  ? aDesign.image1Attachment
                                    ? aDesign.image1Attachment.s3Path
                                    : DesignDefaultImg
                                  : DesignNamingDefaultImg
                              }
                              votes={aDesign.votesCount}
                              draft={aDesign.isTemporary}
                              hidden={aDesign.isHidden}
                              mainContest={aDesign.contest.mainContest && aDesign.contest.mainContest.id}
                            ></DesignCard>
                          </div>
                        )
                  )
                ) : (
                  ''
                )
              ) : (
                <NoContent />
              )}

              <div className="row mt-3 ">
                <div className="">
                  {layoutPortfolio === 2 ? (
                    visibleDesign.length > 0 ? (
                      visibleDesign.map((aDesign: UserDesigns, index: number) =>
                        !aDesign.isTemporary
                          ? // (console.log(aDesign),
                            !aDesign.isCensored &&
                            aDesign.isPublished &&
                            aDesign.contest.name && (
                              <DesignRow
                                key={index}
                                isPrivateContest={aDesign.contest ? aDesign.contest.isPrivate : false}
                                contest={aDesign.contest}
                                uploadDate={aDesign.uploadDate}
                                votes={aDesign.votesCount}
                                draft={aDesign.isTemporary}
                                galleryImages={[
                                  aDesign.image1Attachment ? aDesign.image1Attachment.s3Path : '',
                                  aDesign.image2Attachment ? aDesign.image2Attachment.s3Path : '',
                                  aDesign.image3Attachment ? aDesign.image3Attachment.s3Path : '',
                                  aDesign.image4Attachment ? aDesign.image4Attachment.s3Path : '',
                                  aDesign.image5Attachment ? aDesign.image5Attachment.s3Path : '',
                                ]}
                                image={
                                  aDesign.contest.contestType.name !== 'Naming'
                                    ? aDesign.image1Attachment
                                      ? aDesign.image1Attachment.s3Path
                                      : ''
                                    : DesignNamingDefaultImg
                                }
                                designName={aDesign.name}
                                userId={communityUser?.id}
                                idDesign={aDesign.id}
                                hidden={aDesign.isHidden}
                                idClient={aDesign.contest.client.id}
                              ></DesignRow>
                            )
                          : !aDesign.isCensored &&
                            aDesign.isPublished &&
                            aDesign.contest.name &&
                            currentUser?.id === communityUser?.id && (
                              <DesignRow
                                key={index}
                                isPrivateContest={aDesign.contest ? aDesign.contest.isPrivate : false}
                                contest={aDesign.contest}
                                uploadDate={aDesign.uploadDate}
                                votes={aDesign.votesCount}
                                draft={aDesign.isTemporary}
                                galleryImages={[
                                  aDesign.image1Attachment ? aDesign.image1Attachment.s3Path : '',
                                  aDesign.image2Attachment ? aDesign.image2Attachment.s3Path : '',
                                  aDesign.image3Attachment ? aDesign.image3Attachment.s3Path : '',
                                  aDesign.image4Attachment ? aDesign.image4Attachment.s3Path : '',
                                  aDesign.image5Attachment ? aDesign.image5Attachment.s3Path : '',
                                ]}
                                image={
                                  aDesign.contest.contestType.name !== 'Naming'
                                    ? aDesign.image1Attachment
                                      ? aDesign.image1Attachment.s3Path
                                      : ''
                                    : DesignNamingDefaultImg
                                }
                                designName={aDesign.name}
                                userId={communityUser?.id}
                                idDesign={aDesign.id}
                                hidden={aDesign.isHidden}
                                idClient={aDesign.contest.client.id}
                              ></DesignRow>
                            )
                      )
                    ) : (
                      <NoContent />
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          )}

          {communityUser?.userType === 'C' && (
            <div className={`row mt-2  px-md-4 px-lg-0`} style={{ minHeight: '100px' }}>
              {visibleContests.map((item, index) => (
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 py-3">
                  <ContestCard
                    key={index}
                    // liked={isLiked(aContest.id)}
                    contestId={item.id}
                    startDate={item.startDate}
                    endVotingDate={item.endVotingDate}
                    image={item?.image}
                    title={item?.name}
                    statusText={statusText(item)}
                    entries={numberVotes(item?.entries)}
                    category={item.category}
                    endDate={startDateString(item?.endDate)}
                    name={choseUserName(item)}
                    award={item.prices}
                    linkToAward={item.linkToAward}
                    linkToUrl={item.linkToUrl}
                    private={item.isPrivate}
                    accesscode={item.accessCode}
                    global={!item.accessCode}
                    nda={item.nda}
                    status={checkStatus(item)}
                    userId={item.userId}
                    contestType={item.contestType}
                    nameforurl={item.nameForUrl}
                    click={handleClick}
                  />
                </div>
              ))}
            </div>
          )}

          {/* <ProjectCard
            title="Contest title"
            contestTitle="Furnishing Creative
            Lighting Award"
            votes={21}
            category="Tipology - Category"
            startDate="05 Mar 21"
            name="Pablo Diego José
            Francisco de Paula"
          ></ProjectCard>
          <ProjectCard
            title="Nia - A very very very
            long design title"
            contestTitle="Contest title"
            votes={14}
            category="Tipology - Category"
            startDate="13 Apr 01"
            name="Mina Rossetti"
          ></ProjectCard>
          <ProjectCard
            image="https://source.unsplash.com/Ry9WBo3qmoc/800x680"
            title="Contest title"
            contestTitle="Contest title"
            votes={123}
            category="Tipology - Category"
            startDate="28 Nov 19"
            name="Joseph Mattermost"
          ></ProjectCard> */}

          <div className="row ">
            <div className="col-12  d-flex justify-content-sm-start justify-content-md-end">
              {visibleDesign.length > 0 ? <PaginationSwitch onChangePage={setPortfolioPageNumber} /> : ''}
            </div>
          </div>
        </div>

        {/* <div className="row mt-5">
          <div className="col-12">
            <ProjectRow
              image="https://source.unsplash.com/_HqHX3LBN18/800x680"
              title="Contest title"
              contestTitle="Contest title"
              votes={56}
              category="Tipology - Category"
              startDate="02 Giu 22"
              name="Karen White"
              galleryImages={mockGalleryImages}
            ></ProjectRow>
            <ProjectRow
              title="Nia - A very very very
            long design title"
              contestTitle="Contest title"
              votes={14}
              category="Tipology - Category"
              startDate="13 Apr 01"
              name="Mina Rossetti"
              galleryImages={mockGalleryImagesHalf}
            ></ProjectRow>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default Portfolio;
