import React, { useState } from 'react';
import { useStoreState } from 'pullstate';
//Header Data
import headerLogo from '../assets/desall_logo.png';
import styles from './chooseUsername.module.scss';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
//Assets

//Components

import AppFooter from '../components/AppFooter/AppFooter';

import DslStore, { setCommunityUser, setCurrentUser, setUserLogged } from '../store/DslStore';
import { getFooterMenu, getHeaderMenu, getSocialUser } from '../store/Selectors';

import AppHeader from '../components/AppHeader/AppHeader';
import { useForm } from 'react-hook-form';
import { CreateFacebookUser, CreateTwitterUser, SignUpSocialForm } from '../global';
import { Link, useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import axiosHelper from '../helpers/axiosHelper';

const ChooseUsername: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const socialUser = useStoreState(DslStore, getSocialUser);

  const [email, setEmail] = useState(socialUser.user.email ? socialUser.user.email : '');
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [progress, setProgress] = useState(0);
  const { register, handleSubmit } = useForm<SignUpSocialForm>();
  const redirect = useNavigate();

  const onSubmit = async (data: SignUpSocialForm) => {
    setEmailError(false);
    setUsernameError(false);
    console.log(data);
    console.log(socialUser.user.email);
    if (data.agreement) {
      setProgress(1);
      console.log('agreement');
      if (socialUser.social === 'F') {
        console.log('facebook');
        //facebook
        if (socialUser.user.email && socialUser.user.firstname && socialUser.user.lastname) {
          const cfu: CreateFacebookUser = {
            uid: socialUser.user.uid,
            email: socialUser.user.email,
            firstname: socialUser.user.firstname,
            lastname: socialUser.user.lastname,
            newsletter: data.receiveNewsletter,
            username: data.username,
          };
          await axiosHelper
            .createFacebookUser(cfu)
            .then((response) => {
              console.log(response);
              axiosHelper.setAuthToken(response.dslToken, true);

              setCurrentUser(response);
              setCommunityUser(response);
              axiosHelper.saveUserProfile(response);
              setUserLogged(true);
              redirect(`/community/${response.nameForURL}/profile-editable`);
            })
            .catch((error) => {
              console.log(error);
              if (error.response.status === 409) {
                setUsernameError(true);
              }
              setProgress(0);
            });
        }
      } else if (socialUser.social === 'T') {
        //twitter
        const ctu: CreateTwitterUser = {
          uid: socialUser.user.uid,
          email: email,
          username: data.username,
          newsletter: data.receiveNewsletter,
        };
        await axiosHelper
          .createTwitterUser(ctu)
          .then((response) => {
            console.log(response);
            setCurrentUser(response);
            // setCommunityUser(response);
            axiosHelper.setAuthToken(response.dslToken, true);

            axiosHelper.saveUserProfile(response);
            setUserLogged(true);
            setTimeout(() => {
              redirect(`/community/${response.nameForURL}/profile-editable`);
            }, 1500);
          })
          .catch((error) => {
            console.log(error.response.status);
            if (error.response.status === 400) {
              setEmailError(true);
            }
            if (error.response.status === 409) {
              setUsernameError(true);
            }
            setProgress(0);
          });
      } else if (socialUser.social === 'G') {
        console.log('google');
        //facebook
        if (socialUser.user.email && socialUser.user.firstname && socialUser.user.lastname) {
          const cfu: CreateFacebookUser = {
            uid: socialUser.user.uid,
            email: socialUser.user.email,
            firstname: socialUser.user.firstname,
            lastname: socialUser.user.lastname,
            newsletter: data.receiveNewsletter,
            username: data.username,
          };
          await axiosHelper
            .createGoogleUser(cfu)
            .then((response) => {
              console.log(response);
              axiosHelper.setAuthToken(response.dslToken, true);

              setCurrentUser(response);
              setCommunityUser(response);
              axiosHelper.saveUserProfile(response);
              setUserLogged(true);
              redirect(`/community/${response.nameForURL}/profile-editable`);
            })
            .catch((error) => {
              console.log(error);
              if (error.response.status === 409) {
                setUsernameError(true);
              }
              setProgress(0);
            });
        }
      } else if (socialUser.social === 'L') {
        //twitter
        const ctu: CreateTwitterUser = {
          uid: socialUser.user.uid,
          email: email,
          username: data.username,
          newsletter: data.receiveNewsletter,
        };
        await axiosHelper
          .createLinkedinUser(ctu)
          .then((response) => {
            console.log(response);
            setCurrentUser(response);
            // setCommunityUser(response);
            axiosHelper.setAuthToken(response.dslToken, true);

            axiosHelper.saveUserProfile(response);
            setUserLogged(true);
            setTimeout(() => {
              redirect(`/community/${response.nameForURL}/profile-editable`);
            }, 1500);
          })
          .catch((error) => {
            console.log(error.response.status);
            if (error.response.status === 400) {
              setEmailError(true);
            }
            if (error.response.status === 409) {
              setUsernameError(true);
            }
            setProgress(0);
          });
      }
    }
  };

  return (
    <>
      <AppHeader links={headerMenu} bgColor="#7997c5" logoImg={headerLogo} pageTitle="Choose username" />
      <main className="container-fluid py-5 bg-light-gray">
        <div className="container ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className=" col-12 col-lg-5 py-5">
              <h1 className="bold-title">Choose username</h1>
              <span className="text-16">Please select your Desall username</span>
            </div>
            <div className=" col-12 d-flex flex-row align-items-center  py-5">
              <div className="col-2  "></div>
              <div className="col-1 text-start">
                <span className="text-16"> Username</span>
              </div>
              <div className="col-1  "></div>
              <div className="col-8 ">
                <input type="text" className="" placeholder="username" {...register('username', { required: true })} />
              </div>
            </div>
            {usernameError && (
              <div className="col-12 d-flex flex-row">
                <div className="col-4"></div>
                <div className="col-8">
                  <span className="color-danger">Please change username</span>
                </div>
              </div>
            )}
            <div className=" col-12 d-flex flex-row align-items-center  py-5">
              <div className="col-2  "></div>
              <div className="col-1 text-start">
                <span className="text-16"> Email</span>
              </div>
              <div className="col-1  "></div>
              <div className="col-8 ">
                <input
                  type="text"
                  className=""
                  value={email}
                  placeholder={email ? email : 'email'}
                  // disabled={socialUser.user.email ? true : false}
                  style={{ border: '0px ' }}
                  {...register('email', {
                    required: true,
                    onChange: (e) => (socialUser.user.email ? null : setEmail(e.target.value)),
                  })}
                />
              </div>
            </div>
            {emailError && (
              <div className="col-12 d-flex flex-row">
                <div className="col-4"></div>
                <div className="col-8">
                  <span className="color-danger">Please change email</span>
                </div>
              </div>
            )}
            <div className="col-12 d-flex flex-row  pt-7 pb-5">
              <div className="col-4 d-flex flex-row align-items-center"></div>
              <div className="col-8 d-flex flex-row align-items-center">
                <input type="checkbox" className=" me-4" {...register('agreement', { required: true })} />
                <span className="text-16">
                  I'm over 18 and agree to the <Link to="/Rules/Privacy-policy"> Privacy Policy</Link> &{' '}
                  <Link to={`/Rules/User-agreement`}> User Agreement</Link>
                </span>
              </div>
            </div>
            <div className="col-12 d-flex flex-row  py-5">
              <div className="col-4"></div>
              <div className="col-8 d-flex flex-row align-items-center">
                <input type="checkbox" className=" me-4" {...register('receiveNewsletter', { required: false })} />
                <span className="text-16">
                  I want to receive the newsletter and agree to the Privacy policy Newsletter
                </span>
              </div>
            </div>
            <div className=" col-12 d-flex flex-row pt-3 pb-7">
              <div className=" col-9"></div>
              <div className=" col-3">
                {progress === 0 && (
                  <button
                    className="btn-success full rounded
                  "
                    type="submit"
                  >
                    complete signup
                  </button>
                )}

                {progress !== 0 && (
                  <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                    <TailSpin
                      height="15"
                      width="15"
                      color="#7997c5"
                      wrapperStyle={{}}
                      wrapperClass="me-2"
                      visible={true}
                      ariaLabel="tail-spin-loading"
                    />
                    &nbsp;
                    <span>Uploading</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </>
  );
};

export default ChooseUsername;
