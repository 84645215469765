import React, { useEffect, useState } from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import AppFooter from '../components/AppFooter/AppFooter';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

//Header Data
import headerimg from '../assets/header_placeholder_transparent.png';
import headerLogo from '../assets/desall_logo.png';

//Footer Data
import footerImg from '../assets/footer_placeholder_transparent.png';
import { useStoreState } from 'pullstate';
import DslStore, {
  fetchUsers,
  setCommunity,
  setCommunityCopy,
  setCustomUsers,
  setLastUsers,
  setStrapiCommunity,
  setTopUsers,
  setTopUsersOfTheWeek,
} from '../store/DslStore';
import {
  getLayoutCommunity,
  getCommunityPageNumber,
  getUsers,
  getTopOfAllTimes,
  getTopOfTheWeek,
  getFooterMenu,
  getHeaderMenu,
  getCurrentUser,
  getStrapiCommunity,
  getLastUsers,
  getUsersCopy,
} from '../store/Selectors';
import { CommunityStrapiComponent, User } from '../global';


import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import QueryCommunity from '../components/Query/QueryCommunity';

const Community: React.FC = () => {
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const communitycopy = useStoreState(DslStore, getUsersCopy);
  
  const redirect = useNavigate();

  
  // const arraysfill = () => {
  //   let chunkSize = 20;
  //   let startchunk = (pageNumber - 1) * chunkSize;
  //   let tempArray: User[] = [];
  //   const chunk: User[] = allUsers.slice(startchunk, startchunk + chunkSize);

  //   for (let i = 0; i < allUsers.length; i += chunkSize) {
  //     const chunk: User[] = allUsers.slice(startchunk, startchunk + chunkSize);
  //     // console.log('chunk');
  //     // console.log(chunk);
  //   }
  //   for (let i = 0; i < chunkSize; i++) {
  //     if (chunk[i] != undefined) {
  //       tempArray.push(chunk[i]);
  //     } else {
  //       break;
  //     }
  //   }
  //   // console.log('array1:  ');
  //   // console.log(tempArray);
  //   setVisibleUsers(tempArray);
  // };

  // const arraysfillAllTimeTopMembers = () => {
  //   let chunkSize = 20;
  //   let startchunk = (pageNumber - 1) * chunkSize;
  //   let tempArray: User[] = [];
  //   const chunk: User[] = allTimeTopMembers.slice(startchunk, startchunk + chunkSize);

  //   for (let i = 0; i < allTimeTopMembers.length; i += chunkSize) {
  //     const chunk: User[] = allTimeTopMembers.slice(startchunk, startchunk + chunkSize);
  //     // console.log('chunk');
  //     // console.log(chunk);
  //   }
  //   for (let i = 0; i < chunkSize; i++) {
  //     if (chunk[i] != undefined) {
  //       tempArray.push(chunk[i]);
  //     } else {
  //       break;
  //     }
  //   }
  //   // console.log('array1:  ');
  //   // console.log(tempArray);
  //   setVisibleAllTimeTopUsers(tempArray);
  // };

  // const arraysfillLastMembers = () => {
  //   let chunkSize = 20;
  //   let startchunk = (pageNumber - 1) * chunkSize;
  //   let tempArray: User[] = [];
  //   const chunk: User[] = lastUsers.slice(startchunk, startchunk + chunkSize);

  //   for (let i = 0; i < lastUsers.length; i += chunkSize) {
  //     const chunk: User[] = lastUsers.slice(startchunk, startchunk + chunkSize);
  //     // console.log('chunk');
  //     // console.log(chunk);
  //   }
  //   for (let i = 0; i < chunkSize; i++) {
  //     if (chunk[i] != undefined) {
  //       tempArray.push(chunk[i]);
  //     } else {
  //       break;
  //     }
  //   }
  //   // console.log('array1:  ');
  //   // console.log(tempArray);
  //   setVisibleLastUsers(tempArray);
  // };

  // useEffect(() => {
  //   // console.log('sono dentro community');
  //   axiosHelper.fetchStrapiCommunity().then((response) => {
  //     // console.log(response.data.attributes.DynamicCommunity);
  //     setStrapiCommunity(response.data.attributes.DynamicCommunity);
  //   });
  // }, []);

  // useEffect(() => {
  //   console.log('strapiCommunity');
  //   console.log(strapiCommunity);
  //   strapiCommunity.map(async (item: CommunityStrapiComponent, index: number) => {
  //     console.log(item.__component);
  //     if (item.__component === 'sections.top-5-of-the-week') {
  //       await axiosHelper.retrieveTopOfTheWeek(4).then((response) => {
  //         setTopUsersOfTheWeek(response);
  //       });
  //     }
  //     if (item.__component === 'sections.top-10-of-the-week') {
  //       await axiosHelper.retrieveTopOfTheWeek(8).then((response) => {
  //         setTopUsersOfTheWeek(response);
  //       });
  //     }
  //     if (item.__component === 'sections.last-5-members') {
  //       await axiosHelper.retrieveLastMembers(4).then((response) => {
  //         setLastUsers(response);
  //       });
  //     }
  //     if (item.__component === 'sections.last-10-members') {
  //       await axiosHelper.retrieveLastMembers(8).then((response) => {
  //         setLastUsers(response);
  //       });
  //     }
  //     if (item.__component === 'sections.top-5-all-times') {
  //       await axiosHelper.retrieveTopOfAllTimes(4).then((response) => {
  //         setTopUsers(response);
  //       });
  //     }
  //     if (item.__component === 'sections.top-10-all-times') {
  //       await axiosHelper.retrieveTopOfAllTimes(8).then((response) => {
  //         setTopUsers(response);
  //       });
  //     }
  //     // if (item.__component === 'sections.top-10-all-times') {
  //     //   await axiosHelper.retrieveTopOfAllTimes(10).then((response) => {
  //     //     setTopUsers(response);
  //     //   });
  //     // }
  //     if (item.__component === 'sections.5-custom-users') {
  //       await axiosHelper
  //         .retrieveCustom4Users(item.username1, item.username2, item.username3, item.username4)
  //         .then((response) => {
  //           setCustomUsers(response);
  //         });
  //     }
  //   });
  // }, [strapiCommunity]);

  // useEffect(() => {
  //   setVisibleAllTimeTopUsers(allTimeTopMembers);
  //   setVisibleUsers(allUsers);
  //   arraysfill();
  //   arraysfillAllTimeTopMembers();
  //   arraysfillLastMembers();
  // }, [allUsers, allTimeTopMembers, lastUsers]);

  const goToCommunitySearch = async () => {
    if (communitycopy.length > 0) {
      setCommunity(communitycopy);
      redirect('/community/community-search');
    } else {
      console.log('redirecta');
      redirect('/community/community-search');
      // await axiosHelper.getUsers().then((response) => {
      // });
    }
  };

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Community"
        breadcrumbs="Home/Community"
      />
      {/* <PageNavigation bgColor="#E3E3E3" links={topNavPages} /> */}
      <main className="container mb-5   ">
        {/* <div className="col-12">
            <div className="row px-3 mt-5 mb-3">
              <h2 className="fw-bold color-dark mb-3">
                Last members &nbsp;
                <span className="text-s">
                  <small>
                    <span onClick={() => goToCommunitySearch()} style={{ cursor: 'pointer' }} className="color-blue">
                      (see all)
                    </span>
                  </small>
                </span>
              </h2>
            </div>
          </div> */}

        <div className="row mt-4">
          <QueryCommunity />
          {/* <div>
            <>
              {strapiCommunity.map(async (item: CommunityStrapiComponent, index: number) => {
                console.log(item.__component);
                if (item.__component === 'sections.top-5-of-the-week') {
                  return React.createElement(Top4Week, null);
                }
                if (item.__component === 'sections.top-10-of-the-week') {
                  return React.createElement(Top4Week, null);
                }
                if (item.__component === 'sections.last-5-members') {
                  return React.createElement(LastMembers, null);
                }
                if (item.__component === 'sections.last-10-members') {
                  return React.createElement(LastMembers, null);
                }
                if (item.__component === 'sections.top-5-of-all-times') {
                  return React.createElement(TopAllTime, null);
                }
                if (item.__component === 'sections.top-10-of-all-times') {
                  return React.createElement(TopAllTime, null);
                }
                // if (item.__component === 'sections.top-10-all-times') {
                //   await axiosHelper.retrieveTopOfAllTimes(10).then((response) => {
                //     setTopUsers(response);
                //   });
                // }
                if (item.__component === 'sections.4-custom-users') {
                  console.log('ciao');
                }
              })}
            </>
          </div> */}
        </div>

        <div className="row d-flex flex-row justify-content-center">
          <div className="col-12 col-md-4 px-4 ">
            <div className="btn-blue rounded full text-center" onClick={() => goToCommunitySearch()}>
              <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} className="color-light me-3" /> advanced search
            </div>
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default Community;
