import { CreativeBigImg, CreativesBigImgProps, ElementComponent } from '../../../global';
import { pathStrapiImage } from '../../../helpers/utils';
import { FourElementSectionProps } from '../../../global';
import HorizontalSection from '../../HorizontalSection/HorizontalSection';
import { Link, useNavigate } from 'react-router-dom';

function TwoElementComponent(props: FourElementSectionProps) {
  let navigate = useNavigate();
  return (
    <HorizontalSection bgColor={props.bgcolor ? `${props.bgcolor}` : '#ededed'}>
      <section className="container py-7">
        <div className="row">
          {props.title || props.subtitle || props.text ? (
            <div className="col-12 text-center pb-7 pt-3">
              <h3 className={`bold-title color-${props.titlecolor} pb-3`}>{props.title}</h3>
              <h4 className={`sub-title color-${props.subtitlecolor} pb-5`}>{props.subtitle}</h4>
              <p className={`text-16 mx-4 color-${props.textcolor}`}>{props.text}</p>
            </div>
          ) : null}
        </div>
        <div className="row">
          {props.elementcomponent.map((item: ElementComponent) => (
            <div className="col-12 col-md-6 text-center d-flex flex-column align-items-center justify-content-center ">
              {item.image?.data && (
                <div className=" w-100 p-2">
                  <img
                    className="img-fluid w-100  "
                    alt="Placeholder"
                    src={pathStrapiImage(item.image.data.attributes.url)}
                  />
                </div>
              )}
              {item.title && (
                <div
                  className={` d-flex px-3 w-100 align-items-center mt-4 ${
                    item.titlealign ? `justify-content-${item.titlealign}` : `justify-content-center`
                  }`}
                >
                  <span className={`bold-title   color-${item.titleCol} `}>{item.title}</span>
                </div>
              )}
              {item.bigtext && (
                <div
                  className={` mt-5 mb-3 d-flex align-items-center ${
                    item.bigtextalign ? `justify-content-${item.bigtextalign}` : `justify-content-center`
                  } px-3  w-100 `}
                  style={{ height: '35px' }}
                >
                  <span className={`sub-title color-${item.bigtextColor} `} style={{ height: '35px' }}>
                    {item.bigtext}
                  </span>
                </div>
              )}
              {item.boldtext && (
                <div
                  className={` mt-5 mb-3 d-flex align-items-center  ${
                    item.boldtextalign ? `justify-content-${item.boldtextalign}` : `justify-content-center`
                  } w-100 px-3`}
                  style={{ height: '35px' }}
                >
                  <span className={`fw-bold text-16 color-${item.boldtextColor} `}>{item.boldtext}</span>
                </div>
              )}
              {item.text && (
                <span
                  className={`text-16 mt-4 ${
                    item.textalign ? `text-${item.textalign}` : `text-center`
                  } mb-3 mx-3 color-${item.txtColor}`}
                >
                  {item.text}
                </span>
              )}
              {item.buttontext && (
                <div className="col-12 col-md-4 col-lg-6 align-self-center mb-7 ">
                  <a href={item.link && item.link} target={`${item.target ? item.target : '_self'}`}>
                    <button className={`btn-${item.buttcolor} full rounded mt-2`}>{item.buttontext}</button>
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
    </HorizontalSection>
  );
}

export default TwoElementComponent;
