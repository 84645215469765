import { Attribute, ImageSliderProps } from '../../../global';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/scss/pagination';
import { pathStrapiImage } from '../../../helpers/utils';

SwiperCore.use([Pagination]);

function CommunitySlider(props: ImageSliderProps) {
  return (
    <section className="container  py-5  ">
      <Swiper
        className="d-flex w-100 h-100 "
        modules={[Autoplay, Pagination]}
        style={{borderRadius:'8px'}}
        // slidesPerView={2}
        // centeredSlides={true}
        // freeMode={true}
        loop={true}
        // roundLengths={true}
        pagination={{ enabled: true, type: 'bullets' }}
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true,
        }}
      >
        <div className={'swiper-wrapper '}>
          {props.images.map((item: Attribute, index: number) => (
            <SwiperSlide key={index}>
              <div className=" d-flex  flex-row align-items-center justify-content-center " style={{ height: '400px' }}>
                <img
                  src={pathStrapiImage(item.attributes.url)}
                  className="h-100 w-100"
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
      <div className=".swiper-pagination"></div>
    </section>
  );
}

export default CommunitySlider;
