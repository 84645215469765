import React, { useEffect, useRef, useState } from 'react';
import { format } from 'fecha';
import styles from './MiniTimeLine.module.scss';
import { Transition } from 'react-transition-group';

import { ITimeLine } from './timelineInterfaces';
import TLHLP from './timeline.helper';
import { registrationDateString, setTimelines } from '../../helpers/utils';
import MemoProgressBar from './MemoProgressBar';
import { useStoreState } from 'pullstate';
import DslStore, { fetchCoCreationContest } from '../../store/DslStore';
import { getAllCoCreationContests, getCurrentContest } from '../../store/Selectors';
import { CoCreationContest } from '../../global';

const MiniTimeLine: React.FC<ITimeLine> = (props) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const cocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const endDate = useRef('');
  const contestEndDate = useRef('');

  const [leftHours, setLeftHours] = useState(0);
  const [leftMinutes, setLeftMinutes] = useState(0);
  const [leftSeconds, setLeftSeconds] = useState(0);
  const [totalUpload, setTotalUpload] = useState(0);
  const [passedUpload, setPassedUpload] = useState(0);
  const [totalCommunity, setTotalCommunity] = useState(0);
  const [passedCommunity, setPassedCommunity] = useState(0);
  const [totalClient, setTotalClient] = useState(0);
  const [passedClient, setPassedClient] = useState(0);
  const [uploadActive, setUploadActive] = useState(false);
  const [communityActive, setCommunityActive] = useState(false);
  const [clientActive, setClientActive] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const date = new Date().getTime();

  const uploadProgress: number = (passedUpload / totalUpload) * 100;
  const communityProgress: number | undefined = (passedCommunity / totalCommunity) * 100;
  const clientProgress: number = (passedClient / totalClient) * 100;

  // useEffect(() => {
  //   console.log(totalClient);
  //   console.log(passedClient);
  // }, [totalClient, passedClient]);

  useEffect(() => {
    if (contest) {
      setContestEndDate();
    }
  }, [props.contest, props.cocreationcontest]);

  const setContestEndDate = () => {
    // console.log(props.contest.contestType);
    let sons: CoCreationContest[] = [];
    if (contest) {
      if (props.contest.contestType === 'Co-creation') {
        console.log(cocreationContests);
        props.cocreationcontest.map((item) => {
          if (item.mainContest.id === contest.id) {
            sons.push(item);
          }
        });
        sons.map((item) => {
          console.log(item);
          if (date > new Date(item.startUploadDate).getTime() && date < new Date(item.endUploadDate).getTime()) {
            // console.log('upload phase');
            setTotalUpload(
              Math.trunc((new Date(item.endUploadDate).getTime() - new Date(item.startUploadDate).getTime()) / 86400000)
            );
            setPassedUpload(Math.trunc((date - new Date(item.startUploadDate).getTime()) / 86400000));
            if (item.endVotingDate) {
              setCommunityActive(false);
            }
            setUploadActive(true);
            setClientActive(false);
            setTotalClient(100);
            setPassedClient(10);
            calculateTimer(item.endUploadDate);
            return;
            // console.log(new Date(item.endUploadDate).getTime());
          } else {
            if (item.endVotingDate) {
              if (date > new Date(item.endUploadDate).getTime() && date < new Date(item.endVotingDate).getTime()) {
                calculateTimer(item.endVotingDate);
                setCommunityActive(true);
                setUploadActive(false);
                setTotalUpload(100);
                setPassedUpload(100);
                setTotalCommunity(
                  Math.trunc(
                    (new Date(item.endVotingDate).getTime() - new Date(item.endUploadDate).getTime()) / 86400000
                  )
                );
                setPassedCommunity(Math.trunc((date - new Date(item.endUploadDate).getTime()) / 86400000));
                setTotalClient(100);
                setPassedClient(0);
              }
            } else {
              if (item.hypotheticalEndDate) {
                if (
                  date > new Date(item.endUploadDate).getTime() &&
                  date < new Date(item.hypotheticalEndDate).getTime()
                ) {
                  setClientActive(true);
                  setTotalClient(
                    Math.trunc(
                      (new Date(item.hypotheticalEndDate).getTime() - new Date(item.endUploadDate).getTime()) / 86400000
                    )
                  );
                  setPassedClient(Math.trunc((date - new Date(item.endUploadDate).getTime()) / 86400000));
                  calculateTimer(item.hypotheticalEndDate);
                } else {
                  if (
                    date > new Date(item.endUploadDate).getTime() &&
                    date > new Date(item.hypotheticalEndDate).getTime()
                  ) {
                    setIsClosed(true);
                  }
                }
              } else {
              }
            }
            console.log(date);
          }
        });
      } else {
        if (date > new Date(props.contest.startDate).getTime() && date < new Date(props.contest.endDate).getTime()) {
          // console.log('upload contest normale');
          setUploadActive(true);
          setClientActive(false);
          setCommunityActive(false);
          calculateTimer(props.contest.endDate);
          if (props.contest.endVotingDate) {
            setCommunityActive(true);
          }
          setTotalUpload(
            Math.trunc(
              (new Date(props.contest.endDate).getTime() - new Date(props.contest.startDate).getTime()) / 86400000
            )
          );
          setPassedUpload(Math.trunc((date - new Date(props.contest.startDate).getTime()) / 86400000));
        }
        if (contest.endVotingDate) {
          setCommunityActive(true);
          if (
            date > new Date(props.contest.endDate).getTime() &&
            date < new Date(props.contest.endVotingDate).getTime()
          ) {
            // console.log('community vote contest normale');
            calculateTimer(props.contest.endVotingDate);
            setClientActive(false);
            setUploadActive(false);
            setTotalUpload(100);
            setPassedUpload(100);
            setTotalCommunity(
              Math.trunc(
                (new Date(props.contest.endVotingDate).getTime() - new Date(props.contest.endDate).getTime()) / 86400000
              )
            );
            setPassedCommunity(Math.trunc((date - new Date(props.contest.endDate).getTime()) / 86400000));
          } else if (date > new Date(props.contest.endVotingDate).getTime()) {
            // console.log('client vote contest normale');
            if (props.contest.hypotheticalEndDate) {
              calculateTimer(props.contest.hypotheticalEndDate);
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);
              setCommunityActive(false);
              setTotalCommunity(100);
              setPassedCommunity(100);
              setClientActive(true);
              setTotalClient(
                Math.trunc(
                  (new Date(props.contest.hypotheticalEndDate).getTime() -
                    new Date(props.contest.endVotingDate).getTime()) /
                    86400000
                )
              );
              setPassedClient(Math.trunc((date - new Date(props.contest.endVotingDate).getTime()) / 86400000));
            } else if (props.contest.isClosed) {
              setIsClosed(true);
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);
              setCommunityActive(false);
              setTotalCommunity(100);
              setPassedCommunity(100);
              setClientActive(false);
              setTotalClient(100);
              setPassedClient(100);
            }
          }
        } else {
          if (props.contest.hypotheticalEndDate) {
            if (
              date > new Date(props.contest.endDate).getTime() &&
              date < new Date(props.contest.hypotheticalEndDate).getTime()
            ) {
              // console.log('client vote contest normale');
              calculateTimer(props.contest.hypotheticalEndDate);
              setClientActive(true);
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);
              setCommunityActive(false);
              setTotalClient(
                Math.trunc(
                  (new Date(props.contest.hypotheticalEndDate).getTime() - new Date(props.contest.endDate).getTime()) /
                    86400000
                )
              );
              setPassedClient(Math.trunc((date - new Date(props.contest.endDate).getTime()) / 86400000));
            }
          } else {
            if (props.contest.isClosed) {
              setIsClosed(true);
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);
              setCommunityActive(false);
              setTotalCommunity(100);
              setPassedCommunity(100);
              setClientActive(false);
              setTotalClient(100);
              setPassedClient(100);
            }
          }
        }
      }
    }
  };

  const getPhaseName = () => {
    if (
      TLHLP.isUploadActive(props.progress) &&
      !TLHLP.isCommunityactive(props.progress) &&
      !TLHLP.isClientActive(props.progress)
    ) {
      return 'Upload Phase';
    }
    if (TLHLP.isCommunityactive(props.progress) && !TLHLP.isClientActive(props.progress)) {
      return 'Vote Phase';
    }
    if (TLHLP.isClientActive(props.progress)) {
      return 'Client Vote Phase';
    }
    if (isClosed) {
      setIsClosed(true);
      return 'Closed';
    }
  };

  useEffect(() => {
    getPhaseName();
    // console.log(props.progress);
  }, []);

  useEffect(() => {
    console.log(totalClient);
    console.log(passedClient);
    console.log(clientProgress);
  }, [totalClient, passedClient]);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('interval');
      // console.log(props.endDate);
      // console.log(endDate);
      getCorrectEndDate();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // console.log(cocreationContests);
    getCorrectEndDate();
    const interval = setInterval(() => {
      // console.log('interval');
      // console.log(props.endDate);
      // console.log(endDate);
      getCorrectEndDate();
    }, 1000);

    return () => clearInterval(interval);
  }, [props.cocreationcontest]);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('interval');
      // console.log(props.endDate);
      // console.log(endDate);
      getCorrectEndDate();
    }, 1000);

    return () => clearInterval(interval);
  }, [props.contest]);

  const getCorrectEndDate = () => {
    // console.log('correctDate');

    const date = new Date().getTime();

    // console.log('GET CORRECT END DATE : ');
    if (props.contest) {
      if (props.contest.contestType === 'Co-creation') {
        let contestAttached: CoCreationContest[] = [];
        // console.log('GET CORRECT END DATE : CO-CREATION');
        props.cocreationcontest.map((item) => {
          if (props.contest.id === item.mainContest.id) {
            contestAttached.push(item);
          }
        });
        // console.log('GET CORRECT END DATE : contestattached fatto' + JSON.stringify(contestAttached));
        contestAttached.map((item) => {
          // console.log(item);

          if (!item.isClosed) {
            if (date > new Date(item.startUploadDate).getTime() && date < new Date(item.endUploadDate).getTime()) {
              // console.log('CO-CREATION UPLOAD');
              contestEndDate.current = `Ends ${registrationDateString(item.endUploadDate)}`;
              calculateTimer(item.endUploadDate);
              // setTimelines(item.startUploadDate, item.endUploadDate, item.endVotingDate, item.hypotheticalEndDate, item);
              return;
            } else if (item.endVotingDate) {
              if (date > new Date(item.endUploadDate).getTime() && date < new Date(item.endVotingDate).getTime()) {
                // console.log('CO-CREATION COMMUNITY');
                contestEndDate.current = `Ends: ${registrationDateString(item.endVotingDate)}`;
                calculateTimer(item.endVotingDate);
              } else if (
                date > new Date(item.endUploadDate).getTime() &&
                date > new Date(item.endVotingDate).getTime()
              ) {
                // console.log('CO-CREATION CLIENT');
                contestEndDate.current = `Ends: ${registrationDateString(item.hypotheticalEndDate)}`;
                calculateTimer(item.hypotheticalEndDate);
              }
            } else if (date > new Date(item.endUploadDate).getTime()) {
              // console.log('CO-CREATION CLIENT VOTE');
              setIsClosed(false);
              contestEndDate.current = `Ends: ${registrationDateString(item.hypotheticalEndDate)}`;
              calculateTimer(item.hypotheticalEndDate);
            }
          } else {
            console.log('CO-CREATION CLOSED');
            setIsClosed(true);
            contestEndDate.current = `Closed: ${registrationDateString(item.hypotheticalEndDate)}`;
          }
        });
      } else {
        // console.log('GET CORRECT END DATE : NORMAL CONTEST');
        if (date < new Date(props.contest.endDate).getTime() && !props.contest.isClosed) {
          // console.log('GET CORRECT END DATE : UPLOAD PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(props.contest.endDate)}`;
          calculateTimer(props.contest.endDate);
          return;
        } else if (
          props.contest.endVotingDate &&
          date < new Date(props.contest.endVotingDate).getTime() &&
          !props.contest.isClosed
        ) {
          // console.log('GET CORRECT END DATE : COMMUNITY VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(props.contest.endVotingDate)}`;
          calculateTimer(props.contest.endVotingDate);
          return;
        } else if (
          (props.contest.endVotingDate &&
            date > new Date(props.contest.endVotingDate).getTime() &&
            !props.contest.isClosed) ||
          (date > new Date(props.contest.endDate).getTime() && !props.contest.isClosed)
        ) {
          // console.log('GET CORRECT END DATE : CLIENT VOTE PHASE');
          contestEndDate.current = `Ends: ${registrationDateString(props.contest.hypotheticalEndDate)}`;
          calculateTimer(props.contest.hypotheticalEndDate);
          return;
        } else if (props.contest.isClosed) {
          // console.log('GET CORRECT END DATE : CLOSED PHASE');
          setIsClosed(true);
          contestEndDate.current = `Closed:  ${registrationDateString(
            props.contest.assignmentDate ? props.contest.assignmentDate : props.contest.endDate
          )}`;
          return;
        }
      }
      // return contest?.endDate;
    }
  };

  const calculateTimer = (endDate?: string) => {
    // console.log('calculate');
    if (endDate) {
      let dateiso = new Date().toISOString();
      let dateHours = Number(endDate.slice(11, 13));
      let nowHours = Number(dateiso.slice(11, 13));
      let dateMins = Number(endDate.slice(14, 16));
      let nowMins = Number(dateiso.slice(14, 16));
      let dateSec = Number(endDate.slice(17, 19));
      let nowSec = Number(dateiso.slice(17, 19));
      // console.log(contest?.endDate);
      // console.log(dateiso);
      // console.log(dateHours);
      // console.log(nowHours);
      // console.log(dateMins);
      // console.log(nowMins);
      // console.log(dateSec);
      // console.log(nowSec);
      // console.log(dateHours );
      // console.log(nowHours );

      if (dateHours - nowHours > 0) {
        if (dateHours > 0) {
          setLeftHours(dateHours - nowHours);
        } else {
          setLeftHours(nowHours);
        }
      } else {
        if (dateHours > 0) {
          setLeftHours(23 + (dateHours - nowHours));
        } else {
          setLeftHours(24 - nowHours);
        }
      }
      // console.log(nowSec);
      if (dateMins > 0) {
        setLeftMinutes(dateMins - nowMins);
      } else {
        setLeftMinutes(59 - nowMins);
      }
      if (dateSec > 0) {
        setLeftSeconds(dateSec - nowSec);
      } else {
        setLeftSeconds(59 - nowSec);
      }
    }
  };

  // const ProgressBar = (props: { perc: number }) => {

  //   useEffect(() => {
  //       console.log('MINI TIME LINE  PROGRESS BAR '+ props.perc);
  //   }, []);

  //   const animationDuration = 800;

  //   let transitions: any = {
  //     entering: {
  //       width: 0,
  //       opacity: 1,
  //     },
  //     entered: {
  //       opacity: 1,
  //       width: props.perc + '%',
  //     },
  //     exiting: {
  //       opacity: 0,
  //       width: 0,
  //     },
  //     exited: {
  //       opacity: 0,
  //       width: 0,
  //     },
  //   };

  //   return (
  //     <Transition in={true} appear={true} timeout={animationDuration}>
  //       {(state) => <div className={`${styles.progressBar}`} style={{ ...transitions[state] }}></div>}
  //     </Transition>
  //   );
  // };

  return (
    <div className={`row mx-0 ${styles.timelineContainer} py-4`}>
      <div className="col-12 text-center pb-1 text-uppercase text-xl">{getPhaseName()}</div>
      {/* <div className="col-12 text-center pb-3">ends: {format(new Date(), 'dd MMM YYYY')}</div> */}
      {/* original */}
      {/* <div className="col-12 text-center pb-3">ends: {registrationDateString(endDate.current)} </div> */}
      {/* modified */}
      <div className="col-12 text-center pb-3"> {contestEndDate.current}</div>
      <div className="col-12">
        <div className={`row ${styles.timelineWrapper} ${isClosed && styles.closed} d-flex`}>
          <div
            className={`col-12 px-0 ${styles.progressWrapper} ${uploadActive && styles.upload} ${
              communityActive && !uploadActive && !clientActive && styles.community
            } ${clientActive && styles.client} ${isClosed && styles.closed}`}
          >
            {uploadActive && <MemoProgressBar perc={uploadProgress} />}
            {communityActive && <MemoProgressBar perc={communityProgress ?? 0} />}
            {clientActive && <MemoProgressBar perc={clientProgress ?? 0} />}
            {isClosed && <MemoProgressBar perc={100} />}
          </div>
        </div>
      </div>
      {/* Timer */}
      <div
        className={`col-12 bg-color-light-gray color-dark d-flex justify-content-center align-items-center text-center py-4`}
      >
        <div className="px-3">
          {/* <div className="text-xl fw-bold">{TLHLP.getLeftDays([props.progress])}</div> */}
          <div className="text-xl fw-bold">
            {isClosed
              ? 0
              : uploadActive
              ? (passedUpload * 100) / totalUpload > 0
                ? TLHLP.getDaysLeft({ total: totalUpload, passed: passedUpload + 1 })
                : TLHLP.getDaysLeft({ total: totalUpload, passed: passedUpload })
              : communityActive && !clientActive && !uploadActive
              ? (passedCommunity * 100) / totalCommunity > 1
                ? TLHLP.getDaysLeft({ total: totalCommunity, passed: passedCommunity + 1 })
                : TLHLP.getDaysLeft({ total: totalCommunity, passed: passedCommunity })
              : clientActive
              ? (passedClient * 100) / totalClient > 1
                ? TLHLP.getDaysLeft({ total: totalClient, passed: passedClient + 1 })
                : TLHLP.getDaysLeft({ total: totalClient, passed: passedClient })
              : 0}
          </div>
          {/* <div className="text-xl fw-bold">{}</div> */}

          <div>days</div>
        </div>
        <div className="d-none d-lg-flex px-3 px-md-0 px-lg-3 ps-5">
          <div className="px-2">
            <div className="text-xl fw-bold">{isClosed ? '00' : leftHours >= 10 ? leftHours : `0${leftHours}`}</div>
            <div>hrs</div>
          </div>
          <div className="separator">:</div>
          <div className="px-2">
            <div className="text-xl fw-bold">
              {isClosed ? '00' : leftMinutes >= 10 ? leftMinutes : `0${leftMinutes}`}
            </div>
            <div>min</div>
          </div>
          <div className="separator">:</div>
          <div className="px-2">
            <div className="text-xl fw-bold">
              {isClosed ? '00' : leftSeconds >= 10 ? leftSeconds : `0${leftSeconds}`}
            </div>
            <div>sec</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniTimeLine;
