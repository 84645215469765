import { useStoreState } from 'pullstate';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FooterData, INavLink } from '../../global';
import DslStore, {
  setContestGallery,
  setContestGalleryCopy,
  setContestInspirations,
  setContestInspirationsCopy,
  setNavLinkActive,
} from '../../store/DslStore';
import {
  getAllMenus,
  getCommunityUser,
  getCurrentContest,
  getCurrentUser,
  getFooterMenuSelected,
  getNavLinkActive,
} from '../../store/Selectors';
import styles from './PageNavigation.module.scss';
import PopUpLogin from '../PopUp/PopUpLogin/PopUpLogin';
import axiosHelper from '../../helpers/axiosHelper';
import { response } from 'express';

interface IPageNavigation {
  links: FooterData[];
  bgColor?: string;
  clientlogged?: boolean;
  closed?: boolean;
  private?: boolean;
  hideClientGallery?: boolean;
  hideInspirationTab?: boolean;
  hideAwardsTab?: boolean;
  ndaAuth?: boolean;
  accesscodeAuth?: boolean;
}

const PageNav = ({ links }: { links: INavLink[] }) => {
  // const activePage = useStoreState(DslStore, getNavLinkActive);
  const activeFooter = useStoreState(DslStore, getFooterMenuSelected);
  const menus = useStoreState(DslStore, getAllMenus);
  const location = useLocation();
  const pathName = location.pathname;
  const [searchedItem, setSearchedItem] = useState<FooterData>({ id: 0, text: '', path: '' });

  function findPage() {
    let pathLength = pathName.split('/').length - 1;
    if (pathLength === 2) {
      let path = '/' + pathName.split('/')[2];
      menus.map((item: any) =>
        item.data.map((item: FooterData) => (item.path === path ? setSearchedItem(item) : null))
      );
    } else if (pathLength === 3) {
      let path = '/' + pathName.split('/')[3];
      menus.map((item: any) =>
        item.data.map((item: FooterData) => (item.path === path ? setSearchedItem(item) : null))
      );
    } else if (pathLength === 4) {
      let path = '/' + pathName.split('/')[4];
      menus.map((item: any) =>
        item.data.map((item: FooterData) => (item.path === path ? setSearchedItem(item) : null))
      );
    }

    // menus.map((item: any) => item.data.map((item: FooterData) => (item.path === path ? setSearchedItem(item) : null)));
  }

  useEffect(() => {
    // console.log(pathName.split('/')[2]);
    // console.log(activePage);
    findPage();

    links.map((navlink) => (navlink.path === pathName ? setNavLinkActive(navlink) : null));
    return () => {};
  });

  // useEffect(() => {
  //   console.log(activePage.path);
  // }, [activePage]);

  //TODO handle active page
  return (
    <>
      {links.map((navLink, index) => (
        // console.log(navLink.path),
        // console.log(searchedItem.path),
        <Link
          onClick={() => setNavLinkActive(navLink)}
          key={'pageLink_' + index}
          className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTab} ${
            navLink.path === searchedItem.path && styles.activeTab
          }`}
          to={activeFooter.path + navLink.path}
        >
          <span className="text-16">{navLink.text}</span>
        </Link>
      ))}
    </>
  );
};

const PageNavSearch = ({ links }: { links: INavLink[] }) => {
  // const activePage = useStoreState(DslStore, getNavLinkActive);
  // const activeFooter = useStoreState(DslStore, getFooterMenuSelected);
  const menus = useStoreState(DslStore, getAllMenus);
  const location = useLocation();
  const pathName = location.pathname;
  const [searchedItem, setSearchedItem] = useState<FooterData>({ id: 0, text: '', path: '' });

  //TODO handle active page
  return (
    <>
      {links.map((navLink, index) => (
        // console.log(navLink.path),
        // console.log(searchedItem.path),
        <Link
          onClick={() => setNavLinkActive(navLink)}
          key={'pageLink_' + index}
          className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabSearch} ${
            navLink.path === pathName && styles.activeTabSearch
          }`}
          to={navLink.path}
        >
          <span className="text-16">{navLink.text}</span>
        </Link>
      ))}
    </>
  );
};

const PageNavBrief = ({
  links,
  client,
  closed,
  hideClientGallery,
  hideAwardsTab,
  hideInspirationTab,
}: {
  links: INavLink[];
  client?: boolean;
  closed?: boolean;
  privatecontest?: boolean;
  hideClientGallery?: boolean;
  hideAwardsTab?: boolean;
  hideInspirationTab?: boolean;
}) => {
  const currentContest = useStoreState(DslStore, getCurrentContest);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const navlinkActive = useStoreState(DslStore, getNavLinkActive);
  const location = useLocation();
  const fullPath = location.pathname;
  const path = '/' + fullPath.split('/')[3];
  const [popup, setPopup] = useState(false);
  const date = new Date().toISOString();
  const [uploadAuth, setUploadAuth] = useState(true);
  const [awardsAuth, setAwardsAuth] = useState(true);
  const [inspirationsTabPermission, setInspirationsTabPermission] = useState(false);
  const [galleryTabPermission, setGalleryTabPermission] = useState(false);

  useEffect(() => {
    console.log(navlinkActive);
  }, [navlinkActive]);

  const activeUpload = () => {
    // console.log(currentContest);
    if (currentUser) {
      if (currentUser.userType === 'C' && currentContest?.userId !== currentUser.id) {
        setUploadAuth(false);
        return false;
      } else {
        if (currentContest?.contestType !== 'Co-creation') {
          if (currentContest?.endDate) {
            if (date.valueOf() < currentContest?.endDate.valueOf()) {
              setUploadAuth(true);
            } else {
              setUploadAuth(false);
            }
          }
        } else {
          return true;
        }
      }
    } else {
      if (currentContest?.contestType !== 'Co-creation') {
        if (currentContest?.endDate) {
          if (date.valueOf() < currentContest?.endDate.valueOf()) {
            setUploadAuth(true);
          } else {
            setUploadAuth(false);
          }
        }
      } else {
        return true;
      }
    }
  };

  const isAwardsUploadTabActive = async () => {
    // if (currentContest?.nda || currentContest?.accessCode) {
    if (currentUser) {
      if (!currentContest?.hideAwardsTab) {
        await axiosHelper
          .getContestAuth(currentContest?.id)
          ?.then((response) => {
            setAwardsAuth(true);
            // console.log(response);
            // console.log('AUTH CE');
            activeUpload();
          })
          .catch((error) => {
            // console.log('AUTH ERROR');
            setAwardsAuth(false);
            setUploadAuth(false);
          });
      } else {
        console.log('no currentuser');
        if (currentContest?.nda || currentContest?.accessCode) {
          setAwardsAuth(false);
        }
        if (currentContest.hideAwardsTab) {
          setAwardsAuth(false);
        }
      }
    }
    // }
  };

  const inspirationTab = async () => {
    await axiosHelper
      .getContestInspirations(currentContest?.id)
      ?.then((response) => {
        setContestInspirations(response.inspirations);
        setContestInspirationsCopy(response.inspirations);
        setInspirationsTabPermission(true);
      })
      .catch((error) => {
        setInspirationsTabPermission(false);
      });
  };

  const galleryTab = async () => {
    await axiosHelper
      .getContestGallery(currentContest?.id)
      ?.then((response) => {
        setContestGallery(response);
        setContestGalleryCopy(response);
        setGalleryTabPermission(true);
      })
      .catch((error) => {
        setGalleryTabPermission(false);
      });
  };

  // useEffect(() => {

  // });

  useEffect(() => {
    inspirationTab();
    galleryTab();
    isAwardsUploadTabActive();
    // console.log('activeUpload : ' + activeUpload());
  }, []);

  useEffect(() => {
    inspirationTab();
    galleryTab();
    isAwardsUploadTabActive();
  }, [currentUser]);

  useEffect(() => {
    isAwardsUploadTabActive();
    inspirationTab();
    galleryTab();
  }, [currentContest]);

  useEffect(() => {
    links.map((navlink) => (navlink.path === fullPath ? setNavLinkActive(navlink) : null));
  }, [fullPath, links]);

  //TODO handle active page
  return (
    <>
      {links.map((navLink, index) =>
        client ? (
          <Link
            onClick={() => setNavLinkActive(navLink)}
            key={'pageLink_' + index}
            className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabBrief} ${
              navLink.path === path && styles.activeTabBrief
            }
            ${
              navLink.text === 'Gallery' &&
              hideClientGallery === true &&
              currentUser?.userType !== 'G' &&
              styles.disabledLinks
            }
          `}
            to={`/contest/${currentContest?.nameForUrl}${navLink.path}`}
          >
            <span className="text-16">{navLink.text}</span>
          </Link>
        ) : navLink.text !== 'Upload' ? (
          <Link
            onClick={() => setNavLinkActive(navLink)}
            key={'pageLink_' + index}
            className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabBrief} ${
              navLink.path === path && styles.activeTabBrief
            }
            ${
              navLink.text === 'Gallery' &&
              (hideClientGallery === true || !galleryTabPermission) &&
              currentUser?.userType !== 'G' &&
              styles.disabledLinks
            }
            ${
              navLink.text === 'Awards' &&
              (hideAwardsTab || !awardsAuth) &&
              currentUser?.userType !== 'G' &&
              styles.disabledLinks
            }
            ${
              navLink.text === 'Inspirations' &&
              (hideInspirationTab === true || !inspirationsTabPermission) &&
              currentUser?.userType !== 'G' &&
              styles.disabledLinks
            }
            ${
              closed &&
              navLink.text === 'Upload' &&
              !uploadAuth &&
              currentUser?.userType !== 'G' &&
              styles.disabledLinks
            }
          `}
            to={`/contest/${currentContest?.nameForUrl}${navLink.path}`}
          >
            <span className="text-16 ">{navLink.text}</span>
          </Link>
        ) : currentUser ? (
          <Link
            onClick={() => setNavLinkActive(navLink)}
            key={'pageLink_' + index}
            className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabBrief} ${
              navLink.path === path && styles.activeTabBrief
            }
            ${
              closed &&
              navLink.text === 'Upload' &&
              !uploadAuth && 
              currentUser?.userType !== 'G' &&
              styles.disabledLinks
            }
            ${navLink.text === 'Upload' && !uploadAuth && currentUser?.userType !== 'G' && styles.disabledLinks}
          `}
            to={`/contest/${currentContest?.nameForUrl}${navLink.path}`}
          >
            <span className="text-16 ">{navLink.text}</span>
          </Link>
        ) : //   : (
        //     <Link
        //       onClick={() => setNavLinkActive(navLink)}
        //       key={'pageLink_' + index}
        //       className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabBrief} ${
        //         navLink.path === path && styles.activeTabBrief
        //       }
        //     ${navLink.text === 'Upload' && styles.disabledLinks}
        //   `}
        //       to={`/contest/${currentContest?.nameForUrl}${navLink.path}`}
        //     >
        //       <span className="text-16 ">{navLink.text}</span>
        //     </Link>
        //   )
        // )
        uploadAuth ? (
          <span
            className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabBrief} ${
              navLink.path === path && styles.activeTabBrief
            }`}
            onClick={() => setPopup(true)}
          >
            <span className="text-16 cursor-pointer"> {navLink.text}</span>
          </span>
        ) : (
          <Link
            onClick={() => setNavLinkActive(navLink)}
            key={'pageLink_' + index}
            className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabBrief} ${
              navLink.path === path && styles.activeTabBrief
            }
       ${navLink.text === 'Upload' && styles.disabledLinks}
     `}
            to={`/contest/${currentContest?.nameForUrl}${navLink.path}`}
          >
            <span className="text-16 ">{navLink.text}</span>
          </Link>
        )
      )}
      {popup && <PopUpLogin close={() => setPopup(false)} popup={popup} login={(e: boolean) => setPopup(e)} />}
    </>
  );
};

const PageNavProfileEditable = ({ links }: { links: INavLink[] }) => {
  const activePage = useStoreState(DslStore, getNavLinkActive);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const location = useLocation();
  const fullPath = location.pathname;
  const path = '/' + fullPath.split('/')[3];

  useEffect(() => {
    console.log(fullPath);
    console.log(path);
    // console.log(fullPath.split('/')[3]);
    console.log(activePage);
    links.map((navlink) => (navlink.path === fullPath ? setNavLinkActive(navlink) : null));
  }, [fullPath, links]);

  //TODO handle active page
  return (
    <>
      {links.map((navLink, index) => (
        <Link
          onClick={() => setNavLinkActive(navLink)}
          key={'pageLink_' + index}
          className={`text color-dark flex-grow-1 flex-shrink-0 text-center ${styles.pageTabProfile} ${
            navLink.path === path && styles.activeTabProfile
          }`}
          to={`/community/${communityUser?.nameForURL}${navLink.path}`}
        >
          <span className="text-16">{navLink.text}</span>
        </Link>
      ))}
    </>
  );
};

const PageNavProfile = ({ links }: { links: INavLink[] }) => {
  const activePage = useStoreState(DslStore, getNavLinkActive);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const location = useLocation();
  const fullPath = location.pathname;
  const path = '/' + fullPath.split('/')[3];

  useEffect(() => {
    console.log(fullPath);
    console.log(path);
    console.log(links);

    // console.log(fullPath.split('/')[3]);
    console.log(activePage);
    links.map((navlink) => (navlink.path === fullPath ? setNavLinkActive(navlink) : null));
  }, [fullPath, links]);

  //TODO handle active page
  return (
    <>
      {links.map((navLink, index) =>
        currentUser?.userType === 'C' && navLink.text !== 'Ambassador' ? (
          <Link
            onClick={() => setNavLinkActive(navLink)}
            key={'pageLink_' + index}
            className={`text color-dark flex-grow-1 flex-shrink-0  text-center ${styles.pageTabProfile} ${
              navLink.path === path && styles.activeTabProfile
            }`}
            to={`/community/${communityUser?.nameForURL}${navLink.path}`}
          >
            <span className="text-16">{navLink.text}</span>
          </Link>
        ) : (
          <Link
            onClick={() => setNavLinkActive(navLink)}
            key={'pageLink_' + index}
            className={`text color-dark flex-grow-1 flex-shrink-0  text-center ${styles.pageTabProfile} ${
              navLink.path === path && styles.activeTabProfile
            }`}
            to={`/community/${communityUser?.nameForURL}${navLink.path}`}
          >
            <span className="text-16">{navLink.text}</span>
          </Link>
        )
      )}
    </>
  );
};

export const PageNavigation: React.FC<IPageNavigation> = (props) => {
  return (
    <div className={`${styles.navigationWrapper} container-fluid px-0 `} style={{ backgroundColor: props.bgColor }}>
      <div className={`container-md`}>
        <div className="row">
          <div className={`col-12 d-flex flex-nowrap ${styles.tabsWrapper}`}>
            {/* {console.log(props.links)} */}
            <PageNav links={props.links} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageNavigationProfileEditable: React.FC<IPageNavigation> = (props) => {
  const navigationRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);

  const callbackFunction = () => {
    const scroll = window.scrollY;
    if (scroll > 170) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  window.addEventListener('scroll', callbackFunction);

  return (
    <div
      className={`${styles.navigationWrapperProfile} container-fluid ${!visible ? '' : styles.fixed}`}
      ref={navigationRef}
      style={{ backgroundColor: props.bgColor }}
    >
      <div className="container-md">
        <div className="row">
          <div className={`col-12 d-flex flex-nowrap ${styles.tabsWrapperProfile}`}>
            <PageNavProfileEditable links={props.links} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageNavigationProfile: React.FC<IPageNavigation> = (props) => {
  const navigationRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);

  const callbackFunction = () => {
    const scroll = window.scrollY;
    if (scroll > 170) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    console.log(props.links);
  }, []);

  window.addEventListener('scroll', callbackFunction);
  return (
    <div
      className={`${styles.navigationWrapperProfile}  container-fluid ${!visible ? '' : styles.fixed}`}
      ref={navigationRef}
      style={{ backgroundColor: props.bgColor }}
    >
      <div className="container-md ">
        <div className="row px-md-2 ps-md-3">
          <div className={`col-12 d-flex flex-nowrap px-lg-0 ${styles.tabsWrapperProfile}`}>
            <PageNavProfile links={props.links} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageNavigationBrief: React.FC<IPageNavigation> = (props) => {
  const navigationRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);

  const callbackFunction = () => {
    const scroll = window.scrollY;
    if (scroll > 170) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  window.addEventListener('scroll', callbackFunction);
  return (
    <div
      className={`${styles.navigationWrapperBrief} container-fluid px-0 ${!visible ? '' : styles.fixed}`}
      ref={navigationRef}
      style={{ backgroundColor: props.bgColor }}
    >
      <div className="container-md">
        <div className="row">
          <div className={`col-12 d-flex flex-nowrap ${styles.tabsWrapperBrief}`}>
            <PageNavBrief
              hideClientGallery={props.hideClientGallery}
              links={props.links}
              client={props.clientlogged}
              // closed={props.closed}
              closed={props.closed}
              privatecontest={props.private}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageNavigationSearch: React.FC<IPageNavigation> = (props) => {
  const navigationRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);

  const callbackFunction = () => {
    const scroll = window.scrollY;
    if (scroll > 170) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  window.addEventListener('scroll', callbackFunction);
  return (
    <div
      className={`${styles.navigationWrapperSearch} container-fluid px-0 ${!visible ? '' : styles.fixed}`}
      ref={navigationRef}
      style={{ backgroundColor: props.bgColor }}
    >
      <div className="container-md">
        <div className="row">
          <div className={`col-12 d-flex flex-nowrap ${styles.tabsWrapperSearch}`}>
            <PageNavSearch
              links={props.links}

              // closed={props.closed}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNavigation;
