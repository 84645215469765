import { useStoreState } from 'pullstate';
import DslStore, { setTopUsersOfTheWeek } from '../../../store/DslStore';
import { getCurrentUser, getTopOfTheWeek } from '../../../store/Selectors';
import { CurstomCommunityProps, User } from '../../../global';
import DesignerSquare from '../../Community/DesignerSquare/DesignerSquare';
import { fullPathLogoCommunity } from '../../../helpers/utils';
import { useEffect } from 'react';
import axiosHelper from '../../../helpers/axiosHelper';
import Spinner from '../../Spinner/spinner';
import Skeleton from '@mui/material/Skeleton';

function Top4Week(props: CurstomCommunityProps) {
  const users = useStoreState(DslStore, getTopOfTheWeek);
  const loggedUser = useStoreState(DslStore, getCurrentUser);

  useEffect(() => {
    if (users.length === 0) {
      fetchUsers();
    }
  }, []);

  const fetchUsers = async () => {
    await axiosHelper.retrieveTopOfTheWeek(8).then((response) => {
      setTopUsersOfTheWeek(response);
    });
  };
  const isLiked = (idUser?: number) => {
    if (idUser) {
      let state: boolean = false;
      loggedUser &&
        loggedUser?.userType !== 'C' &&
        loggedUser.usersILike.findIndex((item) => (item.id === idUser ? (state = true) : null));
      return state;
    }
    return false;
  };

  return (
    <section className="container py-5 ">
      <div className="row">
        <div className="col-12">
          {/* <CommunityNavigation onChangeLayout={setLayoutCommunity} /> */}
          <h2 className="fw-bold color-dark px-3 mb-3">{props.title} </h2>
        </div>
      </div>
      <div className="row mb-5 mt-3 ">
        {users.length > 0 ? (
          users?.map((aUser: User, index: number) =>
            // console.log(aUser),
            props.limit ? (
              index < props.limit ? (
                // <DesignerCard
                //   key={index}
                //   nameForURL={aUser.nameForURL}
                //   user={aUser}
                //   circle
                //   reputation={aUser?.reputationCount}
                //   entries={numberVotes(aUser?.badgeDesignsOut)}
                //   awarded={aUser?.badgeAmountIn}
                //   userType={userTypeString(aUser?.userType)}
                //   role={jobString(aUser?.jobName)}
                //   location={locationString(aUser?.city, aUser?.countryName)}
                //   startDate={aUser?.registrationDate}
                //   name={aUser?.username}
                //   image={fullPathLogoCommunity(aUser?.profilePic)}
                //   liked={isLiked(aUser.id)}
                // ></DesignerCard>
                <div className="col-6 col-md-6  mb-4 col-lg-3 d-flex flex-row align-items-center justify-content-center">
                  <DesignerSquare
                    image={fullPathLogoCommunity(aUser.profilePic)}
                    name={aUser?.username}
                    circle
                    user={aUser}
                    nameForURL={aUser.nameForURL}
                    liked={isLiked(aUser.id)}
                  />
                </div>
              ) : (
                ''
              )
            ) : (
              <div className="col-6 col-md-6  mb-4 col-lg-3 d-flex flex-row align-items-center justify-content-center">
                <DesignerSquare
                  image={fullPathLogoCommunity(aUser.profilePic)}
                  name={aUser?.username}
                  circle
                  user={aUser}
                  nameForURL={aUser.nameForURL}
                  liked={isLiked(aUser.id)}
                />
              </div>
            )
          )
        ) : (
          <>
            <div className=" col-12 d-flex flex-row mt-3 mb-4">
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
              <div className=" col-3 d-flex align-items-center justify-content-center">
                <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
              </div>
            </div>
            {!props.limit && (
              <div className=" col-12 d-flex flex-row">
                <div className=" col-3 d-flex align-items-center justify-content-center">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
                </div>
                <div className=" col-3 d-flex align-items-center justify-content-center">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
                </div>
                <div className=" col-3 d-flex align-items-center justify-content-center">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
                </div>
                <div className=" col-3 d-flex align-items-center justify-content-center">
                  <Skeleton variant="rectangular" style={{ borderRadius: '8px' }} width={270} height={270} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default Top4Week;
