import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from '../../../routes/profile/ProfileEditable.module.scss';

import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';

import { useStoreState } from 'pullstate';
import DslStore, { setCurrentUser, setUploadContestEditProfile } from '../../../store/DslStore';
import { getCurrentUser } from '../../../store/Selectors';

import { Country, Job, UploadContestUserData, User } from '../../../global';

import axiosHelper from '../../../helpers/axiosHelper';
import { DropDownJob } from '../../../components/Forms/DropDown/DropDownJob';
import { DropDownCountry } from '../../../components/Forms/DropDown/DropDownCountry';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';

interface ProfileInfoProps {
  user: (info: boolean) => void;
}

const ProfileInfo: React.FC<ProfileInfoProps> = (props) => {
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [country, setCountry] = useState(currentUser?.country);
  const [countries, setCountries] = useState<Country[]>([]);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [job, setJob] = useState(currentUser?.job);
  const [mobileNumber, setMobileNumber] = useState(currentUser?.mobileNumber);
  const [addressLine1, setAddressLine1] = useState(currentUser?.addressLine1);
  const [city, setCity] = useState(currentUser?.city);
  const [state, setState] = useState(currentUser?.state);
  const [zipCode, setZipCode] = useState(currentUser?.zipCode);
  const [jobtitle, setJobtitle] = useState(currentUser?.jobTitle);
  const [firstSchool, setFirstSchool] = useState(currentUser?.school1);
  const { register, handleSubmit, getValues } = useForm<UploadContestUserData>();
  const [freelanceLicense, setFreelanceLicense] = useState(currentUser?.freelanceLicense);
  const [firstName, setFirstName] = useState(currentUser?.firstName);
  const [lastName, setLastName] = useState(currentUser?.lastName);

  const fillData = (data: UploadContestUserData) => {
    data.countryName = country?.name;
    data.country = country;
    data.freelanceLicense = freelanceLicense;
    if (!data.job) {
      data.job = job;
    }
    if (!data.addressLine1) {
      data.addressLine1 = currentUser?.addressLine1;
    }
    if (!data.firstName) {
      data.firstName = currentUser?.firstName;
    }
    if (!data.lastName) {
      data.lastName = currentUser?.lastName;
    }
    if (!data.jobTitle) {
      data.jobTitle = currentUser?.jobTitle;
    }
    if (!data.city) {
      data.city = currentUser?.city;
    }
    if (!data.mobileNumber) {
      data.mobileNumber = currentUser?.mobileNumber;
    }
    if (!data.school1) {
      data.school1 = currentUser?.school1;
    }

    if (!data.state) {
      data.state = currentUser?.state;
    }
    if (!data.zipCode) {
      data.zipCode = currentUser?.zipCode;
    }
    localStorage.setItem('editProfileData', JSON.stringify(data));

    setUploadContestEditProfile(data);
  };

  const checkUser = (user: User) => {
    if (user) {
      // console.log(userLogged?.username);
      // console.log(userLogged.firstName);
      // console.log(userLogged.lastName);
      // console.log(userLogged.mobileNumber);
      // console.log(userLogged.city);
      // console.log(userLogged.state);
      // console.log(userLogged.zipCode);
      // console.log(userLogged.country);
      // console.log(userLogged.job?.name);
      // console.log(userLogged.jobTitle);
      // console.log(userLogged.school1);
      // console.log(userLogged.freelanceLicense);
      if (
        user.username &&
        user.firstName &&
        user.lastName &&
        user.mobileNumber &&
        user.city &&
        user.state &&
        user.zipCode &&
        user.country &&
        user.job?.name &&
        user.jobTitle &&
        user.school1 &&
        user.freelanceLicense
      ) {
        return true;
      } else {
        console.log('no completo');

        return false;
        // redirect(`/community/${userLogged?.nameForURL}/profile-editable`);
      }
    }
    return false;
  };

  const onSubmit = async (data: UploadContestUserData) => {
    fillData(data);
    console.log(data);
    if (data && currentUser) {
      await axiosHelper.updateUserForUploadContest(data, currentUser.id)?.then((response) => {
        console.log(response);
        setCurrentUser(response);
        props.user && props.user(checkUser(response));
      });
    }
  };

  const handleJobs = (e: Job) => {
    console.log(e);
    setJob(e);
  };

  const handleCountry = (e: Country) => {
    console.log(e);
    setCountry(e);
  };

  const getCountries = async () => {
    let result: Country[] = await axiosHelper.getCountries();
    if (result) {
      // console.log(result);
      setCountries(result);
    }
  };

  const getJobs = async () => {
    let result: Job[] = await axiosHelper.getJobs();
    if (result) {
      // console.log(result);
      setJobs(result);
    }
  };

  function containsOnlyNumbers(str: string) {
    if (str !== '') {
      return /^\d+$/.test(str);
    }
    return true;
  }

  useEffect(() => {
    getCountries();
    getJobs();
  }, []);

  return (
    <div className="bg-light-gray">
      <main className={`${styles.profileWrapper} container   `}>
        <div className="row ">
          {/* Left column  ====================================================== */}
          <div className="col-12 col-md-12 ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row  ">
                <div className="col-12   ">
                  <div className="row mb-7 ">
                    <div className="col-12 col-md-2 text fw-bold"></div>
                    <div className="col-12 col-md-10">
                      <span className="text color-blue">
                        <i>
                          <b>
                            <span className="color-red">*</span> &nbsp; Mandatory fields (required to upload your
                            design)
                          </b>
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5 mt-4">
                    <div className="col-12 col-md-2 text fw-bold">Username</div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        style={{ border: '0px ' }}
                        id="username"
                        disabled={true}
                        placeholder={currentUser?.username}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      First name <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={firstName}
                        {...register('firstName', { onChange: (e) => setFirstName(e.target.value) })}
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Last name <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={lastName}
                        {...register('lastName', { onChange: (e) => setLastName(e.target.value) })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Mobile
                      <br /> number <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={mobileNumber}
                        {...register('mobileNumber', {
                          onChange: (e) =>
                            containsOnlyNumbers(e.target.value) ? setMobileNumber(e.target.value) : null,
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Address <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={addressLine1}
                        {...register('addressLine1', {
                          required: currentUser?.addressLine1 ? false : true,
                          onChange: (e) => setAddressLine1(e.target.value),
                        })}
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      City <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={city}
                        {...register('city', {
                          required: true,
                          onChange: (e) => setCity(e.target.value),
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      State <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={state}
                        {...register('state', { required: true, onChange: (e) => setState(e.target.value) })}
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Zip Code <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={zipCode}
                        {...register('zipCode', { required: true, onChange: (e) => setZipCode(e.target.value) })}
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Who are you <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-4 d-flex flex-row justify-content-start align-items-center ">
                      <DropDownJob options={jobs} onSelect={handleJobs} />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Your current country <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-4 d-flex flex-row justify-content-start align-items-center ">
                      <DropDownCountry options={countries} onSelect={handleCountry} />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      Job title <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={jobtitle}
                        {...register('jobTitle', {
                          required: currentUser?.jobTitle ? false : true,
                          onChange: (e) => setJobtitle(e.target.value),
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      School/ University <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10">
                      <input
                        type="text"
                        value={firstSchool}
                        {...register('school1', {
                          required: currentUser?.school1 ? false : true,
                          onChange: (e) => setFirstSchool(e.taget.value),
                        })}
                      />
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col-12 col-md-2 text fw-bold">
                      License to work as freelance <span className="color-red">*</span>
                    </div>
                    <div className="col-12 col-md-10 d-flex flex-row justify-content-between align-items-center ">
                      <div>
                        <input
                          type="radio"
                          onChange={() => setFreelanceLicense('N')}
                          checked={freelanceLicense === 'N' ? true : false}
                        />
                        <label className="align-self-middle fw-normal ">N/A</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          onChange={() => setFreelanceLicense('Y')}
                          checked={freelanceLicense === 'Y' ? true : false}
                        />
                        <label className="align-self-middle fw-normal ">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          onChange={() => setFreelanceLicense('F')}
                          checked={freelanceLicense === 'F' ? true : false}
                        />
                        <label className="align-self-middle fw-normal ">No</label>
                      </div>
                    </div>
                  </div>

                  <div className="row  d-flex justify-content-center align-items-center mb-7 mt-7">
                    <button className={`btn-success rounded col-md-2 mx-5 ${styles.button}  `}>update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProfileInfo;
