import React from 'react';
import SquaredImage from '../../SquaredImage/SquaredImage';
import Spinner from '../../Spinner/spinner';

import styles from './SquareInfo.module.scss';
import { User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import { setCommunityUser } from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
export interface ICardInfo {
  image?: string;
  circle?: boolean;
  altText?: string;
  name?: string;
  userId?: number;
}

const SquareInfo: React.FC<ICardInfo> = (props) => {
  const redirect = useNavigate();

  const goToDetail = async (userId?: number) => {
    // console.log(user);
    if (userId) {
      let result: User = await axiosHelper.retrieveUserById(userId);
      // console.log(result);
      setCommunityUser(result);
      redirect(`/community/${result.nameForURL}/profile`);
    }
  };

  return (
    <div className={`${styles.cardInfoWrapper}  d-flex flex-column bg-light position-relative`}>
      <div
        className={`${props.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light ${
          props.circle && 'px-5 pt-4'
        }`}
        onClick={() => goToDetail(props.userId)}
        style={{ cursor: 'pointer' }}
      >
        {props.image ? (
          // <img src={props.image} alt={props.altText} className={`${props.circle ?? 'circle'}`} />
          <SquaredImage image={props.image} altText={props.altText} circle={props.circle} />
        ) : (
          <div className={`${styles.squaredImageWrapper}`}>
            <div className={`${styles.imageInner}`}>
              <FontAwesomeIcon icon={faUser as IconProp} className="color-light " />
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.cardInfoContent} bg-light`}>{props.children}</div>
    </div>
  );
};

export default SquareInfo;
