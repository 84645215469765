import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BigCardComponent, SmallCardComponent } from '../../global';
import SwiperCore, { Autoplay } from 'swiper';

import 'swiper/scss';
import 'swiper/css/autoplay';
import styles from './Slider.module.scss';
import { pathStrapiImage } from '../../helpers/utils';
import CardSmall from '../CardSmall/CardSmall';
import CardLarge from '../CardLarge/CardLarge';
import { render } from '@testing-library/react';

interface ISlider {
  timeInterval: number;
  children?: React.ReactNode[];
  slides?: BigCardComponent[];
}

interface ISmallCardSlider {
  timeInterval: number;
  children?: React.ReactNode[];
  slides: SmallCardComponent[];
}
// https://swiperjs.com/react
/**
 * Must have at least two children
 * @param timeInterval auto swipte interval in ms
 * @returns
 */
const Slider: React.FC<ISlider> = (props) => {
  // console.log(props.children);

  SwiperCore.use([Autoplay]);

  return (
    <section className={`container-fluid ${styles.sliderWrapper}`}>
      <div className="row">
        <div className="col-12">
          <Swiper
            className="desall-swiper "
            modules={[Autoplay]}
            slidesPerView={4}
            centeredSlides={true}
            // freeMode={true}
            loop={true}
            // roundLengths={true}

            autoplay={{
              delay: props.timeInterval,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: window.innerWidth / 250,
                spaceBetween: 30,
              },

              775: {
                slidesPerView: window.innerWidth / 320,
                spaceBetween: 30,
              },

              1200: {
                slidesPerView: window.innerWidth / 386,
                spaceBetween: 30,
              },
            }}
            spaceBetween={30}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <div className={'swiper-wrapper  '}>
              {props.slides?.map((item: BigCardComponent, index: number) => (
                <SwiperSlide virtualIndex={index} key={index} className={`${styles.slide}`}>
                  {/* <CardLarge
                    onClick={() => item.link && window.open(item.link, `${item.target ? item.target : '_self'}`)}
                    topText={item.toptext}
                    middleText={item.middletext}
                    bottomText={item.bottomtext}
                    backGround={pathStrapiImage(item.image.data.attributes.url)}
                  ></CardLarge> */}

                  <div
                    // onClick={handleOnClick}
                    className={`${
                      item.toptext || item.middletext || item.bottomtext
                        ? styles.cardWrapper
                        : styles.cardWrapperNoHover
                    }  rounded `}
                  >
                    <img className="" src={pathStrapiImage(item.image.data.attributes.url)} alt="Placeholder" />
                    <div className={`${styles.cardOverlay} d-flex flex-column`}>
                      {item.toptext && (
                        <div
                          className={`color-light sub-title flex-grow-1 d-flex justify-content-center align-items-center`}
                        >
                          {item.toptext}
                        </div>
                      )}
                      {item.middletext && (
                        <div
                          className={`color-light m-5   text-center flex-grow-1 d-flex justify-content-center align-items-center`}
                        >
                          {item.middletext}
                        </div>
                      )}

                      {item.bottomtext && (
                        <div
                          className={`color-light sub-title flex-grow-1 d-flex justify-content-center align-items-center`}
                        >
                          {item.bottomtext}
                        </div>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export const SmallCardSlider: React.FC<ISmallCardSlider> = (props) => {
  // console.log(props.children);

  SwiperCore.use([Autoplay]);
  return (
    <section className={`container-fluid  ${styles.sliderWrapper}`}>
      <div className="row ">
        <div className="col-12">
          <Swiper
            className="desall-swiper"
            modules={[Autoplay]}
            freeMode={true}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: props.timeInterval,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: window.innerWidth / 175,
                spaceBetween: 30,
              },
              775: {
                slidesPerView: window.innerWidth / 285,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: window.innerWidth / 240,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: window.innerWidth / 275,
                spaceBetween: 30,
              },
            }}
            // spaceBetween={30}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {props.slides?.map((item: SmallCardComponent, index: number) => (
              <SwiperSlide key={index}>
                <CardSmall
                  onClick={() => item.link && window.open(item.link, `${item.target ? item.target : '_self'}`)}
                  topText={item.toptext}
                  bottomText={item.bottomtext}
                  backGround={pathStrapiImage(item.image.data.attributes.url)}
                ></CardSmall>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Slider;
