import React, { useEffect, useState } from 'react';
import styles from './Timer.module.scss';
import { useStoreState } from 'pullstate';
import DslStore from '../../../store/DslStore';

import { getAllCoCreationContests, getCurrentContest } from '../../../store/Selectors';

interface ITimer {
  leftDays: number;
  closed?: boolean;
  endDate: string;
}

// https://swiperjs.com/react
/**
 * Must have at least two children
 * @param timeInterval auto swipte interval in ms
 * @returns
 */
const Timer: React.FC<ITimer> = (props) => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const cocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const [endDate, setEndDate] = useState('');

  const [leftHours, setLeftHours] = useState(0);
  const [leftMinutes, setLeftMinutes] = useState(0);
  const [leftSeconds, setLeftSeconds] = useState(0);

  useEffect(() => {
    // console.log(props.leftDays);
    // console.log(props.endDate);
    calculateTimer(props.endDate);
  }, []);

  const getCorrectDate = () => {
    // console.log('correctDate');
    const date = new Date().getTime();
    if (contest) {
      if (contest?.contestType === 'Co-creation') {
        cocreationContests.map((item) => {
          if (contest?.id === item.mainContest.id) {
            if (date < new Date(item.endUploadDate).getTime()) {
              // console.log('ci sono  ' + item.endUploadDate);

              setEndDate(item.endUploadDate);
              calculateTimer(item.endUploadDate);
              return;
            }
            if (item.endVotingDate) {
              if (date > new Date(item.endUploadDate).getTime() && date < new Date(item.endVotingDate).getTime()) {
                setEndDate(item.endVotingDate);
                calculateTimer(item.endVotingDate);
                return;
              } else if (
                date > new Date(item.endVotingDate).getTime() &&
                date < new Date(item.hypotheticalEndDate).getTime()
              ) {
                setEndDate(item.hypotheticalEndDate);
                calculateTimer(item.hypotheticalEndDate);
                return;
              }
            }
            if (date < new Date(item.hypotheticalEndDate).getTime() && date > new Date(item.endUploadDate).getTime()) {
              setEndDate(item.hypotheticalEndDate);
              calculateTimer(item.hypotheticalEndDate);
              return;
            }
          }
        });
      } else {
        if (date < new Date(contest.endDate).getTime()) {
          setEndDate(contest?.endDate);
          calculateTimer(contest?.endDate);
        }
        if (contest.endVotingDate) {
          if (date < new Date(contest.endVotingDate).getTime() && date > new Date(contest.endDate).getTime()) {
            setEndDate(contest?.endVotingDate);
            calculateTimer(contest?.endVotingDate);
          }
          if (
            date > new Date(contest.endVotingDate).getTime() &&
            date < new Date(contest.hypotheticalEndDate).getTime()
          ) {
            setEndDate(contest?.hypotheticalEndDate);
            calculateTimer(contest?.hypotheticalEndDate);
          }
        } else {
          if (date < new Date(contest.hypotheticalEndDate).getTime() && date > new Date(contest.endDate).getTime()) {
            setEndDate(contest?.hypotheticalEndDate);
            calculateTimer(contest?.hypotheticalEndDate);
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // console.log('interval');
  //     // console.log(props.endDate);
  //     // console.log(endDate);
  //     getCorrectDate();
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   // console.log(cocreationContests);
  //   const interval = setInterval(() => {
  //     // console.log('interval');
  //     // console.log(props.endDate);
  //     // console.log(endDate);
  //     getCorrectDate();
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [cocreationContests]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // console.log('interval');
  //     // console.log(props.endDate);
  //     // console.log(endDate);
  //     getCorrectDate();
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [contest]);
  useEffect(() => {
    // console.log(props.leftDays);
    const interval = setInterval(() => {
      calculateTimer(props.endDate);
    }, 1000);
    return () => clearInterval(interval);
  }, [props.endDate]);

  const calculateTimer = (endDate?: string) => {
    if (endDate) {
      let dateiso = new Date().toISOString();
      let dateHours = Number(endDate.slice(11, 13));
      let nowHours = Number(dateiso.slice(11, 13));
      let dateMins = Number(endDate.slice(14, 16));
      let nowMins = Number(dateiso.slice(14, 16));
      let dateSec = Number(endDate.slice(17, 19));
      let nowSec = Number(dateiso.slice(17, 19));
      // console.log(contest?.endDate);
      // console.log(dateiso);
      // console.log(dateHours);
      // console.log(nowHours);
      // console.log(dateMins);
      // console.log(nowMins);
      // console.log(dateSec);
      // console.log(nowSec);
      // console.log(dateHours - nowHours);
      if (dateHours - nowHours > 0) {
        if (dateHours > 0) {
          // console.log(dateHours);
          // console.log(nowHours);
          setLeftHours(dateHours - nowHours);
        } else {
          setLeftHours(nowHours);
        }
      } else {
        if (dateHours > 0) {
          setLeftHours(23 + (dateHours - nowHours));
        } else {
          setLeftHours(24 - nowHours);
        }
      }
      // console.log(nowSec);
      if (dateMins > 0) {
        setLeftMinutes(dateMins - nowMins);
      } else {
        setLeftMinutes(59 - nowMins);
      }
      if (dateSec > 0) {
        setLeftSeconds(dateSec - nowSec);
      } else {
        setLeftSeconds(59 - nowSec);
      }
    }
  };

  return (
    <div
      className={`${styles.mainCountDown} col-3 flex-shrink-1 bg-light-gray-3  color-light fw-bold d-flex align-items-center text-center justify-content-center`}
    >
      <div className="px-3">
        <div className="text-xl fw-bold">{props.leftDays >= 0 ? props.leftDays : 0}</div>
        <div>days</div>
      </div>
      <div className="d-none d-lg-flex px-3 px-md-0 px-lg-3 ps-5">
        <div className="px-2">
          <div className="text-xl fw-bold">{props.closed ? '00' : leftHours >= 10 ? leftHours : `0${leftHours}`}</div>
          <div>hrs</div>
        </div>
        <div className="separator">:</div>
        <div className="px-2">
          <div className="text-xl fw-bold">
            {props.closed ? '00' : leftMinutes >= 10 ? leftMinutes : `0${leftMinutes}`}
          </div>
          <div>min</div>
        </div>
        <div className="separator">:</div>
        <div className="px-2">
          <div className="text-xl fw-bold">
            {props.closed ? '00' : leftSeconds >= 10 ? leftSeconds : `0${leftSeconds}`}
          </div>
          <div>sec</div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
