import React from 'react';
import styles from '../TimeLine.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProgressSection {
  progress: number;
  label: string;
  daysLeft: number;
  active: boolean;
  iconLabel?: string;
  icon?: IconProp;
  closed?: boolean;
  pero?: number;
}

/**
 * Progress label to show above timeline section
 * @param props
 * @returns
 */
const ProgressLabel = (props: { perc: number; label: string; daysLeft?: number }) => {
  const animationDuration = 800;
  let transitions: any = {
    entering: {
      opacity: 1,
      right: '100%',
    },
    entered: {
      opacity: 1,
      right: 100 - props.perc + '%',
    },
    exiting: {
      opacity: 0,
      right: '100%',
    },
    exited: {
      opacity: 0,
      right: '100%',
    },
  };

  return (
    <Transition in={true} appear={true} timeout={animationDuration}>
      {(state) => (
        <div className={`${styles.progressLabel} fw-bold text-end pe-2`} style={{ ...transitions[state] }}>
          <div>{props.label}</div>
          {props.daysLeft  && <div className={`${styles.progressSub}`}>{props.daysLeft} days left</div>}
        </div>
      )}
    </Transition>
  );
};

/**
 * Prograss bar section
 * @param props
 * @returns
 */
const ProgressBar = (props: { perc: number }) => {
  const animationDuration = 800;

  let transitions: any = {
    entering: {
      width: 0,
      opacity: 1,
    },
    entered: {
      opacity: 1,
      width: props.perc + '%',
    },
    exiting: {
      opacity: 0,
      width: 0,
    },
    exited: {
      opacity: 0,
      width: 0,
    },
  };

  return (
    <Transition in={true} appear={true} timeout={animationDuration}>
      {(state) => <div className={`${styles.progressBar}`} style={{ ...transitions[state] }}></div>}
    </Transition>
  );
};

/**
 * Progress icon showed inside timeline section
 * @param props
 * @returns
 */
const ProgressIcon = (props: { icon: IconProp; label?: string }) => {
  return (
    <div className={`${styles.progressIcon} color-light d-flex flex-column justify-content-center align-items-center`}>
      <FontAwesomeIcon icon={props.icon} className="text color-light" />
      {props.label && <div className="fw-bold text-center">{props.label}</div>}
    </div>
  );
};

// https://swiperjs.com/react
/**
 * Must have at least two children
 * @param timeInterval auto swipte interval in ms
 * @returns
 */
const ProgressSection: React.FC<IProgressSection> = (props) => {
  return (
    <>
      <ProgressBar perc={props.progress} />
      {props.progress > -1 && props.progress <= 99 && props.active && (
        <ProgressLabel
          perc={props.closed ? 0 : props.progress}
          label={props.label}
          daysLeft={props.closed ? 0 : props.daysLeft}
        />
      )}

      {props.active && props.label === 'Client Vote' && props.pero === 0 && (
        <ProgressLabel
          perc={props.closed ? 0 : props.progress}
          label={props.label}
          // daysLeft={props.closed ? 0 : props.daysLeft}
        />
      )}

      {props.closed && props.pero === 1 && (
        <ProgressLabel
          perc={props.closed ? 0 : props.progress}
          label={props.label}
          // daysLeft={props.closed ? 0 : props.daysLeft}
        />
      )}

      {props.icon && <ProgressIcon icon={props.icon} label={props.iconLabel} />}
    </>
  );
};

export default ProgressSection;
