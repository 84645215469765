import React, { useEffect, useState } from 'react';
import styles from './ContestsNavigation.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleLeft, faAngleRight, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { DropDownContestCategory, DropDownContestSort } from '../../Forms/DropDown/DropDownContest';
import DslStore, { setContest, setContestPageNumber, setContestSorted } from '../../../store/DslStore';
import { LayoutContestSwitch } from '../../Reusable/LayoutSwitch';
import {
  getAllCoCreationContests,
  getCoCreationContests,
  getContestCategories,
  getContestPageNumber,
  getContests,
  getContestsFiltered,
} from '../../../store/Selectors';
import { useStoreState } from 'pullstate';
import { Contest } from '../../../global';
import resetFilter from '../../../assets/Reset-Filter.svg';

type IProjecNavigation = {
  onChangeLayout?: (val: number) => void;
};

type PaginationSwitchProps = {
  onChangePage?: (val: number) => void;
};

export const PaginationSwitch: React.FC<PaginationSwitchProps> = ({ onChangePage = () => {} }) => {
  const pageNumber = useStoreState(DslStore, getContestPageNumber);
  const contests = useStoreState(DslStore, getContestsFiltered);
  let contestPerPage = 20;

  const pageNumberLimit = Math.ceil(contests.length / contestPerPage);
  const [array, setArray] = useState<Array<number>>([1, 2, 3, 4, 5]);

  useEffect(() => {
    console.log('cambiato numero page');
    // setArray([1, 2, 3, 4, 5]);
    if (pageNumberLimit === 1) {
      setArray([1]);
    }
    if (pageNumberLimit < 5) {
      let numbers: number[] = [];
      for (var i = 1; i < pageNumberLimit + 1; i++) {
        numbers.push(i);
      }
      setArray(numbers);
    } else {
      let numbers: number[] = [];
      if (pageNumber <= 3) {
        for (var i = 1; i < pageNumberLimit + 1; i++) {
          if (i < 6) {
            numbers.push(i);
          }
        }
      }
      setArray(numbers);
      if (pageNumber > 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      } else {
        if (pageNumber > 3 && pageNumber <= pageNumberLimit - 1) {
          setArray([pageNumber - 3, pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1]);
        } else {
          if (pageNumber > 3 && pageNumber === pageNumberLimit) {
            setArray([pageNumber - 4, pageNumber - 3, pageNumber - 2, pageNumber - 1, pageNumber]);
          }
        }
      }
    }
  }, [pageNumberLimit, pageNumber]);

  const handleChange = (value: number) => {
    console.log(value);
    if (value > 0 && value <= pageNumberLimit) {
      onChangePage(value);
      if (pageNumber >= 3 && pageNumber <= pageNumberLimit - 2) {
        setArray([pageNumber - 2, pageNumber - 1, pageNumber, pageNumber + 1, pageNumber + 2]);
      }
    } else {
      console.log('0 o max++');
    }
  };

  return (
    <div className={`${styles.paginationSwitch}  d-flex align-items-center justify-content-center bg-light py-2 px-0 `}>
      <button className="btn-icon" onClick={() => handleChange(pageNumber - 1)}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} className="btn-icon active " />
      </button>

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber - 1)}>
        <strong> &lt; </strong>
      </button> */}

      {array.map((index: number) => (
        <button
          key={index}
          className={`${pageNumber === index ? 'btn-icon active' : 'btn-icon'} px-2`}
          onClick={() => handleChange(index)}
        >
          <small>{index}</small>
        </button>
      ))}

      {/* <button className={'btn-icon active'} onClick={() => handleChange(pageNumber + 1)}>
        <strong> &gt; </strong>
      </button> */}

      <button className="btn-icon" onClick={() => handleChange(pageNumber + 1)}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} className="btn-icon active " />
      </button>

      {/* 
      <button className={"btn-icon active"} 
      //  onClick={() => handleClick(1)}
      > <b> &lt; </b> </button>
       <button className={pageNumber == 1 ? "btn-icon active" : "btn-icon"} 
      //  onClick={() => handleClick(1)}
      >
        1
      </button>
      <button className={pageNumber == 2 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(2)}
      >
        2
      </button>
      <button className={pageNumber == 3 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(3)}
      >
        3
      </button>
      <button className={pageNumber == 4 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(4)}
      >
        4
      </button>
      <button className={pageNumber == 5 ? "btn-icon active" : "btn-icon"} 
      // onClick={() => handleClick(5)}
      >
        5
      </button>  */}

      {/* <button className="btn-icon">
         <FontAwesomeIcon icon={faAngleRight as IconProp} className="text" />
       </button> */}

      {/* <Pagination count={numberOfPages} page={pageNumber} color='primary'  hideNextButton={numberOfPages > 1 ? false : true} hidePrevButton={numberOfPages > 1 ? false : true} size="small" onChange={handleChange} /> */}
    </div>
  );
};

let filterStatus = [
  {
    label: 'All',
    sort: 'all',
    value: '0',
  },
  {
    label: 'Active',
    sort: 'active',
    value: '1',
  },
  {
    label: 'Closed',
    sort: 'closed',
    value: '2',
  },
];

let filterSort = [
  {
    label: 'Name',
    sort: 'name',
    value: '1',
  },
  {
    label: 'Date',
    sort: 'date',
    value: '2',
  },

  {
    label: 'Entries',
    sort: 'entries',
    value: '3',
  },
  {
    label: 'Award',
    sort: 'award',
    value: '4',
  },
];

interface IDropDownItem {
  label: string;
  sort: string;
  value: string;
}

const ContestsNavigation: React.FC<IProjecNavigation> = (props) => {
  const contests = useStoreState(DslStore, getContests);
  const contestSorted = useStoreState(DslStore, getContestsFiltered);
  const cocreationContests = useStoreState(DslStore, getAllCoCreationContests);
  const categories = useStoreState(DslStore, getContestCategories);
  const [reload, setReload] = useState(false);
  const [categoriesFilter, setCategoriesFilter] = useState<IDropDownItem[]>([]);
  const [statusSort, setStatusSort] = useState('');
  const [categorySort, setCategorySort] = useState('');
  const [sortBySort, setSortBySort] = useState('');
  const [descs, setDescs] = useState(false);
  const date = new Date().toISOString();

  useEffect(() => {
    let newItems: IDropDownItem[] = [];
    if (categories) {
      console.log(categories);
      categories.map((item, index: number) => {
        let name = item.name === 'Link to Other' ? 'Partnership' : item.name;

        if (!newItems.includes({ label: `${name}`, sort: `${item.name}`, value: `${index}` })) {
          newItems.push({ label: `${name}`, sort: `${item.name}`, value: `${index}` });
          // setTypologyFilter(newItems);
          // console.log(newItems);
        }
      });
      setCategoriesFilter(newItems);
    }
  }, [categories]);

  const sortContests = () => {
    let sorted: Contest[] = [...contests];
    console.log(statusSort);
    console.log(categorySort);
    console.log(sortBySort);
    console.log(descs);
    if (statusSort) {
      if (statusSort === 'all') {
        setContestSorted(contests);
        setSortBySort('');
        setCategorySort('');
        setStatusSort('');
      }
      if (statusSort === 'active') {
        let contestsActive: Contest[] = [];
        sorted.map((item) =>
          item.contestType !== 'Co-creation'
            ? !item.isClosed && contestsActive.push(item)
            : cocreationContests.map((cocreation) => {
                // console.log(cocreation.mainContest);
                cocreation.mainContest.id === item.id && !cocreation.isClosed && contestsActive.push(item);
              })
        );
        sorted = contestsActive;
      } else {
        let contestClosed: Contest[] = [];
        sorted.map((item) => item.isClosed && contestClosed.push(item));
        sorted = contestClosed;
      }
    }
    if (categorySort) {
      let categoriesContests: Contest[] = [];
      sorted.map((item) => item.category === categorySort && categoriesContests.push(item));
      sorted = categoriesContests;
    }
    if (sortBySort) {
      if (sortBySort === 'name') {
        if (descs) {
          sorted.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? 1 : a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 0
          );
        } else {
          sorted.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
          );
          // setContestInspirations(sorted);
        }
      }
      if (sortBySort === 'date') {
        if (descs) {
          sorted.sort((a, b) => {
            return b.creationDate.localeCompare(a.creationDate);
          });
        } else {
          sorted.sort((a, b) => {
            return a.creationDate.localeCompare(b.creationDate);
          });
        }
      }
      if (sortBySort === 'entries') {
        if (descs) {
          sorted.sort((a, b) => (b.entries > a.entries ? 1 : a.entries > b.entries ? -1 : 0));
        } else {
          sorted.sort((a, b) => (b.entries > a.entries ? -1 : a.entries > b.entries ? 1 : 0));
        }
      }
      if (sortBySort === 'award') {
        if (descs) {
          sorted.sort((a, b) => (b.prices > a.prices ? 1 : a.prices > b.prices ? -1 : 0));
        } else {
          sorted.sort((a, b) => (b.prices > a.prices ? -1 : a.prices > b.prices ? 1 : 0));
        }
      }
    }
    console.log(sorted);
    setContestSorted(sorted);
  };

  useEffect(() => {
    // isLiked();
    sortContests();
  }, [sortBySort, statusSort, categorySort, descs]);

  return (
    <div className="row py-4 mt-4 px-0 ">
      <div
        className="col-12    d-flex flex-row  flex-lg-row justify-content-between mb-4 mb-md-0"
        // className="col-12  col-lg-9 col-xl-9  d-flex flex-row  flex-lg-row justify-content-between mb-4 mb-md-0"
        // style={{ height: '3rem' }}
      >
        <div className="me-0 me-md-4   mb-lg-0 mb-sm-4">
          <DropDownContestCategory
            label="Status"
            reload={reload}
            options={filterStatus}
            onSelect={(option) => option.sort && setStatusSort(option.sort)}
            // thin
          />
        </div>
        <div className="me-0 me-md-4  mb-lg-0 mb-sm-4">
          <DropDownContestCategory
            label="Category"
            reload={reload}
            options={categoriesFilter}
            onSelect={(option) => option.sort && setCategorySort(option.sort)}
            thin
          />
        </div>
        <div className=" me-md-4 mb-lg-0 mb-sm-4  ">
          <DropDownContestSort
            label="Sort by"
            reload={reload}
            options={filterSort}
            onSelect={(option) => option.sort && setSortBySort(option.sort)}
            descs={(descs) => setDescs(descs)}
            thin
          />
        </div>
        <div className=" d-flex flex-row  align-items-center justify-content-center h-100 ">
          <div className=" me-lg-3">
            <div
              // className="btn-blue rounded"
              className=" d-flex  flex-row align-items-center  justify-content-center btn-reset-blue   "
              onClick={() => (
                setReload(!reload),
                setContestSorted(contests),
                setSortBySort(''),
                setCategorySort(''),
                setStatusSort('all')
              )}
              style={{ width: '3rem', height: '3rem', borderRadius: '8px' }}
            >
              <img src={resetFilter} style={{ height: '25px' }} />
            </div>
          </div>
          {/* <div className="btn-blue rounded" onClick={() => (setReload(!reload), setPortfolioDesign(correctDesignCopy))}>
            reset
          </div> */}
          <div
            className={`  col-lg-8 me-lg-2   col-md-3 col-sm-4    mb-lg-0  d-flex  justify-content-sm-start justify-content-xl-center   `}
            style={{ minWidth: '150px' }}
          >
            <div className="pe-4 ">
              <PaginationSwitch onChangePage={setContestPageNumber} />
            </div>
          </div>
          <div className={`col-lg-2  col-md-9 col-sm-8 pe-2   mb-lg-0  d-flex  justify-content-sm-end`}>
            <div className=" d-lg-block ">
              <LayoutContestSwitch onChangeLayout={props.onChangeLayout} />
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className={`col-12  col-md-12  mb-sm-5 mb-lg-0 col-lg-3 col-xl-3  d-flex  justify-content-sm-start justify-content-lg-end  `}
      >
        <div
          className={`  col-lg-9 me-lg-2   col-md-3 col-sm-4    mb-lg-0  d-flex  justify-content-sm-start justify-content-xl-center   `}
        >
          <PaginationSwitch onChangePage={setContestPageNumber} />
        </div>
        <div className={`col-lg-2  col-md-9 col-sm-8   mb-lg-0  d-flex  justify-content-sm-end`}>
          <div className=" d-lg-block ">
            <LayoutContestSwitch onChangeLayout={props.onChangeLayout} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ContestsNavigation;
