import React, { useEffect, useState } from 'react';

//Header Data

import { getCommunityUser, getCurrentUser, getUserAwards } from '../../store/Selectors';
import DslStore from '../../store/DslStore';
import { useStoreState } from 'pullstate';

import styles from './Profile.module.scss';

import { Award, ContestTag } from '../../global';

import { fullPathLogoUser } from '../../helpers/utils';
import { UserAwardRow } from '../../components/Contest/AwardRow/AwardRow';
import DesignDefaultImg from '../../assets/Design.png';
import { NoContent } from '../../components/Reusable/NoContent';
import axiosHelper from '../../helpers/axiosHelper';
import PopUpSendMessage from '../../components/PopUp/PopUpSendMessage/PopUpSendMessage';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';

interface ProfileProps {
  breadcrumbs: (breadcrumbs: string) => void;
  messageBanner: (message: number) => void;
}

const UserAward: React.FC<ProfileProps> = (props) => {
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const userAwards = useStoreState(DslStore, getUserAwards);
  const currentUser = useStoreState(DslStore, getCurrentUser);
  const [liked, setLiked] = useState(false);
  const [popUpLogin, setPopupLogin] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState(false);

  const [awards, setAwards] = useState<Award[]>([]);

  useEffect(() => {
    props.breadcrumbs && props.breadcrumbs('Awards');
    isLiked();
  }, []);

  const isLiked = () => {
    if (currentUser && communityUser) {
      let state: boolean = false;

      currentUser?.userType !== 'C' &&
        currentUser.usersILike.findIndex((item) => (item.id === communityUser.id ? (state = true) : null));
      setLiked(state);
    }
  };

  const userLiked = () => {
    if (communityUser?.id && currentUser) {
      currentUser.id !== communityUser.id &&
        axiosHelper.likeUser(communityUser.id).then((response) => {
          setLiked(true);
          // alert(`${props.user?.username} added to favorites`);
        });
    }
  };

  const userUnliked = () => {
    if (communityUser?.id) {
      axiosHelper.unlikeUser(communityUser.id).then((response) => {
        setLiked(false);
        // alert(`${props.user?.username} removed from favorites`);
      });
    }
  };

  useEffect(() => {
    console.log(userAwards);
    setAwards(userAwards);
  }, [userAwards]);

  return (
    <div className="bg-light-gray">
      <main className={`${styles.profileWrapper} container pt-5 mb-5 px-sm-0 overflow-hidden`}>
        <div className={`row    `}>
          {/* Left column  ====================================================== */}
          <div className="col-12  col-md-3 ">
            {communityUser?.id !== currentUser?.id && (
              <div className="buttons">
                <button
                  className={`${liked ? 'btn-danger' : 'btn-blue'} rounded full  mb-3`}
                  onClick={() => (currentUser ? (liked ? userUnliked() : userLiked()) : setPopupLogin(true))}
                >
                  {liked ? 'Remove from favorites' : 'Add to favorite'}
                </button>
                <button className="btn-blue rounded full mb-3" onClick={() => setPopUpMessage(true)}>
                  Send message
                </button>
                {popUpMessage && communityUser && (
                  <PopUpSendMessage
                    popup={popUpMessage}
                    close={() => setPopUpMessage(false)}
                    message={(message) => (props.messageBanner(message), setPopUpMessage(false))}
                    recipient={communityUser.username}
                  />
                )}

                {popUpLogin && (
                  <PopUpLogin
                    popup={popUpLogin}
                    close={() => setPopupLogin(false)}
                    login={(e: boolean) => setPopupLogin(e)}
                  />
                )}
                <button className="btn-red rounded big full mb-3">Block</button>
              </div>
            )}
            <div className={`tags ${communityUser?.id !== currentUser?.id ? 'mt-5' : ''} d-flex flex-wrap `}>
              {communityUser?.tags &&
                communityUser?.tags.map((item: ContestTag, index: number) => (
                  <>
                    <button className="btn-tag mb-4 ">
                      <span>{item.name.replace(',', '')}</span>
                    </button>
                  </>
                ))}
              {/* <button className="btn-tag mb-4 ">
                <span>Even more looong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
            </div>
          </div>
          <div className="col-12 col-md-9  ps-5 ps-sm-4">
            {awards && awards.length > 0 ? (
              awards?.map(
                (UserAward: Award, index: number) => (
                  console.log(UserAward.design.image1Attachment),
                  (
                    <div className="row mb-4 mx-0">
                      <UserAwardRow
                        key={index}
                        contestAward={UserAward}
                        image={
                          UserAward.design.image1Attachment !== null
                            ? fullPathLogoUser(UserAward.design.image1Attachment.s3Path)
                            : DesignDefaultImg
                        }
                      />
                    </div>
                  )
                )
              )
            ) : (
              <NoContent />
            )}
          </div>
          {/* <div className="row mt-3 px-4"></div> */}
        </div>
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};
export default UserAward;
